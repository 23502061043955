import * as React from 'react';
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { tokens } from "../../theme";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#bcd6d0',
    padding: theme.spacing(0),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    borderRadius: 20,
    height: '98%'

})); 

function GrigToGivawayDetail(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
            <Grid item xs={5} style={{ paddingRight: 10 }}>
                <Item>
                    <Grid item xs={12} textAlign="center" style={{ paddingTop: 10 }}>
                        <Typography style={{ color: colors.while[100], fontSize: 26, fontWeight: 'bold', lineHeight: "4vh" }} >
                            FG (kg)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center" style={{ paddingTop: 5 }}>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                            <Grid item xs={5.9} >
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <Typography style={{ color: colors.while[100], fontSize: 23, fontWeight: 'bold', lineHeight: "8vh" }} >
                                        Target
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <Typography style={{ color: colors.while[100], fontSize: 36, fontWeight: 'bold', lineHeight: "8vh" }} >
                                        {props.target_kg}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={0.2} style={{ borderLeft: "2px dashed #000000", height: '13vh', position: "relative", top: '1.8vw' }} >

                            </Grid>

                            <Grid item xs={5.9}>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <Typography style={{ color: colors.while[100], fontSize: 23, fontWeight: 'bold', lineHeight: "8vh"  }} >
                                        Actual
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <Typography style={{ color: colors.green[100], fontSize: 36, fontWeight: 'bold', lineHeight: "8vh" }} >
                                        {props.actual_kg}
                                    </Typography>   
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Item>
            </Grid>
            <Grid item xs={7}>
                <Item>
                    <Grid item xs={12} textAlign="center" style={{ paddingTop: 10 }}>
                        <Typography style={{ color: colors.while[100], fontSize: 26, fontWeight: 'bold', lineHeight: "4vh"  }} >
                            FG (bag)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center" style={{ paddingTop: 5 }}>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                            <Grid item xs={3.9} >
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <Typography style={{ color: colors.while[100], fontSize: 23, fontWeight: 'bold', lineHeight: "8vh"  }} >
                                        Target
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <Typography style={{ color: colors.while[100], fontSize: 36, fontWeight: 'bold', lineHeight: "8vh" }} >
                                        {props.target_pack}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={0.1} style={{ borderLeft: "2px dashed #000000", height: '13vh', position: "relative", top: '1.8vw' }} >

                            </Grid>

                            <Grid item xs={3.9}>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <Typography style={{ color: colors.while[100], fontSize: 23, fontWeight: 'bold', lineHeight: "8vh" }} >
                                        Actual
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <Typography style={{ color: colors.green[100], fontSize: 36, fontWeight: 'bold', lineHeight: "8vh" }} >
                                        {props.actual_pack}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                        <Typography style={{ color: "#636363", fontSize: 16, fontWeight: 'bold', lineHeight: "5vh" }} >
                                            Diff:
                                        </Typography>
                                        <Typography style={{ color: "#636363", fontSize: 20, fontWeight: 'bold', lineHeight: "5vh" }} >
                                            {props.remaining_pack}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item xs={0.1} style={{ borderLeft: "2px dashed #000000", height: '13vh', position: "relative", top: '1.8vw' }} >

                            </Grid>

                            <Grid item xs={3.9}>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <Typography style={{ color: '#8c0e0e', fontSize: 23, fontWeight: 'bold', lineHeight: "8vh" }} >
                                        Reject
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <Typography style={{ color: '#8c0e0e', fontSize: 36, fontWeight: 'bold', lineHeight: "8vh" }} >
                                        {props.reject_pack}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Item>
            </Grid>
        </Grid>
    )
}


export default GrigToGivawayDetail