import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import axios from 'axios';
import Circular from '../components/model/Circular';

var token_url = apiUrl.TOKEN;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingLeft: 150,

  color: theme.palette.text.secondary,
}));

function Giveaway() {
  const today = new Date();
  const hourago = new Date(today.getTime());
  const timestr = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');
  const time = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DDTHH:mm:ss');
  //console.log("time: " + time);
  //const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');
  


  //const [startDate, setStartDate] = useState('2023-01-25T06:00:00');
  //const [endDate, setEndDate] = useState(moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DDTHH:mm:ss'));

  //console.log("date: " + date);
  const [getTodayGiveaway, setGetTodayGiveaway] = useState({
    total_giveaway: "",
    percent_giveaway: "",
    opportunity_cost: "",
  });
  /*const [getSinceGiveaway, setGetSinceGiveaway] = useState({
    total_giveaway: "",
    percent_giveaway: "",
    opportunity_cost: "",
  });*/
  const [noDataToday, setNoDataToday] = useState(false);
  //const [noDataSince, setNoDataSince] = useState(false);
  const [done, setDone] = useState(false);

    // ฟังก์ชั่นเช็คtoken
    const checkToken = async () => {
      let access_exp = localStorage.getItem("rb_ac_exp");
      let refresh_exp = localStorage.getItem("rb_rf_exp");
      //console.log('access_exp: ' + access_exp);
      //console.log('refresh_exp: ' + refresh_exp);
      if (refresh_exp < Date.now() / 1000) {
        //console.log("refresh is expiried");
        // alert('Please log in again')
        localStorage.clear();
        window.location = "/Login";
      } else if (access_exp < Date.now() / 1000) {
        const refresh = localStorage.getItem("rb_rf_token");
  
        let data = {
          refresh: refresh,
        };
        let result = await axios.post(token_url, data);
        //console.log("got new access_token!");
        localStorage.setItem("rb_ac_token", result.data.access);
      }
    };

    useEffect(() => {
      async function fetchMyAPI() {
        checkToken();
        setDone(true);
        await axios.get(`${apiUrl.GET_GIVEAWAY}start=${timestr}T06:00:00/end=${time}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
            "X-xss-protection": "1; mode=block;",
            "X-frame-options": "SAMEORIGIN",
            "X-content-type-options": "nosniff",
            "Cache-Control": "no-cache, private",
            "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
            "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
          }
      }
      )
        .then(response => {
          //console.log(response.data.accumulative[0].percent_giveaway)
          if (response.status == 200) {
            setGetTodayGiveaway({
              total_giveaway: response.data.accumulative[0].total_giveaway,
              percent_giveaway: response.data.accumulative[0].percent_giveaway,
              opportunity_cost: response.data.accumulative[0].opportunity_cost,
            })
            setNoDataToday(1)
            setDone(false);
          }
        })
        .catch(error => {
          setNoDataToday(2)
          setDone(false);
          console.log('error: ', error)
          
        })
      }
      fetchMyAPI()

      const interval = setInterval(() => fetchMyAPI(), 1 * 60 * 1000)
        return () => {
          clearInterval(interval);
    }

    }, [])

    //console.log(getTodayGiveaway,timestrs)




    /*useEffect(() => {
      async function fetchMyAPI() {
        checkToken();
        console.log(`${apiUrl.GET_GIVEAWAY}start=${startDate}/end=${endDate}/`);
        await axios.get(`${apiUrl.GET_GIVEAWAY}start=${startDate}/end=${endDate}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
            "X-xss-protection": "1; mode=block;",
            "X-frame-options": "SAMEORIGIN",
            "X-content-type-options": "nosniff",
            "Cache-Control": "no-cache, private",
            "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
            "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
          }
      }
      )
        .then(response => {
          //console.log(response.data.accumulative[0].percent_giveaway)
          if (response.status == 200) {
            setGetSinceGiveaway({
              total_giveaway: response.data.accumulative[0].total_giveaway,
              percent_giveaway: response.data.accumulative[0].percent_giveaway,
              opportunity_cost: response.data.accumulative[0].opportunity_cost,
            })
            setNoDataSince(1)
            //setDone(true);
          }
        })
        .catch(error => {
          setNoDataSince(2)
          console.log('error: ', error)
  
        })
      }
      fetchMyAPI()

    }, [getSinceGiveaway])*/

    //console.log(getSinceGiveaway,timestrs)

  
  return (
    <>
      <Helmet>
        <title>Giveaway | MES Korat Fur2</title>
      </Helmet>
      {/* use spinner and then display a component after call API (https://stackoverflow.com/questions/70322448/how-to-use-spinner-and-then-display-a-component-after-submit-button) */}
      { done ? <Circular /> : 
      <Grid
        container spacing={1}
        direction="row"
        alignItems="top"
        justifyContent="center"
        style={{ minHeight: '78vh', maxHeight: '100vh', flexGrow: 1, paddingLeft: 25, paddingTop: 75 }}
      >

        <Grid id="top-row" container sx={{ pt: 2.2 }} spacing={2}>
          <Box sx={{ width: '100%', bgcolor: "#3cbea8", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="50px" style={{ position: "relative", paddingLeft: -10 }} textAlign="center" >
            <Typography style={{ color: "white", fontWeight: "bold", fontSize: "22px"}} >
              Giveaway
            </Typography>
          </Box>
        </Grid>

        {/* Top row */}
        { noDataToday === 1 ?
        <Grid id="top-row" container sx={{ pt: 2.2 }} spacing={1}>
          <Grid xs={12}>
            <Item sx={() => ({
              textAlign: 'left',
              width: '98%',
              height: '300px',
            })}>
              <Box mb={20}>
              <Typography color="#47ccc1" fontSize="22px" fontWeight="bold" align='left' style={{ position: "relative", top: "10px", left: "535px", width: "50px" }}>
                  วันนี้
                </Typography>

                <Grid container spacing="18px" alignItems="left" justifyContent="center" style={{ position: "relative", top: "15px", left: "-80px" }} >
                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px"}}  >
                          {getTodayGiveaway.percent_giveaway}
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px"}}  >
                            Avg. Giveaway (%)
                          </Typography> 
                        </Box>             
                      </Grid>                      
                    </Box>
                  </Grid>

                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px"}}  >
                          {(getTodayGiveaway.total_giveaway).toLocaleString()}
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px"}}  >
                            Total Giveaway (kg)
                          </Typography> 
                        </Box>             
                      </Grid> 
                    </Box>
                  </Grid>

                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px"}}  >
                          {(getTodayGiveaway.opportunity_cost).toLocaleString()}
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px"}}  >
                            Opportunity Cost (THB)
                          </Typography>  
                        </Box>            
                      </Grid> 
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
        </Grid>
        : console.log()
        }

        { noDataToday === 2 ?
        <Grid id="top-row" container sx={{ pt: 2.2 }} spacing={1}>
          <Grid xs={12}>
            <Item sx={() => ({
              textAlign: 'left',
              width: '98%',
              height: '300px',
            })}>
              <Box mb={20}>
                <Typography color="#47ccc1" fontSize="22px" fontWeight="bold"  align='left' style={{ position: "relative", top: "10px", left: "535px", width: "50px" }}>
                  วันนี้
                </Typography>

                <Grid container spacing="18px" alignItems="left" justifyContent="center" style={{ position: "relative", top: "15px", left: "-80px" }} >
                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px"}}  >
                          No data
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px"}}  >
                            Avg. Giveaway (%)
                          </Typography> 
                        </Box>             
                      </Grid>                      
                    </Box>
                  </Grid>

                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px"}}  >
                          No data
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px"}}  >
                            Total Giveaway (kg)
                          </Typography> 
                        </Box>             
                      </Grid> 
                    </Box>
                  </Grid>

                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px"}}  >
                          No data
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px"}}  >
                            Opportunity Cost (THB)
                          </Typography>  
                        </Box>            
                      </Grid> 
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
        </Grid>
        : console.log()
        }

        {/* Bottom row */}
        { /*noDataSince === 1 ?
        <Grid id="Bottom-row" container spacing={1} style={{ position: "relative", top: "30px" }}>
          <Grid xs={12}>
            <Item sx={() => ({
              width: '98%',
              height: '300px',
            })}>
              <Box mb={20}>

              <Grid id="top-row" container sx={{ pt: 2.2 }} spacing={2}>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="50px" textAlign="center" >                                                                         
                  <Typography color="#47ccc1" fontSize="22px" fontWeight="bold"  align='center' >
                    สะสม&nbsp;
                  </Typography>     
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      label=""
                      value={startDate}
                      onChange={(newValue) => {
                        //////Get React DatePicker date value in onChange(https://stackoverflow.com/questions/70182747/get-react-datepicker-date-value-in-onchange)
                        const d = new Date(newValue);
                        const formatted = moment(d).tz('Asia/Bangkok').format('YYYY-MM-DDTHH:mm:ss');
                        setStartDate(formatted)
                        console.log("startDate: " + formatted);
                      }}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: '14.5%',
                            svg: { color: '#47ccc1' },
                            "& .MuiOutlinedInput-input": {
                              color: "#47ccc1",
                              fontFamily: 'CPF_Imm_Sook',
                              fontWeight: "bold",
                              fontSize: 18
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'white',
                              },
                              '&:hover fieldset': {
                                borderColor: 'white',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'white',
                              },
                            },
                          }}
                        />
                      )}
                    />
                    <Typography style={{ color: "#47ccc1", fontWeight: "bold", fontSize: "18px", fontFamily: 'Monospace' }}  >
                    -
                    </Typography>   

                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      label=""
                      value={endDate}
                      onChange={(newValue) => {
                        //////Get React DatePicker date value in onChange(https://stackoverflow.com/questions/70182747/get-react-datepicker-date-value-in-onchange)
                        const d = new Date(newValue);
                        const formatted = moment(d).tz('Asia/Bangkok').format('YYYY-MM-DDTHH:mm:ss');
                        setEndDate(formatted)
                        console.log("endDate: " + formatted);
                      }}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: '14.5%',
                            svg: { color: '#47ccc1' },
                            "& .MuiOutlinedInput-input": {
                              color: "#47ccc1",
                              fontFamily: 'CPF_Imm_Sook',
                              fontWeight: "bold",
                              fontSize: 18
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'white',
                              },
                              '&:hover fieldset': {
                                borderColor: 'white',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'white',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider> 
                </Box>
              </Grid>
                
                <Grid container spacing="18px" alignItems="left" justifyContent="center" style={{ position: "relative", top: "-1px", left: "-80px" }} >
                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px" }}  >
                          {getSinceGiveaway.percent_giveaway}
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px"}}  >
                            Avg. Giveaway (%)
                          </Typography> 
                        </Box>             
                      </Grid>                      
                    </Box>
                  </Grid>

                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px"}}  >
                          {(getSinceGiveaway.total_giveaway).toLocaleString()}
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px"}}  >
                            Total Giveaway (kg)
                          </Typography> 
                        </Box>             
                      </Grid> 
                    </Box>
                  </Grid>

                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px"}}  >
                          {(getSinceGiveaway.opportunity_cost).toLocaleString()}
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px"}}  >
                            Opportunity Cost (THB)
                          </Typography>  
                        </Box>            
                      </Grid> 
                    </Box>
                  </Grid>

                </Grid>
              </Box>
            </Item>
          </Grid>
        </Grid>
        : console.log()
        }

        { noDataSince === 2 ?
        <Grid id="Bottom-row" container spacing={1} style={{ position: "relative", top: "30px" }}>
          <Grid xs={12}>
            <Item sx={() => ({
              width: '98%',
              height: '300px',
            })}>
              <Box mb={20}>

              <Grid id="top-row" container sx={{ pt: 2.2 }} spacing={2}>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="50px" textAlign="center" >                                                                         
                  <Typography color="#47ccc1" fontSize="22px" fontWeight="bold" align='center' >
                  สะสม&nbsp;
                  </Typography>     
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      label=""
                      value={startDate}
                      onChange={(newValue) => {
                        //////Get React DatePicker date value in onChange(https://stackoverflow.com/questions/70182747/get-react-datepicker-date-value-in-onchange)
                        const d = new Date(newValue);
                        const formatted = moment(d).tz('Asia/Bangkok').format('YYYY-MM-DDTHH:mm:ss');
                        setStartDate(formatted)
                        console.log("startDate: " + formatted);
                      }}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: '14.5%',
                            svg: { color: '#47ccc1' },
                            "& .MuiOutlinedInput-input": {
                              color: "#47ccc1",
                              fontFamily: 'CPF_Imm_Sook',
                              fontWeight: "bold",
                              fontSize: 18
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'white',
                              },
                              '&:hover fieldset': {
                                borderColor: 'white',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'white',
                              },
                            },
                          }}
                        />
                      )}
                    />
                    <Typography style={{ color: "#47ccc1", fontWeight: "bold", fontSize: "18px"}}  >
                    -
                    </Typography> 
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      label=""
                      value={endDate}
                      onChange={(newValue) => {
                        //////Get React DatePicker date value in onChange(https://stackoverflow.com/questions/70182747/get-react-datepicker-date-value-in-onchange)
                        const d = new Date(newValue);
                        const formatted = moment(d).tz('Asia/Bangkok').format('YYYY-MM-DDTHH:mm:ss');
                        setEndDate(formatted)
                        console.log("endDate: " + formatted);
                      }}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: '14.5%',
                            svg: { color: '#47ccc1' },
                            "& .MuiOutlinedInput-input": {
                              color: "#47ccc1",
                              fontFamily: 'CPF_Imm_Sook',
                              fontWeight: "bold",
                              fontSize: 18
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'white',
                              },
                              '&:hover fieldset': {
                                borderColor: 'white',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'white',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>                     
                </Box>
              </Grid>
                
                <Grid container spacing="18px" alignItems="left" justifyContent="center" style={{ position: "relative", top: "-1px", left: "-80px" }} >
                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px"}}  >
                          No Data
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px"}}  >
                            Avg. Giveaway (%)
                          </Typography> 
                        </Box>             
                      </Grid>                      
                    </Box>
                  </Grid>

                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px"}}  >
                          No data
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px" }}  >
                            Total Giveaway (kg)
                          </Typography> 
                        </Box>             
                      </Grid> 
                    </Box>
                  </Grid>

                  <Grid item xs={3.5}>
                    <Box sx={{ width: '100%', bgcolor: "#47ccc1", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", paddingLeft: 10, borderRadius: 30 }} textAlign="center" >
                      <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "10px" }}>                                                                          
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "50px"}}  >
                          No data
                        </Typography>
                        <Box style={{ position: "relative", top: "20px" }}>
                          <Typography style={{ color: "white", fontWeight: "bold", fontSize: "25px"}}  >
                            Opportunity Cost (THB)
                          </Typography>  
                        </Box>            
                      </Grid> 
                    </Box>
                  </Grid>

                </Grid>
              </Box>
            </Item>
          </Grid>
        </Grid>
        : console.log()
                        */}
      </Grid>
    } 

    </>
  );
}

export default Giveaway;