import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography,
  Button
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const Sent = () => (
  <>
    <Helmet>
      <title>success link | MES Korat Fur2</title>
    </Helmet>
    <Box
      sx={{
        //backgroundColor: '#f5f5f5',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="xl"       
      sx={{
        marginTop: {
          xs: 1,
        }
      }}>
        <Typography
          align="center"
          color="textPrimary"
          variant="h4"
        >
          Successfully send reset password link to your email
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="subtitle1"
        >
          Please check your inbox. If you have not received a link in 2-3 minutes,
          Try to contact admin.
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <img
            alt="Under development"
            src={require("../assets/images/folder.png")}
            style={{
              marginTop: 50,
              display: 'inline-block',
              maxWidth: '100%',
              width: 100
            }}
          />
        </Box>
        <Box sx={{ py: 2 }}>
          <Button
              component={RouterLink}
              style={{ background: '#009688', color: "white", fontSize: 20}}
              fullWidth
              size="medium"
              type="submit"
              variant="contained"
              to="/login"
            >
              Back to login 
            </Button>
          </Box>
      </Container>
    </Box>
  </>
);

export default Sent;
