import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import Email from '../components/setting/Email';

const Emailpw = () => (
  <>
    <Helmet>
      <title>Email| MES Korat Fur2</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        minWidth: '100%',
        py: 3,
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ pt: 0 }}>
          <Email />
        </Box>
      </Container>
    </Box>
  </>
);

export default Emailpw;
