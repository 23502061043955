import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Typography from "@mui/material/Typography";
import Disconnect from 'assets/images/disconnect.png';

export default function Popup() {

    return (
        <div>
            <Backdrop
                sx={{  backgroundColor: "rgb(0, 0, 0, 0.85)",zIndex: (theme) => theme.zIndex.drawer + 1 }}

                open
            >
                <img src={Disconnect} height="160px" style={{position: "relative", top: "-2vw", left: "2vw"}} />
                <Typography style={{ color: "#EF193B", fontWeight: "bold", fontSize: 48, position: "relative", top: "3vw", left: "-4vw" }} >
                    Disconnect
                </Typography>
            </Backdrop>
        </div>
    );
}