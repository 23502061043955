import React from 'react'
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Chart from 'react-apexcharts';
import { ColorModeContext, tokens } from "theme";



////// add icon annotations (https://github.com/apexcharts/apexcharts.js/issues/1674)

function BarChartTargetLine7(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

    return (
        <>
            <Box width="100%" mb={-7} ml={3} style={{ position: "relative", top: "-40px" }}>
                <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            stacked: 'true',
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            }
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 10,
                                horizontal: true,
                                dataLabels: {
                                    enabled: true,
                                    enabledOnSeries: undefined,
                                    formatter: function (val, opts) {
                                        return val.toFixed(2)
                                    },
                                    textAnchor: 'start',
                                    distributed: false,
                                    offsetX: 0,
                                    offsetY: 0,
                                    total: {
                                        enabled: false,
                                        offsetX: 0,
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 900,
                                            alignItems: 'left'
                                        }
                                    }
                                }
                            }
                        },
                        colors: [props.chart_color, '#e0e0e0'],
                        dataLabels: {
                            enabled: false,
                        },
                        legend: {
                            show: false,
                            showForSingleSeries: true,
                            customLegendItems: ['Actual', 'Expected'],
                            markers: {
                                fillColors: [ props.chart_color, '#775DD0']
                            }
                        },
                        tooltip: {
                            enabled: false,
                        },
                        xaxis: {
                            type: 'category',
                            categories: [], type: 'category',
                            categories: [],
                            range: props.target_quantity,
                            min: 0,
                            max: props.target_quantity > props.actual_weight ? props.target_quantity : props.actual_weight,
                            show: false,
                            labels: {
                                show: false
                            },
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                        },
                        yaxis: {
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false,
                            },
                            labels: {
                                show: false,
                            },
                        },
                        grid: {
                            padding: {
                                left: -16
                            },
                            show: false,
                        },
                        annotations: {
                            xaxis: [
                                {
                                    x: props.target_now > props.target_quantity ? (props.target_quantity > props.actual_weight ? props.target_quantity : props.actual_weight) : props.target_now,
                                    strokeDashArray: 0,
                                    borderColor: "#fff",
                                    label: {
                                        borderColor: "transparent",
                                        borderWidth: 10,
                                        borderRadius: 0,
                                        offsetX: props.position,
                                        offsetY: 13,
                                        style: {
                                            color: colors.black[100],
                                            background: "transparent",
                                            fontSize: props.fontsize,
                                            fontFamily: "CPF_Imm_Sook",
                                        },
                                        position: "buttom",
                                        orientation: "horizontal",
                                        text:  (props.target_now).toLocaleString() + "(" + props.persent + "%" + ")"
                                    },
                                },
                            ],
                            points: [{
                                x: props.target_now > props.target_quantity ? (props.target_quantity > props.actual_weight ? props.target_quantity : props.actual_weight) : props.target_now,
                                y: 2,
                                marker: {
                                    size: 0
                                  },
                                image: {
                                    path: colors.icon[200],
                                    width: props.width_svg,
                                    height: props.height_svg,
                                    offsetX: 0,
                                    offsetY: 0,
                                }
                            }]
                        }
                    }}

                    series={[
                        {
                            name: 'Actual',
                            data: [(Math.round(props.actual_weight * 100) / 100).toFixed(2)]
                        },
                        {
                            name: 'Waiting',
                            data: [(Math.round((props.target_quantity - props.actual_weight < 0 ? 0 : props.target_quantity - props.actual_weight) * 100) / 100).toFixed(2)]
                        }
                    ]}
                    type='bar'
                    width='120%'
                    height={props.height}
                />
            </Box>
        </>
    )
}

BarChartTargetLine7.propTypes = {}

export default BarChartTargetLine7

