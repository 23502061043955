import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from "@mui/material";
import Chart from 'react-apexcharts';

function Defect(props) {
  return (
    <>
        <Typography color="black" fontSize={'16px'} fontWeight="bold" align='left' fontFamily='Monospace' >
            {props.title}
        </Typography>
        <Box>
        <Chart
            options={{
            chart: {
                type: 'donut',
                },
            labels: props.labels,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 200
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
            }]
        }}
            series={props.value}
            type="donut"
            width="100%"
            height="100%"
        />
        </Box>
    </>
  )
}

Defect.propTypes = {}

export default Defect
