import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import axios from 'axios';
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import { useNavigate } from 'react-router-dom';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import GaugeChartYield from '../components/chart/GaugeChart';
import Packaging from '../assets/images/packaging.png';
import PopupGiveawayDashboard from "../components/model/PopupGiveawayDashboard";
import db from "firebase-config";




export default function GiveawayPage1() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');
    const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');

    const [no_data, setNo_data] = useState(false);
    const [giveaway_dashboard, setgiveaway_dashboard] = useState({
        WARNING: "",
        LINE: "",
        SHIFT: "",
        SKU: "",
        TARGET_KG: "",
        ACTUAL_KG: "",
        TARGET_PACK: "",
        ACTUAL_PACK: "",
        REMAINING_PACK: "",
        REJECT_PACK: "",
        PACK_SIZE_STD: "",
        SETTING_PACK_SIZE: "",
        AVG_PACK_SIZE: "",
        ACTUAL_PERCENT_GIVEAWAY: "",
        COST_SAVING_PACK: "",
        TARGET_GIVEAWAY: "",
        MIN_GIVEAWAY: ""
    });
    const [keeplive, setkeeplive] = useState([]);

    setTimeout(() => {
        navigate('/giveaway/p2');
    }, 30000);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.GIVEAWAY_DASHBOARG_PAGE1}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        sessionStorage.setItem('warning', response.data[0].WARNING);
                        sessionStorage.setItem('line', response.data[0].LINE);
                        sessionStorage.setItem('sku', response.data[0].SKU);
                        sessionStorage.setItem('target_kg', response.data[0].TARGET_KG);
                        sessionStorage.setItem('actual_kg', response.data[0].ACTUAL_KG);
                        sessionStorage.setItem('target_pack', response.data[0].TARGET_PACK);
                        sessionStorage.setItem('actual_pack', response.data[0].ACTUAL_PACK);
                        sessionStorage.setItem('remaining_pack', response.data[0].REMAINING_PACK);
                        sessionStorage.setItem('reject_pack', response.data[0].REJECT_PACK);
                        sessionStorage.setItem('pack_size_std', response.data[0].PACK_SIZE_STD);
                        sessionStorage.setItem('setting_pack_size', response.data[0].SETTING_PACK_SIZE);
                        sessionStorage.setItem('avg_pack_size', response.data[0].AVG_PACK_SIZE);
                        sessionStorage.setItem('actual_percent_giveaway', response.data[0].ACTUAL_PERCENT_GIVEAWAY);
                        sessionStorage.setItem('cost_saving_pack', response.data[0].COST_SAVING_PACK);
                        sessionStorage.setItem('target_giveaway', response.data[0].TARGET_GIVEAWAY);
                        sessionStorage.setItem('min_giveaway', response.data[0].MIN_GIVEAWAY);
                        setgiveaway_dashboard({
                            WARNING: response.data[0].WARNING,
                            LINE: response.data[0].LINE,
                            SHIFT: response.data[0].SHIFT,
                            SKU: response.data[0].SKU,
                            TARGET_KG: response.data[0].TARGET_KG,
                            ACTUAL_KG: response.data[0].ACTUAL_KG,
                            TARGET_PACK: response.data[0].TARGET_PACK,
                            ACTUAL_PACK: response.data[0].ACTUAL_PACK,
                            REMAINING_PACK: response.data[0].REMAINING_PACK,
                            REJECT_PACK: response.data[0].REJECT_PACK,
                            PACK_SIZE_STD: response.data[0].PACK_SIZE_STD,
                            SETTING_PACK_SIZE: response.data[0].SETTING_PACK_SIZE,
                            AVG_PACK_SIZE: response.data[0].AVG_PACK_SIZE,
                            ACTUAL_PERCENT_GIVEAWAY: response.data[0].ACTUAL_PERCENT_GIVEAWAY,
                            COST_SAVING_PACK: response.data[0].COST_SAVING_PACK,
                            TARGET_GIVEAWAY: response.data[0].TARGET_GIVEAWAY,
                            MIN_GIVEAWAY: response.data[0].MIN_GIVEAWAY,
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }



    }, []);

    useEffect(() => {
        const subscriber = db
            .collection("meskr_keeplive")
            .onSnapshot((querySnapshot) => {
                const newData = [];
                querySnapshot.forEach((doc) => {
                    newData.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                if (JSON.stringify(newData) !== JSON.stringify(keeplive)) {
                    setkeeplive(newData);
                    let mul1 = newData.find((user) => user.ID.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));
                    sessionStorage.setItem('multihead1', mul1?.VALUE);
                    let mul2 = newData.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line7_2.0NoError"));
                    sessionStorage.setItem('multihead2', mul2?.VALUE);
                    let c_w = newData.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line7.Status"));
                    sessionStorage.setItem('c_w', c_w?.VALUE);
                    let x_ray = newData.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line7.Status"));
                    sessionStorage.setItem('x_ray', x_ray?.VALUE);
                    let m_d = newData.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line7.Status"));
                    sessionStorage.setItem('m_d', m_d?.VALUE);
                }


            });

        // return cleanup function
        return () => subscriber();
    }, [keeplive]);



    const multihead1 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));
    const multihead2 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line7_2.0NoError"));
    const check_weihgt = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line7.Status"));
    const xray = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line7.Status"));
    const md = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line7.Status"));

    const scale = (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : ((giveaway_dashboard.SETTING_PACK_SIZE - giveaway_dashboard.PACK_SIZE_STD) / giveaway_dashboard.PACK_SIZE_STD) * 100) * 2


    const getColorGiveaway = () => {
        let color;
        try {
            if (scale == 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale == 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 0.25 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 0.50) {
                color = '#37af9b'
            } else if (scale == 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 0.50 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 0.75) {
                color = '#56cab7'
            } else if (scale == 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 0.75 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#96ded2'
            } else if (scale == 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 1.25) {
                color = '#f5c842'
            } else if (scale == 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 1.25 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 1.50) {
                color = '#fcad00'
            } else if (scale == 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 1.50 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 1.75) {
                color = '#ec7728'
            } else if (scale == 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 1.75) {
                color = '#e20000'
            } else if (scale == 2 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale == 2 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 0.25 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 0.50) {
                color = '#37af9b'
            } else if (scale == 2 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 0.50 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 0.75) {
                color = '#56cab7'
            } else if (scale == 2 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 0.75 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#96ded2'
            } else if (scale == 2 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 1.25) {
                color = '#f5c842'
            } else if (scale == 2 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 1.25 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 1.50) {
                color = '#fcad00'
            } else if (scale == 2 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 1.50 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 1.75) {
                color = '#ec7728'
            } else if (scale == 2 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 1.75) {
                color = '#e20000'
            } else if (scale == 4 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 0.5) {
                color = '#2c8c7c'
            } else if (scale == 4 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 0.5 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#37af9b'
            } else if (scale == 4 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 1.5) {
                color = '#56cab7'
            } else if (scale == 4 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 1.5 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 2) {
                color = '#96ded2'
            } else if (scale == 4 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 2 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 2.5) {
                color = '#f5c842'
            } else if (scale == 4 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 2.5 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 3) {
                color = '#fcad00'
            } else if (scale == 4 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 3 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) <= 3.5) {
                color = '#ec7728'
            } else if (scale == 4 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)) > 3.5) {
                color = '#e20000'
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const scale_old = (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : ((sessionStorage.getItem('setting_pack_size') - sessionStorage.getItem('pack_size_std')) / sessionStorage.getItem('pack_size_std')) * 100) * 2

    const getColorGiveawayOld = () => {
        let color;
        try {
            if (scale_old == 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) >= 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 0.25 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 0.50) {
                color = '#37af9b'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 0.50 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 0.75) {
                color = '#56cab7'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 0.75 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 1) {
                color = '#96ded2'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 1 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 1.25) {
                color = '#f5c842'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 1.25 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 1.50) {
                color = '#fcad00'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 1.50 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 1.75) {
                color = '#ec7728'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 1.75) {
                color = '#e20000'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) >= 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 0.25 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 0.50) {
                color = '#37af9b'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 0.50 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 0.75) {
                color = '#56cab7'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 0.75 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 1) {
                color = '#96ded2'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 1 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 1.25) {
                color = '#f5c842'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 1.25 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 1.50) {
                color = '#fcad00'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 1.50 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 1.75) {
                color = '#ec7728'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 1.75) {
                color = '#e20000'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) >= 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 0.5) {
                color = '#2c8c7c'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 0.5 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 1) {
                color = '#37af9b'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 1 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 1.5) {
                color = '#56cab7'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 1.5 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 2) {
                color = '#96ded2'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 2 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 2.5) {
                color = '#f5c842'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 2.5 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 3) {
                color = '#fcad00'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 3 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) <= 3.5) {
                color = '#ec7728'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))) > 3.5) {
                color = '#e20000'
            }
            return color;
        }
        catch {
            console.log()
        }
    }


    /*const getColorGiveaway = () => {
        let color;
        try {
            if ((mytime== 'NA' ? 0 : mytime) >= 0 && (mytime== 'NA' ? 0 : mytime) <= 0.24) {
                color = '#2c8c7c'
              } else if ((mytime== 'NA' ? 0 : mytime) >= 0.25 && (mytime== 'NA' ? 0 : mytime) <= 0.49) {
                color = '#41c3ad'
              } else if ((mytime== 'NA' ? 0 : mytime) >=  0.50 && (mytime== 'NA' ? 0 : mytime) <= 0.74) {
                color =  '#93ddd1'
              } else if ((mytime== 'NA' ? 0 : mytime) >=  0.75 && (mytime== 'NA' ? 0 : mytime) <= 0.99) {
                color =  '#c8eee8'
              }else if ((mytime== 'NA' ? 0 : mytime) >=  1 && (mytime== 'NA' ? 0 : mytime) <= 1.24) {
                color =  '#ffe699'
              } else if ((mytime== 'NA' ? 0 : mytime) >=  1.25 && (mytime== 'NA' ? 0 : mytime) <= 1.49) {
                color =  '#ffc91d'
              } else if ((mytime== 'NA' ? 0 : mytime) >=  1.50 && (mytime== 'NA' ? 0 : mytime) <= 1.74) {
                color =  '#ec7728'
              } else if ((mytime== 'NA' ? 0 : mytime) >=  1.75 ) {
                color =  '#e20000'
              }
            return color;
        }
        catch {
            console.log()
        }
    }


    const [mytime, setMytime] = useState(0);

  useEffect(() => {
    // create a interval and get the id
    const myInterval = setInterval(() => {
      setMytime((prevTime) => prevTime + 0.25);
    }, 2000);
    // clear out the interval using the id when unmounting the component
    return () => clearInterval(myInterval);
  }, []);

  useEffect(() => {
    if (mytime === 2 ) {
        setMytime(0);
    }
  }, [mytime]);*/

    const getValidValue = (value) => {
        return value === undefined || value === null || value === '-' ? 0 : Number(value);
    }



    return (
        <>
            {giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' || sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' || multihead1?.VALUE == false || multihead2?.VALUE == false || check_weihgt?.VALUE == 0 || xray?.VALUE == 0 || md?.VALUE == 0 || (sessionStorage.getItem('multihead1') == 'true') == false || (sessionStorage.getItem('multihead2') == 'true') == false || Number(sessionStorage.getItem('c_w')) == 0 || Number(sessionStorage.getItem('x_ray')) == 0 || Number(sessionStorage.getItem('m_d')) == 0 ? <PopupGiveawayDashboard /> :
                <Helmet>
                    <title>Giveaway | MES Korat Fur2</title>
                </Helmet>
            }
            <Grid
                container spacing={1}
                direction="row"
                alignItems="top"
                justifyContent="center"
                style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 48, paddingTop: 45 }}
            >
                {/* Top row */}

                <Grid container sx={{ pt: 0 }} spacing={5} style={{ position: "relative", left: "0vw", top: "0vw" , overflow: 'hidden'}}>
                    <Grid id="top-row" container xs={1.5} md={1.5} lg={1.5} xl={1.5} style={{ position: "relative", left: "0vw", overflow: 'hidden' }}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center">
                            <Typography style={{ color: "white", fontWeight: "bold", fontSize: 30, position: "relative", left: "0vw", top: "0vw", background: "#2e9281", height: '70%' }} textAlign="center" >
                                {no_data == 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 'Line7' : giveaway_dashboard.LINE) : 'Line7'}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid id="top-row" container xs={4.5} md={4.5} lg={2} xl={2} style={{overflow: 'hidden'}}>
                        <Grid item xs={12} textAlign="left" style={{overflow: 'hidden'}}>
                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" style={{overflow: 'hidden'}}>
                                <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 30, position: "relative", paddingLeft: 5 }}>
                                    SKU :&nbsp;
                                </Typography>
                                <Typography style={{ color: "#4AC6B1", fontWeight: "bold", fontSize: 30 }}>
                                    {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : giveaway_dashboard.SKU) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : sessionStorage.getItem('sku'))}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid id="top-row" container xs={5} md={5} lg={2} xl={2} style={{overflow: 'hidden'}}>
                        <Grid item xs={12} textAlign="right" style={{overflow: 'hidden'}}>
                            <Box sx={{ display: 'flex', alignItems: 'right', justifyContent: 'right' }} textAlign="right"  style={{overflow: 'hidden'}}>
                                <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 30, position: "relative", left: '0vw' }}>
                                    {timestr}
                                </Typography>
                                <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 30, position: "relative", left: '0vw' }}>
                                    &nbsp;&nbsp;{timestrs}
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>
                    <Grid id="top-row" container xs={1} md={1} lg={0.5} xl={0.5} style={{overflow: 'hidden'}}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="right" style={{overflow: 'hidden'}}>
                            <Typography>
                                {giveaway_dashboard.SHIFT === "N" ? (
                                    <DarkModeOutlinedIcon style={{ color: '#ffffff', position: "relative", top: "1vw", left: '-3vw' }} />
                                ) : (
                                    <LightModeOutlinedIcon style={{ color: '#ffffff', position: "relative", top: "1vw", left: '-3vw' }} />
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container spacing={5} style={{ position: "relative", top: '3.5vw', left: '0vw', overflow: 'hidden'}}>
                    <Grid id="top-row" container xs={5} md={5} lg={2.5} xl={2.5} style={{ position: "relative", padding: 3, overflow: 'hidden'}}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ background: '#BCD6D0', borderRadius: 20, height: '82%', overflow: 'hidden'}}>
                            <Grid container spacing={2} style={{overflow: 'hidden'}}>
                                <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '4vw', overflow: 'hidden'}}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-2vw', height: '12px', overflow: 'hidden'}}>
                                        <Typography style={{ color: colors.while[100], fontSize: 28, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            FG (kg)
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid id="top-row" container xs={6} md={6} lg={6} xl={6} textAlign="center" style={{ borderRight: "1px dashed #000000", position: "relative", top: '6vw', height: '60px', left: '1vw', overflow: 'hidden'}}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw', overflow: 'hidden'}}>
                                        <Typography style={{ color: colors.while[100], fontSize: 25, position: "relative", top: "-1.5vw", fontWeight: 'bold', overflow: 'hidden'}} >
                                            &nbsp;&nbsp;Target
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4.5vw', overflow: 'hidden'}}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" style={{overflow: 'hidden'}}>
                                            <Typography style={{ color: colors.while[100], fontSize: 38, fontWeight: 'bold' }} >
                                                &nbsp;&nbsp;{no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : Number(giveaway_dashboard.TARGET_KG).toLocaleString()) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : Number(sessionStorage.getItem('target_kg')).toLocaleString())}&nbsp;
                                            </Typography>
                                        </Box>
                                    </Grid>

                                </Grid>
                                <Grid id="top-row" container xs={6} md={6} lg={6} xl={6} textAlign="center" style={{ position: "relative", top: '4.5vw', overflow: 'hidden'}}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw', overflow: 'hidden'}}>
                                        <Typography style={{ color: colors.while[100], fontSize: 25, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            Actual
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-3vw', overflow: 'hidden'}}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" style={{overflow: 'hidden'}}>
                                            <Typography style={{ color: '#2e9281', fontSize: 38, fontWeight: 'bold' }} >
                                                {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : Number(giveaway_dashboard.ACTUAL_KG).toLocaleString()) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : Number(sessionStorage.getItem('actual_kg')).toLocaleString())}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid id="top-row" container xs={7} md={7} lg={3.5} xl={3.5} style={{ position: "relative", padding: 3, overflow: 'hidden' }}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ background: '#BCD6D0', borderRadius: 20, height: '82%', overflow: 'hidden' }}>
                            <Grid container spacing={2} >
                                <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '4vw', overflow: 'hidden' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-2vw', height: '12px', overflow: 'hidden' }}>
                                        <Typography style={{ color: colors.while[100], fontSize: 28, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            FG (bag) <img src={Packaging} style={{ position: "relative", top: '0.5vw' }} height="25px" />
                                        </Typography>

                                    </Grid>
                                </Grid>
                                <Grid id="top-row" container xs={5} md={5} lg={2.5} xl={2.5} textAlign="center" style={{ borderRight: "1px dashed #000000", position: "relative", top: '6vw', height: '62px', overflow: 'hidden' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-1.5vw', overflow: 'hidden' }} >
                                        <Typography style={{ color: colors.while[100], fontSize: 25, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            &nbsp;&nbsp;Target
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4.5vw', overflow: 'hidden' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" style={{overflow: 'hidden'}}>
                                            <Typography style={{ color: colors.while[100], fontSize: 38, fontWeight: 'bold' }} >
                                                &nbsp;&nbsp;{no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveaway_dashboard.TARGET_PACK == '-' ? '-' : Number(giveaway_dashboard.TARGET_PACK).toLocaleString())) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('target_pack') == '-' ? '-' : Number(sessionStorage.getItem('target_pack')).toLocaleString()))}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid id="top-row" container xs={4} md={4} lg={2} xl={2} textAlign="center" style={{ position: "relative", top: '6vw', overflow: 'hidden' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-1vw', overflow: 'hidden' }}>
                                        <Typography style={{ color: colors.while[100], fontSize: 25, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            Actual
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4vw', overflow: 'hidden' }} >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" style={{overflow: 'hidden'}}>
                                            <Typography style={{ color: '#2e9281', fontSize: 38, fontWeight: 'bold' }} >
                                                {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveaway_dashboard.ACTUAL_PACK == '-' ? '-' : Number(giveaway_dashboard.ACTUAL_PACK).toLocaleString())) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('actual_pack') == '-' ? '-' : Number(sessionStorage.getItem('actual_pack')).toLocaleString()))}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-7vw', overflow: 'hidden' }} >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" style={{overflow: 'hidden'}}>
                                            <Typography style={{ color: "#636363", fontSize: 18, fontWeight: 'bold' }} >
                                                &nbsp;Diff:
                                            </Typography>
                                            <Typography style={{ color: "#636363", fontSize: 22, fontWeight: 'bold', position: "relative", top: "0vw" }} >
                                                {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveaway_dashboard.REMAINING_PACK == '-' ? '-' : Number(giveaway_dashboard.REMAINING_PACK).toLocaleString())) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('remaining_pack') == '-' ? '-' : Number(sessionStorage.getItem('remaining_pack')).toLocaleString()))}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                </Grid>

                                <Grid id="top-row" container xs={3} md={3} lg={1.5} xl={1.5} textAlign="center" style={{ borderLeft: "1px dashed #000000", position: "relative", top: '6vw', height: '60px', overflow: 'hidden'  }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-1vw' }}>
                                        <Typography style={{ color: '#8c0e0e', fontSize: 25, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            Reject
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4.2vw', overflow: 'hidden'  }} >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" style={{overflow: 'hidden'}}>
                                            <Typography style={{ color: '#8c0e0e', fontSize: 38, fontWeight: 'bold' }} >
                                                {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveaway_dashboard.REJECT_PACK == '-' ? '-' : Number(giveaway_dashboard.REJECT_PACK).toLocaleString())) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('reject_pack') == '-' ? '-' : Number(sessionStorage.getItem('reject_pack')).toLocaleString()))}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={5} style={{ position: "relative", top: '0vw', left: '0vw' }}>
                    <Grid id="top-row" container xs={12} md={12} lg={6} xl={6} style={{ position: "relative", padding: 3 }}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ border: "2px dashed #4A4A49", borderRadius: 20, height: '130%', position: "relative", top: '6vw' }}>
                            <Grid container spacing={2} >
                                <Grid id="top-row" container xs={4} md={4} lg={4} xl={4} textAlign="center" style={{ position: "relative", top: '5vw', height: '125px', left: '1vw', borderRight: "1px dashed #c2c2c2" }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw', height: '50%' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <Typography style={{ color: colors.black[100], fontSize: 32, position: "relative", top: "-2.5vw", fontWeight: 'bold' }} >
                                                &nbsp;&nbsp;Pack Size
                                            </Typography>
                                            <Typography style={{ color: colors.black[100], fontSize: 22, position: "relative", top: "-2.5vw", fontWeight: 'bold' }} >
                                                &nbsp;(g/bag)
                                            </Typography>
                                        </Box>

                                        <Typography style={{ color: '#2e9281', fontSize: 28, position: "relative", top: "-3vw", fontWeight: 'bold' }} >
                                            Avg. Actual
                                        </Typography>
                                        <Typography style={{ color: '#2e9281', fontSize: 38, position: "relative", top: "-6vw", fontWeight: 'bold' }} >
                                            {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveaway_dashboard.AVG_PACK_SIZE == '-' ? '-' : Number(giveaway_dashboard.AVG_PACK_SIZE).toLocaleString())) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('avg_pack_size') == '-' ? '-' : Number(sessionStorage.getItem('avg_pack_size')).toLocaleString()))}
                                        </Typography>
                                        <Typography style={{ color: colors.grey[100], fontSize: no_data == 1 ? (giveaway_dashboard.PACK_SIZE_STD == 'ไม่มีข้อมูล Std. Pack ในระบบ' ? 15 : 22) : 22, position: "relative", top: "-7vw", fontWeight: 'bold' }} >
                                            {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveaway_dashboard.PACK_SIZE_STD == 'ไม่มีข้อมูล Std. Pack ในระบบ' ? 'ไม่มีข้อมูล Std. Pack ในระบบ' : 'Std. ' + Number(giveaway_dashboard.PACK_SIZE_STD).toLocaleString())) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : Number(sessionStorage.getItem('pack_size_std')).toLocaleString())}/Setting {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveaway_dashboard.SETTING_PACK_SIZE == '-' ? '-' : Number(giveaway_dashboard.SETTING_PACK_SIZE).toLocaleString())) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('setting_pack_size') == '-' ? '-' : Number(sessionStorage.getItem('setting_pack_size')).toLocaleString()))}
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Grid id="top-row" container xs={8} md={8} lg={8} xl={8} textAlign="center" style={{ position: "relative", top: '4vw' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ height: '75%' }}>
                                        <Grid container spacing={3} >
                                            <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '4vw' }}>
                                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-2vw', height: '12px' }}>
                                                    <Typography style={{ color: colors.black[100], fontSize: 32, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                                        Giveaway
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid id="top-row" container xs={4} md={4} lg={2.5} xl={2.5} textAlign="center" style={{ position: "relative", top: '7vw', left: '2vw', height: '60px' }}>
                                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-1.5vw', height: '30%' }} >
                                                    <Typography style={{ color: colors.grey[100], fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                                        Forecast
                                                    </Typography>
                                                    <Typography style={{ color: colors.grey[100], fontSize: 28, position: "relative", top: "-2.5vw", fontWeight: 'bold' }} >
                                                        {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveaway_dashboard.TARGET_GIVEAWAY == '-' ? '-' : Number(giveaway_dashboard.TARGET_GIVEAWAY).toLocaleString())) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('target_giveaway') == '-' ? '-' : Number(sessionStorage.getItem('target_giveaway')).toLocaleString()))}%
                                                    </Typography>
                                                    <Typography style={{ color: colors.grey[100], fontSize: 22, position: "relative", top: "-2.2vw", fontWeight: 'bold' }} >
                                                        Best Practice
                                                    </Typography>
                                                    <Typography style={{ color: colors.grey[100], fontSize: 28, position: "relative", top: "-4.5vw", fontWeight: 'bold' }} >
                                                        {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveaway_dashboard.MIN_GIVEAWAY == '-' ? '-' : Number(giveaway_dashboard.MIN_GIVEAWAY).toLocaleString())) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('min_giveaway') == '-' ? '-' : Number(sessionStorage.getItem('min_giveaway')).toLocaleString()))}%
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid id="top-row" container xs={4} md={4} lg={2} xl={2} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '8vw', left: '2vw' }}>
                                                    {giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY !== undefined && giveaway_dashboard.SETTING_PACK_SIZE !== undefined && giveaway_dashboard.PACK_SIZE_STD !== undefined ? (
                                                        <GaugeChartYield
                                                            value={getValidValue(no_data === 1 ? giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY : sessionStorage.getItem('actual_percent_giveaway'))}
                                                            setting={getValidValue(no_data === 1 ? giveaway_dashboard.SETTING_PACK_SIZE : sessionStorage.getItem('setting_pack_size'))}
                                                            std={getValidValue(no_data === 1 ? giveaway_dashboard.PACK_SIZE_STD : sessionStorage.getItem('pack_size_std'))}
                                                        />) : ''}
                                                </Grid>
                                            </Grid>

                                            <Grid id="top-row" container xs={4} md={4} lg={1.5} xl={1.5} textAlign="center" style={{ position: "relative", top: '5vw', height: '60px' }}>
                                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-1vw' }}>
                                                    <Typography style={{ color: colors.black[100], fontSize: 26, position: "relative", top: "1.2vw", fontWeight: 'bold' }} >
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Actual
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }} >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#4a4a49', width: '75%', height: '60%', borderRadius: 2 }} style={{ position: "relative", left: '5vw' }} textAlign="center" >
                                                        <Typography style={{ color: no_data == 1 ? getColorGiveaway() : getColorGiveawayOld(), fontSize: 38, fontWeight: 'bold' }} >
                                                            {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == '-' ? '-' : Number(giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY).toLocaleString())) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('actual_percent_giveaway') == '-' ? '-' : Number(sessionStorage.getItem('actual_percent_giveaway')).toLocaleString()))}
                                                        </Typography>
                                                        <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', position: "relative", top: "0.5vw" }} >
                                                            %
                                                        </Typography>
                                                    </Box>
                                                </Grid>


                                                <Grid item xs={9} md={9} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-3vw', left: '5vw', height: '80%', background: '#4a4a49', borderRadius: 10 }} >
                                                    <Typography style={{ color: no_data == 1 ? getColorGiveaway() : getColorGiveawayOld(), fontSize: 38, position: "relative", top: "-1.5vw", fontWeight: 'bold' }} >
                                                        {no_data === 1 ? (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveaway_dashboard.COST_SAVING_PACK == '-' ? '-' : Number(giveaway_dashboard.COST_SAVING_PACK).toLocaleString())) : sessionStorage.getItem('warning') == null ? '-' : (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('cost_saving_pack') == '-' ? '-' : Number(sessionStorage.getItem('cost_saving_pack')).toLocaleString()))}
                                                    </Typography>
                                                    <Typography style={{ color: colors.black[100], fontSize: 22, position: "relative", top: "-5.5vw", fontWeight: 'bold' }} textAlign="right">
                                                        bag&nbsp;&nbsp;
                                                    </Typography>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>




                </Grid>



            </Grid>

        </>
    );
}
