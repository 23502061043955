import React, { useEffect, useState } from "react";
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { blueGrey, deepOrange, grey, teal } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import FactoryIcon from '@mui/icons-material/Factory';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';




const drawerWidth = 200;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});




export default function AppbarUnlock() {
  const [mode, setMode] = React.useState('light');
  const [open, setOpen] = React.useState(false);
  const path = useLocation().pathname.split("/").slice(1);
  const [value, setValue] = React.useState(0);
  const [anchorElNav, setAnchorElNav] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };




  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
              primary: teal,
              background: {
                default: teal[200],
                paper: teal[200],
              },
              divider: teal[50],
              text: {
                primary: grey[900],
                secondary: grey[800],
              },
              typography: {
                button: {
                  fontWeight: 600
                },
                fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                body1: {
                  fontSize: '1rem',
                  fontWeight: 400,
                  lineHeight: 1.5
                },
                body2: {
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  lineHeight: 1.57
                },
                subtitle1: {
                  fontSize: '1rem',
                  fontWeight: 500,
                  lineHeight: 1.75
                },
                subtitle2: {
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  lineHeight: 1.57
                },
                overline: {
                  fontSize: '0.75rem',
                  fontWeight: 600,
                  letterSpacing: '0.5px',
                  lineHeight: 2.5,
                  textTransform: 'uppercase'
                },
                caption: {
                  fontSize: '0.75rem',
                  fontWeight: 400,
                  lineHeight: 1.66
                },
                h1: {
                  fontWeight: 700,
                  fontSize: '3.5rem',
                  lineHeight: 1.375
                },
                h2: {
                  fontWeight: 700,
                  fontSize: '3rem',
                  lineHeight: 1.375
                },
                h3: {
                  fontWeight: 700,
                  fontSize: '2.25rem',
                  lineHeight: 1.375
                },
                h4: {
                  fontWeight: 700,
                  fontSize: '2rem',
                  lineHeight: 1.375
                },
                h5: {
                  fontWeight: 600,
                  fontSize: '1.5rem',
                  lineHeight: 1.375
                },
                h6: {
                  fontWeight: 600,
                  fontSize: '1.125rem',
                  lineHeight: 1.375
                },
                h7: {
                  fontWeight: 600,
                  fontSize: '1.0rem',
                  lineHeight: 1.375
                }
              }
            }
            : {
              primary: blueGrey,
              divider: blueGrey[700],
              background: {
                default: deepOrange[900],
                paper: blueGrey[900],
              },
              text: {
                primary: '#fff',
                secondary: grey[500],
              },
              typography: {
                button: {
                  fontWeight: 600
                },
                fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                body1: {
                  fontSize: '1rem',
                  fontWeight: 400,
                  lineHeight: 1.5
                },
                body2: {
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  lineHeight: 1.57
                },
                subtitle1: {
                  fontSize: '1rem',
                  fontWeight: 500,
                  lineHeight: 1.75
                },
                subtitle2: {
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  lineHeight: 1.57
                },
                overline: {
                  fontSize: '0.75rem',
                  fontWeight: 600,
                  letterSpacing: '0.5px',
                  lineHeight: 2.5,
                  textTransform: 'uppercase'
                },
                caption: {
                  fontSize: '0.75rem',
                  fontWeight: 400,
                  lineHeight: 1.66
                },
                h1: {
                  fontWeight: 700,
                  fontSize: '3.5rem',
                  lineHeight: 1.375
                },
                h2: {
                  fontWeight: 700,
                  fontSize: '3rem',
                  lineHeight: 1.375
                },
                h3: {
                  fontWeight: 700,
                  fontSize: '2.25rem',
                  lineHeight: 1.375
                },
                h4: {
                  fontWeight: 700,
                  fontSize: '2rem',
                  lineHeight: 1.375
                },
                h5: {
                  fontWeight: 600,
                  fontSize: '1.5rem',
                  lineHeight: 1.375
                },
                h6: {
                  fontWeight: 600,
                  fontSize: '1.125rem',
                  lineHeight: 1.375
                },
                h7: {
                  fontWeight: 600,
                  fontSize: '1.0rem',
                  lineHeight: 1.375
                }
              }
            }),
        },
        components: {
          MuiButton: {
            defaultProps: {
              disableElevation: true
            },
            styleOverrides: {
              root: {
                textTransform: 'none'
              },
              sizeSmall: {
                padding: '6px 16px'
              },
              sizeMedium: {
                padding: '8px 20px'
              },
              sizeLarge: {
                padding: '11px 24px'
              },
              textSizeSmall: {
                padding: '7px 12px'
              },
              textSizeMedium: {
                padding: '9px 16px'
              },
              textSizeLarge: {
                padding: '12px 16px'
              }
            }
          },
          MuiButtonBase: {
            defaultProps: {
              disableRipple: true
            }
          },
          MuiCardContent: {
            styleOverrides: {
              root: {
                padding: '32px 24px',
                '&:last-child': {
                  paddingBottom: '32px'
                }
              },
            }
          },
          MuiCardHeader: {
            defaultProps: {
              titleTypographyProps: {
                variant: 'h6'
              },
              subheaderTypographyProps: {
                variant: 'body2'
              }
            },
            styleOverrides: {
              root: {
                padding: '32px 24px'
              }
            }
          },
          MuiCssBaseline: {
            styleOverrides: {
              '*': {
                boxSizing: 'border-box',
                margin: 0,
                padding: 0
              },
              html: {
                MozOsxFontSmoothing: 'grayscale',
                WebkitFontSmoothing: 'antialiased',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%',
                width: '100%'
              },
              body: {
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                minHeight: '100%',
                width: '100%'
              },
              '#__next': {
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                height: '100%',
                width: '100%'
              }
            }
          },
          MuiOutlinedInput: {
            styleOverrides: {
              notchedOutline: {
                borderColor: '#E6E8F0'
              }
            }
          },
          MuiTableHead: {
            styleOverrides: {
              root: {
                backgroundColor: '#000000',
                '.MuiTableCell-root': {
                  color: '#FFF'
                },
                borderBottom: 'none',
                '& .MuiTableCell-root': {
                  borderBottom: 'none',
                  fontSize: '12px',
                  fontWeight: 600,
                  lineHeight: 1,
                  letterSpacing: 0.5,
                  textTransform: 'uppercase',
                  border: '1px solid rgba(255,255,255,0.05)'
                },
                '& .MuiTableCell-paddingCheckbox': {
                  paddingTop: 4,
                  paddingBottom: 4
                }
              }
            }
          },
        },

      }),
    [mode],
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <AppBar color='primary' position="fixed" open={open}>
          <StyledToolbar>

            <FactoryIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <RouterLink to={"/home"} style={{ textDecoration: 'none' }}>
              <Typography
                fontSize="20px"
                noWrap
                component="a"
                sx={{
                  mr: 0,
                  mt: 1,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'CPF_Imm_Sook',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: '#ffffff',
                  textDecoration: 'none',
                }}
              >
                MES KORAT
              </Typography>
            </RouterLink>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to= "/check_list" >Giveaway</MenuItem>
              <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to= "/unlock" >Unlock</MenuItem>
            </Menu>
              <Typography
                fontSize="14px"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'CPF_Imm_Sook',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
                style={{ position: "relative", paddingLeft: 20, paddingTop: 0 }}
              >

               MES KORAT
              </Typography>

            </Box>
            {/*<FactoryIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />*/}
            
              <Typography
                fontSize="16px"
                noWrap
                component="a"
                sx={{
                  //mr: 17,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'CPF_Imm_Sook',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: '#ffffff',
                  textDecoration: 'none',
                }}
              >
              <RouterLink to={"/home"} style={{ textDecoration: 'none',color: "#ffffff" }}>
               MES KORAT
               </RouterLink>
              </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

            <BottomNavigation
              sx={{
                bgcolor: '#08948c',
                '& .Mui-selected': {
                  '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
                    color: '#64ffda'
                  }
                }
              }}
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <BottomNavigationAction label="Giveaway" style={{ fontWeight: 'bold', color: "white", width: "20%", top: "0.4vw", left:"1vw" }} component={RouterLink} to= "/check_list"/>
              <BottomNavigationAction label="Unlock" style={{ fontWeight: 'bold', color: "white", width: "20%", top: "0.4vw", left:"1vw" }} component={RouterLink} to= "/unlock"/>
            </BottomNavigation>
            </Box>
          </StyledToolbar>
        </AppBar>
      </Box>
    </ThemeProvider>

  );
}
