import routes from "./routes";
import { useRoutes} from 'react-router-dom';
import { StyledEngineProvider } from "@mui/material";

function App() {
  // Check if the user already logged in or not
  const isLoggedIn = ()=>{
    var authen = localStorage.getItem('rb_authen')
    if (authen === 'True') {
      return true
    }else{
      return false
    }
  };
  // send isLoggedIn argument to routes.js
  const content = useRoutes(routes(isLoggedIn()));

  return (
    <StyledEngineProvider injectFirst>
      {content}
    </StyledEngineProvider>
    
    
  );
}

export default App;
