import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import Target from 'assets/images/svg/target.svg';
import Fighting from 'assets/images/fist.png';
import Run from 'assets/images/runner.png';
import axios from 'axios';
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";




export default function TargetLine7() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const today = new Date();
  const hourago = new Date(today.getTime());
  const timestr = moment(hourago).add(543, 'year').tz('Asia/Bangkok').format('DD/MM/YY');
  const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');


  const [no_data, setNo_data] = useState(false);
  const [line_monitoring, setLine_monitoring] = useState({
    LINE: "",
    SKU: "",
    FACTORY_DATE: "",
    TARGET: "",
    TARGET_UNIT: "",
    ACTUAL_WEIGHT: "",
    ACTUAL_WEIGHT_PERCENT: "",
    REMAINING: "",
    REMAINING_PERCENT: "",
    ACTUAL_FEED_RATE: "",
    STD_FEED_RATE: ""
  })
  /*setTimeout(() => {
    navigate('/targetLine7Page2');
  }, 5000);*/

  useEffect(() => {
    async function fetchMyAPI() {
      await axios.get(`${apiUrl.LINE_MONITORING_PAGE1}`, {
        "headers": {
          "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
        }
      }
      )
        .then(response => {
          //console.log(response.data)
          if (response.status == 200) {
            setLine_monitoring({
              LINE: response.data.LINE,
              SKU: response.data.SKU,
              FACTORY_DATE: response.data.FACTORY_DATE,
              TARGET: response.data.TARGET,
              TARGET_UNIT: response.data.TARGET_UNIT,
              ACTUAL_WEIGHT: response.data.ACTUAL_WEIGHT,
              ACTUAL_WEIGHT_PERCENT: response.data.ACTUAL_WEIGHT_PERCENT,
              REMAINING: response.data.REMAINING,
              REMAINING_PERCENT: response.data.REMAINING_PERCENT,
              ACTUAL_FEED_RATE: response.data.ACTUAL_FEED_RATE,
              STD_FEED_RATE: response.data.STD_FEED_RATE
            })
            setNo_data(1)

          }
        })
        .catch(error => {
          setNo_data(2)
          console.log('error: ', error)

        })
    }
    fetchMyAPI()


  }, [line_monitoring]);



  return (
    <>
      <Helmet>
        <title>Target Line7 | MES Korat Fur2</title>
      </Helmet>
        <Grid
          container spacing={1}
          direction="row"
          alignItems="top"
          justifyContent="center"
          style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 25, paddingTop: 75 }}
        >
          {/* Top row */}

          <Grid id="top-row" container sx={{ pt: 0 }} spacing={8}>
            <Box sx={{ width: '20%', bgcolor: "#2e9281" }}>
              <Typography style={{ color: "white", fontWeight: "bold", fontSize: 70, position: "relative", paddingLeft: 50 }} textAlign="center" >
                {no_data === 1 ? line_monitoring.LINE : 'NA'}
              </Typography>
            </Box>
            <Box sx={{ width: '80%', bgcolor: colors.while[100], display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", paddingLeft: 20 }} textAlign="center" >
              <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 70 }}>
                SKU :&nbsp;
              </Typography>
              <Typography style={{ color: "#2e9281", fontWeight: "bold", fontSize: 70 }}>
                {no_data === 1 ? line_monitoring.SKU : 'NA'}
              </Typography>
              <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 70, position: "relative", paddingLeft: 80 }}>
                {timestr}&nbsp;&nbsp;{timestrs}
              </Typography>
              <IconButton onClick={colorMode.toggleColorMode}>
                {theme.palette.mode === "dark" ? (
                  <DarkModeOutlinedIcon />
                ) : (
                  <LightModeOutlinedIcon />
                )}
              </IconButton>
            </Box>
          </Grid>



          <Grid id="top-row" container sx={{ pt: 0 }} spacing={5}>
            <Box sx={{ width: '100%', bgcolor: "#bcd6d0", display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "2vw" }} textAlign="center" >
              <Typography style={{ position: "relative", paddingLeft: 350 }}>
                <img src={Target} height="150px" />
              </Typography>
              <Typography style={{ color: "#2e9281", fontSize: 100, position: "relative", paddingLeft: 20, pt: 0 }}>
                เป้าหมาย
              </Typography>
              <Typography style={{ color: "#2e9281", fontWeight: "bold", fontSize: 125, position: "relative", paddingLeft: 20 }}>
                {no_data === 1 ? (line_monitoring.TARGET).toLocaleString() : 'NA'}
              </Typography>
              <Typography style={{ color: "#2e9281", fontSize: 60, position: "relative", paddingLeft: 20, top: "1vw" }}>
                กก.
              </Typography>
            </Box>
          </Grid>


          <Grid container spacing={0} style={{ position: "relative", top: "-0.1vw", left: "0vw" }}>
            <Grid item xs={6}>
              <Box sx={{ height: '5%', display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "0vw" }} textAlign="center" >

              </Box>
              <Box sx={{ bgcolor: colors.while[100], display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "1vw" }} textAlign="center">
                <Typography style={{ position: "relative", paddingLeft: 130 }}>
                  <img src={Fighting} height="150px" />
                </Typography>
                <Typography style={{ color: colors.black[100], fontSize: 70, position: "relative", paddingLeft: 0, top: "2.5vw" }}>
                  ทำได้
                </Typography>
                <Typography style={{ color: "#b58514", fontSize: 110, position: "relative", paddingLeft: 5, top: "2vw" }}>
                  {no_data === 1 ? line_monitoring.ACTUAL_WEIGHT_PERCENT : 'NA'}%
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "0vw" }} >
                <Box sx={{ width: '80%', bgcolor: "#b58514", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", left: '5vw', borderRadius: 20 }} textAlign="center" >
                  <Typography style={{ color: "#ffffff", fontSize: 180 }}>
                    {no_data === 1 ? (line_monitoring.ACTUAL_WEIGHT).toLocaleString() : 'NA'}
                  </Typography>
                  <Typography style={{ color: "#ffffff", fontSize: 60, position: "relative", paddingLeft: 10, top: "1.7vw" }}>
                    กก.
                  </Typography>
                </Box>
              </Box>

            </Grid>

            <Grid item xs={6}>
              <Box sx={{ height: '5%', display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "0vw" }} textAlign="center" >

              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "1vw" }} textAlign="center" >
                <Typography style={{ color: "white", fontWeight: "bold", fontSize: "24px", position: "relative", paddingLeft: 100 }}>
                  <img src={colors.icon[100]} height="150px" />
                </Typography>
                <Typography style={{ color: colors.black[100], fontSize: 70, position: "relative", paddingLeft: 0, top: "2.5vw" }}>
                  คงเหลือ
                </Typography>
                <Typography style={{ color: colors.grey[100], fontSize: 110, position: "relative", paddingLeft: 0, top: "2vw" }}>
                  {no_data === 1 ? line_monitoring.REMAINING_PERCENT : 'NA'}%
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "0vw" }} textAlign="center" >
                <Box sx={{ width: '80%', bgcolor: "#4a4a49", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", left: '5vw', borderRadius: 20 }} textAlign="center" >
                  <Typography style={{ color: "#ffffff", fontSize: 180 }}>
                    {no_data === 1 ? (line_monitoring.REMAINING).toLocaleString() : 'NA'}
                  </Typography>
                  <Typography style={{ color: "#ffffff", fontSize: 60, position: "relative", paddingLeft: 10, top: "1.7vw" }}>
                    กก.
                  </Typography>
                </Box>
              </Box>

            </Grid>
          </Grid>

          <Grid container spacing={0} style={{ position: "relative", top: "-2vw", left: "0vw" }}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "2vw" }} textAlign="center" >
                <Typography style={{ color: "#b58514", fontSize: 80, position: "relative", paddingLeft: 160, top: "0vw" }}>
                  {no_data === 1 ? (line_monitoring.ACTUAL_FEED_RATE).toLocaleString() : 'NA'}
                </Typography>
                <Typography style={{ color: colors.black[100], fontSize: 40, position: "relative", paddingLeft: 20, top: "0.6vw" }}>
                  กก./ชม.(Std. {no_data === 1 ? (line_monitoring.STD_FEED_RATE).toLocaleString() : 'NA'})
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

    </>
  );
}

