import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Time from 'assets/images/fast-time.png';
import Fighting from 'assets/images/fist.png';
import Run from 'assets/images/runner.png';
import Target from 'assets/images/svg/target.svg';
import moment from 'moment-timezone';
import BarChartTargetLine7 from "../components/chart/BarChartTargetLine7";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import { apiUrl } from "../constants";
import axios from 'axios';





const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  height: '95%',
  width: '98%',
  borderRadius: 25,
  color: theme.palette.text.secondary,
}));


export default function TargetLine7Page2() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const today = new Date();
  const hourago = new Date(today.getTime());
  const timestr = moment(hourago).add(543, 'year').tz('Asia/Bangkok').format('DD/MM/YY');
  const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');


  const [no_data, setNo_data] = useState(false);
  const [line_monitoring, setLine_monitoring] = useState({
    LINE: "",
    SKU: "",
    FACTORY_DATE: "",
    TARGET: "",
    TARGET_UNIT: "",
    ACTUAL_WEIGHT: "",
    ACTUAL_WEIGHT_PERCENT: "",
    REMAINING: "",
    REMAINING_PERCENT: "",
    ACTUAL_FEED_RATE: "",
    STD_FEED_RATE: "",
    TOTAL_RUNTIME: "",
    PRODUCTION_TIME: "",
    START_DATETIME: "",
    FINISH_DATETIME: "",
    FORECAST_FINISH_PRODUCTION_TIME: "",
    PRODUCTIVITY_TARGET_NOW: "",
    PRODUCTIVITY_TARGET_NOW_PERCENT: "",
    FORECAST_TOTAL_PRODUCTION: "",
    OPPORTUNITY_COST_OF_PRODUCTION: ""
  })

  useEffect(() => {
    async function fetchMyAPI() {
      await axios.get(`${apiUrl.LINE_MONITORING_PAGE2}`, {
        "headers": {
          "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
        }
      }
      )
        .then(response => {
          //console.log(response.data)
          if (response.status == 200) {
            setLine_monitoring({
              LINE: response.data.LINE,
              SKU: response.data.SKU,
              FACTORY_DATE: response.data.FACTORY_DATE,
              TARGET: response.data.TARGET,
              TARGET_UNIT: response.data.TARGET_UNIT,
              ACTUAL_WEIGHT: response.data.ACTUAL_WEIGHT,
              ACTUAL_WEIGHT_PERCENT: response.data.ACTUAL_WEIGHT_PERCENT,
              REMAINING: response.data.REMAINING,
              REMAINING_PERCENT: response.data.REMAINING_PERCENT,
              ACTUAL_FEED_RATE: response.data.ACTUAL_FEED_RATE,
              STD_FEED_RATE: response.data.STD_FEED_RATE,
              TOTAL_RUNTIME: response.data.TOTAL_RUNTIME,
              PRODUCTION_TIME: response.data.PRODUCTION_TIME,
              START_DATETIME: response.data.START_DATETIME,
              FINISH_DATETIME: response.data.FINISH_DATETIME,
              FORECAST_FINISH_PRODUCTION_TIME: response.data.FORECAST_FINISH_PRODUCTION_TIME,
              PRODUCTIVITY_TARGET_NOW: response.data.PRODUCTIVITY_TARGET_NOW,
              PRODUCTIVITY_TARGET_NOW_PERCENT: response.data.PRODUCTIVITY_TARGET_NOW_PERCENT,
              FORECAST_TOTAL_PRODUCTION: response.data.FORECAST_TOTAL_PRODUCTION,
              OPPORTUNITY_COST_OF_PRODUCTION: response.data.OPPORTUNITY_COST_OF_PRODUCTION,
            })
            setNo_data(1)

          }
        })
        .catch(error => {
          setNo_data(2)
          console.log('error: ', error)

        })
    }
    fetchMyAPI()



  }, [line_monitoring]);

  //console.log(line_monitoring.LINE)


  return (
    <>
      <Helmet>
        <title>Target Line7 Page 2 | MES Korat Fur2</title>
      </Helmet>
      <Grid
        container spacing={1}
        direction="row"
        alignItems="top"
        justifyContent="center"
        style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 25, paddingTop: 75 }}
      >
        {/* Top row */}

        <Grid id="top-row" container sx={{ pt: 0 }} spacing={8}>
          <Box sx={{ width: '20%', bgcolor: "#2e9281" }}>
            <Typography style={{ color: "white", fontWeight: "bold", fontSize: 70, position: "relative", paddingLeft: 50 }} textAlign="center" >
              {no_data === 1 ? line_monitoring.LINE : 'NA'}
            </Typography>
          </Box>
          <Box sx={{ width: '80%', bgcolor: colors.while[100], display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", paddingLeft: 20 }} textAlign="center" >
            <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 70 }}>
              SKU :&nbsp;
            </Typography>
            <Typography style={{ color: "#2e9281", fontWeight: "bold", fontSize: 70 }}>
              {no_data === 1 ? line_monitoring.SKU : 'NA'}
            </Typography>
            <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 70, position: "relative", paddingLeft: 80 }}>
              {timestr}&nbsp;&nbsp;{timestrs}
            </Typography>
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
          </Box>
        </Grid>



        <Grid id="top-row" container sx={{ pt: 0 }} spacing={5}>
          <Box sx={{ width: '100%', height: "100%", bgcolor: "#bcd6d0", display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "30px" }} textAlign="center" >
            <Typography sx={{ display: 'relative', pl: 20 }}>
              <img src={Time} height="110px" />
            </Typography>
            <Box sx={{ display: 'relative', pl: 20 }}>
              <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 35, position: "relative", top: "1vw" }}>
                เวลาเริ่มผลิต
              </Typography>
              <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 45, pl: 10 }}>
                {no_data === 1 ? moment(line_monitoring.START_DATETIME).format('HH:mm') : 'NA'}
              </Typography>
            </Box>
            <Box sx={{ display: 'relative', pl: 20 }}>
              <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 35, position: "relative", top: "1vw" }}>
                เวลาสิ้นสุด
              </Typography>
              <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 45, pl: 10, }}>
                {no_data === 1 ? moment(line_monitoring.FINISH_DATETIME).format('HH:mm') : 'NA'}
              </Typography>
            </Box>
            <Box sx={{ display: 'relative', pl: 20, height: 90, borderRight: "3px dashed #3cbea8" }}></Box>
            <Box sx={{ display: 'relative', pl: 20 }}>
              <Typography style={{ color: "#2e9281", fontWeight: "bold", fontSize: 35, position: "relative", top: "1vw" }}>
                เวลาผลิตรวม
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                <Typography style={{ color: "#8c8b8b", fontSize: 35, fontWeight: 'bold' }} textAlign="left">
                  {no_data === 1 ? line_monitoring.TOTAL_RUNTIME : 'NA'}
                </Typography>
                <Box sx={{ display: 'relative', pl: 2, height: 20, borderRight: "3px solid #3cbea8" }}></Box>
                <Typography style={{ color: "#2e9281", fontSize: 45, fontWeight: 'bold', position: "relative", left: "1vw" }} textAlign="left">
                  {no_data === 1 ? line_monitoring.PRODUCTION_TIME : 'NA'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid direction="rows" item xs={12} container style={{ position: "relative", top: "2vw" }} spacing={0}>
          <Item sx={{ background: colors.while[100], border: "3px solid #f5f5f5" }}>
            <Grid container >
              <Grid item xs={6}>
                <Grid container >
                  <Grid item xs={6} style={{ position: "relative", left: "10vw" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                      <Typography style={{ color: "#b58514", fontSize: 50, fontWeight: 'bold' }} textAlign="left">
                        <img src={Fighting} height="80px" />
                      </Typography>
                      <Typography style={{ color: "#b58514", fontSize: 50, fontWeight: 'bold', position: "relative", top: "0.8vw", left: "4vw" }}>
                        {no_data === 1 ? line_monitoring.ACTUAL_WEIGHT_PERCENT : 'NA'}%
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                      <Typography style={{ color: colors.black[100], fontSize: 40, fontWeight: 'bold', position: "relative", top: "-1.5vw" }} textAlign="left">
                        ทำได้
                      </Typography>
                      <Typography style={{ color: "#b58514", fontSize: 70, fontWeight: 'bold', position: "relative", top: "-1.9vw" }} textAlign="left">
                        {no_data === 1 ? (line_monitoring.ACTUAL_WEIGHT).toLocaleString() : 'NA'}
                      </Typography>
                      <Typography style={{ color: colors.black[100], fontSize: 30, fontWeight: 'bold', position: "relative", top: "-1.2vw", left: "0.8vw" }} textAlign="left">
                        กก.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} style={{ position: "relative", left: "12vw" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                      <Typography style={{ color: "#5e5e5d", fontSize: 50, fontWeight: 'bold', position: "relative", top: "0.5vw" }} textAlign="left">
                        <img src={colors.icon[100]} height="80px" />
                      </Typography>
                      <Typography style={{ color: colors.grey[100], fontSize: 50, fontWeight: 'bold', position: "relative", top: "0.5vw", left: "7vw" }}>
                        {no_data === 1 ? line_monitoring.REMAINING_PERCENT : 'NA'}%
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                      <Typography style={{ color: colors.black[100], fontSize: 40, fontWeight: 'bold', position: "relative", top: "-1.5vw" }} textAlign="left">
                        คงเหลือ
                      </Typography>
                      <Typography style={{ color: colors.grey[100], fontSize: 70, fontWeight: 'bold', position: "relative", top: "-1.9vw", left: "1vw" }} textAlign="left">
                        {no_data === 1 ? (line_monitoring.REMAINING).toLocaleString() : 'NA'}
                      </Typography>
                      <Typography style={{ color: colors.black[100], fontSize: 30, fontWeight: 'bold', position: "relative", top: "-1.2vw", left: "1.7vw" }} textAlign="left">
                        กก.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12} style={{ position: "relative", top: "-2vw" }}>
                  <Typography style={{ color: "#ffffff", fontSize: "16px", fontFamily: 'Monospace', position: "relative", left: '5vw', top: "0px" }} textAlign="left">
                    {no_data === 1 ?
                      <BarChartTargetLine7
                        target_quantity={line_monitoring.TARGET}
                        actual_weight={line_monitoring.ACTUAL_WEIGHT}
                        target_now={line_monitoring.PRODUCTIVITY_TARGET_NOW}
                        persent={line_monitoring.PRODUCTIVITY_TARGET_NOW_PERCENT}
                        height='90%'
                      />
                      :
                      console.log()
                    }
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'relative', pl: 40 }}>
                  <tr>
                    <td ><img src={Target} height="80px" /></td>
                    <td >
                      <Typography style={{ color: "#2e9281", fontWeight: "bold", fontSize: 50, position: "relative", top: "-25px", left: "5px" }}>
                        &nbsp;เป้าหมาย
                      </Typography>
                    </td>
                  </tr>
                  <Typography sx={{ display: 'relative', pl: 3, pt: 0 }} style={{ position: "relative", top: "-3vw" }} >
                    <tr>
                      <td style={{ color: "#2e9281", fontSize: 80, fontWeight: 'bold' }}>{no_data === 1 ? (line_monitoring.TARGET).toLocaleString() : 'NA'}</td>
                      <td style={{ color: "#2e9281", fontSize: 40, fontWeight: 'bold' }}>กก.</td>
                    </tr>
                  </Typography>

                  <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 50, position: "relative", top: "-4vw", left: "1vw" }} textAlign="left">
                    ปริมาณการผลิต
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Item>
        </Grid>

        <Grid container item xs={12} >
          <Grid container>
            <Grid item xs={6} style={{ position: "relative", left: "16vw" }}>
              <Typography style={{ color: colors.black[100], fontSize: 40, position: "relative", top: "1.5vw" }}>
                ประสิทธิภาพการผลิต(กระบวนการทำสุก)
              </Typography>
              <tr>
                <td>
                  <Typography style={{ color: "#b58514", fontSize: 60, fontWeight: 'bold', position: "relative", left: "9vw", top: "0.5vw" }}>
                    {no_data === 1 ? (line_monitoring.ACTUAL_FEED_RATE).toLocaleString() : 'NA'}
                  </Typography>
                </td>
                <td>
                  <Typography style={{ color: colors.black[100], fontSize: 30, fontWeight: 'bold', position: "relative", left: "10vw", top: "0.5vw" }}>
                    กก./ชม.
                  </Typography>
                </td>
              </tr>
              <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 30, position: "relative", left: "7vw", top: "-0.5vw" }} >
                มาตรฐาน {no_data === 1 ? (line_monitoring.STD_FEED_RATE).toLocaleString() : 'NA'} กก./ชม.
              </Typography>
            </Grid>

            <Grid item xs={6} >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
                <Typography style={{ color: colors.black[100], fontSize: 40, position: "relative", left: "21vw", top: "1.5vw" }}>
                  คาดการณ์ผลผลิตรวม
                </Typography>
              </Box>

              <Box sx={{ width: '40%', height: '50%', bgcolor: "#ebb4a9", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="200px" style={{ position: "relative", left: '20vw', borderRadius: 20, top: "1.5vw" }} textAlign="center" >
                <Box sx={{ display: 'relative', pl: 0 }}>
                  <tr>
                    <td >
                      <Typography style={{ color: "#d14d32", fontSize: 60, fontWeight: 'bold', position: "relative", top: "1vw" }}>
                        {no_data === 1 ? (line_monitoring.FORECAST_TOTAL_PRODUCTION).toLocaleString() : 'NA'}
                      </Typography>
                    </td>
                    <td>
                      <Typography style={{ color: "#d14d32", fontSize: 30, fontWeight: 'bold', position: "relative", top: "1vw" }}>
                        กก.
                      </Typography>
                    </td>
                  </tr>
                  <Typography style={{ color: "#d14d32", fontWeight: "bold", fontSize: 36, position: "relative", top: "-1vw", left: "1vw" }}>
                    {no_data === 1 ? (line_monitoring.OPPORTUNITY_COST_OF_PRODUCTION).toLocaleString() : 'NA'}
                  </Typography>
                </Box>
              </Box>

            </Grid>
          </Grid>
        </Grid>
      </Grid>


    </>
  );
}

