import { useRef, useEffect, useState } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { apiUrl } from '../../../constants'
import axios from 'axios'
import db from "firebase-config"
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "theme";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from 'moment-timezone';
import Target from "assets/images/svg/target.svg"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import Fighting from "assets/images/first1.gif";
import Running from "assets/images/running.gif"
import Run from 'assets/images/running_png.png';
import Fist from 'assets/images/protest_png.png';
import HeaderDetail from "components/header/HeaderDetail";
import Time from "assets/images/fast-time.png";
import GrigToStartTime from "components/grid/GridToStartTime";
import BarChartTargetLine7 from "components/chart/BarChartTargetLine7";
import Pin from "assets/images/pin_light.png";




const ItemTarget = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(0),
    textAlign: 'center',
    border: "2px solid #4a4a49",
    borderRadius: 10,
    //height: '102%'

}));

const ItemBackground = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(1),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    borderRadius: 0,
    //height: '65vh'

}));


const ProductionLine9P2 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');

    const [no_data, setNo_data] = useState(false);
    const [performanceP2, setPerformanceP2] = useState({
        WARNING: "",
        LINE: "",
        SKU: "",
        FACTORY_DATE: "",
        SHIFT: "",
        TARGET: "",
        TARGET_UNIT: "",
        ACTUAL_WEIGHT: "",
        ACTUAL_WEIGHT_PERCENT: "",
        REMAINING: "",
        REMAINING_PERCENT: "",
        ACTUAL_FEED_RATE: "",
        STD_FEED_RATE: "",
        TOTAL_RUNTIME: "",
        PRODUCTION_TIME: "",
        NON_PRODUCTION_TIME: "",
        START_DATETIME: "",
        FINISH_DATETIME: "",
        FORECAST_FINISH_PRODUCTION_TIME: "",
        PRODUCTIVITY_TARGET_NOW: "",
        PRODUCTIVITY_TARGET_NOW_PERCENT: "",
        FORECAST_TOTAL_PRODUCTION: "",
        OPPORTUNITY_COST_OF_PRODUCTION: "",
        PRODUCTION_EFFICIENCY: ""
    })

    const [taglist, setTaglist] = useState([]);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.PERFORMANCE_LINE9_PAGE2}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    if (response.status == 200) {
                        setPerformanceP2({
                            WARNING: response.data.WARNING,
                            LINE: response.data.LINE,
                            SKU: response.data.SKU,
                            FACTORY_DATE: response.data.FACTORY_DATE,
                            SHIFT: response.data.SHIFT,
                            TARGET: response.data.TARGET,
                            TARGET_UNIT: response.data.TARGET_UNIT,
                            ACTUAL_WEIGHT: response.data.ACTUAL_WEIGHT,
                            ACTUAL_WEIGHT_PERCENT: response.data.ACTUAL_WEIGHT_PERCENT,
                            REMAINING: response.data.REMAINING,
                            REMAINING_PERCENT: response.data.REMAINING_PERCENT,
                            ACTUAL_FEED_RATE: response.data.ACTUAL_FEED_RATE,
                            STD_FEED_RATE: response.data.STD_FEED_RATE,
                            TOTAL_RUNTIME: response.data.TOTAL_RUNTIME,
                            PRODUCTION_TIME: response.data.PRODUCTION_TIME,
                            NON_PRODUCTION_TIME: response.data.NON_PRODUCTION_TIME,
                            START_DATETIME: response.data.START_DATETIME,
                            FINISH_DATETIME: response.data.FINISH_DATETIME,
                            FORECAST_FINISH_PRODUCTION_TIME: response.data.FORECAST_FINISH_PRODUCTION_TIME,
                            PRODUCTIVITY_TARGET_NOW: response.data.PRODUCTIVITY_TARGET_NOW,
                            PRODUCTIVITY_TARGET_NOW_PERCENT: response.data.PRODUCTIVITY_TARGET_NOW_PERCENT,
                            FORECAST_TOTAL_PRODUCTION: response.data.FORECAST_TOTAL_PRODUCTION,
                            OPPORTUNITY_COST_OF_PRODUCTION: response.data.OPPORTUNITY_COST_OF_PRODUCTION,
                            PRODUCTION_EFFICIENCY: response.data.PRODUCTION_EFFICIENCY
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }

    }, []);

    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_line_monitoring_tag_list")
            .where("TAG", "==", "ACCUMURATE_WEIGHT")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setTaglist(getPostFromFirebase);
            });

        // return cleanup function
        return () => subscriber();
    }, [taglist]);


    var accumurate_weight = taglist.find((user) => user.TAG.includes("ACCUMURATE_WEIGHT"));

    ///// split time non production time

    var non_production_time = performanceP2.NON_PRODUCTION_TIME != 'NA' ? (performanceP2.NON_PRODUCTION_TIME).split(":") : "00:00"

    var non_production_hour = Number(non_production_time[0])

    var non_production_minute = Number(non_production_time[1])

    ///// split time production time

    var production_time = performanceP2.PRODUCTION_TIME != 'NA' ? (performanceP2.PRODUCTION_TIME).split(":") : "00:00"

    var production_hour = Number(production_time[0])

    var production_minute = Number(production_time[1])

    const getChartColor = () => {
        let color;
        try {
            if ((performanceP2.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP2.ACTUAL_WEIGHT) >= ((((production_hour + (production_minute / 60)) + (non_production_hour + (non_production_minute / 60))) * (performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP2.STD_FEED_RATE)) * 1)) {
                color = "#2e9281"
            } else if ((performanceP2.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP2.ACTUAL_WEIGHT) >= ((((production_hour + (production_minute / 60)) + (non_production_hour + (non_production_minute / 60))) * (performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP2.STD_FEED_RATE)) * 0.8)) {
                color = "#b58514"
            } else if ((performanceP2.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP2.ACTUAL_WEIGHT) < ((((production_hour + (production_minute / 60)) + (non_production_hour + (non_production_minute / 60))) * (performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP2.STD_FEED_RATE)) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getFeedrateColor = () => {
        let color;
        try {
            if ((performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) >= (((performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP2.STD_FEED_RATE) * 1)) {
                color = "#2e9281"
            } else if ((performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) >= (((performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP2.STD_FEED_RATE) * 0.8)) {
                color = "#b58514"
            } else if ((performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) < (((performanceP2.STD_FEED_RATE == 'NA' || performanceP2.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP2.STD_FEED_RATE) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getForecastColor = () => {
        let color;
        try {
            if ((performanceP2.FORECAST_TOTAL_PRODUCTION == 'NA' ? 0 : performanceP2.FORECAST_TOTAL_PRODUCTION) >= (performanceP2.TARGET == 'NA' ? 0 : performanceP2.TARGET)) {
                color = "#2e9281"
            } else if ((performanceP2.FORECAST_TOTAL_PRODUCTION == 'NA' ? 0 : performanceP2.FORECAST_TOTAL_PRODUCTION) < (performanceP2.TARGET == 'NA' ? 0 : performanceP2.TARGET)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }


    return (
        <>
            <Helmet>
                <title>Production Tracking Line9 Page2 | MES</title>
            </Helmet>
            <Grid item xs={12}>
                <HeaderDetail
                    line={no_data === 1 ? (performanceP2.LINE == 'NA' ? 'Line9' : performanceP2.LINE) : 'Line9'}
                    sku={no_data === 1 ? (performanceP2.WARNING == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : performanceP2.WARNING == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? performanceP2.SKU : performanceP2.SKU) : '-'}
                    timestr={timestr}
                    icon={performanceP2.SHIFT === "N" ? (<DarkModeOutlinedIcon style={{ color: '#ffffff' }} />) : (<LightModeOutlinedIcon style={{ color: '#ffffff' }} />)}
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <GrigToStartTime
                    image={<img src={Time} height="55vh" style={{ position: "relative", top: '0.5vw' }} />}
                    start_time={no_data === 1 ? (performanceP2.START_DATETIME == 'NA' ? '-' : moment(performanceP2.START_DATETIME).format('HH:mm')) : '00:00'}
                    end_time={no_data === 1 ? (performanceP2.FINISH_DATETIME == 'NA' ? performanceP2.FORECAST_FINISH_PRODUCTION_TIME == 'NA' || performanceP2.FORECAST_FINISH_PRODUCTION_TIME == null ? '-' : moment(performanceP2.FORECAST_FINISH_PRODUCTION_TIME).format('HH:mm') : moment(performanceP2.FINISH_DATETIME).format('HH:mm')) : "00:00"}
                    production_time_hour={no_data === 1 ? (performanceP2.PRODUCTION_TIME == 'NA' ? "-" : production_hour) : 0}
                    production_time_minute={no_data === 1 ? (performanceP2.PRODUCTION_TIME == 'NA' ? "-" : production_minute) : 0}
                    non_production_time_hour={no_data === 1 ? (performanceP2.NON_PRODUCTION_TIME == 'NA' ? '-' : non_production_hour) : 0}
                    non_production_time_minute={no_data === 1 ? (performanceP2.NON_PRODUCTION_TIME == 'NA' ? '-' : non_production_minute) : 0}
                />

            </Grid>

            <Grid item xs={12} sm={12}>
                <ItemBackground>
                    <Grid item xs={12} sm={12} style={{ paddingTop: 0, paddingRight: 5, paddingLeft: 5 }}>
                        <ItemTarget style={{height: '45vh'}}>
                            <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }}>
                                <Grid item xs={7} style={{ paddingLeft: 50 }}>
                                    <Grid item xs={12} textAlign="center" style={{ paddingTop: 5 }}>
                                        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                            <Grid item xs={6}>
                                                <table>
                                                    <tbody>
                                                        <tr >
                                                            <td style={{ width: '30%', fontSize: 14, fontWeight: 'bold,' }} align="right">
                                                                {accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Fighting} height="35vh" /> : <img src={Fist} height="35vh" />) : <img src={Fist} height="35vh" />}
                                                            </td>
                                                            <td style={{ width: '20%', color: no_data == 1 ? getChartColor() : colors.green[100], fontSize: 30, fontWeight: 'bold' }} align="right">
                                                                {no_data === 1 ? performanceP2.ACTUAL_WEIGHT_PERCENT : 0}
                                                            </td>
                                                            <td style={{ width: '20%', color: no_data == 1 ? getChartColor() : colors.green[100], fontSize: 30, fontWeight: 'bold' }} align="left">
                                                                %
                                                            </td>
                                                        </tr>

                                                        <tr >
                                                            <td style={{ width: '30%', color: colors.black[100], fontSize: 25, fontWeight: 'bold', lineHeight: "4vh", paddingTop: 2 }} align="right">
                                                                Actual
                                                            </td>
                                                            <td style={{ width: '20%', color: no_data == 1 ? getChartColor() : colors.green[100], fontSize: 35, fontWeight: 'bold', lineHeight: "4vh" }} align="right">
                                                                {no_data === 1 ? (performanceP2.ACTUAL_WEIGHT).toLocaleString() : 0}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.black[100], fontSize: 20, fontWeight: 'bold', lineHeight: "4vh", paddingTop: 5 }} align="left">
                                                                kg
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <table>
                                                    <tbody>
                                                        <tr >
                                                            <td style={{ width: '30%', fontSize: 14, fontWeight: 'bold,' }} align="right">
                                                                {accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Running} height="35vh" /> : <img src={Run} height="35vh" />) : <img src={Run} height="35vh" />}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.grey[100], fontSize: 30, fontWeight: 'bold' }} align="right">
                                                                {no_data === 1 ? (performanceP2.ACTUAL_WEIGHT_PERCENT === 'NA' ? 'NA' : 100 - performanceP2.ACTUAL_WEIGHT_PERCENT) : 0}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.grey[100], fontSize: 30, fontWeight: 'bold' }} align="left">
                                                                %
                                                            </td>
                                                        </tr>

                                                        <tr >
                                                            <td style={{ width: '30%', color: colors.black[100], fontSize: 25, fontWeight: 'bold', lineHeight: "4vh", paddingTop: 2 }} align="right">
                                                                Diff.
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.grey[100], fontSize: 35, fontWeight: 'bold', lineHeight: "4vh" }} align="right">
                                                                {no_data === 1 ? (performanceP2.REMAINING).toLocaleString() : 0}
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.grey[100], fontSize: 20, fontWeight: 'bold', lineHeight: "4vh", paddingTop: 5 }} align="left">
                                                                kg
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={9} textAlign="left" style={{ paddingTop: 15, paddingRight: 0 }}>
                                        {(performanceP2.TARGET ===
                                            ''
                                            && performanceP2.ACTUAL_WEIGHT ===
                                            ''
                                            && performanceP2.PRODUCTIVITY_TARGET_NOW ===
                                            ''
                                            && performanceP2.PRODUCTIVITY_TARGET_NOW_PERCENT ===
                                            ''
                                        ) || (performanceP2.TARGET ===
                                            'NA'
                                            && performanceP2.ACTUAL_WEIGHT ===
                                            'NA'
                                            ) ?
                                            <BarChartTargetLine7
                                                target_quantity={0}
                                                actual_weight={0}
                                                target_now={0}
                                                persent={0}
                                                //chart_color={getChartColor()}
                                                position={40}
                                                fontsize={24}
                                                width_svg={18}
                                                height_svg={18}
                                                height='55%'
                                            />
                                            : <BarChartTargetLine7
                                                target_quantity={performanceP2.TARGET === 'NA' ? 0 : performanceP2.TARGET}
                                                actual_weight={performanceP2.ACTUAL_WEIGHT === 'NA' ? 0 : performanceP2.ACTUAL_WEIGHT}
                                                target_now={performanceP2.PRODUCTIVITY_TARGET_NOW === 'NA' ? 0 : performanceP2.PRODUCTIVITY_TARGET_NOW}
                                                persent={performanceP2.PRODUCTIVITY_TARGET_NOW_PERCENT === 'NA' ? 0 : performanceP2.PRODUCTIVITY_TARGET_NOW_PERCENT}
                                                chart_color={getChartColor()}
                                                position={performanceP2.PRODUCTIVITY_TARGET_NOW_PERCENT === 'NA' ? 0 : (performanceP2.PRODUCTIVITY_TARGET_NOW_PERCENT <= 50 ? 40 : -60)}
                                                fontsize={24}
                                                width_svg={18}
                                                height_svg={18}
                                                pin={Pin}
                                                height='55%'
                                                width='125%'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={5} style={{ paddingTop: 0 }}>
                                    <Grid item xs={12} textAlign="center">
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <img src={Target} height="40px" />
                                            <Typography style={{ color: colors.green[100], fontWeight: "bold", fontSize: 35, paddingTop: 30 }}>
                                                &nbsp;Target
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} textAlign="center" >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <Typography style={{ color: colors.green[100], fontSize: 38, fontWeight: 'bold', lineHeight: "4vh" }}>
                                                &nbsp;{no_data === 1 ? (performanceP2.TARGET).toLocaleString() : 0}
                                            </Typography>
                                            <Typography style={{ color: colors.green[100], fontSize: 25, fontWeight: 'bold', lineHeight: "4vh", paddingTop: 6 }}>
                                                &nbsp;kg
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ItemTarget>
                    </Grid>
                </ItemBackground>
            </Grid>

            <Grid item xs={12} sm={12} style={{ paddingTop: 0, paddingRight: 5, paddingLeft: 5}}>
                <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }}>
                    <Grid item xs={7} style={{ paddingLeft: 50 }}>
                        <Grid item xs={12} textAlign="center">
                            <Typography style={{ color: colors.black[100], fontSize: 25, lineHeight: "6vh" }} >
                                Performance ({no_data === 1 ? (performanceP2.STD_FEED_RATE == "ไม่มีข้อมูล Std. Cap ในระบบ" ? (performanceP2.STD_FEED_RATE).toLocaleString() : "Std." + (performanceP2.STD_FEED_RATE).toLocaleString()) : "Std." + (0)})
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center" >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: no_data == 1 ? getFeedrateColor() : colors.green[100], fontSize: 38, fontWeight: 'bold', lineHeight: "9.5vh" }} >
                                    {no_data === 1 ? (performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 'NA' : (Math.round(performanceP2.PRODUCTION_EFFICIENCY)).toLocaleString()) : 0}
                                </Typography>
                                <Typography style={{ color: colors.black[100], fontSize: 22, paddingTop: 5, lineHeight: "9.5vh" }} >
                                    &nbsp;kg/hr
                                </Typography>
                                <Typography style={{ color: colors.black[100], fontSize: 26, paddingTop: 2, lineHeight: "9.5vh" }} >
                                    &nbsp;(Realtime {accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? (no_data == 1 ? (performanceP2.ACTUAL_FEED_RATE).toLocaleString() : 0) : 0) : 0})
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid item xs={12} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 25, lineHeight: "6vh" }}>
                                Forecast
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center" style={{}}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: colors.black[100], fontSize: 35, fontWeight: 'bold', lineHeight: "9.5vh" }} >
                                    {no_data === 1 ? (performanceP2.FORECAST_TOTAL_PRODUCTION).toLocaleString() : 0}
                                </Typography>
                                <Typography style={{ color: colors.black[100], fontSize: 22, paddingTop: 5, lineHeight: "9.5vh" }} >
                                    &nbsp;kg
                                </Typography>
                                <Typography style={{ color: no_data == 1 ? getForecastColor() : colors.green[100], fontWeight: "bold", fontSize: 26, paddingTop: 2, lineHeight: "9.5vh" }} >
                                    &nbsp;({no_data === 1 ? (performanceP2.OPPORTUNITY_COST_OF_PRODUCTION >= 0 ? "+" + (performanceP2.OPPORTUNITY_COST_OF_PRODUCTION).toLocaleString() : (performanceP2.OPPORTUNITY_COST_OF_PRODUCTION).toLocaleString()) : 0})
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default ProductionLine9P2
