import { useRef, useEffect, useState } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { apiUrl } from '../../../constants'
import db from "firebase-config"
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "theme";
import { styled } from '@mui/material/styles';
import moment from 'moment-timezone';
import Paper from '@mui/material/Paper';
import Target from "assets/images/svg/target.svg"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import Fighting from "assets/images/first1.gif";
import Running from "assets/images/running.gif"
import Run from 'assets/images/running_png.png';
import Fist from 'assets/images/protest_png.png';
import HeaderDetail from "components/header/HeaderDetail";
import GrigToProductionTracking from "components/grid/GridToProductionTracking";
import Popup from 'components/model/Popup';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#bcd6d0',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    //borderRadius: 10,
    //height: '102%'

}));

const ItemTarget = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    borderRadius: 0,
    //height: '65vh'

}));


const ProductionFPPLine2P1 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');
    const day = hourago.toLocaleDateString('th-TH', {
        year: '2-digit',
        month: 'short',
        day: 'numeric',
    })
    const [performanceP1, setPerformanceP1] = useState([]);
    const [on_error, setNo_error] = useState([]);

    useEffect(() => {
        const subscriberPerformance = db
            .collection("line_monitoring_performance_tracking_page1")
            .onSnapshot((querySnapshot) => {
                const performanceData = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setPerformanceP1(performanceData);
            });

        const subscriberError = db
            .collection("meskr_keeplive")
            .where("ID", "==", "Further.4071.00000000000000.Thailand.Protrax.Packing.LINE2.0NoError")
            .onSnapshot((querySnapshot) => {
                const errorData = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setNo_error(errorData);
            });

        // Cleanup listeners
        return () => {
            subscriberPerformance();
            subscriberError();
        };
    }, []);


    const active_sku = performanceP1.find((x) => x.id.includes("4071.00000000000000.LINE2ACTIVE_SKU")) || { ACTIVE_SKU: "-" };
    const actual_pack = performanceP1.find((x) => x.id.includes("4071.00000000000000.LINE2ACTUAL_PACK")) || { ACTUAL_PACK: 0 };
    const actual_kg = performanceP1.find((x) => x.id.includes("4071.00000000000000.LINE2ACTUAL_KG")) || { ACTUAL_KG: 0 };
    const shift = performanceP1.find((x) => x.id.includes("4071.00000000000000.LINE2SHIFT")) || { SHIFT: "" };
    const status = performanceP1.find((x) => x.id.includes("4071.00000000000000.LINE2STATUS")) || { STATUS: 0 };
    const target_kg = performanceP1.find((x) => x.id.includes("4071.00000000000000.LINE2TARGET_KG")) || { TARGET_KG: 0 };
    const warning = performanceP1.find((x) => x.id.includes("4071.00000000000000.LINE2WARNING")) || { MESSAGE: null };

    const remaining = Math.round(target_kg?.TARGET_KG) - Math.round(actual_kg?.ACTUAL_KG) <= 0 ? 0 : Math.round(target_kg?.TARGET_KG) - Math.round(actual_kg?.ACTUAL_KG) || 0;
    const persent_actual = (actual_kg?.ACTUAL_KG * 100) / target_kg?.TARGET_KG || 0;

    const packing_line2_no_error = on_error.find((user) => user.ID.includes("Further.4071.00000000000000.Thailand.Protrax.Packing.LINE2.0NoError"));

    const isError = packing_line2_no_error?.VALUE === false;






    return (
        <>
            {isError ? <Popup /> :
                <Helmet>
                    <title>Production Tracking Line2 Page1 | FPP1</title>
                </Helmet>
            }
            <Grid item xs={12}>
                <HeaderDetail
                    line={'Line2'}
                    sku={warning?.MESSAGE == 'ไม่มีแผนผลิต' ? 'ไม่ตรงแผนผลิต' : actual_kg?.ACTUAL_KG == null ? active_sku?.ACTIVE_SKU : active_sku?.ACTIVE_SKU}
                    timestr={timestr}
                    icon={shift?.SHIFT === "N" ? (<DarkModeOutlinedIcon style={{ color: '#ffffff' }} />) : (<LightModeOutlinedIcon style={{ color: '#ffffff' }} />)}
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <Item>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                        <Typography style={{ color: '#b58514', fontSize: 20, lineHeight: "0px", fontWeight: 'bold' }} >
                            <img src={Target} height="60px" />
                        </Typography>
                        <Typography style={{ color: colors.green[100], fontSize: 45, fontWeight: 'bold', lineHeight: "0px", paddingTop: 5 }} >
                            &nbsp;เป้าหมาย&nbsp;
                        </Typography>
                        <Typography style={{ color: colors.green[100], fontSize: 55, lineHeight: "0px", fontWeight: 'bold' }} >
                            {warning?.MESSAGE == 'ไม่มีแผนผลิต' ? '-' : actual_kg?.ACTUAL_KG == null ? Math.round(target_kg?.TARGET_KG).toLocaleString() : Math.round(target_kg?.TARGET_KG).toLocaleString()}&nbsp;
                        </Typography>
                        <Typography style={{ color: colors.green[100], fontSize: 35, lineHeight: "0px", fontWeight: 'bold', paddingTop: 10 }} >
                            กก.
                        </Typography>
                    </Box>
                </Item>
            </Grid>

            <Grid item xs={12} sm={12}>
                <ItemTarget>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
                        <GrigToProductionTracking
                            color={colors.green[100]}
                            image={status?.STATUS == 1 ? <img src={Fighting} height="50vh" /> : <img src={Fist} height="50vh" />}
                            section={'ทำได้'}
                            persent={warning?.MESSAGE == 'ไม่มีแผนผลิต' ? '-' : actual_kg?.ACTUAL_KG == null ? 0 : Math.round(persent_actual)}
                            color_persent={colors.green[100]}
                            value={warning?.MESSAGE == 'ไม่มีแผนผลิต' ? '-' : actual_kg?.ACTUAL_KG == null ? 0 : Math.round(actual_kg?.ACTUAL_KG).toLocaleString()}
                            unit={'กก.'}
                        />

                        <GrigToProductionTracking
                            color={'#4a4a49'}
                            image={status?.STATUS == 1 ? <img src={Running} height="50vh" /> : <img src={Run} height="50vh" />}
                            section={'คงเหลือ'}
                            persent={warning?.MESSAGE == 'ไม่มีแผนผลิต' ? '-' : actual_kg?.ACTUAL_KG == null ? 0 : (100 - Math.round(persent_actual) <= 0 ? 0 : 100 - Math.round(persent_actual))}
                            color_persent={colors.grey[100]}
                            value={warning?.MESSAGE == 'ไม่มีแผนผลิต' ? '-' : actual_kg?.ACTUAL_KG == null ? 0 : (remaining).toLocaleString()}
                            unit={'กก.'}
                        />
                    </Grid>

                    {actual_kg?.ACTUAL_KG == null ?
                        <Grid item xs={12} textAlign="left" style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
                                <Grid item xs={6}>
                                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'center' }}>
                                        <Typography style={{ color: '#2e9281', fontSize: 35, lineHeight: "15vh" }}>
                                            {actual_kg?.ACTUAL_KG == null ? Math.round(actual_pack?.ACTUAL_PACK).toLocaleString() : ''}
                                        </Typography>
                                        <Typography style={{ color: '#ffffff', fontSize: 20, paddingTop: 4, lineHeight: "15vh" }}>
                                            &nbsp;ถุง (ไม่มีข้อมูล Std. Cap ในระบบ)
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>

                                </Grid>
                            </Grid>
                        </Grid>
                        : ''}
                </ItemTarget>
            </Grid>


        </>
    )
}

export default ProductionFPPLine2P1
