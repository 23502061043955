import React, { useEffect, useState } from "react";
import QrReader from "react-qr-reader";
import { Helmet } from "react-helmet";
import { Box, Grid, Card, Container, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import '../styles.css'
import TextField from '@mui/material/TextField';
import axios from 'axios';
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


var token_url = apiUrl.TOKEN;

export default function Qrcode() {
  const today = new Date();
  const hourago = new Date(today.getTime());
  const datetime = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');

  const [openConfirmChangeMeatFromQrcode, setOpenConfirmChangeMeatFromQrcode] = useState();
  const [openConfirmChangeMeatFromAPI, setOpenConfirmChangeMeatFromAPI] = useState();

  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [laserStar, setLaserStar] = useState(false);

  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");
  const [dataLaser, setDataLaser] = useState("");
  const [seleteMeatTankFromQrcod, setSeleteMeatTankFromQrcod] = useState(0);
  const [seleteMeatTankFromAPI, setSeleteMeatTankFromAPI] = useState(0);


  const [status_preparation_zone, setStatus_preparation_zon] = useState({
    LINE: "",
    MATERIAL_DESCRIPTION: "",
    BATCH: "",
    TOTAL_BATCH_WEIGHT: "",
    ACTUAL_BARREL_WEIGHT: "",
    ACTUAL_BARREL_MEAT: "",
    BARREL_WEIGHT: "",
    BARREL_MEAT: "",
    BARREL_TIMESTAMP: "",
    PROCESS_ORDER_NUMBER: ""
  })
  const [sum_preparation_zone, setSum_preparation_zone] = useState([]);
  const [noData, setNoData] = useState(false);
  const [SuccessfulFromQrcode, setSuccessfulFromQrcode] = useState();
  const [notifySubmittedSuccessfulFromQrcode, setNotifySubmittedSuccessfulFromQrcode] = useState(false);
  const [notifySubmittedFailedFromQrcode, setNotifySubmittedFailedFromQrcode] = useState(false);

  const [SuccessfulFromAPI, setSuccessfulFromAPI] = useState();
  const [notifySubmittedSuccessfulFromAPI, setNotifySubmittedSuccessfulFromAPI] = useState(false);
  const [notifySubmittedFailedFromAPI, setNotifySubmittedFailedFromAPI] = useState(false);

  const [insert_data, setInsert_data] = useState(false);
  const [insert_data_api, setInsert_data_api] = useState(false);


  const handleClickConfirmChangeMeatFromQrcode = () => {
    setOpenConfirmChangeMeatFromQrcode(true);
  };

  const handleCloseConfirmChangeMeatFromQrcode = () => {
    setOpenConfirmChangeMeatFromQrcode(false);
  };

  const handleClickConfirmChangeMeatFromAPI = () => {
    setOpenConfirmChangeMeatFromAPI(true);
  };

  const handleCloseConfirmChangeMeatFromAPI = () => {
    setOpenConfirmChangeMeatFromAPI(false);
  };

  const handleScan = async (scanData) => {
    setLoadingScan(true);
    //console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      //console.log(`loaded >>>`, scanData);
      setData(scanData);
      setStartScan(false);
      setLoadingScan(false);
      // setPrecScan(scanData);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  /*const handleLaserScan = async () => {
    setDataLaser();
    setLaserStar(false);
  };*/

  const handleSelectionLaserScan = (event) => {

    if (event.target.name === "laser_scan") {
        setData(event.target.value);
    }

  };

  const data_split = data.split("|")

  console.log(data_split)


  const checkToken = async () => {
    let access_exp = localStorage.getItem("rb_ac_exp");
    let refresh_exp = localStorage.getItem("rb_rf_exp");
    //console.log('access_exp: ' + access_exp);
    //console.log('refresh_exp: ' + refresh_exp);
    if (refresh_exp < Date.now() / 1000) {
      //console.log("refresh is expiried");
      // alert('Please log in again')
      localStorage.clear();
      window.location = "/Login";
    } else if (access_exp < Date.now() / 1000) {
      const refresh = localStorage.getItem("rb_rf_token");

      let data = {
        refresh: refresh,
      };
      let result = await axios.post(token_url, data);
      //console.log("got new access_token!");
      localStorage.setItem("rb_ac_token", result.data.access);
    }
  };

  useEffect(() => {
    async function fetchMyAPI() {
      // check access token expiry
      checkToken();
      //console.log(`${apiUrl.GET_PREPARATION_ZONE}${data_split[4]}`)
      var get_datakorat = `${apiUrl.GET_PREPARATION_ZONE}${data_split[4]}/` // date format must be YYYY-MM-DD, eg. 2022-06-16
      await axios.get(get_datakorat, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
          "X-xss-protection": "1; mode=block;",
          "X-frame-options": "SAMEORIGIN",
          "X-content-type-options": "nosniff",
          "Cache-Control": "no-cache, private",
          "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
          "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
        }
      }
      )
        .then(response => {
          // console.log(response)
          if (response.status == 200) {
            //console.log(response.data.map(x => x));
            let num = response.data.map(x => x)
            setStatus_preparation_zon({
              LINE: response.data[num.length - 1].LINE,
              MATERIAL_DESCRIPTION: response.data[num.length - 1].MATERIAL_DESCRIPTION,
              BATCH: response.data[num.length - 1].BATCH,
              TOTAL_BATCH_WEIGHT: response.data[num.length - 1].TOTAL_BATCH_WEIGHT,
              ACTUAL_BARREL_WEIGHT: response.data[num.length - 1].ACTUAL_BARREL_WEIGHT,
              ACTUAL_BARREL_MEAT: response.data[num.length - 1].ACTUAL_BARREL_MEAT,
              BARREL_WEIGHT: response.data[num.length - 1].BARREL_WEIGHT,
              BARREL_MEAT: response.data[num.length - 1].BARREL_MEAT,
              BARREL_TIMESTAMP: response.data[num.length - 1].BARREL_TIMESTAMP,
              PROCESS_ORDER_NUMBER: response.data[num.length - 1].PROCESS_ORDER_NUMBER
            })
            setSum_preparation_zone(response.data)
            //setSeleteMeatTankFromAPI(response.data[0].ACTUAL_BARREL_MEAT)
            setNoData(1)
          }


        })
        .catch(error => {
          setNoData(2)
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

  }, [status_preparation_zone, data_split[4]], sum_preparation_zone);

  // sum value in object (https://stackoverflow.com/questions/54862549/sum-up-all-score-values-from-object-arrays-inside-react-props)

  let sum = sum_preparation_zone.reduce(function (prev, current) {
    return prev + +current.ACTUAL_BARREL_WEIGHT
  }, 0);
  //console.log(sum)


  const handleSelectionInPreparationZoneFromAPI = (event) => {

    if (event.target.name === "meat_tank_api") {
      setSeleteMeatTankFromAPI(event.target.value);
    }

  };


  const comfirmInPreparationZoneinAPI = async () => {
    /*console.log({
      "process_order_number": status_preparation_zone.PROCESS_ORDER_NUMBER,
      "line": status_preparation_zone.LINE,
      "material_description": status_preparation_zone.MATERIAL_DESCRIPTION,
      "batch": status_preparation_zone.BATCH,
      "barrel_timestamp": status_preparation_zone.BARREL_TIMESTAMP,
      "barrel_weight": status_preparation_zone.BARREL_WEIGHT,
      "total_batch_weight": status_preparation_zone.TOTAL_BATCH_WEIGHT,
      "barrel_meat": status_preparation_zone.BARREL_MEAT == '' ? 0 : status_preparation_zone.BARREL_MEAT,
      "actual_barrel_weight": seleteMeatTankFromAPI,
      "actual_barrel_meat": seleteMeatTankFromAPI,
      "actual_timestamp": datetime,
      "username": localStorage.getItem('rb_email'),

    });*/

    await axios.post(apiUrl.POST_PREPARATION_ZONE, {
      "process_order_number": status_preparation_zone.PROCESS_ORDER_NUMBER,
      "line": status_preparation_zone.LINE,
      "material_description": status_preparation_zone.MATERIAL_DESCRIPTION,
      "batch": status_preparation_zone.BATCH,
      "barrel_timestamp": status_preparation_zone.BARREL_TIMESTAMP,
      "barrel_weight": status_preparation_zone.BARREL_WEIGHT,
      "total_batch_weight": status_preparation_zone.TOTAL_BATCH_WEIGHT,
      "barrel_meat": status_preparation_zone.BARREL_MEAT == '' ? 0 : status_preparation_zone.BARREL_MEAT,
      "actual_barrel_weight": seleteMeatTankFromAPI,
      "actual_barrel_meat": seleteMeatTankFromAPI,
      "actual_timestamp": datetime,
      "username": localStorage.getItem('rb_email'),

    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 201) {
          setSeleteMeatTankFromAPI()
          setSuccessfulFromAPI(1)
          setNotifySubmittedSuccessfulFromAPI(true);
          sessionStorage.setItem('process_order_number', response.data.process_order_number);
          sessionStorage.setItem('line_api', response.data.line);
          sessionStorage.setItem('material_description', response.data.material_description);
          sessionStorage.setItem('batch', response.data.batch);
          sessionStorage.setItem('barrel_timestamp', response.data.barrel_timestamp);
          sessionStorage.setItem('actual_barrel_weight', response.data.actual_barrel_weight);
          sessionStorage.setItem('total_batch_weight', response.data.total_batch_weight);
          sessionStorage.setItem('barrel_meat', response.data.barrel_meat);
          setInsert_data_api(1)
        }
        else {
          setInsert_data_api(2)
          setSuccessfulFromAPI(2)
          setNotifySubmittedFailedFromAPI(true);
        }
      })
      .catch(error => {
        console.log('error: ', error);
      })
  }


  const handleSelectionInPreparationZoneFromQrcode = (event) => {

    if (event.target.name === "meat_tank") {
      setSeleteMeatTankFromQrcod(event.target.value);
    }

  };


  const comfirmInPreparationZoneinQrcode = async () => {
    /*console.log({
      "process_order_number": (data_split[0] == ""  || data_split[0]  == " ") ? "-" : data_split[0],
      "line": (data_split[1] == "" || data_split[1] == " ") ? "-" : data_split[1],
      "material_description": (data_split[2] == "" || data_split[2] == " ") ? "-" : data_split[2],
      "batch": (data_split[3] == "" || data_split[3] == " ") ? "-" : data_split[3],
      "barrel_timestamp": (data_split[4] == "" || data_split == " ") ? "-" : data_split[4],
      "barrel_weight": (data_split[5] == "" || data_split[5] == " ") ? 0 : data_split[5],
      "total_batch_weight": (data_split[6] == "" || data_split[6] == " ") ? 0 : data_split[6],
      "barrel_meat": data_split[7] == undefined ? 0 : ((data_split[7] == "" || data_split[7] == " ") ? 0 : Number(data_split[7])),
      "actual_barrel_weight": seleteMeatTankFromQrcod > Number(data_split[5]) ? data_split[5] : seleteMeatTankFromQrcod,
      "actual_barrel_meat": seleteMeatTankFromQrcod > Number(data_split[5]) ? data_split[5] : seleteMeatTankFromQrcod,
      "actual_timestamp": datetime,
      "username": localStorage.getItem('rb_email'),
    });*/

    await axios.post(apiUrl.POST_PREPARATION_ZONE, {
      "process_order_number": (data_split[0] == ""  || data_split[0]  == " ") ? "-" : data_split[0],
      "line": (data_split[1] == "" || data_split[1] == " ") ? "-" : data_split[1],
      "material_description": (data_split[2] == "" || data_split[2] == " ") ? "-" : data_split[2],
      "batch": (data_split[3] == "" || data_split[3] == " ") ? "-" : data_split[3],
      "barrel_timestamp": (data_split[4] == "" || data_split == " ") ? "-" : data_split[4],
      "barrel_weight": (data_split[5] == "" || data_split[5] == " ") ? 0 : data_split[5],
      "total_batch_weight": (data_split[6] == "" || data_split[6] == " ") ? 0 : data_split[6],
      "barrel_meat": data_split[7] == undefined ? 0 : ((data_split[7] == "" || data_split[7] == " ") ? 0 : Number(data_split[7])),
      "actual_barrel_weight": seleteMeatTankFromQrcod > Number(data_split[5]) ? data_split[5] : seleteMeatTankFromQrcod,
      "actual_barrel_meat": seleteMeatTankFromQrcod > Number(data_split[5]) ? data_split[5] : seleteMeatTankFromQrcod,
      "actual_timestamp": datetime,
      "username": localStorage.getItem('rb_email'),

    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 201) {
          setSeleteMeatTankFromQrcod()
          setSuccessfulFromQrcode(1)
          setNotifySubmittedSuccessfulFromQrcode(true);
          sessionStorage.setItem('process_order_number', response.data.process_order_number);
          sessionStorage.setItem('line', response.data.line);
          sessionStorage.setItem('material_description', response.data.material_description);
          sessionStorage.setItem('batch', response.data.batch);
          sessionStorage.setItem('barrel_timestamp', response.data.barrel_timestamp);
          sessionStorage.setItem('actual_barrel_weight', response.data.actual_barrel_weight);
          sessionStorage.setItem('total_batch_weight', response.data.total_batch_weight);
          sessionStorage.setItem('barrel_meat', response.data.barrel_meat);
          setInsert_data(1)
        }
        else {
          setInsert_data(2)
          setSuccessfulFromQrcode(2)
          setNotifySubmittedFailedFromQrcode(true);
        }
      })
      .catch(error => {
        console.log('error: ', error);
      })
  }
  //console.log(insert_data)

  useEffect(() => {
    setSeleteMeatTankFromQrcod(data_split[5])
    setSeleteMeatTankFromAPI(status_preparation_zone.BARREL_WEIGHT - sum < 0 ? 0 : status_preparation_zone.BARREL_WEIGHT - sum)
  }, [data_split[5], status_preparation_zone.BARREL_WEIGHT, status_preparation_zone.ACTUAL_BARREL_WEIGHT]);



  useEffect(() => {
    setTimeout(() => {
      setLaserStar(true);
    }, 100); 
  }, []);

  //console.log(data.length)


  return (

    <div className="App">
      <Helmet>
        <title>Preparation Zone | MES Korat Fur2</title>
      </Helmet>
      <Container maxWidth={false} sx={{ p: 0 }}>
        <Grid
          container
          style={{ paddingTop: 60 }}
        >
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
            <Typography style={{ fontWeight: "bold", fontSize: 36, position: "relative", left: 0, paddingTop: 0 }} textAlign="center">
              Scan QRCode
            </Typography>
          </Grid>

          <Grid container spacing={2} style={{ position: "relative", top: '0vw', left: '0vw' }}>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
              <Grid item lg={1} md={1.5} sm={1.5} xl={1.5} xs={3.5} sx={{ display: 'flex', justifyContent: 'center' }} >
                <Button disabled={startScan == true} style={{ backgroundColor: startScan == true ? '#cfcfcf' :'#009688' }} variant="contained" onClick={() => { setLaserStar(!laserStar); }}>{laserStar ? "Laser Stop" : "Laser Start"}</Button>
              </Grid>

              <Grid item lg={1} md={1.5} sm={1.5} xl={1.5} xs={3.5} sx={{ display: 'flex', justifyContent: 'center' }} >
                <Button disabled={laserStar == true} style={{ backgroundColor: laserStar == true ? '#cfcfcf' : '#009688' }} variant="contained" onClick={() => { setStartScan(!startScan); }}>{startScan ? "Camera Stop" : "Camera Start"}</Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>
      <Box style={{ height: 10 }}>

      </Box>

      {laserStar  && data.length <= 0 && (
        <>
          <TextField
            autoFocus
            id="outlined-number"
            //type="number"
            name="laser_scan"
            value={data}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleSelectionLaserScan}
            rows={4}
          />
        </>
      )}

      {startScan && (
        <>
          <QrReader
            facingMode={selected}
            delay={1000}
            onError={handleError}
            onScan={handleScan}
            // chooseDeviceId={()=>selected}
            style={{ width: "300px" }}
          />
        </>
      )}
      {loadingScan &&
        <Typography style={{ fontSize: 22, position: "relative", left: 0, paddingTop: 10 }} textAlign="center">
          Loading...
        </Typography>
      }

      {data !== "" && noData == 1 &&
        <Grid container spacing={1} style={{ paddingLeft: 25, paddingTop: 25, paddingRight: 25 }} >
          <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" style={{ position: "relative", top: '-2vw', height: '12px' }}>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                PO Number
              </Typography>
              <TextField
                //required
                id="outlined-required"
                //type="number"
                name="po"
                value={status_preparation_zone.PROCESS_ORDER_NUMBER}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Line
              </Typography>
              <TextField
                //required
                id="outlined-required"
                //type="number"
                name="line"
                value={status_preparation_zone.LINE}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                SKU Name
              </Typography>
              <TextField
                //required
                id="outlined-required"
                //type="number"
                name="sku"
                value={status_preparation_zone.MATERIAL_DESCRIPTION}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Batch Number
              </Typography>
              <TextField
                //required
                id="outlined-required"
                //type="number"
                name="batch"
                value={status_preparation_zone.BATCH}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Weighting Time
              </Typography>
              <TextField
                //required
                id="outlined-required"
                //type="number"
                name="time"
                value={status_preparation_zone.BARREL_TIMESTAMP}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0 }}>
                Knead Meat Tank
              </Typography>
              <TextField
                //autoFocus
                id="outlined-number"
                type="number"
                name="meat_tank_api"
                value={seleteMeatTankFromAPI}
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={status_preparation_zone.BARREL_WEIGHT - sum < 0 ? 0 : status_preparation_zone.BARREL_WEIGHT - sum}
                onChange={handleSelectionInPreparationZoneFromAPI}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#000000",

                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#000000',
                    },
                  }
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Knead Meat Batch
              </Typography>
              <TextField
                id="outlined-number"
                type="number"
                name="meat_batch"
                value={status_preparation_zone.TOTAL_BATCH_WEIGHT}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Raw Meat
              </Typography>
              <TextField
                id="outlined-number"
                type="number"
                name="raw_meat"
                value={status_preparation_zone.BARREL_MEAT}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>


            <Box style={{ height: 10 }}>

            </Box>

            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
              <Button disabled={(status_preparation_zone.BARREL_WEIGHT - sum < 0 ? 0 : status_preparation_zone.BARREL_WEIGHT - sum) == 0} fullWidth style={{ backgroundColor: (status_preparation_zone.BARREL_WEIGHT - sum < 0 ? 0 : status_preparation_zone.BARREL_WEIGHT - sum) == 0 ? '#cfcfcf' : '#009688' }} variant="contained" onClick={() => { handleClickConfirmChangeMeatFromAPI(); }}>Confirm</Button>
            </Grid>

            <Box style={{ height: 10 }}>

            </Box>
          </Grid>

        </Grid>
      }

      {data !== "" && noData == 2 &&
        <Grid container spacing={1} style={{ paddingLeft: 25, paddingTop: 25, paddingRight: 25 }} >
          <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" style={{ position: "relative", top: '-2vw', height: '12px' }}>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                PO Number
              </Typography>
              <TextField
                //required
                id="outlined-required"
                //type="number"
                name="po"
                value={(data_split[0] == "" || data_split[0] == " ") ? "-" : data_split[0]}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Line
              </Typography>
              <TextField
                //required
                id="outlined-required"
                //type="number"
                name="line"
                value={(data_split[1] == "" || data_split[1] == " ") ? "-" : data_split[1]}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                SKU Name
              </Typography>
              <TextField
                //required
                id="outlined-required"
                //type="number"
                name="sku"
                value={(data_split[2] == "" || data_split[2]  == " ") ? "-" : data_split[2]}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Batch Number
              </Typography>
              <TextField
                //required
                id="outlined-required"
                //type="number"
                name="batch"
                value={(data_split[3] == "" || data_split[3] == " ") ? "-" : data_split[3]}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Weighting Time
              </Typography>
              <TextField
                //required
                id="outlined-required"
                //type="number"
                name="time"
                value={(data_split[4] == "" || data_split[4] == " ") ? "-" : data_split[4]}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0 }}>
                Knead Meat Tank
              </Typography>
              <TextField
                //autoFocus
                id="outlined-number"
                type="number"
                name="meat_tank"
                value={seleteMeatTankFromQrcod > Number(data_split[5]) ? data_split[5] : seleteMeatTankFromQrcod}
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={data_split[5]}
                onChange={handleSelectionInPreparationZoneFromQrcode}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#000000",

                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#000000',
                    },
                  }
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Knead Meat Batch
              </Typography>
              <TextField
                id="outlined-number"
                type="number"
                name="meat_batch"
                value={(data_split[6] == "" || data_split[6] == " ") ? 0 : data_split[6]}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            {data_split[7] == undefined ? '' :
              <Box>
                <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                  Raw Meat
                </Typography>
                <TextField
                  id="outlined-number"
                  type="number"
                  name="raw_meat"
                  value={(data_split[7] == "" || data_split[7] == " ") ? "-" : data_split[7]}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    width: '100%',
                    "& .MuiOutlinedInput-input": {
                      color: "#9e9e9e",

                    },
                  }}
                />
              </Box>}

            {/*<Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Raw Meat 02
              </Typography>
              <TextField
                id="outlined-number"
                type="number"
                name="raw_meat02"
                value={data_split[8]}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Raw Meat 03
              </Typography>
              <TextField
                id="outlined-number"
                type="number"
                name="raw_meat03"
                value={data_split[9]}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 0, paddingTop: 0, color: '#9e9e9e' }}>
                Raw Meat 04
              </Typography>
              <TextField
                id="outlined-number"
                type="number"
                name="raw_meat04"
                value={data_split[10]}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  "& .MuiOutlinedInput-input": {
                    color: "#9e9e9e",

                  },
                }}
              />
            </Box>*/}
            <Box style={{ height: 10 }}>

            </Box>

            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
              <Button fullWidth style={{ backgroundColor: '#009688' }} variant="contained" onClick={() => { handleClickConfirmChangeMeatFromQrcode(); }}>Confirm</Button>
            </Grid>

            <Box style={{ height: 10 }}>

            </Box>
          </Grid>

        </Grid>
      }

      {/*insert_data_api == false && data == "" ? //show history api
        <Grid container spacing={2} style={{ paddingLeft: 30, paddingTop: 25, paddingRight: 15 }} >
          {sessionStorage.getItem('process_order_number') == null ? '' :
            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" style={{ border: "2px dashed #4A4A49", borderRadius: 20, position: "relative", height: '310px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  PO Number:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('process_order_number')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  Line:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('line')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  SKU Name:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('material_description')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  Batch Number:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('batch')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 19, position: "relative", fontWeight: 'bold' }} >
                  Weighting Time:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('barrel_timestamp')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  Knead Meat Tank:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('actual_barrel_weight')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  Knead Meat Batch:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('total_batch_weight')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  Raw Meat:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('barrel_meat')}
                </Typography>
              </Box>
            </Grid>}
        </Grid>
        : ''*/}

      {(insert_data == false || insert_data_api == false ) && data == "" ? // show history qrcode
        <Grid container spacing={2} style={{ paddingLeft: 30, paddingTop: 25, paddingRight: 15 }} >
          {sessionStorage.getItem('process_order_number') == null ? '' :
            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" style={{ border: "2px dashed #4A4A49", borderRadius: 20, position: "relative", height: '310px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  PO Number:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('process_order_number')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  Line:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('line')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  SKU Name:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('material_description')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  Batch Number:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('batch')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 19, position: "relative", fontWeight: 'bold' }} >
                  Weighting Time:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('barrel_timestamp')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  Knead Meat Tank:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('actual_barrel_weight')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  Knead Meat Batch:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('total_batch_weight')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                <Typography style={{ color: '#000000', fontSize: 20, position: "relative", fontWeight: 'bold' }} >
                  Raw Meat:
                </Typography>
                <Typography style={{ color: '#000000', fontSize: 22, position: "relative" }} >
                  &nbsp;{sessionStorage.getItem('barrel_meat')}
                </Typography>
              </Box>
            </Grid>}
        </Grid>
        : ''}



      {/* qrcode */}
      <Dialog
        open={openConfirmChangeMeatFromQrcode}
        onClose={handleCloseConfirmChangeMeatFromQrcode}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenConfirmChangeMeatFromQrcode(false); }}>Cancel</Button>
          <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenConfirmChangeMeatFromQrcode(false); comfirmInPreparationZoneinQrcode(); }}>Confirm</Button>
        </DialogActions>
      </Dialog>

      {/* api */}
      <Dialog
        open={openConfirmChangeMeatFromAPI}
        onClose={handleCloseConfirmChangeMeatFromAPI}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenConfirmChangeMeatFromAPI(false); }}>Cancel</Button>
          <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenConfirmChangeMeatFromAPI(false); comfirmInPreparationZoneinAPI(); }}>Confirm</Button>
        </DialogActions>
      </Dialog>

      {SuccessfulFromQrcode == 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifySubmittedSuccessfulFromQrcode}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successful
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688' }} variant="contained" onClick={() => { setNotifySubmittedSuccessfulFromQrcode(false); window.location.reload(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }

      {SuccessfulFromQrcode == 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifySubmittedFailedFromQrcode}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688' }} aligh='Center' variant="contained" onClick={() => { setNotifySubmittedFailedFromQrcode(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }

      {SuccessfulFromAPI == 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifySubmittedSuccessfulFromAPI}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successful
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688' }} variant="contained" onClick={() => { setNotifySubmittedSuccessfulFromAPI(false); window.location.reload(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }

      {SuccessfulFromAPI == 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifySubmittedFailedFromAPI}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688' }} aligh='Center' variant="contained" onClick={() => { setNotifySubmittedFailedFromAPI(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }
    </div>
  );
}





