import AppbarMarinationTracking from "../layout/AppbarMarinationTracking";
import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { useState } from "react";
import { Outlet } from 'react-router-dom';
import * as React from 'react';
import { ColorModeContext, useMode } from "theme";



function MainLayoutTargetLine7() {
  const [theme, colorMode] = useMode();

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <AppbarMarinationTracking />
          <Outlet />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>

  )
}

export default MainLayoutTargetLine7
