import { useRef, useEffect, useState } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { apiUrl } from '../../../constants'
import axios from 'axios'
import db, { firebase } from "firebase-config"
import { Box, Grid, Typography, Button, useTheme, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { tokens } from "theme";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Target from "assets/images/svg/target.svg"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import Fighting from "assets/images/first1.gif";
import Running from "assets/images/running.gif"
import Run from 'assets/images/running_png.png';
import Fist from 'assets/images/protest_png.png';
import HeaderDetail from "components/header/HeaderDetail";
import GrigToProductionTracking from "components/grid/GridToProductionTracking";
import Popup from 'components/model/Popup';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#bcd6d0',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    //borderRadius: 10,
    //height: '102%'

}));

const ItemTarget = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    borderRadius: 0,
    //height: '65vh'

}));


const ProductionEggP1 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const today = new Date();
    const hourago = new Date(today.getTime());
    const day = hourago.toLocaleDateString('th-TH', {
        year: '2-digit',
        month: 'short',
        day: 'numeric',
    })
    const [open, setOpen] = useState();
    const [performanceP1, setPerformanceP1] = useState([])
    const [on_error, setNo_error] = useState([]);
    const [add_target, setAdd_target] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddTarget = (event) => {
        if (event.target.name === "target") {
            setAdd_target(event.target.value);
        }
    };

    const handleSubmitAddTarget = async () => {
        try {
            // กำหนดชื่อ Collection และ Document
            const collectionName = "line_monitoring_performance_tracking_page1";
            const documentName = "4042.00000000000333.line_3TARGET";

            // สร้าง Reference ไปยัง Document ใน Firestore
            const docRef = db.collection(collectionName).doc(documentName);

            // ตรวจสอบว่า add_target มีค่าหรือไม่
            if (!add_target || isNaN(add_target)) {
                alert("กรุณากรอกเป้าหมายเป็นตัวเลขที่ถูกต้อง!");
                return;
            }
            // สร้างข้อมูลสำหรับบันทึก
            const data = {
                TARGET: Number(add_target), // เป้าหมายใหม่
                //REMAINING: remaining_target, // เป้าหมายที่เหลือ
                timestamp: firebase.firestore.FieldValue.serverTimestamp(), // เวลาบันทึก
            };

            // อัปเดตข้อมูลลง Firestore
            await docRef.set(data);

            // อัปเดต state และรีเซ็ต Dialog
            //alert("บันทึกเป้าหมายสำเร็จ!");
            setAdd_target(null);
            setOpen(false);
        } catch (error) {
            console.error("Error updating target:", error);
            alert("เกิดข้อผิดพลาดในการอัปเดตเป้าหมาย");
        }
    };


    useEffect(() => {
        const subscriberPerformance = db
            .collection("line_monitoring_performance_tracking_page1")
            .onSnapshot((querySnapshot) => {
                const performanceData = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setPerformanceP1(performanceData);
                sessionStorage.setItem('target_cup', performanceData.find((x) => x.id.includes("4042.00000000000333.line_3TARGET"))?.TARGET);
                sessionStorage.setItem('actual_cup', performanceData.find((x) => x.id.includes("4042.00000000000333.line_3ACTUAL_CUP"))?.ACTUAL_CUP);
                sessionStorage.setItem('production_time_cup', performanceData.find((x) => x.id.includes("4042.00000000000333.line_3PRODUCTION_TIME"))?.PRODUCTION_TIME);
                sessionStorage.setItem('shift_cup', performanceData.find((x) => x.id.includes("4042.00000000000333.line_3SHIFT"))?.SHIFT);
                sessionStorage.setItem('sku_cup', performanceData.find((x) => x.id.includes("4042.00000000000333.line_3SKU"))?.SAP_CODE == '23061619' ? "หมูดำคูโรบูตะ" : performanceData.find((x) => x.id.includes("4042.00000000000333.line_3SKU"))?.SAP_CODE == '23079724' ? "ไก่สับและน้ำซุปผัก" : performanceData.find((x) => x.id.includes("4042.00000000000333.line_3SKU"))?.SAP_CODE == '23061617' ? "สไตล์ญี่ปุ่น" : performanceData.find((x) => x.id.includes("4042.00000000000333.line_3SKU"))?.SAP_CODE == '23091330' ? "รสดั้งเดิม" : performanceData.find((x) => x.id.includes("4042.00000000000333.line_3SKU"))?.SAP_CODE == '23104647' ? "กุ้งผสมหมูและไก่" : "-");
                sessionStorage.setItem('std_speed_cup', performanceData.find((x) => x.id.includes("4042.00000000000333.line_3STD_SPEED"))?.STD_SPEED);
                sessionStorage.setItem('remaining_cup', performanceData.find((x) => x.id.includes("4042.00000000000333.line_3TARGET"))?.TARGET - performanceData.find((x) => x.id.includes("4042.00000000000333.line_3ACTUAL_CUP"))?.ACTUAL_CUP);

                sessionStorage.setItem('persent_actual_cup', (performanceData.find((x) => x.id.includes("4042.00000000000333.line_3ACTUAL_CUP"))?.ACTUAL_CUP * 100) / performanceData.find((x) => x.id.includes("4042.00000000000333.line_3TARGET"))?.TARGET);

            });

        const subscriberError = db
            .collection("meskr_keeplive")
            .where("ID", "==", "MES_Egg.4042.00000000000333.thailand.steamed_egg.meat_dispenser.line_3.0NoError")
            .onSnapshot((querySnapshot) => {
                const errorData = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setNo_error(errorData);
            });

        // Cleanup listeners
        return () => {
            subscriberPerformance();
            subscriberError();
        };
    }, []);

    const target_cup = performanceP1.find((x) => x.id.includes("4042.00000000000333.line_3TARGET")) || { TARGET: 0 };
    const actual_cup = performanceP1.find((x) => x.id.includes("4042.00000000000333.line_3ACTUAL_CUP")) || { ACTUAL_CUP: 0 };
    const production_time = performanceP1.find((x) => x.id.includes("4042.00000000000333.line_3PRODUCTION_TIME")) || { PRODUCTION_TIME: 0 };
    const runtime = performanceP1.find((x) => x.id.includes("4042.00000000000333.line_3RUNTIME")) || { RUNTIME: 0 };
    const shift = performanceP1.find((x) => x.id.includes("4042.00000000000333.line_3SHIFT")) || { SHIFT: "" };
    const sku = performanceP1.find((x) => x.id.includes("4042.00000000000333.line_3SKU")) || { SAP_CODE: "-" };
    const std_speed = performanceP1.find((x) => x.id.includes("4042.00000000000333.line_3STD_SPEED")) || { STD_SPEED: 0 };

    const remaining_cup = target_cup?.TARGET - actual_cup?.ACTUAL_CUP || 0
    const persent_actual_cup = (actual_cup?.ACTUAL_CUP * 100) / target_cup?.TARGET || 0;

    const hour_production_time = production_time?.PRODUCTION_TIME / 60 || 0
    const actual_speed = actual_cup?.ACTUAL_CUP / hour_production_time || 0;

    const hour_production_time_old = Number(sessionStorage.getItem('production_time_cup')) / 60 || 0
    const actual_speed_old = Number(sessionStorage.getItem('actual_cup')) / hour_production_time_old || 0

    const packing_line1_no_error = on_error.find((user) => user.ID.includes("MES_Egg.4042.00000000000333.thailand.steamed_egg.meat_dispenser.line_3.0NoError"));

    const isError = packing_line1_no_error?.VALUE === false;

    const sku_name = () => {
        let name;
        try {
            if (sku?.SAP_CODE == '23061619') {
                name = "หมูดำคูโรบูตะ"
            } else if (sku?.SAP_CODE == '23079724') {
                name = "ไก่สับและน้ำซุปผัก"
            } else if (sku?.SAP_CODE == '23061617') {
                name = "สไตล์ญี่ปุ่น"
            } else if (sku?.SAP_CODE == '23091330') {
                name = "รสดั้งเดิม"
            } else if (sku?.SAP_CODE == '23104647') {
                name = "กุ้งผสมหมูและไก่"
            } else if (sku?.SAP_CODE == '-') {
                name = "-"
            }
            return name;
        }
        catch {
            console.log()
        }
    }






    const actual_color = () => {
        let color;
        try {
            if (actual_cup?.ACTUAL_CUP >= ((hour_production_time * std_speed?.STD_SPEED) * 1)) {
                color = "#2e9281"
            } else if (actual_cup?.ACTUAL_CUP >= ((hour_production_time * std_speed?.STD_SPEED) * 0.8)) {
                color = "#b58514"
            } else if (actual_cup?.ACTUAL_CUP < ((hour_production_time * std_speed?.STD_SPEED) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const speed_color = () => {
        let color;
        try {
            if (actual_speed >= (std_speed?.STD_SPEED * 1)) {
                color = "#2e9281"
            } else if (actual_speed >= (std_speed?.STD_SPEED * 0.8)) {
                color = "#b58514"
            } else if (actual_speed < (std_speed?.STD_SPEED * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const actual_color_old = () => {
        let color;
        try {
            if (Number(sessionStorage.getItem('actual_cup')) >= ((hour_production_time_old * Number(sessionStorage.getItem('std_speed_cup'))) * 1)) {
                color = "#2e9281"
            } else if (Number(sessionStorage.getItem('actual_cup')) >= ((hour_production_time_old * Number(sessionStorage.getItem('std_speed_cup'))) * 0.8)) {
                color = "#b58514"
            } else if (Number(sessionStorage.getItem('actual_cup')) < ((hour_production_time_old * Number(sessionStorage.getItem('std_speed_cup'))) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const speed_color_old = () => {
        let color;
        try {
            if (actual_speed_old >= (Number(sessionStorage.getItem('std_speed_cup')) * 1)) {
                color = "#2e9281"
            } else if (actual_speed_old >= (Number(sessionStorage.getItem('std_speed_cup')) * 0.8)) {
                color = "#b58514"
            } else if (actual_speed_old < (Number(sessionStorage.getItem('std_speed_cup')) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }







    return (
        <>
            {isError ? <Popup /> :
                <Helmet>
                    <title>Production Tracking Page1 | MES EGG NJ</title>
                </Helmet>
            }
            <Grid item xs={12}>
                <HeaderDetail
                    line={'Line3'}
                    sku={performanceP1.length == 0 ? sessionStorage.getItem('sku_cup') : <Tooltip title={sku?.SAP_CODE} placement="top-start" arrow><Typography style={{ color: "#2e9281", fontWeight: "bold", fontSize: 25, height: '3vh' }}>{sku_name()}</Typography></Tooltip>}
                    timestr={day}
                    icon={shift?.SHIFT === "N" ? (<Tooltip title="กะ Night" arrow><DarkModeOutlinedIcon style={{ color: '#ffffff' }} /></Tooltip>) : (<Tooltip title="กะ Day" arrow><LightModeOutlinedIcon style={{ color: '#ffffff' }} /></Tooltip>)}
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <Item>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                        <Typography style={{ color: '#b58514', fontSize: 20, lineHeight: "0px", fontWeight: 'bold' }} >
                            <Tooltip title="add target" arrow>
                                <img onClick={handleClickOpen} src={Target} height="60px" />
                            </Tooltip>
                        </Typography>
                        <Typography style={{ color: colors.green[100], fontSize: 45, fontWeight: 'bold', lineHeight: "0px", paddingTop: 5 }} >
                            &nbsp;เป้าหมาย&nbsp;
                        </Typography>
                        <Typography style={{ color: colors.green[100], fontSize: 55, lineHeight: "0px", fontWeight: 'bold' }} >
                            {performanceP1.length == 0 ? Number(sessionStorage.getItem('target_cup')).toLocaleString() : (target_cup?.TARGET).toLocaleString()}&nbsp;
                        </Typography>
                        <Typography style={{ color: colors.green[100], fontSize: 35, lineHeight: "0px", fontWeight: 'bold', paddingTop: 10 }} >
                            ถ้วย
                        </Typography>
                    </Box>
                </Item>
            </Grid>

            <Grid item xs={12} sm={12}>
                <ItemTarget>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
                        <GrigToProductionTracking
                            color={performanceP1.length == 0 ? actual_color_old() : actual_color()}
                            image={<img src={Fighting} height="50vh" />}
                            section={'ทำได้'}
                            persent={performanceP1.length == 0 ? Math.round(Number(sessionStorage.getItem('persent_actual_cup'))) : Math.round(persent_actual_cup)}
                            color_persent={performanceP1.length == 0 ? actual_color_old() : actual_color()}
                            value={performanceP1.length == 0 ? Number(sessionStorage.getItem('actual_cup')).toLocaleString() : (actual_cup?.ACTUAL_CUP).toLocaleString()}
                            unit={'ถ้วย'}
                        />

                        <GrigToProductionTracking
                            color={'#4a4a49'}
                            image={<img src={Running} height="50vh" />}
                            section={'คงเหลือ'}
                            persent={performanceP1.length == 0 ? (100 - Math.round(Number(sessionStorage.getItem('persent_actual_cup'))) <= 0 ? 0 : 100 - Math.round(Number(sessionStorage.getItem('persent_actual_cup')))) : (100 - Math.round(persent_actual_cup) <= 0 ? 0 : 100 - Math.round(persent_actual_cup))}
                            color_persent={colors.grey[100]}
                            value={performanceP1.length == 0 ? (Number(sessionStorage.getItem('remaining_cup')) <= 0 ? 0 : Number(sessionStorage.getItem('remaining_cup')).toLocaleString()) : (remaining_cup <= 0 ? 0 : (remaining_cup).toLocaleString())}
                            unit={'ถ้วย'}
                        />
                    </Grid>

                    <Grid item xs={12} textAlign="left" style={{ paddingRight: 0, paddingLeft: 0 }}>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'center' }}>
                                    <Typography style={{ color: performanceP1.length == 0 ? speed_color_old() : speed_color(), fontSize: 45, lineHeight: "15vh" }}>
                                        {performanceP1.length == 0 ? Math.round(actual_speed_old).toLocaleString() : Math.round(actual_speed).toLocaleString()}
                                    </Typography>
                                    <Typography style={{ color: '#ffffff', fontSize: 20, paddingTop: 4, lineHeight: "15vh" }}>
                                        &nbsp;ถ้วย/ชม.&nbsp;({performanceP1.length == 0 ? Number(sessionStorage.getItem('std_speed_cup')).toLocaleString() : (std_speed?.STD_SPEED).toLocaleString()})
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>

                            </Grid>
                        </Grid>
                    </Grid>
                </ItemTarget>
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title" >
                    Add Target (ถ้วย)
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={6}>
                            <TextField
                                fullWidth
                                label="Target (ถ้วย)"
                                name="target"
                                required
                                value={add_target}
                                variant="standard"
                                sx={{
                                    "& .MuiOutlinedInput-input": {
                                        color: "#000000",
                                        fontFamily: 'CPF_Imm_Sook',
                                        fontSize: 18
                                    },
                                }}
                                onChange={handleAddTarget}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)}>Cancel</Button>
                    <Button onClick={() => { handleClose(false); handleSubmitAddTarget(); }} autoFocus>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ProductionEggP1
