import React, { useEffect, useState, useMemo } from "react";
import QrReader from "react-qr-reader";
import { Box, Grid, Card, Container, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import '../styles.css'
import TextField from '@mui/material/TextField';
import axios from 'axios';
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Slide
} from "@material-ui/core/";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import Circular from '../components/model/Circular';




var token_url = apiUrl.TOKEN;
///change timeout of mui accordion when collapsing(https://stackoverflow.com/questions/63032741/mui-expansionpanel-with-transitioncomponent-doesnt-collapse-the-item)
const slideProps = {
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: { enter: "auto", exit: "auto" }
};


export default function InStock() {
    const today = new Date();
    const hourago_start = new Date(today.getTime() - (24 * 60 * 60 * 1000));
    const hourago = new Date(today.getTime());
    const datetime = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');
    const date = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');
    const date_start = moment(hourago_start).tz('Asia/Bangkok').format('YYYY-MM-DD');
    const date_end = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');

    const time = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');

    const [actualTimestamp, setActualTimestamp] = useState(datetime);

    const [inStock_preparation_zone, setInStock_preparation_zone] = useState([]);
    const [processing_preparation_zone, setProcessing_preparation_zone] = useState([]);
    const [check_status, setCheck_status] = useState([]);


    const [status_preparation_zone, setStatus_preparation_zon] = useState([]);
    const [inStockCheckOnEditWeight, setInStockCheckOnEditWeight] = useState();
    const [startProductionCheckOn, setStartProductionCheckOn] = useState();
    const [inStockCheckOnEditShift, setInStockCheckOnEditShift] = useState();
    const [inStockCheckOnEditLine, setInStockCheckOnEditLine] = useState();
    const [inStockCheckOnEditStatus, setInStockCheckOnEditStatus] = useState();

    const [startProduction, setStartProduction] = useState(false);
    const [applyProduction, setApplyProduction] = useState(false);

    const [inStockHistorySelectedWeight, setInStockHistorySelectedWeight] = useState(0);
    const [editWeightInAPI, setEditWeightInAPI] = useState(false);

    const [inStockHistorySelectedShift, setInStockHistorySelectedShift] = useState(null);
    const [editShiftInAPI, setEditShiftInAPI] = useState(false);
    const [editShift, setEditShift] = useState(false);

    const [inStockHistorySelectedLine, setInStockHistorySelectedLine] = useState('');
    const [editLineInAPI, setEditLineInAPI] = useState(false);

    const [editStatus, setEditStatus] = useState(false);


    const [openConfirmStartInAPI, setOpenConfirmStartInAPI] = useState();
    const [openEditWeightInAPI, setOpenEditWeightInAPI] = useState();
    const [openEditShiftInAPI, setOpenEditShiftInAPI] = useState();
    const [openEditLineInAPI, setOpenEditLineInAPI] = useState();
    const [openSelectLineInAPI, setOpenSelectLineInAPI] = useState(false);

    const [openConfirmStartInQrcode, setOpenConfirmStartInQrcode] = useState();





    const [openEditStatusProcessing, setOpenEditStatusProcessing] = useState();
    const [openEditStatusReject, setOpenEditStatusReject] = useState();




    const [selected, setSelected] = useState("environment");
    const [startScan, setStartScan] = useState(false);
    const [laserStar, setLaserStar] = useState(false);

    const [loadingScan, setLoadingScan] = useState(false);
    const [data, setData] = useState("");


    const [noData, setNoData] = useState(false);
    const [open, setOpen] = useState(false);
    const [done, setDone] = useState(false);



    //// clear value of mui input comment 1(https://stackoverflow.com/questions/57419955/how-to-set-or-clear-value-of-material-ui-input-in-reactjs)
    const clearTextField = () => setData("")

    const selectLine = [
        {
            value: "LINE7",
            label: "LINE7"
        },
        {
            value: "LINE8",
            label: "LINE8"
        },
        {
            value: "LINE9",
            label: "LINE9"
        },
        {
            value: "LINE10",
            label: "LINE10"
        },
        {
            value: "LINE11",
            label: "LINE11"
        },
        {
            value: "LINE12",
            label: "LINE12"
        },
    ];


    /// status


    const handleOpenEditStatusProcessing = () => {
        setOpenEditStatusProcessing(true);
    };

    const handleCloseEditStatusProcessing = () => {
        setOpenEditStatusProcessing(false);
    };

    const handleOpenEditStatusReject = () => {
        setOpenEditStatusReject(true);
    };

    const handleCloseEditStatusReject = () => {
        setOpenEditStatusReject(false);
    };



    const handleClickConfirmStartInAPI = () => {
        setOpenConfirmStartInAPI(true);
    };

    const handleCloseConfirmStartInAPI = () => {
        setOpenConfirmStartInAPI(false);
    };

    const handleOpenEditWeightInAPI = () => {
        setOpenEditWeightInAPI(true);
    };

    const handleCloseEditWeightInAPI = () => {
        setOpenEditWeightInAPI(false);
    };

    const handleOpenEditShiftInAPI = () => {
        setOpenEditShiftInAPI(true);
    };

    const handleCloseEditShiftInAPI = () => {
        setOpenEditShiftInAPI(false);
    };

    const handleOpenEditLineInAPI = () => {
        setOpenEditLineInAPI(true);
    };

    const handleCloseEditLineInAPI = () => {
        setOpenEditLineInAPI(false);
    };

    const handleCloseSelectLineInAPI = () => {
        setOpenSelectLineInAPI(false);
    };

    const handleOpenSelectLineInAPI = () => {
        setOpenSelectLineInAPI(true);
    };



    /// Qr code
    const handleClickConfirmStartInQrcode = () => {
        setOpenConfirmStartInQrcode(true);
    };

    const handleCloseConfirmStartInQrcode = () => {
        setOpenConfirmStartInQrcode(false);
    };


    const handleClose = () => {
        setOpen(false);
    };




    const handleScan = async (scanData) => {
        setLoadingScan(true);
        //console.log(`loaded data data`, scanData);
        if (scanData && scanData !== "") {
            //console.log(`loaded >>>`, scanData);
            setData(scanData);
            setStartScan(false);
            setLoadingScan(false);
            // setPrecScan(scanData);
        }
    };
    const handleError = (err) => {
        console.error(err);
    };



    const handleSelectionLaserScan = (event) => {

        if (event.target.name === "laser_scan") {
            setData(event.target.value);
        }

    };

    const data_split = data.split("|")

    //console.log(data_split)

    const myObjects = {};
    Object.keys(data_split).forEach((key) => {
        const splitKey = key.split(',');
        const elemId = splitKey[1];
        const realKey = splitKey[0];

        if (!myObjects[elemId]) {
            myObjects[elemId] = { id: elemId }; // Create entry
        }
        myObjects[elemId][realKey] = data_split[key];
    });

    // Turn into array
    const myObjectsToArray = Object.values(myObjects);

    //console.log(myObjectsToArray);

    const categories = {};
    for (const item of myObjectsToArray) {
        if (item[2] in categories) {
            categories[item[2]].push(item);
        } else {
            categories[item[2]] = [item];
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setLaserStar(true);
        }, 100);
    }, []);



    const checkToken = async () => {
        let access_exp = localStorage.getItem("rb_ac_exp");
        let refresh_exp = localStorage.getItem("rb_rf_exp");
        //console.log('access_exp: ' + access_exp);
        //console.log('refresh_exp: ' + refresh_exp);
        if (refresh_exp < Date.now() / 1000) {
            //console.log("refresh is expiried");
            // alert('Please log in again')
            localStorage.clear();
            window.location = "/Login";
        } else if (access_exp < Date.now() / 1000) {
            const refresh = localStorage.getItem("rb_rf_token");

            let data = {
                refresh: refresh,
            };
            let result = await axios.post(token_url, data);
            //console.log("got new access_token!");
            localStorage.setItem("rb_ac_token", result.data.access);
        }
    };

    useEffect(() => {
        setInStockHistorySelectedWeight(inStockCheckOnEditWeight === undefined ? '' : inStockCheckOnEditWeight.ACTUAL_BARREL_WEIGHT)
        setInStockHistorySelectedShift(inStockCheckOnEditShift === undefined ? '' : (inStockCheckOnEditShift.SHIFT === 'D' ? 'N' : 'D'))
        //setInStockHistorySelectedShift(((time.toString() >= '06:00:00' && time.toString() <= '17:59:00' ? 'D' : 'N') === undefined ? '' : (time.toString() >= '06:00:00' && time.toString() <= '17:59:00' ? 'D' : 'N')) === 'D' ? 'N' : 'D')
        setInStockHistorySelectedLine(inStockCheckOnEditLine === undefined ? '' : ((inStockCheckOnEditLine.LINE === 'Line7' || inStockCheckOnEditLine.LINE === 'LINE7') ? 'LINE7' : (inStockCheckOnEditLine.LINE === 'Line8' || inStockCheckOnEditLine.LINE === 'LINE8') ? 'LINE8' : (inStockCheckOnEditLine.LINE === 'Line9' || inStockCheckOnEditLine.LINE === 'LINE9') ? 'LINE9' : (inStockCheckOnEditLine.LINE === 'Line10' || inStockCheckOnEditLine.LINE === 'LINE10') ? 'LINE10' : (inStockCheckOnEditLine.LINE === 'Line11' || inStockCheckOnEditLine.LINE === 'LINE11') ? 'LINE11' : 'LINE12'))
    }, [inStockCheckOnEditWeight, inStockCheckOnEditLine, inStockCheckOnEditShift]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                checkToken();
                setDone(true);
                const response = await axios.get(`${apiUrl.BETWEEN_PREPARATION_ZONE}${date_start}/${date_end}/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                        "X-xss-protection": "1; mode=block;",
                        "X-frame-options": "SAMEORIGIN",
                        "X-content-type-options": "nosniff",
                        "Cache-Control": "no-cache, private",
                        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                    }
                });
                if (response.status === 200) {
                    setInStock_preparation_zone(response.data.filter((i) => i.STATUS === "In Stock"));
                    setDone(false);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
                setDone(false);
            } 
        };

        fetchData();
    }, [date_start, date_end]);




    const sort_factory_date = inStock_preparation_zone.sort((a, b) => a.FACTORY_DATE.localeCompare(b.FACTORY_DATE));

    const sort_batch = sort_factory_date.sort((a, b) => a.BARREL_TIMESTAMP.localeCompare(b.BARREL_TIMESTAMP));

    const categories_between = useMemo(() => {
        const categories = {};
        inStock_preparation_zone.forEach((item) => {
            if (!categories[item.MATERIAL_DESCRIPTION]) {
                categories[item.MATERIAL_DESCRIPTION] = [];
            }
            categories[item.MATERIAL_DESCRIPTION].push(item);
        });
        return categories;
    }, [inStock_preparation_zone]);


    const fetchPreparationZoneData = async () => {
        try {
            const response = await axios.get(`${apiUrl.GET_PREPARATION_ZONE}${data_split[4]}/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                    "X-xss-protection": "1; mode=block;",
                    "X-frame-options": "SAMEORIGIN",
                    "X-content-type-options": "nosniff",
                    "Cache-Control": "no-cache, private",
                    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                    "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                }
            });
            if (response.status === 200) {
                setStatus_preparation_zon(response.data.filter((i) => i.STATUS === "In Stock"));
                setCheck_status(response.data.filter((i) => i.STATUS !== "In Stock"));
                setNoData(1);
            }
        } catch (error) {
            if (error.response?.status === 404) {
                setNoData(2);
            } else {
                setNoData(3);
            }
            console.error("Error fetching preparation zone data: ", error);
        }
    };

    useEffect(() => {
        fetchPreparationZoneData();
    }, [data_split[4], actualTimestamp]);


    const check_update = async () => {
        await axios.get(`${apiUrl.BETWEEN_PREPARATION_ZONE}${date_start}/${date_end}/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                "X-xss-protection": "1; mode=block;",
                "X-frame-options": "SAMEORIGIN",
                "X-content-type-options": "nosniff",
                "Cache-Control": "no-cache, private",
                "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
            }
        }
        )
            .then(response => {
                //console.log(response.data)
                if (response.status == 200) {
                    setInStock_preparation_zone(response.data.filter(i => i.STATUS === "In Stock"))

                }


            })
            .catch(error => {
                console.log('error: ', error)

            })
    }





    const handleStartProductionInAPI = () => {
        setTimeout(() => {
            axios.put(`${apiUrl.PUT_PREPARATION_ZONE}${startProductionCheckOn.CHECK_ON}/`, {
                "at_process_timestamp": datetime,
                "status": "Processing",
                "shift": (time.toString() >= '06:00:00' && time.toString() <= '17:59:00') ? 'D' : 'N',
                "username": localStorage.getItem('rb_email'),

            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                    "X-xss-protection": "1; mode=block;",
                    "X-frame-options": "SAMEORIGIN",
                    "X-content-type-options": "nosniff",
                    "Cache-Control": "no-cache, private",
                    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                    "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                }
            })
                .then(response => {
                    // console.log(response)
                    if (response.status == 200) {
                        setStartProduction(1)

                    }
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        setStartProduction(2)
                    }
                    else {
                        setStartProduction(2)
                    }
                    console.log('start tumbler error: ', error);
                })
        });
    }

    const handleEditionWeightInAPI = (event) => {
        if (event.target.name === "weight") {
            setInStockHistorySelectedWeight(event.target.value);
        }
    };

    const handleEditWeightInAPI = () => {
        setTimeout(() => {
            axios.put(`${apiUrl.PUT_PREPARATION_ZONE}${inStockCheckOnEditWeight.CHECK_ON}/`, {
                "actual_barrel_weight": Number(inStockHistorySelectedWeight),
                "username": localStorage.getItem('rb_email'),
                //"check_on": inStockCheckOnEditWeight.CHECK_ON,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                    "X-xss-protection": "1; mode=block;",
                    "X-frame-options": "SAMEORIGIN",
                    "X-content-type-options": "nosniff",
                    "Cache-Control": "no-cache, private",
                    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                    "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                }
            })
                .then(response => {
                    // console.log(response)
                    if (response.status == 200) {
                        setInStockHistorySelectedWeight();
                        setEditWeightInAPI(1)
                        check_update()
                    }
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        setEditWeightInAPI(2)
                    }
                    else {
                        setEditWeightInAPI(2)
                    }
                    console.log('edit tumbler error: ', error);
                })
        });
    }

    const handleEditionShiftInAPI = (event) => {
        if (event.target.name === "shift") {
            setInStockHistorySelectedShift(event.target.value);
        }
    };

    const handleEditShiftInAPI = () => {
        setTimeout(() => {
            axios.put(`${apiUrl.PUT_PREPARATION_ZONE}${inStockCheckOnEditShift.CHECK_ON}/`, {
                "shift": inStockHistorySelectedShift,
                "username": localStorage.getItem('rb_email'),
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                    "X-xss-protection": "1; mode=block;",
                    "X-frame-options": "SAMEORIGIN",
                    "X-content-type-options": "nosniff",
                    "Cache-Control": "no-cache, private",
                    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                    "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                }
            })
                .then(response => {
                    // console.log(response)
                    if (response.status == 200) {
                        setInStockHistorySelectedShift();
                        setEditShiftInAPI(1)
                        setEditShift(1)
                        check_update()

                    }
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        setEditShiftInAPI(2)
                        setEditShift(2)
                    }
                    else {
                        setEditShiftInAPI(2)
                        setEditShift(2)
                    }
                    console.log('edit shift error: ', error);
                })
        });
    }

    const handleEditionLineInAPI = (event) => {
        if (event.target.name === "line") {
            setInStockHistorySelectedLine(event.target.value);
        }
    };

    const handleEditLineInAPI = () => {
        setTimeout(() => {
            axios.put(`${apiUrl.PUT_PREPARATION_ZONE}${inStockCheckOnEditLine.CHECK_ON}/`, {
                "line": inStockHistorySelectedLine,
                "username": localStorage.getItem('rb_email'),
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                    "X-xss-protection": "1; mode=block;",
                    "X-frame-options": "SAMEORIGIN",
                    "X-content-type-options": "nosniff",
                    "Cache-Control": "no-cache, private",
                    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                    "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                }
            })
                .then(response => {
                    // console.log(response)
                    if (response.status == 200) {
                        setInStockHistorySelectedLine();
                        setEditLineInAPI(1)
                        check_update()

                    }
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        setEditLineInAPI(2)
                    }
                    else {
                        setEditLineInAPI(2)
                    }
                    console.log('edit line error: ', error);
                })
        });
    }

    // const categories_api = {};
    // for (const item of status_preparation_zone) {
    //     if (item.MATERIAL_DESCRIPTION in categories_api) {
    //         categories_api[item.MATERIAL_DESCRIPTION].push(item);
    //     } else {
    //         categories_api[item.MATERIAL_DESCRIPTION] = [item];
    //     }
    // }

    const categories_api = useMemo(() => {
        const categories = {};
        status_preparation_zone.forEach((item) => {
            if (!categories[item.MATERIAL_DESCRIPTION]) {
                categories[item.MATERIAL_DESCRIPTION] = [];
            }
            categories[item.MATERIAL_DESCRIPTION].push(item);
        });
        return categories;
    }, [status_preparation_zone]);




    const handleStartProductionInQrcode = async () => {
        await axios.post(apiUrl.POST_PREPARATION_ZONE, {
            "plant_id": "4117",
            "factory": "00000000000000",
            "machine_name": data_split[10] == undefined ? "null" : (data_split[10] == "" || data_split[10] == " ") ? "null" : data_split[10],
            "process_order_number": (data_split[0] == "" || data_split[0] == " ") ? "null" : data_split[0],
            "line": (data_split[1] == "" || data_split[1] == " ") ? "null" : data_split[1],
            "material_description": (data_split[2] == "" || data_split[2] == " ") ? "null" : data_split[2],
            "batch": (data_split[3] == "" || data_split[3] == " ") ? "null" : data_split[3],
            "barrel_timestamp": (data_split[4] == "" || data_split[4] == " ") ? "null" : data_split[4],
            "barrel_weight": (data_split[5] == "" || data_split[5] == " ") ? 0 : data_split[5],
            "total_batch_weight": (data_split[6] == "" || data_split[6] == " ") ? 0 : data_split[6],
            "barrel_meat": data_split[7] == undefined ? 0 : ((data_split[7] == "" || data_split[7] == " ") ? 0 : Number(data_split[7])),
            "actual_barrel_weight": (data_split[5] == "" || data_split[5] == " ") ? 0 : data_split[5],
            "actual_barrel_meat": (data_split[7] == "" || data_split[7] == " ") ? 0 : data_split[7],
            "actual_timestamp": actualTimestamp,
            "ready_timestamp": data_split[8] == undefined ? actualTimestamp : (data_split[8] == "" || data_split[8] == " ") ? actualTimestamp : data_split[8],
            "factory_date": data_split[9] == undefined ? "null" : (data_split[9] == "" || data_split[9] == " ") ? "null" : data_split[9],
            "status": "In Stock",
            "shift": (time.toString() >= '06:00:00' && time.toString() <= '17:59:00') ? 'D' : 'N',
            "username": localStorage.getItem('rb_email'),

        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                "X-xss-protection": "1; mode=block;",
                "X-frame-options": "SAMEORIGIN",
                "X-content-type-options": "nosniff",
                "Cache-Control": "no-cache, private",
                "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
            }
        })
            .then(response => {
                // console.log(response)
                if (response.status == 201) {
                    console.log(response.data)
                    setApplyProduction(1)

                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    setApplyProduction(2)
                }
                else {
                    setApplyProduction(2)
                }
                console.log('start tumbler error: ', error);
            })
    }



    const handleEditStatusProcessing = () => {
        setTimeout(() => {
            axios.put(`${apiUrl.PUT_PREPARATION_ZONE}${inStockCheckOnEditStatus.CHECK_ON}/`, {
                "status": 'Processing',
                "at_process_timestamp": datetime,
                "username": localStorage.getItem('rb_email'),
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                    "X-xss-protection": "1; mode=block;",
                    "X-frame-options": "SAMEORIGIN",
                    "X-content-type-options": "nosniff",
                    "Cache-Control": "no-cache, private",
                    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                    "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                }
            })
                .then(response => {
                    // console.log(response)
                    if (response.status == 200) {
                        setEditStatus(1)
                        check_update()

                    }
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        setEditStatus(2)
                    }
                    else {
                        setEditStatus(2)
                    }
                    console.log('edit line error: ', error);
                })
        });
    }

    const handleEditStatusReject = () => {
        setTimeout(() => {
            axios.put(`${apiUrl.PUT_PREPARATION_ZONE}${inStockCheckOnEditStatus.CHECK_ON}/`, {
                "status": 'Reject',
                "at_rejected_timestamp": datetime,
                "username": localStorage.getItem('rb_email'),
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                    "X-xss-protection": "1; mode=block;",
                    "X-frame-options": "SAMEORIGIN",
                    "X-content-type-options": "nosniff",
                    "Cache-Control": "no-cache, private",
                    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                    "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                }
            })
                .then(response => {
                    // console.log(response)
                    if (response.status == 200) {
                        setEditStatus(1)
                        check_update()

                    }
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        setEditStatus(2)
                    }
                    else {
                        setEditStatus(2)
                    }
                    console.log('edit line error: ', error);
                })
        });
    }

    useEffect(() => {
        if (data !== "" && noData == 1 && check_status.length > 0) {
            setOpen(true)
        }
    }, [noData]);

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         setStartProduction(false)
    //         setEditWeightInAPI(false)
    //         setEditShiftInAPI(false)
    //         setEditLineInAPI(false)
    //         setEditStatus(false)
    //         setApplyProduction(false)
    //         if (noData === 1 && check_status.length > 0 && status_preparation_zone.length === 0) {
    //             setData("")
    //             setNoData(false)
    //             //setOpen(false)
    //         }
    //         /*else if (noData === 1 && check_status.length > 0 && status_preparation_zone.length > 0) {
    //             setOpen(false)
    //         }*/
    //     }, 3000);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, [noData, open]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setStartProduction(false);
            setEditWeightInAPI(false);
            setEditShiftInAPI(false);
            setEditLineInAPI(false);
            setEditStatus(false);
            setApplyProduction(false);
            if (noData === 1 && check_status.length > 0 && status_preparation_zone.length === 0) {
                setData("");
                setNoData(false);
                // setOpen(false)
            }
            /*else if (noData === 1 && check_status.length > 0 && status_preparation_zone.length > 0) {
                setOpen(false)
            }*/
        }, 3000);

        return () => {
            clearTimeout(timeout); // ทำการยกเลิก timeout เมื่อ component ถูกทำลาย
        };
    }, [noData, open]);




    useEffect(() => {
        if (data !== "" && noData == 2 && data_split[4] !== undefined) {
            handleStartProductionInQrcode()
        }


    }, [data_split[4], noData]);

    useEffect(() => {
        if (applyProduction === false) {
            clearTextField();
        }


    }, [applyProduction]);






    /*useEffect(() => {
        async function fetchMyAPI() {
            await axios.post(apiUrl.POST_PREPARATION_ZONE, {
                "process_order_number": (data_split[0] == "" || data_split[0] == " ") ? "-" : data_split[0],
                "line": (data_split[1] == "" || data_split[1] == " ") ? "-" : data_split[1],
                "material_description": (data_split[2] == "" || data_split[2] == " ") ? "-" : data_split[2],
                "batch": (data_split[3] == "" || data_split[3] == " ") ? "-" : data_split[3],
                "barrel_timestamp": (data_split[4] == "" || data_split == " ") ? "-" : data_split[4],
                "barrel_weight": (data_split[5] == "" || data_split[5] == " ") ? 0 : data_split[5],
                "total_batch_weight": (data_split[6] == "" || data_split[6] == " ") ? 0 : data_split[6],
                "barrel_meat": data_split[7] == undefined ? 0 : ((data_split[7] == "" || data_split[7] == " ") ? 0 : Number(data_split[7])),
                "actual_barrel_weight": (data_split[5] == "" || data_split[5] == " ") ? "-" : data_split[5],
                "actual_barrel_meat": (data_split[5] == "" || data_split[5] == " ") ? "-" : data_split[5],
                "actual_timestamp": actualTimestamp,
                "status": "In Stock",
                "shift": (time.toString() >= '06:00:00' && time.toString() <= '17:59:00') ? 'D' : 'N',
                "username": localStorage.getItem('rb_email'),
    
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                    "X-xss-protection": "1; mode=block;",
                    "X-frame-options": "SAMEORIGIN",
                    "X-content-type-options": "nosniff",
                    "Cache-Control": "no-cache, private",
                    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                    "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                }
            })
                .then(response => {
                    // console.log(response)
                    if (response.status == 201) {
                        console.log(response.data)
                        setApplyProduction(1)
    
                    }
                    else {
                        setApplyProduction(2)
    
                    }
                })
                .catch(error => {
                    console.log('start tumbler error: ', error);
                })
        }

        if (data !== "" && noData == 2) {
            fetchMyAPI()
            clearTextField();
        }
      

    }, [data_split[4]]);*/



    /*const myObjectsCheckOn = {};
    Object.keys(startProductionCheckOn === undefined ? '' : startProductionCheckOn).forEach((key) => {
        const splitKey = key.split(',');
        const elemId = splitKey[1];
        const realKey = splitKey[0];

        if (!myObjectsCheckOn[elemId]) {
            myObjectsCheckOn[elemId] = { id: elemId }; // Create entry
        }
        myObjectsCheckOn[elemId][realKey] = startProductionCheckOn[key];
    });

    // Turn into array
    const myObjectsToArrayCheckOn = Object.values(myObjectsCheckOn);


    const result = processing_preparation_zone.filter(c => inStock_preparation_zone.some(s => s.FACTORY_DATE === c.FACTORY_DATE && s.MATERIAL_DESCRIPTION === c.MATERIAL_DESCRIPTION && s.LINE === c.LINE && s.BATCH === c.BATCH));

    let result1 = processing_preparation_zone.some((o) => inStock_preparation_zone.some(v => v.FACTORY_DATE === o.FACTORY_DATE && v.MATERIAL_DESCRIPTION === o.MATERIAL_DESCRIPTION && v.LINE === o.LINE && v.BATCH === o.BATCH));

    const resultCheckOn = processing_preparation_zone.filter(c => myObjectsToArrayCheckOn.some(s => s.FACTORY_DATE === c.FACTORY_DATE && s.MATERIAL_DESCRIPTION === c.MATERIAL_DESCRIPTION && s.LINE === c.LINE && s.BATCH === c.BATCH));

    let result1CheckOn = processing_preparation_zone.some((o) => myObjectsToArrayCheckOn.some(v => v.FACTORY_DATE === o.FACTORY_DATE && v.MATERIAL_DESCRIPTION === o.MATERIAL_DESCRIPTION && v.LINE === o.LINE && v.BATCH === o.BATCH));



    useEffect(() => {
        async function fetchMyAPI() {
            await axios.put(`${apiUrl.PUT_PREPARATION_ZONE}${resultCheckOn[0].CHECK_ON}/`, {
                "status": 'Completed',
                "at_completed_timestamp": datetime,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                    "X-xss-protection": "1; mode=block;",
                    "X-frame-options": "SAMEORIGIN",
                    "X-content-type-options": "nosniff",
                    "Cache-Control": "no-cache, private",
                    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                    "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                }
            })
                .then(response => {
                    // console.log(response)
                    if (response.status == 201) {
                        console.log(response.data)


                    }
                    else {


                    }
                })
                .catch(error => {
                    console.log('start tumbler error: ', error);
                })
        }

        if (startProduction === 1 && result1 === true && result1CheckOn === true) {
            setStartProduction(false)
            fetchMyAPI()
        }

    }, [resultCheckOn]);*/



    /*var filtered = [];

    for (var arr in inStock_preparation_zone) {
        for (var filter in status_preparation_zone) {
            if (inStock_preparation_zone[arr].CHECK_ON == status_preparation_zone[filter].CHECK_ON) {
                filtered.push(inStock_preparation_zone[arr].CHECK_ON);
            }
        }
    }*/












    return (

        <div className="App">
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                <Container maxWidth={false} sx={{ p: 0 }}>
                    <Grid
                        container
                        style={{ paddingTop: 0 }}
                    >
                        <Grid item lg={12} md={12} sm={12} xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <Typography style={{ fontWeight: "bold", fontSize: 36, position: "relative", left: 0, paddingTop: 0 }} textAlign="center">
                                {noData === 1 ? 'QRCode Information' : 'Scan QRCode'}
                            </Typography>
                        </Grid>

                        {noData === 1 || (noData === 2 && applyProduction !== false) ? '' :
                            <Grid container spacing={2} style={{ position: "relative", top: '0vw', left: '0vw' }}>
                                <Grid item lg={12} md={12} sm={12} xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                                    <Grid item lg={4} md={4} sm={4} xl={4} xs={4.5} sx={{ display: 'flex', justifyContent: 'center' }} >
                                        <Button disabled={startScan == true} style={{ backgroundColor: startScan == true ? '#cfcfcf' : '#009688' }} variant="contained" onClick={() => { setLaserStar(!laserStar); }}>{laserStar ? "Laser Stop" : "Laser Start"}</Button>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={4} xl={4} xs={4.5} sx={{ display: 'flex', justifyContent: 'center' }} >
                                        <Button disabled={laserStar == true} style={{ backgroundColor: laserStar == true ? '#cfcfcf' : '#009688' }} variant="contained" onClick={() => { setStartScan(!startScan); }}>{startScan ? "Camera Stop" : "Camera Start"}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>}

                    </Grid>
                </Container>
                <Box style={{ height: 10 }}>

                </Box>
            </Grid>

            {laserStar && data.length <= 0 && (
                <>
                    <TextField
                        autoFocus
                        id="outlined-number"
                        //type="number"
                        name="laser_scan"
                        value={data}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleSelectionLaserScan}
                        onClick={(newValue) => {
                            //////Get React DatePicker date value in onChange(https://stackoverflow.com/questions/70182747/get-react-datepicker-date-value-in-onchange)
                            const d = new Date(newValue);
                            const formatted = moment(d).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');
                            setActualTimestamp(formatted)
                            console.log("date: " + formatted);
                        }}
                        rows={4}
                    />
                </>
            )}

            {startScan && (
                <>
                    <QrReader
                        facingMode={selected}
                        delay={1000}
                        onError={handleError}
                        onScan={handleScan}
                        // chooseDeviceId={()=>selected}
                        style={{ width: "300px" }}
                    />
                </>
            )}
            {loadingScan &&
                <Typography style={{ fontSize: 22, position: "relative", left: 0, paddingTop: 10 }} textAlign="center">
                    Loading...
                </Typography>
            }


            <div>
                <Backdrop
                    sx={{ backgroundColor: "rgb(0, 0, 0, 0.85)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <Grid
                        container spacing={1}
                        direction="row"
                        alignItems="top"
                        justifyContent="center"
                        style={{ minHeight: '60vh', maxHeight: '0vh', flexGrow: 1, paddingLeft: 20, paddingRight: 10, paddingTop: 0 }}
                    >

                        {check_status.map((item) => {
                            return <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ border: "2px dashed #ffffff", borderRadius: 20, position: "relative", top: '1vw', height: '105%', padding: 5 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                                    <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                        &nbsp;&nbsp;&nbsp;{item.MATERIAL_DESCRIPTION}&nbsp;&nbsp;&nbsp;
                                        <Button style={{ backgroundColor: '#E8AB6D', color: "black", fontSize: 16, height: '20px' }} onClick={() => { }}>
                                            {item.SHIFT}
                                        </Button>
                                    </Typography>
                                    <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} textAlign="right" >
                                        {item.STATUS}&nbsp;&nbsp;&nbsp;
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                                    <Typography style={{ color: '#ffffff', fontSize: 18, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                        &nbsp;&nbsp;&nbsp;WEIGHT TIME: {item.BARREL_TIMESTAMP}
                                    </Typography>
                                </Box>
                                {item.STATUS === "Processing" ?
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                                        <Typography style={{ color: '#ffffff', fontSize: 18, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            &nbsp;&nbsp;&nbsp;PROCESS TIME: {item.AT_PROCESS_TIMESTAMP}
                                        </Typography>
                                    </Box>
                                    : item.STATUS === "Completed" ?
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                                            <Typography style={{ color: '#ffffff', fontSize: 18, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                                &nbsp;&nbsp;&nbsp;COMPLETED TIME: {item.AT_COMPLETED_TIMESTAMP}
                                            </Typography>
                                        </Box>
                                        : item.STATUS === "Reject" ?
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                                                <Typography style={{ color: '#ffffff', fontSize: 18, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                                    &nbsp;&nbsp;&nbsp;REJECT TIME: {item.AT_REJECTED_TIMESTAMP}
                                                </Typography>
                                            </Box>
                                            : ""
                                }
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                                    <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                        &nbsp;&nbsp;&nbsp;ชุดนวด: {item.BATCH}
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} textAlign="left" >
                                    <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                        &nbsp;&nbsp;&nbsp;{item.ACTUAL_BARREL_WEIGHT} kg.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Typography>
                                    <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold' }} textAlign="right">
                                        {item.LINE}
                                    </Typography>
                                </Box>

                            </Grid>
                        })}
                    </Grid>


                </Backdrop>
            </div>




            {data !== "" && noData == 2 &&
                <Grid container spacing={1} style={{ paddingLeft: 0, paddingTop: 25, paddingRight: 0 }} >
                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" style={{ position: "relative", top: '0vw', height: '0px' }}>

                        <Box >
                            <div>
                                {Object.entries(categories).map((entry) => {
                                    const category = entry[0];
                                    const itemList = entry[1];

                                    const categories_time = {};
                                    for (const item of itemList) {
                                        if (item[4] in categories_time) {
                                            categories_time[item[4].split(" ")[0]].push(item);
                                        } else {
                                            categories_time[item[4].split(" ")[0]] = [item];
                                        }
                                    }
                                    return (
                                        <Accordion defaultExpanded>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {Object.entries(categories_time).map((entry) => {
                                                    const category_list = entry[0];
                                                    const item_list = entry[1];
                                                    return (
                                                        <Accordion defaultExpanded>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category_list}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                {item_list.map((item) => {

                                                                    return <Card sx={{
                                                                        p: 1,
                                                                        margin: 'auto',
                                                                        //maxWidth: 1500,
                                                                        flexGrow: 1,
                                                                        backgroundColor: (theme) =>
                                                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                                                    }}>
                                                                        <Grid item xs={12} sm container>
                                                                            <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "0vw" }}>
                                                                                <Grid item xs>
                                                                                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                        <Typography style={{ fontSize: 16 }} gutterBottom>
                                                                                            {item[2]}&nbsp;&nbsp;&nbsp;
                                                                                        </Typography>
                                                                                        <Typography style={{ fontSize: 16, width: '5%' }} gutterBottom>
                                                                                            <Button style={{ backgroundColor: '#E8AB6D', color: "black", fontSize: 16, height: '20px' }} onClick={() => { }}>
                                                                                                {(time.toString() >= '06:00:00' && time.toString() <= '17:59:00') ? 'D' : 'N'}
                                                                                            </Button>
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Typography style={{ fontSize: 16, width: '1000%' }} gutterBottom>
                                                                                        {item[4]}
                                                                                    </Typography>
                                                                                    <Typography style={{ fontSize: 16 }} gutterBottom>
                                                                                        ชุดนวด: {item[3]}
                                                                                    </Typography>
                                                                                    <Tooltip title="edit weight" placement="top-start">
                                                                                        <Typography gutterBottom style={{ color: "black", fontSize: 16, height: '20px' }} onClick={() => { }}>
                                                                                            {item[5]} kg.
                                                                                            <ModeEditIcon style={{ fontSize: '14px' }} ></ModeEditIcon>
                                                                                        </Typography>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                                <Grid item> {/*call multiple functions onClick ReactJS(https://stackoverflow.com/questions/26069238/call-multiple-functions-onclick-reactjs) */}
                                                                                    <Button variant="contained" style={{ fontSize: '12px', width: '80px', fontWeight: 'bold', backgroundColor: '#009688' }} onClick={() => { }}>
                                                                                        APPLY
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs container spacing={1} style={{ position: "relative", top: "-0.5vw" }} textAlign="right">
                                                                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                                                                    <ButtonGroup orientation="vertical" aria-label="Vertical button group">
                                                                                        <Button style={{ height: '20px', width: '80px', fontWeight: 'bold', color: '#3A59F2', backgroundColor: '#c7c9c8' }}>IN STOCK</Button>
                                                                                        <Button style={{ height: '20px', width: '80px', color: '#a9abaa' }} onClick={() => { }}>PROCESSING</Button>
                                                                                        <Button style={{ height: '20px', width: '80px', color: '#a9abaa' }} onClick={() => { }}>REJECT</Button>
                                                                                    </ButtonGroup>
                                                                                </Grid>
                                                                                <Grid item xs={12} md={12} lg={12} xl={12} style={{ position: "relative", top: "-2vw" }}>
                                                                                    <Button variant="outlined" style={{ color: "black", fontWeight: 'bold', fontSize: '14px', height: '25px', width: '80px' }} onClick={() => { }}>
                                                                                        {item[1]}
                                                                                        <ArrowDropDownIcon style={{ fontSize: '14px' }} ></ArrowDropDownIcon>
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Card>
                                                                })}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    );
                                                })}
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}
                            </div>
                        </Box>
                    </Grid>

                </Grid>
            }

            {data !== "" && noData == 1 && status_preparation_zone.length > 0 &&
                <Grid container spacing={1} style={{ paddingLeft: 0, paddingTop: 25, paddingRight: 0 }} >
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        <Grid container spacing={1} style={{ paddingLeft: 0, paddingTop: data !== "" ? 0 : 0, paddingRight: 0 }} >
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" style={{ position: "relative", top: '0vw', height: '0px' }}>

                                <Box >
                                    <div>
                                        {Object.entries(categories_api).map((entry) => {
                                            const category = entry[0];
                                            const itemList = entry[1];

                                            const categories_time = {};
                                            for (const item of itemList) {
                                                if (item.FACTORY_DATE in categories_time) {
                                                    categories_time[item.FACTORY_DATE].push(item);
                                                } else {
                                                    categories_time[item.FACTORY_DATE] = [item];
                                                }
                                            }
                                            return (
                                                <Accordion
                                                    defaultExpanded
                                                    TransitionComponent={Slide}
                                                    TransitionProps={slideProps}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {Object.entries(categories_time).map((entry) => {
                                                            const category_list = entry[0];
                                                            const item_list = entry[1];
                                                            return (
                                                                <Accordion
                                                                    defaultExpanded
                                                                    TransitionComponent={Slide}
                                                                    TransitionProps={slideProps}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"
                                                                    >
                                                                        <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category_list}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        {item_list.map((item) => {

                                                                            return <Card sx={{
                                                                                p: 1,
                                                                                margin: 'auto',
                                                                                //maxWidth: 1500,
                                                                                flexGrow: 1,
                                                                                backgroundColor: (theme) =>
                                                                                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                                                            }}>
                                                                                <Grid item xs={12} sm container>
                                                                                    <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "0vw" }}>
                                                                                        <Grid item xs>
                                                                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                                <Typography style={{ fontSize: 18, fontWeight: 'bold' }} gutterBottom>
                                                                                                    {item.MATERIAL_DESCRIPTION}&nbsp;&nbsp;&nbsp;
                                                                                                </Typography>

                                                                                            </Box>

                                                                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                                <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px', width: '80px' }} onClick={() => { setInStockCheckOnEditLine({ CHECK_ON: item.CHECK_ON, LINE: item.LINE }); handleOpenEditLineInAPI() }}>
                                                                                                    {item.LINE}&nbsp;
                                                                                                    <ModeEditIcon style={{ fontSize: 16, position: "relative", top: "0.2vw" }} ></ModeEditIcon>
                                                                                                </Typography>
                                                                                                <Tooltip title="edit shift" placement="top-start">
                                                                                                    <Button style={{ backgroundColor: '#E8AB6D', color: "black", fontSize: 18, height: '20px' }} onClick={() => { setInStockCheckOnEditShift({ CHECK_ON: item.CHECK_ON, SHIFT: item.SHIFT }); handleOpenEditShiftInAPI() }}>
                                                                                                        {editShift === 1 ? item.SHIFT : ((time.toString() >= '06:00:00' && time.toString() <= '17:59:00') ? 'D' : 'N')}
                                                                                                    </Button>
                                                                                                </Tooltip>
                                                                                            </Box>

                                                                                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', height: '5%' }} textAlign="left" >

                                                                                            </Box>

                                                                                            <Typography style={{ fontSize: 15, width: '1000%' }} gutterBottom>
                                                                                                {(item.BARREL_TIMESTAMP).split("Z")[0]}
                                                                                            </Typography>
                                                                                            <Typography style={{ fontSize: 18 }} gutterBottom>
                                                                                                ชุดนวด: {item.BATCH}
                                                                                            </Typography>
                                                                                            <Tooltip title="edit weight" placement="top-start">
                                                                                                <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px' }} onClick={() => { setInStockCheckOnEditWeight({ CHECK_ON: item.CHECK_ON, ACTUAL_BARREL_WEIGHT: item.ACTUAL_BARREL_WEIGHT }); handleOpenEditWeightInAPI() }}>
                                                                                                    {item.ACTUAL_BARREL_WEIGHT} kg.&nbsp;
                                                                                                    <ModeEditIcon style={{ fontSize: 16, position: "relative", top: "0.2vw" }} ></ModeEditIcon>
                                                                                                </Typography>
                                                                                            </Tooltip>
                                                                                        </Grid>

                                                                                    </Grid>
                                                                                    <Grid item xs container spacing={1} style={{ position: "relative", top: "1vw" }} textAlign="right">
                                                                                        <Grid item xs={12} md={12} lg={12} xl={12}>
                                                                                            <ButtonGroup orientation="vertical" aria-label="Vertical button group">
                                                                                                <Button style={{ height: '30px', width: '85px', fontWeight: 'bold', color: '#3A59F2', backgroundColor: '#c7c9c8', fontSize: 15 }}>IN STOCK</Button>
                                                                                                <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} onClick={() => { setInStockCheckOnEditStatus({ CHECK_ON: item.CHECK_ON, STATUS: item.STATUS }); handleOpenEditStatusProcessing(); }}>PROCESSING</Button>
                                                                                                <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} onClick={() => { setInStockCheckOnEditStatus({ CHECK_ON: item.CHECK_ON, STATUS: item.STATUS }); handleOpenEditStatusReject(); }}>REJECT</Button>
                                                                                            </ButtonGroup>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} md={12} lg={12} xl={12} style={{ height: '15px' }}>

                                                                                    </Grid>

                                                                                    <Grid item xs={12} md={12} lg={12} xl={12} style={{ position: "relative", padding: 0 }}>
                                                                                        <Stack direction="row" spacing={2}>
                                                                                            <Button fullWidth variant="contained" style={{ fontSize: '16px', fontWeight: 'bold', backgroundColor: '#575755' }} onClick={() => { setNoData(false); clearTextField(); }}>
                                                                                                BACK
                                                                                            </Button>
                                                                                            <Button fullWidth variant="contained" style={{ fontSize: '16px', fontWeight: 'bold', backgroundColor: '#009688' }} onClick={() => { setStartProductionCheckOn({ CHECK_ON: item.CHECK_ON, MATERIAL_DESCRIPTION: item.MATERIAL_DESCRIPTION, BARREL_TIMESTAMP: item.BARREL_TIMESTAMP, BATCH: item.BATCH, BARREL_WEIGHT: item.BARREL_WEIGHT, LINE: item.LINE, STATUS: item.STATUS, PROCESS_ORDER_NUMBER: item.PROCESS_ORDER_NUMBER, TOTAL_BATCH_WEIGHT: item.TOTAL_BATCH_WEIGHT, ACTUAL_BARREL_MEAT: item.ACTUAL_BARREL_MEAT, BARREL_MEAT: item.BARREL_MEAT, ACTUAL_TIMESTAMP: item.ACTUAL_TIMESTAMP, SHIFT: item.SHIFT, ACTUAL_BARREL_WEIGHT: item.ACTUAL_BARREL_WEIGHT, FACTORY_DATE: item.FACTORY_DATE }); handleClickConfirmStartInAPI(); }}>
                                                                                                START
                                                                                            </Button>
                                                                                        </Stack>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Card>
                                                                        })}
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            );
                                                        })}
                                                    </AccordionDetails>
                                                </Accordion>
                                            );
                                        })}
                                    </div>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            }


            {done ? <Circular /> :
                ((data !== "" && noData === 1 && status_preparation_zone.length > 0) || (data !== "" && noData === 2 && applyProduction !== false) ? '' :
                    <Grid container spacing={1} style={{ paddingLeft: 0, paddingTop: 25, paddingRight: 0 }} >
                        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                            <Grid container spacing={1} style={{ paddingLeft: 0, paddingTop: data !== "" ? 360 : 0, paddingRight: 0 }} >
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" style={{ position: "relative", top: '0vw', height: '0px' }}>

                                    <Box >
                                        <div>
                                            {Object.entries(categories_between).map((entry) => {
                                                const category = entry[0];
                                                const itemList = entry[1];

                                                const categories_time = {};
                                                for (const item of itemList) {
                                                    if (item.FACTORY_DATE in categories_time) {
                                                        categories_time[item.FACTORY_DATE].push(item);
                                                    } else {
                                                        categories_time[item.FACTORY_DATE] = [item];
                                                    }
                                                }
                                                return (
                                                    <Accordion
                                                        TransitionComponent={Slide}
                                                        TransitionProps={slideProps}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {Object.entries(categories_time).map((entry) => {
                                                                const category_list = entry[0];
                                                                const item_list = entry[1];
                                                                return (
                                                                    <Accordion
                                                                        TransitionComponent={Slide}
                                                                        TransitionProps={slideProps}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                        >
                                                                            <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category_list}</Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            {item_list.map((item) => {

                                                                                return <Card sx={{
                                                                                    p: 1,
                                                                                    margin: 'auto',
                                                                                    //maxWidth: 1500,
                                                                                    flexGrow: 1,
                                                                                    backgroundColor: (theme) =>
                                                                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                                                                }}>
                                                                                    <Grid item xs={12} sm container>
                                                                                        <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "0vw" }}>
                                                                                            <Grid item xs>
                                                                                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                                    <Typography style={{ fontSize: 18, fontWeight: 'bold' }} gutterBottom>
                                                                                                        {item.MATERIAL_DESCRIPTION}&nbsp;&nbsp;&nbsp;
                                                                                                    </Typography>

                                                                                                </Box>

                                                                                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                                    <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px', width: '80px' }} onClick={() => { setInStockCheckOnEditLine({ CHECK_ON: item.CHECK_ON, LINE: item.LINE }); handleOpenEditLineInAPI() }}>
                                                                                                        {item.LINE}&nbsp;
                                                                                                        <ModeEditIcon style={{ fontSize: 16, position: "relative", top: "0.2vw" }} ></ModeEditIcon>
                                                                                                    </Typography>
                                                                                                    <Tooltip title="edit shift" placement="top-start">
                                                                                                        <Button style={{ backgroundColor: '#E8AB6D', color: "black", fontSize: 18, height: '20px' }} onClick={() => { setInStockCheckOnEditShift({ CHECK_ON: item.CHECK_ON, SHIFT: item.SHIFT }); handleOpenEditShiftInAPI() }}>
                                                                                                            {item.SHIFT/* {editShift === 1 ? item.SHIFT : ((time.toString() >= '06:00:00' && time.toString() <= '17:59:00') ? 'D' : 'N')} */}
                                                                                                        </Button>
                                                                                                    </Tooltip>
                                                                                                </Box>

                                                                                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', height: '5%' }} textAlign="left" >

                                                                                                </Box>

                                                                                                <Typography style={{ fontSize: 15, width: '1000%' }} gutterBottom>
                                                                                                    {(item.BARREL_TIMESTAMP).split("Z")[0]}
                                                                                                </Typography>
                                                                                                <Typography style={{ fontSize: 18 }} gutterBottom>
                                                                                                    ชุดนวด: {item.BATCH}
                                                                                                </Typography>
                                                                                                <Tooltip title="edit weight" placement="top-start">
                                                                                                    <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px' }} onClick={() => { setInStockCheckOnEditWeight({ CHECK_ON: item.CHECK_ON, ACTUAL_BARREL_WEIGHT: item.ACTUAL_BARREL_WEIGHT }); handleOpenEditWeightInAPI() }}>
                                                                                                        {item.ACTUAL_BARREL_WEIGHT} kg.&nbsp;
                                                                                                        <ModeEditIcon style={{ fontSize: 16, position: "relative", top: "0.2vw" }} ></ModeEditIcon>
                                                                                                    </Typography>
                                                                                                </Tooltip>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                        <Grid item xs container spacing={1} style={{ position: "relative", top: "1vw" }} textAlign="right">
                                                                                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                                                                                <ButtonGroup orientation="vertical" aria-label="Vertical button group">
                                                                                                    <Button style={{ height: '30px', width: '85px', fontWeight: 'bold', color: '#3A59F2', backgroundColor: '#c7c9c8', fontSize: 15 }}>IN STOCK</Button>
                                                                                                    <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} onClick={() => { setInStockCheckOnEditStatus({ CHECK_ON: item.CHECK_ON, STATUS: item.STATUS }); handleOpenEditStatusProcessing(); }}>PROCESSING</Button>
                                                                                                    <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} onClick={() => { setInStockCheckOnEditStatus({ CHECK_ON: item.CHECK_ON, STATUS: item.STATUS }); handleOpenEditStatusReject(); }}>REJECT</Button>
                                                                                                </ButtonGroup>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ height: '15px' }}>

                                                                                        </Grid>

                                                                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ position: "relative", padding: 0 }}>
                                                                                            <Button fullWidth variant="contained" style={{ fontSize: '16px', fontWeight: 'bold', backgroundColor: '#009688' }} onClick={() => { setStartProductionCheckOn({ CHECK_ON: item.CHECK_ON, MATERIAL_DESCRIPTION: item.MATERIAL_DESCRIPTION, BARREL_TIMESTAMP: item.BARREL_TIMESTAMP, BATCH: item.BATCH, BARREL_WEIGHT: item.BARREL_WEIGHT, LINE: item.LINE, STATUS: item.STATUS, PROCESS_ORDER_NUMBER: item.PROCESS_ORDER_NUMBER, TOTAL_BATCH_WEIGHT: item.TOTAL_BATCH_WEIGHT, ACTUAL_BARREL_MEAT: item.ACTUAL_BARREL_MEAT, BARREL_MEAT: item.BARREL_MEAT, ACTUAL_TIMESTAMP: item.ACTUAL_TIMESTAMP, SHIFT: item.SHIFT, ACTUAL_BARREL_WEIGHT: item.ACTUAL_BARREL_WEIGHT, FACTORY_DATE: item.FACTORY_DATE }); handleClickConfirmStartInAPI(); }}>
                                                                                                START
                                                                                            </Button>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Card>
                                                                            })}
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                );
                                                            })}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                );
                                            })}
                                        </div>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                )}


            {/* pop up start production  */}
            <Dialog
                open={openConfirmStartInAPI}
                transitionDuration={1}
                onClose={handleCloseConfirmStartInAPI}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to start production
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenConfirmStartInAPI(false); }}>Cancel</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenConfirmStartInAPI(false); handleStartProductionInAPI(); clearTextField(); setNoData(false); }}>Confirm</Button>
                </DialogActions>
            </Dialog>

            {/* pop up edit weight */}
            <Dialog
                open={openEditWeightInAPI}
                transitionDuration={1}
                onClose={handleCloseEditWeightInAPI}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
                    EDIT WEIGHT
                </DialogTitle>
                <DialogContent>
                    <Box width='95%' style={{ position: "relative", top: "0px", left: "0px" }} >
                        <TextField
                            id="outlined-number"
                            type="number"
                            name="weight"
                            value={inStockHistorySelectedWeight > (inStockCheckOnEditWeight == undefined ? '' : inStockCheckOnEditWeight.ACTUAL_BARREL_WEIGHT) ? (inStockCheckOnEditWeight == undefined ? '' : inStockCheckOnEditWeight.ACTUAL_BARREL_WEIGHT) : inStockHistorySelectedWeight && inStockHistorySelectedWeight < 0 ? (inStockCheckOnEditWeight == undefined ? '' : inStockCheckOnEditWeight.ACTUAL_BARREL_WEIGHT) : inStockHistorySelectedWeight}
                            defaultValue={inStockCheckOnEditWeight == undefined ? '' : inStockCheckOnEditWeight.WEIGHT}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleEditionWeightInAPI}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenEditWeightInAPI(false); }}>Cancel</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenEditWeightInAPI(false); handleEditWeightInAPI(); }}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* pop up edit shift */}
            <Dialog
                open={openEditShiftInAPI}
                transitionDuration={1}
                onClose={handleCloseEditShiftInAPI}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Box width='95%' style={{ position: "relative", top: "0px", left: "0px" }} >
                        <Typography fontSize={'16px'} fontWeight="medium">
                            EDIT SHIFT
                        </Typography>
                        <TextField
                            id="outlined-number"
                            //type="number"
                            name="shift"
                            value={inStockHistorySelectedShift}
                            //defaultValue={inStockCheckOnEditShift.SHIFT == 'D' ? 'N' : 'D'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleEditionShiftInAPI}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenEditShiftInAPI(false); }}>Cancel</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenEditShiftInAPI(false); handleEditShiftInAPI(); }}>Save</Button>
                </DialogActions>
            </Dialog>


            {/* pop up edit line */}
            <Dialog
                open={openEditLineInAPI}
                transitionDuration={1}
                onClose={handleCloseEditLineInAPI}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ position: "relative", top: "-5px", left: "-12px" }}>
                    EDIT LINE
                </DialogTitle>
                <DialogContentText id="alert-dialog-description">
                    <FormControl sx={{ m: 1, minWidth: '90%' }}>
                        <InputLabel >Select Line</InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={openSelectLineInAPI}
                            onClose={handleCloseSelectLineInAPI}
                            onOpen={handleOpenSelectLineInAPI}
                            value={inStockHistorySelectedLine}
                            label="Select Line"
                            onChange={handleEditionLineInAPI}
                            name="line"
                        >
                            {selectLine.map((item) => (
                                <MenuItem value={item.value}>{item.value}</MenuItem>
                            ))

                            }
                        </Select>
                    </FormControl>
                </DialogContentText>
                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenEditLineInAPI(false); }}>Cancel</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenEditLineInAPI(false); handleEditLineInAPI(); }}>Save</Button>
                </DialogActions>
            </Dialog>


            {/* pop up edit status processing */}
            <Dialog
                open={openEditStatusProcessing}
                transitionDuration={1}
                onClose={handleCloseEditStatusProcessing}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
                    EDIT STATUS
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to edit status from 'IN STOCK' to 'PROCESSING'
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenEditStatusProcessing(false); }}>Cancel</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenEditStatusProcessing(false); handleEditStatusProcessing(); }} >Save</Button>
                </DialogActions>
            </Dialog>

            {/* pop up edit status reject */}
            <Dialog
                open={openEditStatusReject}
                transitionDuration={1}
                onClose={handleCloseEditStatusReject}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
                    EDIT STATUS
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to edit status from 'IN STOCK' to 'REJECT'
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenEditStatusReject(false); }}>Cancel</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenEditStatusReject(false); handleEditStatusReject(); }} >Save</Button>
                </DialogActions>
            </Dialog>



            {/* pop up start production in qrcode */}
            <Dialog
                open={openConfirmStartInQrcode}
                onClose={handleCloseConfirmStartInQrcode}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to apply?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenConfirmStartInQrcode(false); }}>Cancel</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenConfirmStartInQrcode(false); /*handleStartProductionInQrcode(); clearTextField();*/ }}>Confirm</Button>
                </DialogActions>
            </Dialog>

            {/*show pop up success apply production in qrcode*/}
            {applyProduction === 1 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Successfully apply
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }
            {applyProduction === 2 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Failed to apply, Plead try again
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }

            {/*show pop up success start production in api*/}
            {startProduction === 1 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Successfully started production
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }
            {startProduction === 2 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Failed to start production, Plead try again
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }

            {/*show pop up success edit weight in api*/}
            {editWeightInAPI === 1 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Successfully editted weight
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }
            {editWeightInAPI === 2 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Failed to edit weight, Plead try again
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }

            {/*show pop up success edit shift in api*/}
            {editShiftInAPI === 1 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Successfully editted shift
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }
            {editShiftInAPI === 2 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Failed to edit shift, Plead try again
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }

            {/*show pop up success edit shift in api*/}
            {editLineInAPI === 1 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Successfully editted line
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }
            {editLineInAPI === 2 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Failed to edit line, Plead try again
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }

            {/*show pop up success edit status*/}
            {editStatus === 1 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Successfully editted status
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }
            {editStatus === 2 ?
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                            Failed to edit status, Plead try again
                        </Typography>
                    </Backdrop>
                </div>
                : console.log()
            }
        </div>

    );
}






