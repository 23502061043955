import * as React from 'react';
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { tokens } from "theme";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#bcd6d0',
    padding: theme.spacing(0),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    //borderRadius: 10,
    height: '20vh'

}));

function GrigToStartTime(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <Item>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                <Grid item xs={2}>
                    {props.image}
                </Grid>

                <Grid item xs={2} >
                    <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 20, lineHeight: "10vh" }} >
                        Start
                    </Typography>
                    <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 30, lineHeight: "6vh" }} >
                        {props.start_time}
                    </Typography>
                </Grid>

                <Grid item xs={2} >
                    <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 20, lineHeight: "10vh" }} >
                        End
                    </Typography>
                    <Typography style={{ color: props.color_endtime, fontWeight: "bold", fontSize: 30, lineHeight: "6vh" }} >
                        {props.end_time}
                    </Typography>
                </Grid>

                <Grid item xs={0.1} style={{ borderLeft: "2px dashed #3cbea8", height: '15vh', position: "relative", top: '0.6vw' }} >

                </Grid>


                <Grid item xs={2.7}>
                    <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 20, lineHeight: "10vh" }} >
                        Production
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                        <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 30, lineHeight: "4.5vh" }} >
                            {props.production_time_hour}
                        </Typography>
                        <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 20, lineHeight: "4.5vh", paddingTop: 4 }} >
                            hr
                        </Typography>
                        <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 30, lineHeight: "4.5vh" }} >
                        {props.production_time_minute}
                        </Typography>
                        <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 20, lineHeight: "4.5vh", paddingTop: 4 }} >
                            min
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={0.1} style={{ borderLeft: "2px dashed #3cbea8", height: '15vh', position: "relative", top: '0.6vw' }} >

                </Grid>

                <Grid item xs={2.7}>
                    <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 20, lineHeight: "10vh" }} >
                        Non-Production
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                        <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 30, lineHeight: "4.5vh" }} >
                        {props.non_production_time_hour}
                        </Typography>
                        <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 20, lineHeight: "4.5vh", paddingTop: 4 }} >
                            hr
                        </Typography>
                        <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 30, lineHeight: "4.5vh" }} >
                        {props.non_production_time_minute}
                        </Typography>
                        <Typography style={{ color: colors.while[100], fontWeight: "bold", fontSize: 20, lineHeight: "4.5vh", paddingTop: 4 }} >
                            min
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Item>
    )
}


export default GrigToStartTime