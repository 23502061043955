import React, { useEffect, useState } from "react";
import { apiUrl } from "constants/index";
import { Box, Grid, Card } from "@mui/material";
import { Helmet } from "react-helmet";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment-timezone';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ModeEditIcon from '@mui/icons-material/ModeEdit';


var token_url = apiUrl.TOKEN;


function Tumbler() {
  const today = new Date();
  const hourago = new Date(today.getTime());
  const datetime = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');

  //console.log("datetime: " + datetime);

  const [date, setDate] = useState(moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD'));
  const [noData, setNoData] = useState(false);
  const [openEditWeight, setOpenEditWeight] = useState();
  const [tumblerCheckOnEditWeight, setTumblerCheckOnEditWeight] = useState();
  const [tumblerHistorySelectedWeight, setTumblerHistorySelectedWeight] = useState(0);
  const [meatAtTumbler, setMeatAtTumbler] = useState([]);
  const [editTumbler, setEditTumbler] = useState(false);
  const [notifyEdittedTumblerSuccessful, setNotifyEdittedTumblerSuccessful] = useState(false);
  const [notifyEdittedTumblerFailed, setNotifyEdittedTumblerFailed] = useState(false);


  const [openEditStatus, setOpenEditStatus] = useState();
  const [tumblerCheckOnEditStatus, setTumblerCheckOnEditStatus] = useState();
  const [openTumblerSelection, setOpenTumblerSelection] = React.useState(false);
  const [tumblerHistorySelectedStatus, setTumblerHistorySelectedStatus] = useState('');
  const [editStatus, setEditStatus] = useState(false);
  const [notifyEdittedStatusSuccessful, setNotifyEdittedStatusSuccessful] = useState(false);
  const [notifyEdittedStatusFailed, setNotifyEdittedStatusFailed] = useState(false);

  const [openConfirmStartTumbler, setOpenConfirmStartTumbler] = useState();
  const [startTumblerCheckOn, setStartTumblerCheckOn] = useState();
  const [startTumbler, setStartTumbler] = useState(false);
  const [notifyStartTumblerSuccessful, setNotifyStartTumblerSuccessful] = useState(false);
  const [notifyStartTumblerFailed, setNotifyStartTumblerFailed] = useState(false);

  const [openAddLot, setOpenAddLot] = useState();
  const [tumblerCheckOnAddLot, setTumblerCheckOnAddLot] = useState();
  const [tumblerAddLot, setTumblerAddLot] = useState('');
  const [tumblerAddSubLot, setTumblerAddSubLot] = useState('');
  const [addLot, setAddLot] = useState(false);
  const [notifyAddLotSuccessful, setNotifyAddLotSuccessful] = useState(false);
  const [notifyAddLotFailed, setNotifyAddLotFailed] = useState(false);


  const selectStatusTumbler = [
    {
      value: "In stock",
      label: "In stock"
    },
    {
      value: "Processing",
      label: "Processing"
    },
    {
      value: "Completed",
      label: "Completed"
    },
    {
      value: "Reject",
      label: "Reject"
    },
  ];

  const handleCloseTumblerSelection = () => {
    setOpenTumblerSelection(false);
  };

  const handleOpenTumblerSelection = () => {
    setOpenTumblerSelection(true);
  };

  const handleOpenEditWeight = () => {
    setOpenEditWeight(true);
  };

  const handleCloseEditWeight = () => {
    setOpenEditWeight(false);
  };

  const handleOpenEditStatus = () => {
    setOpenEditStatus(true);
  };

  const handleCloseEditStatus = () => {
    setOpenEditStatus(false);
  };

  const handleClickConfirmStartTumbler = () => {
    setOpenConfirmStartTumbler(true);
  };

  const handleCloseConfirmStartTumbler = () => {
    setOpenConfirmStartTumbler(false);
  };

  const handleOpenAddLot = () => {
    setOpenAddLot(true);
  };

  const handleCloseAddLot = () => {
    setOpenAddLot(false);
  };

  useEffect(() => {
    // check access token expiry
    checkToken();
    //console.log('get_select_date: ' + date)
    var get_datakorat = `${apiUrl.GET_MEAT_AT_TUMBLER}${date}/` // date format must be YYYY-MM-DD, eg. 2022-06-16
    axios.get(get_datakorat, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    }
    )
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          //console.log("Tumbler: " + response.data);
          setTumblerHistorySelectedWeight(Math.round(tumblerCheckOnEditWeight.WEIGHT));
          setTumblerHistorySelectedStatus(tumblerCheckOnEditStatus.STATUS)
          setTumblerAddLot(tumblerCheckOnAddLot.LOT)
          setTumblerAddSubLot(tumblerCheckOnAddLot.SUBLOT)
        }
      })
      .catch(error => {
        console.log('error: ', error)

      })
  }, [tumblerCheckOnEditWeight, tumblerCheckOnEditStatus, startTumblerCheckOn, tumblerCheckOnAddLot]);


  // ฟังก์ชั่นเช็คtoken
  const checkToken = async () => {
    let access_exp = localStorage.getItem("rb_ac_exp");
    let refresh_exp = localStorage.getItem("rb_rf_exp");
    //console.log('access_exp: ' + access_exp);
    //console.log('refresh_exp: ' + refresh_exp);
    if (refresh_exp < Date.now() / 1000) {
      //console.log("refresh is expiried");
      // alert('Please log in again')
      localStorage.clear();
      window.location = "/Login";
    } else if (access_exp < Date.now() / 1000) {
      const refresh = localStorage.getItem("rb_rf_token");

      let data = {
        refresh: refresh,
      };
      let result = await axios.post(token_url, data);
      //console.log("got new access_token!");
      localStorage.setItem("rb_ac_token", result.data.access);
    }
  };

  useEffect(() => {
    async function fetchMyAPI() {
    // check access token expiry
    checkToken();
    //console.log('get_select_date: ' + date)
    var get_datakorat = `${apiUrl.GET_MEAT_AT_TUMBLER}${date}/` // date format must be YYYY-MM-DD, eg. 2022-06-16
    await axios.get(get_datakorat, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    }
    )
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          //console.log("Tumbler: " + response.data);
          setMeatAtTumbler(response.data)
          setNoData(1)
        }
      })
      .catch(error => {
        setNoData(2)
        console.log('error: ', error)

      })
    }
    fetchMyAPI()

  }, [meatAtTumbler]);

  //console.log(meatAtTumbler,timestrs);



  const handleEditionInTumbler = (event) => {
    if (event.target.name === "weight") {
      setTumblerHistorySelectedWeight(event.target.value);
    }
  };


  const handleEditTumbler = async () => {
    /*console.log({
      "weight": Number(tumblerHistorySelectedWeight),
      "CHECK_ON": tumblerCheckOnEditWeight.CHECK_ON,
      "status": "Processing",
      "start_time": datetime
    })*/
    //console.log(`${apiUrl.EDIT_TUMBLER}${tumblerCheckOnEditWeight.CHECK_ON}/`)
    await axios.put(`${apiUrl.EDIT_TUMBLER}${tumblerCheckOnEditWeight.CHECK_ON}/`, {
      "weight": Number(tumblerHistorySelectedWeight),
      "status": "Processing",
      "start_time": datetime
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          setTumblerHistorySelectedWeight();
          setEditTumbler(1)
          setNotifyEdittedTumblerSuccessful(true)
        }
        else {
          setEditTumbler(2)
          setNotifyEdittedTumblerFailed(true)
        }
      })
      .catch(error => {
        console.log('edit tumbler error: ', error);
      })
  }


  const handleEditionInStatus = (event) => {
    if (event.target.name === "status") {
      setTumblerHistorySelectedStatus(event.target.value);
    }
  };


  const handleEditStatus = async () => {
    /*console.log({
      "status": tumblerHistorySelectedStatus,
      "start_time": datetime
    })*/ 
    //console.log(`${apiUrl.EDIT_TUMBLER}${tumblerCheckOnEditWeight.CHECK_ON}/`)
    await axios.put(`${apiUrl.EDIT_TUMBLER}${tumblerCheckOnEditStatus.CHECK_ON}/`, {
      "status": tumblerHistorySelectedStatus,
      "start_time": datetime
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          setTumblerHistorySelectedStatus();
          setEditStatus(1)
          setNotifyEdittedStatusSuccessful(true)
        }
        else {
          setEditStatus(2)
          setNotifyEdittedStatusFailed(true)
        }
      })
      .catch(error => {
        console.log('edit status error: ', error);
      })
  }

  const handleStartTumbler = async () => {
    /*console.log({
      "status": "Processing",
      "start_time": datetime
    })*/ 
    //console.log(`${apiUrl.EDIT_TUMBLER}${startTumblerCheckOn.CHECK_ON}/`)
    await axios.put(`${apiUrl.EDIT_TUMBLER}${startTumblerCheckOn.CHECK_ON}/`, {
      "status": "Processing",
      "start_time": datetime
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          setStartTumbler(1)
          setNotifyStartTumblerSuccessful(true)
        }
        else {
          setStartTumbler(2)
          setNotifyStartTumblerFailed(true)
        }
      })
      .catch(error => {
        console.log('start tumbler error: ', error);
      })
  }

  const handleAddInLot = (event) => {
    if (event.target.name === "lot") {
      setTumblerAddLot(event.target.value);
    }
    if (event.target.name === "sublot") {
      setTumblerAddSubLot(event.target.value);
    }
  };


  const handleAddLot = async () => {
   /*console.log({
      "lot": tumblerAddLot,
      "sublot": tumblerAddSubLot
    })
    console.log(`${apiUrl.EDIT_TUMBLER}${tumblerCheckOnAddLot.CHECK_ON}/`)*/
    await axios.put(`${apiUrl.EDIT_TUMBLER}${tumblerCheckOnAddLot.CHECK_ON}/`, {
      "lot": tumblerAddLot,
      "sublot": tumblerAddSubLot
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          setTumblerAddLot();
          setTumblerAddSubLot()
          setAddLot(1)
          setNotifyAddLotSuccessful(true)
        }
        else {
          setAddLot(2)
          setNotifyAddLotFailed(true)
        }
      })
      .catch(error => {
        console.log('add lot error: ', error);
      })
  }


  ///// Dynamically add item into accordion(https://stackoverflow.com/questions/72935045/dynamically-add-item-into-react-bootsrap-accordion)
  const categories = {};
  for (const item of meatAtTumbler) {
    if (item.MATERIAL_DESCRIPTION in categories) {
      categories[item.MATERIAL_DESCRIPTION].push(item);
    } else {
      categories[item.MATERIAL_DESCRIPTION] = [item];
    }
  }

  return (
    <>
      <Helmet>
        <title>Preparation | MES Korat Fur2</title>
      </Helmet>
      <Grid
        container spacing={1}
        direction="row"
        alignItems="top"
        justifyContent="center"
        style={{ minHeight: '78vh', maxHeight: '100vh', flexGrow: 1, paddingLeft: 25, paddingTop: 75 }}
      >
        {/* Top row display date */}
        <Grid id="top-row" container sx={{ pt: 2.2 }} spacing={1}>
          <Grid xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={1}>
                <DatePicker
                  //style={{width:300}}
                  inputFormat="DD/MM/YYYY"
                  //label="Select date"
                  value={date}
                  onChange={(newValue) => {
                    //////Get React DatePicker date value in onChange(https://stackoverflow.com/questions/70182747/get-react-datepicker-date-value-in-onchange)
                    const d = new Date(newValue);
                    const formatted = moment(d).tz('Asia/Bangkok').format('YYYY-MM-DD');
                    setDate(formatted)
                    console.log("date: " + formatted);
                  }}
                  
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: '100%',
                        svg: { color: '#000000' },
                        "& .MuiOutlinedInput-input": {
                          color: "#000000",
                          fontFamily: 'CPF_Imm_Sook',
                          fontWeight: "bold",
                          fontSize: 16
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>

          {noData === 1 ?
          <Grid xs={12}>
            <Box mb={35}>
              <div>
                {Object.entries(categories).map((entry) => {
                  const category = entry[0];
                  const itemList = entry[1];
                  return (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{fontSize: "16px", fontWeight: 'bold' }}>{category}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {itemList.map((item) => {

                          const getMachineName = () => {
                            let tumbler;
                            try {
                              if (item.MACHINE_NAME == 'SMD5083') {
                                tumbler = "Tumbler1"
                              } else if (item.MACHINE_NAME == 'SMD5084') {
                                tumbler = "Tumbler2"
                              } else if (item.MACHINE_NAME == 'SMD5085') {
                                tumbler = "Tumbler3"
                              } else if (item.MACHINE_NAME == 'SMD5086') {
                                tumbler = "Tumbler4"
                              } else if (item.MACHINE_NAME == 'SMD5087') {
                                tumbler = "Tumbler5"
                              } else if (item.MACHINE_NAME == 'SMD5088') {
                                tumbler = "Tumbler6"
                              }
                              return tumbler;
                            }
                            catch {
                              console.log()
                            }
                          }

                          return <Card sx={{
                            p: 1,
                            margin: 'auto',
                            //maxWidth: 1500,
                            flexGrow: 1,
                            backgroundColor: (theme) =>
                              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                          }}>
                            <Grid item xs={12} sm container>
                              <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "5px" }}>
                                <Grid item xs>
                                  <Typography style={{fontSize: "16px"}} gutterBottom>
                                    {item.MATERIAL_DESCRIPTION}
                                  </Typography> 
                                  <Typography style={{fontSize: "15px"}} gutterBottom>
                                    Machine: {getMachineName()}
                                  </Typography>                                
                                  <Typography style={{fontSize: "15px"}} gutterBottom>
                                      lot: {item.LOT},sublot: {item.SUBLOT} 
                                    <Tooltip title="add lot" placement="top-start">
                                      <ModeEditIcon style={{rsor:'pointer', fontSize: '14px'}} onClick={() => { setTumblerCheckOnAddLot({ CHECK_ON: item.CHECK_ON, MATERIAL_DESCRIPTION: item.MATERIAL_DESCRIPTION, LOT: item.LOT, SUBLOT: item.SUBLOT }); handleOpenAddLot(); }}></ModeEditIcon>
                                    </Tooltip>
                                  </Typography>                                 
                                  <Typography style={{fontSize: "15px"}}  gutterBottom>
                                    {(item.TIMESTAMP).substring(0, 10)} {(item.TIMESTAMP).substring(11, 19)}
                                  </Typography>
                                  <Typography style={{fontSize: "15px" }}  gutterBottom>
                                    {item.AGE}
                                  </Typography>
                                  <Tooltip title="edit weight" placement="top-start">
                                    <Button style={{ color: "black", left: '-6px',fontSize: "15px", height: '20px' }} onClick={() => { setTumblerCheckOnEditWeight({ CHECK_ON: item.CHECK_ON, STATUS: item.STATUS, WEIGHT: item.WEIGHT, START_TIME: item.START_TIME }); handleOpenEditWeight(); }}>
                                      {Math.round(item.WEIGHT)} kg. 
                                      <ModeEditIcon style={{fontSize: '14px'}} ></ModeEditIcon> 
                                    </Button>
                                  </Tooltip>
                                </Grid>
                                <Grid item> {/*call multiple functions onClick ReactJS(https://stackoverflow.com/questions/26069238/call-multiple-functions-onclick-reactjs) */}
                                  <Button disabled={isNaN(parseFloat(item.LOT))} variant="contained" style={{ fontSize: '12px', width: '80px', fontWeight: 'bold' }} color="success" onClick={() => { setStartTumblerCheckOn({ ID: item.ID, PLANT: item.PLANT, LINE: item.LINE, MACHINE_TYPE: item.MACHINE_TYPE, MACHINE_NAME: item.MACHINE_NAME, MATERIAL_DESCRIPTION: item.MATERIAL_DESCRIPTION, WEIGHT: item.WEIGHT, TIMESTAMP: item.TIMESTAMP, CHECK_ON: item.CHECK_ON, AGE: item.AGE, START_TIME: item.START_TIME }); handleClickConfirmStartTumbler();}}>
                                    เริ่มผลิต
                                  </Button>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Tooltip title="edit status" placement="top-start">
                                  <Button style={{ color: "black", fontWeight: 'bold', fontSize: '14px' }} onClick={() => {setTumblerCheckOnEditStatus({CHECK_ON: item.CHECK_ON, STATUS: item.STATUS, START_TIME: item.START_TIME}); handleOpenEditStatus();}}>
                                    {item.STATUS}&nbsp;
                                    <ModeEditIcon style={{fontSize: '14px'}} ></ModeEditIcon> 
                                  </Button>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Card>
                        })}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            </Box>
          </Grid>
          : console.log()
          }

          {noData === 2 ?
          <Grid xs={12}>
            <Box mb={35} >
            <Typography style={{ color: "black", fontWeight: "bold", fontSize: "24px"}} variant="button" >
              No Data
            </Typography>
            </Box>
          </Grid>
          : console.log()
        }
        </Grid>
      </Grid>

      {/* pop up start tumbler  */}
      <Dialog
        open={openConfirmStartTumbler}
        onClose={handleCloseConfirmStartTumbler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you went to start product
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenConfirmStartTumbler(false); }}>Cancel</Button>
          <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenConfirmStartTumbler(false); handleStartTumbler(); }}>Confirm</Button>
        </DialogActions>
      </Dialog>

      {/* pop up edit status */}
      <Dialog
        open={openEditStatus}
        onClose={handleCloseEditStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ position: "relative", top: "-5px", left: "-12px"}}>
          Edit Status
        </DialogTitle>
        <DialogContentText id="alert-dialog-description">
          <FormControl sx={{ m: 1, minWidth: '90%' }}>
            <InputLabel fontFamily='Monospace'>Select Status</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={openTumblerSelection}
              onClose={handleCloseTumblerSelection}
              onOpen={handleOpenTumblerSelection}
              value={tumblerHistorySelectedStatus}
              label="Select Status"
              onChange={handleEditionInStatus}
              name="status"
              style={{ fontFamily: 'Monospace' }}
            >
              {selectStatusTumbler.map((item) => (
                <MenuItem value={item.value}>{item.value}</MenuItem>
              ))

              }
            </Select>
          </FormControl>
        </DialogContentText>
        <DialogActions>
          <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenEditStatus(false); }}>Cancel</Button>
          <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenEditStatus(false); handleEditStatus(); }}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* pop up edit weight */}
      <Dialog
        open={openEditWeight}
        onClose={handleCloseEditWeight}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          Edit Weight(น้ำหนัก)
        </DialogTitle>
        <DialogContent>
          <Box width='95%' style={{ position: "relative", top: "0px", left: "0px" }} >
            <Typography fontSize={'16px'} fontWeight="medium">
              Weight(kg)
            </Typography>
            <TextField
              id="outlined-number"
              type="number"
              name="weight"
              style={{ fontFamily: 'Monospace' }}
              value={tumblerHistorySelectedWeight}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleEditionInTumbler}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenEditWeight(false); }}>Cancel</Button>
          <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenEditWeight(false); handleEditTumbler(); }}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* pop up add lot and sublot */}
      <Dialog
        open={openAddLot}
        onClose={handleCloseAddLot}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add lot and sublot
        </DialogTitle>
        <DialogContent>
          <Box width='95%' style={{ position: "relative", top: "0px", left: "0px" }} >
            <Typography fontSize={'16px'} fontWeight="medium">
              Lot
            </Typography>
            <TextField
              id="outlined-number"
              type="number"
              name="lot"
              value={tumblerAddLot}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleAddInLot}
            />
          </Box>

          <Box width='95%' style={{ position: "relative", top: "0px", left: "0px" }} >
            <Typography fontSize={'16px'} fontWeight="medium">
              Sub lot
            </Typography>
            <TextField
              id="outlined-number"
              type="number"
              name="sublot"
              value={tumblerAddSubLot}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleAddInLot}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenAddLot(false); }}>Cancel</Button>
          <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenAddLot(false); handleAddLot(); }}>Save</Button>
        </DialogActions>
      </Dialog>

      {/*show pop up success edit weight*/}
      {editTumbler === 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyEdittedTumblerSuccessful}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successfully editted weight
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} variant="contained" onClick={() => { setNotifyEdittedTumblerSuccessful(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }
      {editTumbler === 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyEdittedTumblerFailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed to edit weight, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} aligh='Center' variant="contained" onClick={() => { setNotifyEdittedTumblerFailed(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }


      {/*show pop up success edit status*/}
      {editStatus === 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyEdittedStatusSuccessful}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successfully editted status
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} variant="contained" onClick={() => { setNotifyEdittedStatusSuccessful(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }
      {editStatus === 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyEdittedStatusFailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed to edit status, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} aligh='Center' variant="contained" onClick={() => { setNotifyEdittedStatusFailed(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }

      {/*show pop up success start tumbler*/}
      {startTumbler === 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyStartTumblerSuccessful}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successfully start product
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} variant="contained" onClick={() => { setNotifyStartTumblerSuccessful(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }
      {startTumbler === 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyStartTumblerFailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed to start product, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} aligh='Center' variant="contained" onClick={() => { setNotifyStartTumblerFailed(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }


      {/*show pop up success add lot*/}
      {addLot === 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyAddLotSuccessful}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successfully add lot and sublot
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} variant="contained" onClick={() => { setNotifyAddLotSuccessful(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }
      {addLot === 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyAddLotFailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed to add lot and sublot, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} aligh='Center' variant="contained" onClick={() => { setNotifyAddLotFailed(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }

    </>
  );
}

export default Tumbler;