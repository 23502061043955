import React, { useEffect, useState, useContext } from "react";
import { Box, Grid, Button, useTheme, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import * as XLSX from 'xlsx';
import { ColorModeContext, tokens } from "../../theme";
import DemoColumnChartGiveaway from '../../components/chart/DemoColumnChartGiveaway';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddchartIcon from '@mui/icons-material/Addchart';
import { Helmet } from "react-helmet";

export default function GiveawayPage2() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const [open, setOpen] = useState(false);
    const [postData, setPostData] = useState(null);
    const [displayedAverages, setDisplayedAverages] = useState([]);
    const [displayedCategories, setDisplayedCategories] = useState([]);
    const [minAverage, setMinAverage] = useState(null);
    const [maxAverage, setMaxAverage] = useState(null);
    const [add_standard, setAdd_standard] = useState(null);
    const [add_target, setAdd_target] = useState(null);
    const [isSaved, setIsSaved] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        setIsSaved(true); // Set isSaved to true when "Save" is clicked
        setOpen(false);
    };

    const handleAddGiveaway = (event) => {
        if (event.target.name === "std") {
            setAdd_standard(event.target.value);
        }
        if (event.target.name === "target") {
            setAdd_target(event.target.value);
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet);

            setPostData(sheetData); // Update postData immediately
        };

        reader.readAsBinaryString(file);
    };

    // Process data for averages and categories
    const outputData = postData == null ? [] : postData.map(item => ({
        ...item,
        "Weight Data": item["Weight Data"] !== undefined ? item["Weight Data"] : 0
    }));

    useEffect(() => {
        if (postData && isSaved && add_standard !== null && add_standard !== undefined) {
            // Reset graph data before processing new averages
            setDisplayedAverages([]);
            setDisplayedCategories([]);

            const newArr = outputData.map(({ __rowNum__, ...rest }) => rest);
            const timeEntries = newArr.map(item => ({
                dateTime: new Date(`${item['Date(Y/M/D)']} ${item['Time(H:M:S)']}`),
                weight: item['Weight Data'],
            }));

            const lastEntryTime = timeEntries[timeEntries.length - 1]?.dateTime;
            if (!lastEntryTime) return;

            const oneHourBefore = new Date(lastEntryTime.getTime() - (60 * 60 * 1000));

            let currentTime = oneHourBefore;
            const averages = [];
            const categories = [];

            while (currentTime <= lastEntryTime) {
                const nextTime = new Date(currentTime.getTime() + 5 * 60 * 1000);
                categories.push(currentTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }).replace(/AM|PM/, ''));

                const entriesInTimeRange = timeEntries.filter(entry => entry.dateTime >= currentTime && entry.dateTime < nextTime && entry.weight > 0);

                console.log(`Start time: ${currentTime.toLocaleTimeString()} - End time: ${nextTime.toLocaleTimeString()} - Entries count: ${entriesInTimeRange.length}`);
                if (entriesInTimeRange.length > 0) {
                    const totalWeight = entriesInTimeRange.reduce((sum, entry) => sum + entry.weight, 0);
                    const averageWeight = totalWeight / entriesInTimeRange.length;
                    averages.push(
                        (Math.round(((Math.abs(Number(add_standard) - averageWeight) / Number(add_standard)) * 100) * 100) / 100).toFixed(2)
                    );
                } else {
                    averages.push(0);
                }

                currentTime = nextTime;
            }

            setMinAverage(
                averages.length > 0
                    ? Math.min(...averages.filter(avg => avg > 0 && !isNaN(avg)))
                    : null
            );

            setMaxAverage(
                averages.length > 0
                    ? Math.max(...averages.filter(avg => avg > 0 && !isNaN(avg)))
                    : null
            );

            const validAverages = averages.filter(avg => avg !== undefined && !isNaN(avg));
            const validCategories = categories.filter((_, index) => averages[index] !== undefined && !isNaN(averages[index]));

            let index = 0;
            const interval = setInterval(() => {
                if (index < validAverages.length) {
                    setDisplayedAverages(prev => [...prev, validAverages[index]]);
                    setDisplayedCategories(prev => [...prev, validCategories[index]]);
                    index++;
                } else {
                    clearInterval(interval);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [postData, isSaved, add_standard, maxAverage]);

    console.log((maxAverage !== null ? (Math.round(maxAverage * 100) / 100).toFixed(2) : 10))

    useEffect(() => {
        setDisplayedAverages([]);
        setDisplayedCategories([]);
    }, [add_standard]);

    const finalDisplayedAverages = displayedAverages.filter(avg => avg !== undefined && !isNaN(avg));
    const finalDisplayedCategories = displayedCategories.filter((_, index) => displayedAverages[index] !== undefined && !isNaN(displayedAverages[index]));

    const calculateAverageWeight = (data) => {
        if (add_standard === null || add_standard === undefined) {
            return '-';
        }

        const validWeights = data.filter(item => item["Weight Data"] !== undefined && item["Weight Data"] !== null && !isNaN(item["Weight Data"]));

        const totalWeight = validWeights.reduce((sum, item) => sum + item["Weight Data"], 0);

        const averageWeight = validWeights.length > 0 ? totalWeight / validWeights.length : 0;

        const percentWeight = (Math.round(((Math.abs(add_standard - averageWeight) / add_standard) * 100) * 100) / 100).toFixed(2);

        return percentWeight;
    };

    const percentWeight = calculateAverageWeight(outputData);


    return (
        <>
            <Helmet>
                <title>Giveaway Page 2 Demo| MES Korat Fur2</title>
            </Helmet>
            <Grid item xs={12} sm={12} style={{ paddingTop: 10, paddingLeft: 50 }}>
                <Grid xs={12} textAlign="left">
                    <Typography style={{ color: "white", fontWeight: "bold", fontSize: 54 }}>
                        Giveaway
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                        <Grid xs={0.6} textAlign="left">
                            <Button
                                component="label"
                                startIcon={<AddchartIcon style={{ color: '#ffffff' }} />}
                                style={{ borderRadius: 5, fontSize: 16, fontWeight: "bold", background: '#9c9797', color: '#ffffff' }}
                                onClick={handleClickOpen}
                            >
                                Add
                            </Button>
                        </Grid>
                        <Grid xs={1} textAlign="left">
                            <Button
                                component="label"
                                startIcon={<UploadFileIcon style={{ color: '#ffffff' }} />}
                                style={{ borderRadius: 5, fontSize: 16, fontWeight: "bold", background: '#9c9797', color: '#ffffff' }}
                            >
                                <input type="file" hidden onChange={handleFileUpload} />
                                Import
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                        <Grid item xs={8}>
                            {finalDisplayedAverages.length > 0 && finalDisplayedCategories.length > 0 ? (
                                <DemoColumnChartGiveaway
                                    value={finalDisplayedAverages}
                                    time={finalDisplayedCategories}
                                    target={isSaved && add_target !== null ? add_target : 0}
                                    max={maxAverage !== null ? Number((Math.round(maxAverage * 100) / 100).toFixed(2)) : 10}
                                />
                            ) : (
                                <Typography style={{ color: colors.black[100], fontSize: 24 }}>
                                    No data available to display.
                                </Typography>
                            )}
                        </Grid>

                        <Grid item xs={4} style={{ paddingTop: 30 }}>
                            <Grid item xs={12} textAlign="center">
                                <Typography style={{ color: colors.black[100], fontSize: 42, fontWeight: 'bold' }}>
                                    Best Practice
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography style={{ color: '#4AC6B1', fontSize: 54, fontWeight: 'bold', lineHeight: "4vh" }}>
                                    {(minAverage !== null ? (Math.round(minAverage * 100) / 100).toFixed(2) : '-')}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: 50 }}>
                                <Typography style={{ color: colors.black[100], fontSize: 42, fontWeight: 'bold' }}>
                                    Target
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography style={{ color: '#4AC6B1', fontSize: 54, fontWeight: 'bold', lineHeight: "4vh" }}>
                                    {isSaved && add_target !== null ? add_target : '-'}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: 50 }}>
                                <Typography style={{ color: colors.black[100], fontSize: 42, fontWeight: 'bold' }}>
                                    Actual
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography style={{ color: '#4AC6B1', fontSize: 54, fontWeight: 'bold', lineHeight: "4vh" }}>
                                    {percentWeight == 100.00 ? '-' : percentWeight}%
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Add Std. and Target
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Std."
                                name="std"
                                required
                                value={add_standard}
                                variant="standard"
                                sx={{
                                    "& .MuiOutlinedInput-input": {
                                        color: "#000000",
                                        fontFamily: 'CPF_Imm_Sook',
                                        fontSize: 18
                                    },
                                }}
                                onChange={handleAddGiveaway}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Target"
                                name="target"
                                required
                                value={add_target}
                                variant="standard"
                                sx={{
                                    "& .MuiOutlinedInput-input": {
                                        color: "#000000",
                                        fontFamily: 'CPF_Imm_Sook',
                                        fontSize: 18
                                    },
                                }}
                                onChange={handleAddGiveaway}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave} autoFocus>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
