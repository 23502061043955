import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import jwt from 'jwt-decode';
import { apiUrl} from '../constants/index';

var urls = apiUrl.LOGIN_URL;

const Login = React.memo(() => {
  const navigate = useNavigate();

  // จะทำงานเมื่อuser คลิ๊กปุ่ม Login to post credential to server
  const handleLogin = React.useCallback(
    async (credentials, setSubmitting, setStatus) => {
      // define body when post API
      var data = {
        email: credentials.email,
        password: credentials.password
      };
      var result;
      try {
        // post username & password to login API
        result = await axios.post(urls, data);
        if (result.status == 200) {
          // decode token to get expiry and store in localstorage
          localStorage.clear();
          let access_user = jwt(result.data.tokens.access);
          let access_exp = access_user.exp;
          let refresh_user = jwt(result.data.tokens.refresh);
          let refresh_exp = refresh_user.exp;
          //console.log('is_admin ', result.data.is_superuser)
          localStorage.setItem('rb_ac_token', result.data.tokens.access);
          localStorage.setItem('rb_rf_token', result.data.tokens.refresh);
          localStorage.setItem('rb_ac_exp', access_exp);
          localStorage.setItem('rb_rf_exp', refresh_exp);
          localStorage.setItem('rb_UserId', result.data.id);
          localStorage.setItem('rb_Admin', result.data.is_superuser);
          localStorage.setItem('rb_Staff', result.data.is_staff);
          localStorage.setItem('rb_authen', 'True');
          localStorage.setItem('rb_email', result.data.email);
          console.log('login ', `${localStorage.getItem('rb_Admin')}`)
          if (`${localStorage.getItem('rb_Admin')}` == 'false' && `${localStorage.getItem('rb_Staff')}` == 'false') 
          {
            localStorage.setItem('settings', ['Profile', 'Giveaway', 'Logout']); 
          } else if (`${localStorage.getItem('rb_Admin')}` == 'false' && `${localStorage.getItem('rb_Staff')}` == 'true') {
            localStorage.setItem('settings', ['Preparation Zone', 'Logout']);
          } else if (`${localStorage.getItem('rb_Admin')}` == 'true' && `${localStorage.getItem('rb_Staff')}` == 'true') {
            localStorage.setItem('settings', ['Profile', 'Account', 'Home', 'Giveaway',  'Preparation Zone', 'Logout']);
          }


          if (`${localStorage.getItem('rb_Admin')}` == 'false' && `${localStorage.getItem('rb_Staff')}` == 'false') 
          {
            navigate('/check_list', { replace: true });
          } else if (`${localStorage.getItem('rb_Admin')}` == 'false' && `${localStorage.getItem('rb_Staff')}` == 'true') {
            navigate('/preparation', { replace: true });
          } else if (`${localStorage.getItem('rb_Admin')}` == 'true' && `${localStorage.getItem('rb_Staff')}` == 'true') {
            navigate('/home', { replace: true });
          }
          


        } else {
          console.log('wrong!');
          setSubmitting(false);
          // set status when user input incorrect username or password
          setStatus('Invalid credentials, try again');
        }
      } catch (error) {
        console.log('wrong!');
        setSubmitting(false);
        // set status when user input incorrect username or password
        setStatus('Invalid credentials, try again');
      }
    }
  );

  return (
    <>
      <Helmet>
        <title>Login | MES Korat</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          pl: 0
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().min(8).required('Password is required')
            })}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              setSubmitting(true);
              handleLogin(values, setSubmitting, setStatus);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography style={{ fontWeight: "bold", fontSize: 54}}>
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    style={{ fontSize: 20}}
                  >
                    Sign in on the internal MES Korat platform
                  </Typography>
                </Box>
                <Typography color="#d32f2f" variant="body4">
                  {status}
                </Typography>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#000000",
                      fontFamily: 'CPF_Imm_Sook',
                      //fontWeight: "bold",
                      fontSize: 18
                    },
                  }}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#000000",
                      fontFamily: 'CPF_Imm_Sook',
                      //fontWeight: "bold",
                      fontSize: 18
                    },
                  }}
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    //sx={{ backgroundColor: '#3cbea8', borderColor: '#3cbea8' }}
                    style={{ color: "white", fontWeight: "bold", fontSize: '18px', backgroundColor: '#3cbea8' }}
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Sign in
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body2">
                  Forget your password?{' '}
                  <Link
                    component={RouterLink}
                    to="/email"
                    variant="body2"
                    underline="hover"
                    style={{color:'#39607a'}}
                  >
                    Reset Password
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
});

export default Login;
