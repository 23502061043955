import React, { useEffect, useState } from "react";
import { Box, Grid, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from 'moment-timezone';
import axios from 'axios';
import { apiUrl } from "../constants";
import OEE from "../components/chart/OEE";
import APQ from "../components/chart/APQ";
import BarChartAccumulativeProduction from "../components/chart/BarChartAccumulativeProduction";
import Defect from '../components/chart/Defect';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Chart from "react-apexcharts";
import Button from '@mui/material/Button';
import LensIcon from '@mui/icons-material/Lens';
import Stepper from '../components/model/Stepper';
import { useLocation } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

var token_url = apiUrl.TOKEN;



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  height: '103%',
  color: theme.palette.text.secondary,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
function sumArray(array) {
  let sum = 0;
  for (const item of array) {
    sum += item;
  }
  return sum;
}
export default function ProductionLine() {

  const location = useLocation();
  const line = location.state.SKU;
  //console.log('location', line);

  const today = new Date();
  const hourago = new Date(today.getTime());
  const timestr = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');
  //const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');


  const [data, setData] = useState({
    WORK_CENTER: "",
  });
  const [oeeDonut, setOeedonut] = useState({
    OEE: "",
    A: "",
    P: "",
    Q: "",
    unit: "",
    OEE_TARGET: "",
    A_TARGET: "",
    P_TARGET: "",
    Q_TARGET: "",
  });
  const [totalWeight, setTotaWeightl] = useState({
    TARGET_PRODUCTION_QUANTITY: "",
    PRODUCTION_UNIT: "",
    ACTUAL_WEIGHT: "",
    TARGET_NOW: "",
    CURRENT_ACTUAL_KG_PER_HOUR: "",
    CURRENT_STD_KG_PER_HOUR: "",
    CURRENT_ACTUAL_KG_PER_PERSON_PER_HOUR: "",
    CURRENT_STD_KG_PER_PERSON_PER_HOUR: "",
    EMPLOYEE: "",
    STD_EMPLOYEE: "",
    YIELD: "",
    STD_YIELD: ""
  });
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(true);
  const [productionDetail, setProductionDetail] = useState([]);
  const [editDefect, setEditDefect] = useState(false);
  const [deleteDefect, setDeleteDefect] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [defectCheckList, setDefectCheckList] = useState([]);
  const [selected, setSelected] = useState("");
  const [defectSelectedPlantID, setDefectSelectedPlantID] = useState('');
  const [defectSelectedLine, setDefectSelectedLine] = useState('');
  const [defectSelectedSKU, setDefectSelectedSKU] = useState(null);
  const [openDefectSKUSelection, setOpenDefectSKUSelection] = useState(false);
  const [defectSelectedArea, setDefectSelectedArea] = useState(null);
  const [openDefectAreaSelection, setOpenDefectAreaSelection] = useState(false);
  const [defectSelectedSubArea, setDefectSelectedSubArea] = useState(null);
  const [defectSelectedType, setDefectSelectedType] = useState(null);
  const [openDefectTypeSelection, setOpenDefectTypeSelection] = useState(false);
  const [openDefectSubAreaSelection, setOpenDefectSubAreaSelection] = useState(false);
  const [defectSelectedRemark, setDefectSelectedRemark] = useState(null);
  const [openDefectRemarkSelection, setOpenDefectRemarkSelection] = useState(false);
  const [defectSelectedNote, setDefectSelectedNote] = useState(null);
  const [openDefectNoteSelection, setOpenDefectNoteSelection] = useState(false);
  const [defectSelectedValue, setDefectSelectedValue] = useState(0);
  const [addDefect, setAddDefect] = useState();
  const [addDefectFailed, setAddDefectFailed] = useState();
  const [addDefectDialog, setAddDefectDialog] = useState(false);
  const [notifySubmittedDefectSuccessful, setNotifySubmittedDefectSuccessful] = useState(false);
  const [notifySubmittedDefectFailed, setNotifySubmittedDefectFailed] = useState(false);
  const [defectIDDeletion, setDefectIDDeletion] = useState();
  const [popUpDefectDeletion, setPopUpDefectDeletion] = useState(false);
  const [defectDeletionDone, setDefectDeletionDone] = useState(false);
  const [defectIDEdition, setDefectIDEdition] = useState();
  const [defectHistorySelectedPlantID, setDefectHistorySelectedPlantID] = useState('');
  const [defectHistorySelectedLine, setDefectHistorySelectedLine] = useState('');
  const [defectHistorySelectedSKU, setDefectHistorySelectedSKU] = useState(null);
  const [defectHistorySelectedArea, setDefectHistorySelectedArea] = useState(null);
  const [defectHistorySelectedSubArea, setDefectHistorySelectedSubArea] = useState(null);
  const [defectHistorySelectedType, setDefectHistorySelectedType] = useState(null);
  const [defectHistorySelectedRemark, setDefectHistorySelectedRemark] = useState(null);
  const [defectHistorySelectedNote, setDefectHistorySelectedNote] = useState(null);
  const [defectHistorySelectedValue, setDefectHistorySelectedValue] = useState(0);
  const [popUpDefectEditionForm, setPopUpDefectEditionForm] = useState();
  const [notifyEdittedDefectSuccessful, setNotifyEdittedDefectSuccessful] = useState(false);
  const [notifyEdittedDefectFailed, setNotifyEdittedDefectFailed] = useState(false);


  const [employeeCheckList, setEmployeeCheckList] = useState([]);
  const [addNoEmployee, setAddNoEmployee] = useState(false);
  const [editEmployee, setEditEmployee] = useState(false);
  const [deleteEmployee, setDeleteEmployee] = useState(false);
  const [newNoEmployee, setNewNoEmployee] = useState();
  const [newEmployeeFailed, setNewEmployeeFailed] = useState();
  const [openEmployee, setOpenEmployee] = useState(false);
  const [anchorElEmployee, setAnchorElEmployee] = useState(null);
  const [employeeSelectedPlantID, setEmployeeSelectedPlantID] = useState({});
  const [employeeSelectedLine, setEmployeeSelectedLine] = useState({});
  const [employeeSelectedSKU, setEmployeeSelectedSKU] = useState(null);
  const [openEmployeeSKUSelection, setOpenEmployeeSKUSelection] = useState(false);
  const [employeeSelectedArea, setEmployeeSelectedArea] = useState(null);
  const [openEmployeeAreaSelection, setOpenEmployeeAreaSelection] = useState(false);
  const [employeeSelectedValue, setEmployeeSelectedValue] = useState(0);
  const [notifySubmittedEmployeeSuccessful, setNotifySubmittedEmployeeSuccessful] = useState(false);
  const [notifySubmittedEmployeeFailed, setNotifySubmittedEmployeeFailed] = useState(false);
  const [employeeIDDeletion, setEmployeeIDDeletion] = useState();
  const [popUpEmployeeDeletion, setPopUpEmployeeDeletion] = useState(false);
  const [employeeDeletionDone, setEmployeeDeletionDone] = useState(false);
  const [employeeIDEdition, setEmployeeIDEdition] = useState();
  const [employeeHistorySelectedPlantID, setEmployeeHistorySelectedPlantID] = useState('');
  const [employeeHistorySelectedLine, setEmployeeHistorySelectedLine] = useState('');
  const [employeeHistorySelectedSKU, setEmployeeHistorySelectedSKU] = useState(null);
  const [employeeHistorySelectedArea, setEmployeeHistorySelectedArea] = useState(null);
  const [employeeHistorySelectedValue, setEmployeeHistorySelectedValue] = useState(0);
  const [popUpEmployeeEditionForm, setPopUpEmployeeEditionForm] = useState();
  const [notifyEdittedEmployeeSuccessful, setNotifyEdittedEmployeeSuccessful] = useState(false);
  const [notifyEdittedEmployeeFailed, setNotifyEdittedEmployeeFailed] = useState(false);
  const [defectDonutDataSeries, setDefectDonutDataSeries] = useState();



  const handleListEdit = () => {
    setPopUpDefectEditionForm(true);
  }

  const handleEmployeeEdit = () => {
    setPopUpEmployeeEditionForm(true);
  }

  const handleCloseDefectSKUSelection = () => {
    setOpenDefectSKUSelection(false);
  };

  const handleOpenDefectSKUSelection = () => {
    setOpenDefectSKUSelection(true);
  };

  const handleCloseDefectAreaSelection = () => {
    setOpenDefectAreaSelection(false);
  };

  const handleOpenDefectAreaSelection = () => {
    setOpenDefectAreaSelection(true);
  };

  const handleCloseDefectTypeSelection = () => {
    setOpenDefectTypeSelection(false);
  };

  const handleOpenDefectTypeSelection = () => {
    setOpenDefectTypeSelection(true);
  };

  const handleCloseDefectSubAreaSelection = () => {
    setOpenDefectSubAreaSelection(false);
  };

  const handleOpenDefectSubAreaSelection = () => {
    setOpenDefectSubAreaSelection(true);
  };

  const handleCloseDefectRemarkSelection = () => {
    setOpenDefectRemarkSelection(false);
  };

  const handleOpenDefectRemarkSelection = () => {
    setOpenDefectRemarkSelection(true);
  };

  const handleCloseDefectNoteSelection = () => {
    setOpenDefectNoteSelection(false);
  };

  const handleOpenDefectNoteSelection = () => {
    setOpenDefectNoteSelection(true);
  };


  const areaDefect = [
    {
      value: "ส่วนดิบ",
      label: "ส่วนดิบ"
    },
    {
      value: "ส่วนสุก",
      label: "ส่วนสุก"
    }
  ];

  const lowRisk = [
    {
      value: "กรรมวิธี",
      label: "กรรมวิธี"
    },
    {
      value: "Cooking",
      label: "Cooking"
    },
  ];

  const highRisk = [
    {
      value: "Cooling",
      label: "Cooling"
    },
    {
      value: "Packing",
      label: "Packing"
    },
  ];

  const typeDefct = [
    {
      value: "เนื้อลงบ่อปลา",
      label: "เนื้อลงบ่อปลา"
    },
    {
      value: "ขายเกรด B",
      label: "ขายเกรด B"
    },
  ];

  const remarkDefect = [
    {
      value: "รูปร่าง",
      label: "รูปร่าง"
    },
    {
      value: "สี",
      label: "สี"
    },
    {
      value: "น้ำหนัก",
      label: "น้ำหนัก"
    },
    {
      value: "Coating",
      label: "Coating"
    },
    {
      value: "-",
      label: "-"
    },
  ]

  const noteShape = [
    {
      value: "สั้น",
      label: "สั้น"
    },
    {
      value: "ยาว",
      label: "ยาว"
    },
    {
      value: "เนื้อแตก",
      label: "เนื้อแตก"
    },
    {
      value: "หนัง",
      label: "หนัง"
    },
  ]

  const noteColors = [
    {
      value: "เข้ม",
      label: "เข้ม"
    },
    {
      value: "อ่อน",
      label: "อ่อน"
    },
    {
      value: "ไหม้",
      label: "ไหม้"
    },
  ]

  const noteWeight = [
    {
      value: "สูง",
      label: "สูง"
    },
    {
      value: "ต่ำ",
      label: "ต่ำ"
    },
    {
      value: "ชิ้นเล็ก",
      label: "ชิ้นเล็ก"
    },
  ]

  const noteCoating = [
    {
      value: "แป้งด่าง(วอยด์)",
      label: "แป้งด่าง(วอยด์)"
    },
    {
      value: "ไม่มีเม็ดแป้ง",
      label: "ไม่มีเม็ดแป้ง"
    },
  ]

  const noNote = [
    {
      value: "-",
      label: "-"
    },
  ]

  const getNote = () => {
    let type;
    try {
      if (defectSelectedRemark == 'รูปร่าง') {
        type = noteShape
      } else if (defectSelectedRemark == 'สี') {
        type = noteColors
      } else if (defectSelectedRemark == 'น้ำหนัก') {
        type = noteWeight
      } else if (defectSelectedRemark == 'Coating') {
        type = noteCoating
      } else if (defectSelectedRemark == '-') {
        type = noNote
      }

      return type;
    }
    catch {
      console.log()
    }
  }

  const getNoteHistory = () => {
    let type;
    try {
      if (defectHistorySelectedRemark == 'รูปร่าง') {
        type = noteShape
      } else if (defectHistorySelectedRemark == 'สี') {
        type = noteColors
      } else if (defectHistorySelectedRemark == 'น้ำหนัก') {
        type = noteWeight
      } else if (defectHistorySelectedRemark == 'Coating') {
        type = noteCoating
      } else if (defectHistorySelectedRemark == '-') {
        type = noNote
      }
      return type;
    }
    catch {
      console.log()
    }
  }






  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const opens = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloses = () => {
    setAnchorEl(null);
  };
  const handleListSelection = () => {
    setAddDefectDialog(true);
  }
  const newOption = [
    {
      list: 'Add New Defect',
      onClick: handleListSelection
    }
  ];

  const handleDefectDeletion = async () => {
    var delete_defect_url = `${apiUrl.DELETE_DEFECT}${defectIDDeletion.id}/`
    await axios.delete(delete_defect_url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        //console.log(response)
        if (response.status == 200) {

          setPopUpDefectDeletion(false);
          setDefectDeletionDone(true);

        }
        else {
          setPopUpDefectDeletion(false);
          setAddDefectFailed(true);
        }
      })
      .catch(error => {
        console.log('inserting pm error: ', error);
        setPopUpDefectDeletion(false)
      })
  };



  /////// pop up no.employee

  const handleClickOpenEmployee = () => {
    setOpenEmployee(true);
  };

  const handleCloseEmployee = () => {
    setOpenEmployee(false);
  };

  const opensEmployee = Boolean(anchorElEmployee);

  const handleClickEmployee = (event) => {
    setAnchorElEmployee(event.currentTarget);
  };
  const handleClosesEmployee = () => {
    setAnchorElEmployee(null);
  };

  // will be triggered when user click edit details menu
  const handleListSelectionEmployee = () => {
    setAddNoEmployee(true)
  };

  const noEmployee = [
    {
      list: 'Add No.Employee',
      onClick: handleListSelectionEmployee
    }
  ];

  const areaNoEmployee = [
    {
      value: "Tumbler",
      label: "Tumbler"
    },
    {
      value: "Carton",
      label: "Carton"
    },
    {
      value: "Low Risk",
      label: "Low Risk"
    },
    {
      value: "High Risk",
      label: "High Risk"
    }
  ];

  const handleCloseEmployeeSKUSelection = () => {
    setOpenEmployeeSKUSelection(false);
  };

  const handleOpenEmployeeSKUSelection = () => {
    setOpenEmployeeSKUSelection(true);
  };

  const handleCloseEmployeeAreaSelection = () => {
    setOpenEmployeeAreaSelection(false);
  };

  const handleOpenEmployeeAreaSelection = () => {
    setOpenEmployeeAreaSelection(true);
  };

  const handleEmployeeDeletion = async () => {
    var delete_employee_url = `${apiUrl.DELECT_NO_EMPLOYEE}${employeeIDDeletion.id}/`
    await axios.delete(delete_employee_url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        //console.log(response)
        if (response.status == 200) {

          setPopUpEmployeeDeletion(false);
          setEmployeeDeletionDone(true);

        }
        else {
          setPopUpEmployeeDeletion(false);
          setNewEmployeeFailed(true);
        }
      })
      .catch(error => {
        console.log('inserting pm error: ', error);
        setPopUpEmployeeDeletion(false)
      })
  };


  // ฟังก์ชั่นเช็คtoken
  const checkToken = async () => {
    let access_exp = localStorage.getItem("rb_ac_exp");
    let refresh_exp = localStorage.getItem("rb_rf_exp");
    //console.log('access_exp: ' + access_exp);
    //console.log('refresh_exp: ' + refresh_exp);
    if (refresh_exp < Date.now() / 1000) {
      //console.log("refresh is expiried");
      // alert('Please log in again')
      localStorage.clear();
      window.location = "/Login";
    } else if (access_exp < Date.now() / 1000) {
      const refresh = localStorage.getItem("rb_rf_token");

      let data = {
        refresh: refresh,
      };
      let result = await axios.post(token_url, data);
      //console.log("got new access_token!");
      localStorage.setItem("rb_ac_token", result.data.access);
    }
  };

  const getData = async () => {
    //var token = localStorage.getItem("rb_ac_token")
    var url = `${apiUrl.GET_SPECIFIC_LINE_DATA}${line}/${timestr}/`
    // console.log(url)
    const result = await axios({
      method: 'get', //you can set what request you want to be
      url: url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
    return result
  }

  useEffect(() => {
    async function fetchMyAPI() {
      // check access token expiry
      checkToken();
      //console.log('getData ------>')
      await getData()
        .then(response => {
          if (response.status == 200) {
            setData({
              WORK_CENTER: response.data.DETAIL[0].WORK_CENTER

            })

            setDefectSelectedPlantID(response.data.DETAIL[0].PLANT);
            setDefectSelectedLine(response.data.DETAIL[0].WORK_CENTER);
            setEmployeeSelectedPlantID(response.data.DETAIL[0].PLANT);
            setEmployeeSelectedLine(response.data.DETAIL[0].WORK_CENTER);
            try {
              setOeedonut({
                OEE: response.data.OEE.OEE != 'NA' ? response.data.OEE.OEE : 0,
                A: response.data.OEE.A != 'NA' ? response.data.OEE.A : 0,
                P: response.data.OEE.P != 'NA' ? response.data.OEE.P : 0,
                Q: response.data.OEE.Q != 'NA' ? response.data.OEE.Q : 0,
                unit: response.data.OEE.unit,
                OEE_TARGET: response.data.OEE.OEE_TARGET,
                A_TARGET: response.data.OEE.A_TARGET,
                P_TARGET: response.data.OEE.P_TARGET,
                Q_TARGET: response.data.OEE.Q_TARGET,
              })
            }
            catch {
              setOeedonut({
                OEE: 0,
                A: 0,
                P: 0,
                Q: 0,
                unit: 0,
                OEE_TARGET: 0,
                A_TARGET: 0,
                P_TARGET: 0,
                Q_TARGET: 0,
              })
            }
            setTotaWeightl({
              TARGET_PRODUCTION_QUANTITY: response.data.PERFORMANCE[0].TARGET_PRODUCTION_QUANTITY,
              PRODUCTION_UNIT: response.data.PERFORMANCE[0].PRODUCTION_UNIT,
              ACTUAL_WEIGHT: response.data.PERFORMANCE[0].ACTUAL_WEIGHT,
              TARGET_NOW: response.data.PERFORMANCE[0].TARGET_NOW,
              CURRENT_ACTUAL_KG_PER_HOUR: response.data.PERFORMANCE[0].CURRENT_ACTUAL_KG_PER_HOUR,
              CURRENT_STD_KG_PER_HOUR: response.data.PERFORMANCE[0].CURRENT_STD_KG_PER_HOUR,
              CURRENT_ACTUAL_KG_PER_PERSON_PER_HOUR: response.data.PERFORMANCE[0].CURRENT_ACTUAL_KG_PER_PERSON_PER_HOUR,
              CURRENT_STD_KG_PER_PERSON_PER_HOUR: response.data.PERFORMANCE[0].CURRENT_STD_KG_PER_PERSON_PER_HOUR,
              EMPLOYEE: response.data.PERFORMANCE[0].EMPLOYEE,
              STD_EMPLOYEE: response.data.PERFORMANCE[0].STD_EMPLOYEE,
              YIELD: response.data.PERFORMANCE[0].YIELD,
              STD_YIELD: response.data.PERFORMANCE[0].STD_YIELD
            })
            setProductionDetail(response.data.PRODUCTION_DETAIL)
            setLoad(false)

          }
        })
        .catch(error => {
          //console.log('------------------->')
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

    const interval = setInterval(() => fetchMyAPI(), 1 * 60 * 1000)
    return () => {
      clearInterval(interval);
    }

  }, []); //oeeDonut, totalWeight, productionDetail, defectDonutDataSeries, employeeCheckList

  //console.log(productionDetail)

  useEffect(() => {
    async function fetchMyAPI() {
      await axios.get(`${apiUrl.GET_DEFECT}${line}/${timestr}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
          "X-xss-protection": "1; mode=block;",
          "X-frame-options": "SAMEORIGIN",
          "X-content-type-options": "nosniff",
          "Cache-Control": "no-cache, private",
          "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
          "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
        }
      }
      )
        .then(response => {
          // console.log(response)
          if (response.status == 200) {
            setDefectCheckList(response.data)
            setDefectHistorySelectedPlantID(defectIDEdition.plant_id);
            setDefectHistorySelectedLine(defectIDEdition.line);
            setDefectHistorySelectedSKU(defectIDEdition.material_description);
            setDefectHistorySelectedArea(defectIDEdition.area);
            setDefectHistorySelectedSubArea(defectIDEdition.sub_area);
            setDefectHistorySelectedType(defectIDEdition.type);
            setDefectHistorySelectedRemark(defectIDEdition.remark);
            setDefectHistorySelectedNote(defectIDEdition.note);
            setDefectHistorySelectedValue(defectIDEdition.value);

          }
        })
        .catch(error => {
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

    const interval = setInterval(() => fetchMyAPI(), 1 * 60 * 1000)
    return () => {
      clearInterval(interval);
    }

  }, [defectIDEdition])

  //console.log(defectCheckList,timestrs)


  useEffect(() => {
    async function fetchMyAPI() {
      await axios.get(`${apiUrl.GET_NO_EMPLOYEE}${line}/${timestr}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
          "X-xss-protection": "1; mode=block;",
          "X-frame-options": "SAMEORIGIN",
          "X-content-type-options": "nosniff",
          "Cache-Control": "no-cache, private",
          "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
          "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
        }
      }
      )
        .then(response => {
          // console.log(response)
          if (response.status == 200) {
            setEmployeeCheckList(response.data)
            setEmployeeHistorySelectedPlantID(employeeIDEdition.plant_id);
            setEmployeeHistorySelectedLine(employeeIDEdition.line);
            setEmployeeHistorySelectedSKU(employeeIDEdition.material_description);
            setEmployeeHistorySelectedArea(employeeIDEdition.area);
            setEmployeeHistorySelectedValue(employeeIDEdition.value);
          }
        })
        .catch(error => {
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

    const interval = setInterval(() => fetchMyAPI(), 1 * 60 * 1000)
    return () => {
      clearInterval(interval);
    }

  }, [employeeIDEdition])

  //console.log(employeeCheckList,timestrs)


  useEffect(() => {
    async function fetchMyAPI() {
      await axios.get(`${apiUrl.GET_DEFECT_FOR_DONUT}${line}/${timestr}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
          "X-xss-protection": "1; mode=block;",
          "X-frame-options": "SAMEORIGIN",
          "X-content-type-options": "nosniff",
          "Cache-Control": "no-cache, private",
          "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
          "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
        }
      }
      )
        .then(response => {
          // console.log(response)
          if (response.status == 200) {
            setDefectDonutDataSeries(response.data)
            setLoading(false)
          }
        })
        .catch(error => {
          setDefectDonutDataSeries('No Data')
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

    const interval = setInterval(() => fetchMyAPI(), 1 * 60 * 1000)
    return () => {
      clearInterval(interval);
    }

  }, [])



  const handleSelectionInDefectForm = (event) => {
    if (event.target.name === "material_description") {
      setDefectSelectedSKU(event.target.value);
    }
    if (event.target.name === "area") {
      setDefectSelectedArea(event.target.value);
    }
    if (event.target.name === "sub_area") {
      setDefectSelectedSubArea(event.target.value);
    }
    if (event.target.name === "type") {
      setDefectSelectedType(event.target.value);
    }
    if (event.target.name === "remark") {
      setDefectSelectedRemark(event.target.value);
    }
    if (event.target.name === "note") {
      setDefectSelectedNote(event.target.value);
    }
    if (event.target.name === "value") {
      setDefectSelectedValue(event.target.value);
    }
  };

  const handleEditionInDefectForm = (event) => {
    if (event.target.name === "material_description") {
      setDefectHistorySelectedSKU(event.target.value);
    }
    if (event.target.name === "area") {
      setDefectHistorySelectedArea(event.target.value);
    }
    if (event.target.name === "sub_area") {
      setDefectHistorySelectedSubArea(event.target.value);
    }
    if (event.target.name === "type") {
      setDefectHistorySelectedType(event.target.value);
    }
    if (event.target.name === "remark") {
      setDefectHistorySelectedRemark(event.target.value);
    }
    if (event.target.name === "note") {
      setDefectHistorySelectedNote(event.target.value);
    }
    if (event.target.name === "value") {
      setDefectHistorySelectedValue(event.target.value);
    }
  };

  const handleAddDefect = async () => {

    /*console.log({
      "plant_id": defectSelectedPlantID,
      "line": defectSelectedLine,
      "area": defectSelectedArea,
      "material_description": defectSelectedSKU,
      "type": defectSelectedType,
      "remark": defectSelectedRemark,
      "note": defectSelectedNote,
      "value": defectSelectedValue,
      "factory_date": timestr,
      "insert_by": localStorage.getItem('rb_email')
    });*/

    await axios.post(apiUrl.ADD_DEFECT, {
      "plant_id": defectSelectedPlantID,
      "line": defectSelectedLine,
      "area": defectSelectedArea,
      "sub_area": defectSelectedSubArea,
      "material_description": defectSelectedSKU,
      "type": defectSelectedType,
      "remark": defectSelectedRemark,
      "note": defectSelectedNote,
      "value": defectSelectedValue,
      "factory_date": timestr,
      "insert_by": localStorage.getItem('rb_email')
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 201) {
          setDefectSelectedSKU();
          setDefectSelectedArea();
          setDefectSelectedSubArea();
          setDefectSelectedType();
          setDefectSelectedRemark();
          setDefectSelectedNote();
          setDefectSelectedValue();
          setAddDefect(1)
          setNotifySubmittedDefectSuccessful(true);
        }
        else {
          setAddDefect(2)
          setAddDefectFailed(true);
          setNotifySubmittedDefectFailed(true);
        }
      })
      .catch(error => {
        console.log('add defect error: ', error);
      })
  }

  const handleEditDefect = async () => {
    /*console.log({
      "plant_id": defectHistorySelectedPlantID,
      "line": defectHistorySelectedLine,
      "area": defectHistorySelectedArea,
      "material_description": defectHistorySelectedSKU,
      "type": defectHistorySelectedType,
      "remark": defectHistorySelectedRemark,
      "value": defectHistorySelectedValue,
      "factory_date": timestr,
      "insert_by": localStorage.getItem('rb_email')
    });*/
    await axios.put(`${apiUrl.EDIT_DEFECT}${defectIDEdition.id}/`, {
      "plant_id": defectHistorySelectedPlantID,
      "line": defectHistorySelectedLine,
      "area": defectHistorySelectedArea,
      "sub_area": defectHistorySelectedSubArea,
      "material_description": defectHistorySelectedSKU,
      "type": defectHistorySelectedType,
      "remark": defectHistorySelectedRemark,
      "note": defectHistorySelectedNote,
      "value": defectHistorySelectedValue,
      "factory_date": timestr,
      "insert_by": localStorage.getItem('rb_email')
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          setDefectHistorySelectedSKU();
          setDefectHistorySelectedArea();
          setDefectHistorySelectedSubArea();
          setDefectHistorySelectedType();
          setDefectHistorySelectedRemark();
          setDefectHistorySelectedNote();
          setDefectHistorySelectedValue();
          setEditDefect(1)
          setNotifyEdittedDefectSuccessful(true);
        }
        else {
          setEditDefect(2)
          setNotifyEdittedDefectFailed(true);
        }
      })
      .catch(error => {
        console.log('add defect error: ', error);
      })
  }

  ///////// No.Employee

  const handleSelectionInEmployeeForm = (event) => {
    if (event.target.name === "material_description") {
      setEmployeeSelectedSKU(event.target.value);
    }
    if (event.target.name === "area") {
      setEmployeeSelectedArea(event.target.value);
    }
    if (event.target.name === "value") {
      setEmployeeSelectedValue(event.target.value);
    }
  };

  const handleEditionInEmployeeForm = (event) => {
    if (event.target.name === "material_description") {
      setEmployeeHistorySelectedSKU(event.target.value);
    }
    if (event.target.name === "area") {
      setEmployeeHistorySelectedArea(event.target.value);
    }
    if (event.target.name === "value") {
      setEmployeeHistorySelectedValue(event.target.value);
    }
  };

  const handleSubmitNoEmployee = async () => {
    /*console.log({
      "plant_id": employeeSelectedPlantID,
      "line": employeeSelectedLine,
      "area": employeeSelectedArea,
      "material_description": employeeSelectedSKU,
      "value": employeeSelectedValue,
      "factory_date": timestr,
      "insert_by": localStorage.getItem('rb_email')
    });*/

    await axios.post(apiUrl.ADD_NO_EMPLOYEE, {
      "plant_id": employeeSelectedPlantID,
      "line": employeeSelectedLine,
      "area": employeeSelectedArea,
      "material_description": employeeSelectedSKU,
      "value": employeeSelectedValue,
      "factory_date": timestr,
      "insert_by": localStorage.getItem('rb_email')
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        //console.log(response.data)
        if (response.status == 201) {
          setEmployeeSelectedSKU();
          setEmployeeSelectedArea();
          setEmployeeSelectedValue();
          setNewNoEmployee(1)
          setNotifySubmittedEmployeeSuccessful(true);
        }
        else {
          setNewNoEmployee(2)
          newEmployeeFailed(true);
          setNotifySubmittedDefectFailed(true);
        }
      })
      .catch(error => {
        console.log('add defect error: ', error);
      })
  }

  const handleEditEmployee = async () => {
    /*console.log({
      "plant_id": employeeHistorySelectedPlantID,
      "line": employeeHistorySelectedLine,
      "area": employeeHistorySelectedArea,
      "material_description": employeeHistorySelectedSKU,
      "value": employeeHistorySelectedValue,
      "factory_date": timestr,
      "insert_by": localStorage.getItem('rb_email')
    })*/
    await axios.put(`${apiUrl.EDIT_NO_EMPLOYEE}${employeeIDEdition.id}/`, {
      "plant_id": employeeHistorySelectedPlantID,
      "line": employeeHistorySelectedLine,
      "area": employeeHistorySelectedArea,
      "material_description": employeeHistorySelectedSKU,
      "value": employeeHistorySelectedValue,
      "factory_date": timestr,
      "insert_by": localStorage.getItem('rb_email')
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          setEmployeeHistorySelectedSKU();
          setEmployeeHistorySelectedArea();
          setEmployeeHistorySelectedValue();
          setEditEmployee(1)
          setNotifyEdittedEmployeeSuccessful(true);
        }
        else {
          setEditEmployee(2)
          setNotifyEdittedEmployeeFailed(true);
        }
      })
      .catch(error => {
        console.log('add Employee error: ', error);
      })
  }

  if (Array.isArray(oeeDonut)) {
    console.log(oeeDonut.pop());
  } else {
    console.log('The value is NOT an array')
  }



  return (
    <>
      <Helmet>
        <title>ProductionLine | MES Korat Fur2</title>
      </Helmet>
      <Grid
        container spacing={1}
        direction="row"
        alignItems="top"
        justifyContent="center"
        style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 25, paddingTop: 75 }}
      >
        {/* Top row */}
        <Grid id="top-row" container sx={{ pt: 2.2 }} spacing={2}>
          <Box sx={{ width: '100%', bgcolor: "#3cbea8", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="50px" style={{ position: "relative", paddingLeft: -10 }} textAlign="center" >
            <Typography style={{ color: "white", fontWeight: "bold", fontSize: "22px"}} variant="button" >
              {data.WORK_CENTER}
            </Typography>
          </Box>
        </Grid>
        {/* left column */}
        <Grid container spacing={1} style={{ position: "relative", top: "0px", left: "-12px" }}>
          <Grid item xs={7} lg={7}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} xl={12}>
                <Item>
                  <Box width="100%" height="8%" sx={{ background: "#d4d4d4" }}>
                    <Typography fontSize={'21px'} fontWeight="bold">
                      ข้อมูลการผลิต
                    </Typography>
                  </Box>
                  <Box mb={-10}>
                    <Grid container spacing="18px" alignItems="left" justifyContent="center" style={{ position: "relative", top: "-1px", left: "-100px" }} >
                      {/* oee */}
                      <Grid item xs={3}>
                        {(load === true || oeeDonut === 'No data')
                          ? <OEE
                            value={[0]}
                            title='OEE'
                          />
                          :
                          <OEE
                            value={[Math.round(oeeDonut.OEE)]}
                            title='OEE'
                          />
                        }
                      </Grid>
                      {/* a */}
                      <Grid item xs={2}>
                        {(load === true || oeeDonut === 'No data')
                          ? <APQ
                            value={[0]}
                            title='AVA'
                            target={['92%']}
                          />
                          :
                          <APQ
                            value={[Math.round(oeeDonut.A)]}
                            title='AVA'
                            target={[Math.round(oeeDonut.A_TARGET)]}
                          />
                        }
                      </Grid>
                      {/* p */}
                      <Grid item xs={2}>
                        {(load === true || oeeDonut === 'No data')
                          ? <APQ
                            value={[0]}
                            title='PER'
                            target={['95%']}
                          />
                          :
                          <APQ
                            value={[Math.round(oeeDonut.P)]}
                            title='PER'
                            target={[Math.round(oeeDonut.P_TARGET)]}
                          />
                        }
                      </Grid>
                      {/* q */}
                      <Grid item xs={2}>
                        {(load === true || oeeDonut === 'No data')
                          ? <APQ
                            value={[0]}
                            title='QUA'
                            target={['98%']}
                          />
                          :
                          <APQ
                            value={[Math.round(oeeDonut.Q)]}
                            title='QUA'
                            target={[Math.round(oeeDonut.Q_TARGET)]}
                          />
                        }
                      </Grid>
                    </Grid>
                  </Box>
                  {/* ผลผลิตสะสม */}
                  <Box pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative", top: "10px", left: "0px" }}>
                    <Grid item xs={12} md={12} xl={12}>
                      <table style={{ 'padding-top': '0px' }}>
                        <tbody>
                          <tr>
                            <td align="left" style={{ width: '120px', fontSize: 22, fontWeight: 'bold', borderBottom: 'none', height: "1px", color: "black" }}>ผลผลิตสะสม</td>
                            <td align="left" style={{ width: '300px', fontSize: 14, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>
                              { load === true || totalWeight === undefined ?
                              <BarChartAccumulativeProduction
                              target_quantity={0}
                              actual_weight={0}
                              target_now={0}
                              unit={'TON'}
                              height='50%'
                            />
                              :
                                <BarChartAccumulativeProduction
                                target_quantity={totalWeight.TARGET_PRODUCTION_QUANTITY}
                                actual_weight={totalWeight.ACTUAL_WEIGHT}
                                target_now={totalWeight.TARGET_NOW}
                                unit={totalWeight.PRODUCTION_UNIT}
                                height='50%'
                              />
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Box>
                  {/* perfrmance */}
                  <Box pt={0} px={2} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative", top: "15px" }}>
                    <Grid item xs={7.5} md={7.5} xl={7.5}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 100 }} aria-label="custom pagination table">
                          <TableBody>
                            {[
                              { name: "Performance", value: totalWeight.CURRENT_ACTUAL_KG_PER_HOUR, unit: "kg/hr", std: "(std. " + [totalWeight.CURRENT_STD_KG_PER_HOUR != 'NA' ? Math.round(totalWeight.CURRENT_STD_KG_PER_HOUR) : 'NA'] + ")" },
                              { name: "", value: totalWeight.CURRENT_ACTUAL_KG_PER_PERSON_PER_HOUR, unit: "kg/person/hr", std: "(std. " + totalWeight.CURRENT_STD_KG_PER_PERSON_PER_HOUR + ")" },
                              { name: <Button variant="outlined" color="success" style={{ color: "black", fontSize: '12px', width: '150px', fontWeight: 'bold' }} onClick={handleClickOpenEmployee}>No.employee</Button>, value: totalWeight.EMPLOYEE, unit: "person", std: "(std. " + totalWeight.STD_EMPLOYEE + ")" },
                              { name: "Yield", value: totalWeight.YIELD, unit: "%", std: "(std. " + [totalWeight.STD_YIELD != 'NA' ? Math.round(totalWeight.STD_YIELD) : 'NA'] + "%)" }
                            ].map((row) => (
                              <TableRow key={row.name}>
                                <TableCell style={{ width: '23%', fontSize: 16, fontWeight: 'bold'}} align="right">
                                  {row.name}
                                </TableCell>
                                <TableCell style={{ width: '20%', fontSize: 16}} align="right">
                                  {row.value}
                                </TableCell>
                                <TableCell style={{ width: '17%', fontSize: 16}} align="left">
                                  {row.unit}
                                </TableCell>
                                <TableCell style={{ width: '40%', fontSize: 14}} align="left">
                                  {row.std}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    {/* defect */}
                    <Grid item xs={4.5}>
                      <Box pt={0} px={2} display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography color="black" fontSize={'18px'} fontWeight="bold">
                          &nbsp;&nbsp;Defect สะสม:&nbsp;&nbsp;{(loading === true || defectDonutDataSeries === 'No data') ? ' ' : sumArray(defectDonutDataSeries.value)} กก.

                        </Typography>
                        <MoreVertIcon fontSize="medium" style={{ position: "relative", top: "5px", cursor: 'pointer' }} onClick={handleClickOpen}></MoreVertIcon>

                        {/* Popup */}
                        <Dialog
                          fullScreen
                          open={open}
                          onClose={handleClose}
                          TransitionComponent={Transition}
                        >
                          <AppBar sx={{ position: 'relative', bgcolor: "#3cbea8" }}>
                            <Toolbar>
                              <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                              >
                                <CloseIcon />
                              </IconButton>
                              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">

                              </Typography>

                            </Toolbar>
                          </AppBar>
                          <Grid item xs={12}>
                            <Card sx={{ height: "100%" }} style={{ paddingTop: 20, paddingLeft: 5, }}>
                              <Box width="100%" height="10%">
                                <Typography fontSize={'20px'} fontWeight="medium">
                                  &nbsp;&nbsp;&nbsp;&nbsp;DEFECT
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={opens ? 'long-menu' : undefined}
                                    aria-expanded={opens ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                  >

                                    <ControlPointIcon fontSize="medium" sx={{ color: '#60d7db' }}></ControlPointIcon>
                                  </IconButton>
                                  <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={opens}
                                    onClose={handleClose}
                                    PaperProps={{
                                      style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                      },
                                    }}
                                  >
                                    {newOption.map((option) => (
                                      <MenuItem key={option.list} selected={option === 'Pyxis'} onClick={handleCloses}>
                                        <div onClick={option.onClick}>{option.list}</div>
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                </Typography>
                              </Box>
                              <Box mb={0}>
                                <Grid container spacing="8px" alignItems="left" justifyContent="left" style={{ position: "relative", top: "-20px" }} >
                                  <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 200, minHeight: 300 }} aria-label="simple table">
                                      <TableRow>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>Product</TableCell>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>Area</TableCell>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>Sub Area</TableCell>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>Reject</TableCell>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>Remark</TableCell>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>Note</TableCell>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>Value(kg)</TableCell>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>Manage</TableCell>
                                      </TableRow>
                                      <TableBody>
                                        {defectCheckList.map((item, index) => {
                                          return <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                          >
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.material_description}</TableCell>
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.area}</TableCell>
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.sub_area}</TableCell>
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.type}</TableCell>
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.remark}</TableCell>
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.note}</TableCell>
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.value}</TableCell>
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}><ModeEditIcon style={{ cursor: 'pointer' }} fontSize="small" onClick={() => { setDefectIDEdition({ id: item.id, line: item.line, material_description: item.material_description, area: item.area, sub_area: item.sub_area, type: item.type, remark: item.remark, note: item.note, value: item.value }); handleListEdit(); }}></ModeEditIcon>
                                              &nbsp;<DeleteIcon style={{ cursor: 'pointer' }} fontSize="small" onClick={() => { setDefectIDDeletion({ id: item.id }); setPopUpDefectDeletion(true); }}></DeleteIcon></TableCell>
                                          </TableRow>
                                        })}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              </Box>

                              {/* add new defect*/}
                              {/* Change Select Options Based on Another Dropdown (https://codesandbox.io/s/ueccx) */}
                              <Dialog
                                open={addDefectDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                disableEnforceFocus
                                sx={{
                                  "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                      width: "100%",
                                      maxWidth: "1000px",
                                    },
                                  },
                                }}
                              >
                                <DialogTitle
                                  className="css-3o4mzd-MuiPaper-root-MuiCard-root"
                                  style={{ borderRadius: 0 }}
                                >
                                  Add Defect

                                </DialogTitle>
                                {/* product */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Product
                                  </Typography>
                                  <Autocomplete
                                    value={defectSelectedSKU}
                                    open={openDefectSKUSelection}
                                    onClose={handleCloseDefectSKUSelection}
                                    onOpen={handleOpenDefectSKUSelection}
                                    onChange={handleSelectionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectSelectedSKU(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectSelectedSKU(newValue.inputValue);
                                      } else {
                                        setDefectSelectedSKU(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.MATERIAL_DESCRIPTION);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={productionDetail}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.MATERIAL_DESCRIPTION;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.MATERIAL_DESCRIPTION}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Product" />
                                    )}
                                  />
                                </Box>

                                {/* Area */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Area
                                  </Typography>
                                  <Autocomplete
                                    value={defectSelectedArea}
                                    open={openDefectAreaSelection}
                                    onClose={handleCloseDefectAreaSelection}
                                    onOpen={handleOpenDefectAreaSelection}
                                    onChange={handleSelectionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectSelectedArea(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectSelectedArea(newValue.inputValue);
                                      } else {
                                        setDefectSelectedArea(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.value);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={areaDefect}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Area" />
                                    )}
                                  />
                                </Box>

                                {/* Sub Area */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Sub Area
                                  </Typography>
                                  <Autocomplete
                                    value={defectSelectedSubArea}
                                    open={openDefectSubAreaSelection}
                                    onClose={handleCloseDefectSubAreaSelection}
                                    onOpen={handleOpenDefectSubAreaSelection}
                                    onChange={handleSelectionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectSelectedSubArea(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectSelectedSubArea(newValue.inputValue);
                                      } else {
                                        setDefectSelectedSubArea(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.value);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={defectSelectedArea === "ส่วนดิบ" ? lowRisk : highRisk}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select sub area" />
                                    )}
                                  />
                                </Box>

                                {/* Type */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Reject
                                  </Typography>
                                  <Autocomplete
                                    value={defectSelectedType}
                                    open={openDefectTypeSelection}
                                    onClose={handleCloseDefectTypeSelection}
                                    onOpen={handleOpenDefectTypeSelection}
                                    onChange={handleSelectionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectSelectedType(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectSelectedType(newValue.inputValue);
                                      } else {
                                        setDefectSelectedType(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.value);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={typeDefct}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Reject" />
                                    )}
                                  />
                                </Box>

                                {/* Remark */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Remark
                                  </Typography>
                                  <Autocomplete
                                    value={defectSelectedRemark}
                                    open={openDefectRemarkSelection}
                                    onClose={handleCloseDefectRemarkSelection}
                                    onOpen={handleOpenDefectRemarkSelection}
                                    onChange={handleSelectionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectSelectedRemark(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectSelectedRemark(newValue.inputValue);
                                      } else {
                                        setDefectSelectedRemark(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.value);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={remarkDefect}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Remark" />
                                    )}
                                  />
                                </Box>

                                {/* Note */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Note
                                  </Typography>
                                  <Autocomplete
                                    value={defectSelectedNote}
                                    open={openDefectNoteSelection}
                                    onClose={handleCloseDefectNoteSelection}
                                    onOpen={handleOpenDefectNoteSelection}
                                    onChange={handleSelectionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectSelectedNote(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectSelectedNote(newValue.inputValue);
                                      } else {
                                        setDefectSelectedNote(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.value);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={getNote()}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Note" />
                                    )}
                                  />
                                </Box>

                                {/* Textfield value */}
                                <Box width='95%' style={{ position: "relative", top: "0px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Value(kg)
                                  </Typography>
                                  <TextField
                                    id="outlined-number"
                                    type="number"
                                    name="value"
                                    value={defectSelectedValue}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={handleSelectionInDefectForm}
                                  />
                                </Box>

                                <DialogActions className="css-3o4mzd-MuiPaper-root-MuiCard-root" style={{ borderRadius: 0 }}>
                                  <Button style={{ minWidth: '110px', backgroundColor: '#009688', fontSize: 16 }} variant="contained" onClick={() => { setAddDefectDialog(false); handleAddDefect(); }}>Save</Button>
                                  <Button style={{ minWidth: '110px', backgroundColor: '#575755', fontSize: 16 }} variant="contained" onClick={() => { setAddDefectDialog(false); }}>Cancel</Button>
                                </DialogActions>
                              </Dialog>

                              {/* edit defect*/}
                              <Dialog
                                open={popUpDefectEditionForm}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                disableEnforceFocus
                                sx={{
                                  "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                      width: "100%",
                                      maxWidth: "1000px",
                                    },
                                  },
                                }}
                              >
                                <DialogTitle
                                  className="css-3o4mzd-MuiPaper-root-MuiCard-root"
                                  style={{ borderRadius: 0 }}
                                >
                                  Edit Defect
                                </DialogTitle>
                                {/* product */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Product
                                  </Typography>
                                  <Autocomplete
                                    value={defectHistorySelectedSKU}
                                    open={openDefectSKUSelection}
                                    onClose={handleCloseDefectSKUSelection}
                                    onOpen={handleOpenDefectSKUSelection}
                                    onChange={handleEditionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectHistorySelectedSKU(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectHistorySelectedSKU(newValue.inputValue);
                                      } else {
                                        setDefectHistorySelectedSKU(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.MATERIAL_DESCRIPTION);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="select product"
                                    options={productionDetail}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.MATERIAL_DESCRIPTION}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Product" />
                                    )}
                                  />
                                </Box>

                                {/* Area */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Area
                                  </Typography>
                                  <Autocomplete
                                    value={defectHistorySelectedArea}
                                    open={openDefectAreaSelection}
                                    onClose={handleCloseDefectAreaSelection}
                                    onOpen={handleOpenDefectAreaSelection}
                                    onChange={handleEditionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectHistorySelectedArea(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectHistorySelectedArea(newValue.inputValue);
                                      } else {
                                        setDefectHistorySelectedArea(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.value);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={areaDefect}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Area" />
                                    )}
                                  />
                                </Box>

                                {/* Sub Area */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Sub Area
                                  </Typography>
                                  <Autocomplete
                                    value={defectHistorySelectedSubArea}
                                    open={openDefectSubAreaSelection}
                                    onClose={handleCloseDefectSubAreaSelection}
                                    onOpen={handleOpenDefectSubAreaSelection}
                                    onChange={handleEditionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectHistorySelectedSubArea(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectHistorySelectedSubArea(newValue.inputValue);
                                      } else {
                                        setDefectHistorySelectedSubArea(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.value);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={defectHistorySelectedArea === "ส่วนดิบ" ? lowRisk : highRisk}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select sub area" />
                                    )}
                                  />
                                </Box>

                                {/* Defect */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Reject
                                  </Typography>
                                  <Autocomplete
                                    value={defectHistorySelectedType}
                                    open={openDefectTypeSelection}
                                    onClose={handleCloseDefectTypeSelection}
                                    onOpen={handleOpenDefectTypeSelection}
                                    onChange={handleEditionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectHistorySelectedType(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectHistorySelectedType(newValue.inputValue);
                                      } else {
                                        setDefectHistorySelectedType(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.value);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={typeDefct}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Reject" />
                                    )}
                                  />
                                </Box>

                                {/* Remark */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Remark
                                  </Typography>
                                  <Autocomplete
                                    value={defectHistorySelectedRemark}
                                    open={openDefectRemarkSelection}
                                    onClose={handleCloseDefectRemarkSelection}
                                    onOpen={handleOpenDefectRemarkSelection}
                                    onChange={handleEditionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectHistorySelectedRemark(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectHistorySelectedRemark(newValue.inputValue);
                                      } else {
                                        setDefectHistorySelectedRemark(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.value);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={remarkDefect}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Remark" />
                                    )}
                                  />
                                </Box>


                                {/* Note */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Note
                                  </Typography>
                                  <Autocomplete
                                    value={defectHistorySelectedNote}
                                    open={openDefectNoteSelection}
                                    onClose={handleCloseDefectNoteSelection}
                                    onOpen={handleOpenDefectNoteSelection}
                                    onChange={handleEditionInDefectForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setDefectHistorySelectedNote(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setDefectHistorySelectedNote(newValue.inputValue);
                                      } else {
                                        setDefectHistorySelectedNote(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.value);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={getNoteHistory()}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Note" />
                                    )}
                                  />
                                </Box>


                                {/* Textfield value */}
                                <Box width='95%' style={{ position: "relative", top: "20px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Value(kg)
                                  </Typography>
                                  <TextField
                                    id="outlined-number"
                                    type="number"
                                    name="value"
                                    value={defectHistorySelectedValue}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={handleEditionInDefectForm}
                                  />
                                </Box>

                                <DialogActions className="css-3o4mzd-MuiPaper-root-MuiCard-root" style={{ borderRadius: 0 }}>
                                  <Button style={{ minWidth: '110px', backgroundColor: '#009688', fontSize: 16 }} variant="contained" onClick={() => { setPopUpDefectEditionForm(false); handleEditDefect(); }}>Save</Button>
                                  <Button style={{ minWidth: '110px', backgroundColor: '#575755', fontSize: 16 }} variant="contained" onClick={() => { setPopUpDefectEditionForm(false); setDefectIDEdition({}); }}>Cancel</Button>
                                </DialogActions>
                              </Dialog>

                              {/*delete defect */}
                              <Dialog
                                open={deleteDefect}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description" minWidth='200px' align='center' style={{ fontWeight: "bold", fontSize: "18px" }}>
                                    Want to delete defect?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button sx={{ backgroundColor: '#009688', borderColor: '#009688' }} style={{ minWidth: '110px', background: '#3cbea8', color: "white" }} variant="contained" onClick={() => { setDeleteDefect(false); }}>OK</Button>
                                  <Button sx={{ backgroundColor: '#575755', borderColor: '#575755' }} style={{ minWidth: '110px', background: '#575755', color: "white" }} variant="contained" onClick={() => { setDeleteDefect(false); }}>Cancel</Button>
                                </DialogActions>
                              </Dialog>

                            </Card>
                          </Grid>
                        </Dialog>
                        {/* Popup no.Employee */}
                        <Dialog
                          fullScreen
                          open={openEmployee}
                          onClose={handleCloseEmployee}
                          TransitionComponent={Transition}
                        >
                          <AppBar sx={{ position: 'relative', bgcolor: "#3cbea8" }}>
                            <Toolbar>
                              <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseEmployee}
                                aria-label="close"
                              >
                                <CloseIcon />
                              </IconButton>
                              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">

                              </Typography>

                            </Toolbar>
                          </AppBar>
                          <Grid item xs={12}>
                            <Card sx={{ height: "100%" }} style={{ paddingTop: 20, paddingLeft: 5, }}>
                              <Box width="100%" height="10%">
                                <Typography fontSize={'20px'} fontWeight="medium">
                                  &nbsp;&nbsp;&nbsp;&nbsp;No.Employee
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={opensEmployee ? 'long-menu' : undefined}
                                    aria-expanded={opensEmployee ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClickEmployee}
                                  >

                                    <ControlPointIcon fontSize="medium" sx={{ color: '#60d7db' }}></ControlPointIcon>
                                  </IconButton>
                                  <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorElEmployee}
                                    open={opensEmployee}
                                    onClose={handleCloseEmployee}
                                    PaperProps={{
                                      style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                      },
                                    }}
                                  >
                                    {noEmployee.map((option) => (
                                      <MenuItem key={option.list} selected={option === 'Pyxis'} onClick={handleClosesEmployee}>
                                        <div onClick={option.onClick}>{option.list}</div>
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                </Typography>
                              </Box>
                              <Box mb={0}>
                                <Grid container spacing="8px" alignItems="left" justifyContent="left" style={{ position: "relative", top: "-20px" }} >
                                  <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 200, minHeight: 300 }} aria-label="simple table">
                                      <TableRow>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>Product</TableCell>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>Area</TableCell>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>No.Employee</TableCell>
                                        <TableCell align="center" style={{ fontSize: 18, fontWeight: "bold" }}>Manage</TableCell>
                                      </TableRow>
                                      <TableBody>
                                        {employeeCheckList.map((item, index) => {
                                          return <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                          >
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.material_description}</TableCell>
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.area}</TableCell>
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.value}</TableCell>
                                            <TableCell align="center" style={{ fontSize: 18, paddingBottom: 0, paddingTop: 0, height: 40 }}><ModeEditIcon style={{ cursor: 'pointer' }} fontSize="small" onClick={() => { setEmployeeIDEdition({ id: item.id, plant_id: item.plant_id, material_description: item.material_description, area: item.area, value: item.value }); handleEmployeeEdit(); }}></ModeEditIcon>
                                              &nbsp;<DeleteIcon style={{ cursor: 'pointer' }} fontSize="small" onClick={() => { setEmployeeIDDeletion({ id: item.id }); setPopUpEmployeeDeletion(true); }}></DeleteIcon></TableCell>
                                          </TableRow>
                                        })}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              </Box>

                              {/* add No.Employee*/}
                              {/* Change Select Options Based on Another Dropdown (https://codesandbox.io/s/ueccx) */}
                              <Dialog
                                open={addNoEmployee}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                disableEnforceFocus
                                sx={{
                                  "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                      width: "100%",
                                      maxWidth: "1000px",
                                    },
                                  },
                                }}
                              >
                                <DialogTitle
                                  className="css-3o4mzd-MuiPaper-root-MuiCard-root"
                                  style={{ borderRadius: 0 }}
                                >
                                  Add No.Employee

                                </DialogTitle>

                                {/* product */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Product
                                  </Typography>
                                  <Autocomplete
                                    value={employeeSelectedSKU}
                                    open={openEmployeeSKUSelection}
                                    onClose={handleCloseEmployeeSKUSelection}
                                    onOpen={handleOpenEmployeeSKUSelection}
                                    onChange={handleSelectionInEmployeeForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setEmployeeSelectedSKU(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setEmployeeSelectedSKU(newValue.inputValue);
                                      } else {
                                        setEmployeeSelectedSKU(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.MATERIAL_DESCRIPTION);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={productionDetail}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.MATERIAL_DESCRIPTION;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.MATERIAL_DESCRIPTION}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Product" />
                                    )}
                                  />
                                </Box>

                                {/* Area */}
                                <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    Area
                                  </Typography>
                                  <Autocomplete
                                    value={employeeSelectedArea}
                                    open={openEmployeeAreaSelection}
                                    onClose={handleCloseEmployeeAreaSelection}
                                    onOpen={handleOpenEmployeeAreaSelection}
                                    onChange={handleSelectionInEmployeeForm}
                                    onInputChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setEmployeeSelectedArea(newValue);
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setEmployeeSelectedArea(newValue.inputValue);
                                      } else {
                                        setEmployeeSelectedArea(newValue);
                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.value);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          value: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={areaNoEmployee}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                    sx={{ width: '100%' }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Area" />
                                    )}
                                  />
                                </Box>

                                {/* Textfield No.Employee */}
                                <Box width='95%' style={{ position: "relative", top: "5px", left: "22px" }} >
                                  <Typography fontSize={'16px'} fontWeight="medium">
                                    No.Employee
                                  </Typography>
                                  <TextField
                                    id="outlined-number"
                                    type="number"
                                    name="value"
                                    value={employeeSelectedValue}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={handleSelectionInEmployeeForm}
                                  />
                                </Box>

                                <DialogActions className="css-3o4mzd-MuiPaper-root-MuiCard-root" style={{ borderRadius: 0 }}>
                                  <Button sx={{ backgroundColor: '#009688', borderColor: '#009688' }} style={{ minWidth: '110px', background: '#3cbea8', color: "white", fontSize: 16 }} variant="contained" onClick={() => { setAddNoEmployee(false); handleSubmitNoEmployee(); }}>Save</Button>
                                  <Button sx={{ backgroundColor: '#009688', borderColor: '#009688' }} style={{ minWidth: '110px', background: '#575755', color: "white", fontSize: 16 }} variant="contained" onClick={() => { setAddNoEmployee(false); }}>Cancel</Button>
                                </DialogActions>
                              </Dialog>
                            </Card>
                          </Grid>
                        </Dialog>

                        {/* edit no.employee*/}
                        <Dialog
                          open={popUpEmployeeEditionForm}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          disableEnforceFocus
                          sx={{
                            "& .MuiDialog-container": {
                              "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "1000px",
                              },
                            },
                          }}
                        >
                          <DialogTitle
                            className="css-3o4mzd-MuiPaper-root-MuiCard-root"
                            style={{ borderRadius: 0 }}
                          >
                            Edit No.Employee
                          </DialogTitle>
                          {/* product */}
                          <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                            <Typography fontSize={'16px'} fontWeight="medium">
                              Product
                            </Typography>
                            <Autocomplete
                              value={employeeHistorySelectedSKU}
                              open={openEmployeeSKUSelection}
                              onClose={handleCloseEmployeeSKUSelection}
                              onOpen={handleOpenEmployeeSKUSelection}
                              onChange={handleEditionInEmployeeForm}
                              onInputChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                  setEmployeeHistorySelectedSKU(newValue);
                                } else if (newValue && newValue.inputValue) {
                                  // Create a new value from the user input
                                  setEmployeeHistorySelectedSKU(newValue.inputValue);
                                } else {
                                  setEmployeeHistorySelectedSKU(newValue);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.MATERIAL_DESCRIPTION);
                                if (inputValue !== '' && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    value: `Add "${inputValue}"`,
                                  });
                                }

                                return filtered;
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              id="free-solo-with-text-demo"
                              options={productionDetail}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                // Regular option
                                return option.MATERIAL_DESCRIPTION;
                              }}
                              renderOption={(props, option) => <li {...props}>{option.MATERIAL_DESCRIPTION}</li>}
                              sx={{ width: '100%' }}
                              freeSolo
                              renderInput={(params) => (
                                <TextField {...params} label="Select Product" />
                              )}
                            />
                          </Box>

                          {/* Area */}
                          <Box width='95%' style={{ position: "relative", top: "-5px", left: "20px" }} >
                            <Typography fontSize={'16px'} fontWeight="medium">
                              Area
                            </Typography>
                            <Autocomplete
                              value={employeeHistorySelectedArea}
                              open={openEmployeeAreaSelection}
                              onClose={handleCloseEmployeeAreaSelection}
                              onOpen={handleOpenEmployeeAreaSelection}
                              onChange={handleEditionInEmployeeForm}
                              onInputChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                  setEmployeeHistorySelectedArea(newValue);
                                } else if (newValue && newValue.inputValue) {
                                  // Create a new value from the user input
                                  setEmployeeHistorySelectedArea(newValue.inputValue);
                                } else {
                                  setEmployeeHistorySelectedArea(newValue);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.value);
                                if (inputValue !== '' && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    value: `Add "${inputValue}"`,
                                  });
                                }

                                return filtered;
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              id="free-solo-with-text-demo"
                              options={areaNoEmployee}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                // Regular option
                                return option.value;
                              }}
                              renderOption={(props, option) => <li {...props}>{option.value}</li>}
                              sx={{ width: '100%' }}
                              freeSolo
                              renderInput={(params) => (
                                <TextField {...params} label="Select Area" />
                              )}
                            />
                          </Box>

                          {/* Textfield No.Employee */}
                          <Box width='95%' style={{ position: "relative", top: "5px", left: "22px" }} >
                            <Typography fontSize={'16px'} fontWeight="medium">
                              No.Employee
                            </Typography>
                            <TextField
                              id="outlined-number"
                              type="number"
                              name="value"
                              value={employeeHistorySelectedValue}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleEditionInEmployeeForm}
                            />
                          </Box>

                          <DialogActions className="css-3o4mzd-MuiPaper-root-MuiCard-root" style={{ borderRadius: 0 }}>
                            <Button style={{ minWidth: '110px', background: '#3cbea8', color: "white" }} variant="contained" onClick={() => { setPopUpEmployeeEditionForm(false); handleEditEmployee(); }}>Save</Button>
                            <Button style={{ minWidth: '110px', background: '#575755', color: "white" }} variant="contained" onClick={() => { setPopUpEmployeeEditionForm(false); setEmployeeIDEdition({}); }}>Cancel</Button>
                          </DialogActions>
                        </Dialog>

                        {/*delete No.Employee */}
                        <Dialog
                          open={deleteEmployee}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description" minWidth='200px' align='center' style={{ fontWeight: "bold", fontSize: "18px" }}>
                              Want to delete no.employee?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button sx={{ backgroundColor: '#009688', borderColor: '#009688' }} style={{ minWidth: '110px', background: '#3cbea8', color: "white" }} variant="contained" onClick={() => { setDeleteEmployee(false); }}>OK</Button>
                            <Button sx={{ backgroundColor: '#009688', borderColor: '#009688' }} style={{ minWidth: '110px', background: '#575755', color: "white" }} variant="contained" onClick={() => { setDeleteEmployee(false); }}>Cancel</Button>
                          </DialogActions>
                        </Dialog>
                      </Box>

                      {(loading === true || defectDonutDataSeries === 'No data') ? 'No Data' :
                        <Defect
                          labels={defectDonutDataSeries.type}
                          value={defectDonutDataSeries.value} />
                      }
                    </Grid>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Grid>
          {/* right column */}
          <Grid item xs={5}>
            <Item >
              <Box width="100%" height="8%" sx={{ background: "#d4d4d4" }}>
                <Typography fontSize={'22px'} fontWeight="bold">
                  รายการสินค้า&nbsp;&nbsp;&nbsp;
                  {/*<Button
                    //sx={{ backgroundColor: '#3cbea8', borderColor: '#3cbea8' }}
                    variant="contained"
                    style={{ color: "white", fontWeight: "bold", fontFamily: 'Monospace', backgroundColor: '#3cbea8' }}
                    onClick={updateDataPO}
                  >
                    Update
                    </Button>*/}
                </Typography>
              </Box>
              <Box mb={0}>
                <Grid container spacing="8px" alignItems="left" justifyContent="left" style={{ position: "relative", top: "20px" }} >
                  <TableContainer sx={{ minWidth: 200, minHeight: 100 }} aria-label="simple table">
                    <Table >
                      <TableRow>
                        <TableCell align="center" style={{ width: '10%', fontSize: 16, fontWeight: "bold"}}></TableCell>
                        <TableCell align="center" style={{ width: '25%', fontSize: 16, fontWeight: "bold"}}></TableCell>
                        <TableCell align="center" style={{ width: '30%', fontSize: 16, fontWeight: "bold"}}>ACTUAL</TableCell>
                        <TableCell align="right" style={{ width: '12%', fontSize: 16, fontWeight: "bold"}}>TARGET</TableCell>
                        <TableCell align="right" style={{ width: '12%', fontSize: 16, fontWeight: "bold"}}>UNIT</TableCell>
                        <TableCell align="right" style={{ width: '11%', fontSize: 16, fontWeight: "bold"}}>YIELD</TableCell>
                      </TableRow>
                      <TableBody>
                        {productionDetail.map((item, index) => {
                          //change color status on condition in table map (https://stackoverflow.com/questions/63872649/change-color-on-conditions-in-table-map-in-reactjs)
                          //change icon border bold (https://github.com/react-icons/react-icons/issues/232)
                          //let colorStatus = item.STATUS == 'Running' ? "light" : item.STATUS == 'Completed' ? 'success' : "disabled"
                          let colorStroke = item.STATUS == 'Running' ? "#00d084" : item.STATUS == 'Completed' ? "#00d084" : "#FFFFFF"

                          const getColor = () => {
                            let color;
                            try {
                              if (item.STATUS == 'Completed') {
                                color = "#00d084"
                              } else if (item.STATUS == 'Running') {
                                color = "#e0e0e0"
                              } else if (item.STATUS == 'Not Start') {
                                color = "#FFFFFF"
                              }
                              return color;
                            }
                            catch {
                              console.log()
                            }
                          }
                          return <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center" style={{ fontSize: 16, width: 80, paddingBottom: 0, paddingTop: 0, height: 40 }}>
                              <LensIcon fontSize="medium" stroke={colorStroke} strokeWidth="2" sx={{ color: getColor() }}>{item.STATUS}</LensIcon>
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: 16, width: 140, paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.MATERIAL_DESCRIPTION}</TableCell>
                            <TableCell align="center" style={{ fontSize: 16, paddingBottom: 0, paddingTop: 0, height: 40 }} >
                              <Box mb={-4} ml={1} style={{ position: "relative", display: "block", top: "-20px" }}>
                                { load === true || productionDetail === undefined ? '' :
                                  <Chart
                                  options={{
                                    chart: {
                                      type: 'bar',
                                      stacked: 'true',
                                      toolbar: {
                                        show: false
                                      },
                                      zoom: {
                                        enabled: false
                                      }
                                    },
                                    plotOptions: {
                                      bar: {
                                        horizontal: true,
                                      }
                                    },
                                    colors: item.STATUS === 'Running' ? ['#00E396', '#fff9c4'] : ['#00E396', '#e0e0e0'],
                                    dataLabels: {
                                      enabled: true,
                                      textAnchor: 'middle',
                                      style: {
                                        colors: ['#616161']
                                      },
                                      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                                        if (seriesIndex === 0) {
                                          return value.toFixed(2)
                                        }
                                      },
                                      offsetX: 0,
                                      dropShadow: {
                                        enabled: false
                                      },
                                      distributed: true,
                                      offsetX: 0,
                                      offsetY: 0,
                                      total: {
                                        enabled: true,
                                        offsetX: 0,
                                        style: {
                                          fontSize: '13px',
                                          fontWeight: 900,
                                          alignItems: 'left',
                                          fontFamily: 'CPF_Imm_Sook'
                                        }
                                      }
                                    },
                                    legend: {
                                      show: false,
                                      showForSingleSeries: true,
                                      customLegendItems: ['Actual', 'Expected'],
                                      markers: {
                                        fillColors: ['#00E396', '#775DD0']
                                      }
                                    },
                                    tooltip: {
                                      enabled: false,
                                    },
                                    xaxis: {
                                      type: 'category',
                                      categories: [], type: 'category',
                                      categories: [],
                                      range: item.TARGET,
                                      min: 0,
                                      max: item.TARGET > item.ACTUAL_WEIGHT ? item.TARGET : item.ACTUAL_WEIGHT,
                                      show: false,
                                      labels: {
                                        show: false
                                      },
                                      axisBorder: {
                                        show: false
                                      },
                                      axisTicks: {
                                        show: false
                                      },
                                    },
                                    yaxis: {
                                      axisBorder: {
                                        show: false
                                      },
                                      axisTicks: {
                                        show: false,
                                      },
                                      labels: {
                                        show: false,
                                      },
                                    },
                                    grid: {
                                      padding: {
                                        left: -16
                                      }
                                    },
                                    annotations: {
                                      xaxis: [
                                        {
                                          x: item.TARGET_NOW,
                                          strokeDashArray: 0,
                                          borderColor: "#775DD0",
                                          label: {
                                            borderColor: "#775DD0",
                                            borderWidth: 2,
                                            borderRadius: 20,
                                            style: {
                                              color: "#fff",
                                              background: "#775DD0"
                                            },
                                            orientation: "horizontal",
                                            text: item.TARGET_NOW
                                          }
                                        },
                                      ]
                                    }
                                  }}

                                  series={[
                                    {
                                      name: 'Actual',
                                      data: [item.ACTUAL_WEIGHT]
                                    },
                                    {
                                      name: 'Waiting',
                                      data: [item.TARGET - item.ACTUAL_WEIGHT < 0 ? 0 : item.TARGET - item.ACTUAL_WEIGHT]
                                    }
                                  ]}
                                  type='bar'
                                  width='100%'
                                  height='50%'
                                />}
                              </Box>
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: 14, fontFamily: 'Monospace', paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.TARGET}</TableCell>
                            <TableCell align="right" style={{ fontSize: 14, fontFamily: 'Monospace', paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.PRODUCTION_UNIT}</TableCell>
                            <TableCell align="right" style={{ fontSize: 14, fontFamily: 'Monospace', paddingBottom: 0, paddingTop: 0, height: 40 }}>{item.YIELD}</TableCell>

                          </TableRow>
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Box>
            </Item>
          </Grid>
        </Grid>
        {/* Bottom row */}
        <Grid container spacing={1} style={{ position: "relative", top: "20px", left: "-10px" }}>
          <Grid item xs={12}>

            <Stepper />

          </Grid>
        </Grid>
      </Grid>
      {/* popup if any */}
      {addDefect == 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifySubmittedDefectSuccessful}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successfully added defect
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688' }} variant="contained" onClick={() => { setNotifySubmittedDefectSuccessful(false); window.location.reload(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }
      {addDefect == 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifySubmittedDefectFailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed to add defect, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688' }} aligh='Center' variant="contained" onClick={() => { setNotifySubmittedDefectFailed(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }
      {/* popup if any */}
      {editDefect === 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyEdittedDefectSuccessful}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successfully editted defect
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{backgroundColor: '#009688' }} variant="contained" onClick={() => { setNotifyEdittedDefectSuccessful(false); window.location.reload(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }
      {editDefect === 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyEdittedDefectFailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed to edit defect, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688' }} aligh='Center' variant="contained" onClick={() => { setNotifyEdittedDefectFailed(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }
      {/* ask if wanna delete defect history */}
      <Dialog
        open={popUpDefectDeletion}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
            Do you want to delete defect record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{backgroundColor: '#009688' }} variant="contained" onClick={() => handleDefectDeletion()}>OK</Button>
          <Button style={{backgroundColor: '#575755' }} variant="contained" onClick={() => { setPopUpDefectDeletion(false); }}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* successfully delete */}
      <Dialog
        open={defectDeletionDone}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
            Successfully deleted defect record
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: '#009688' }} variant="contained" onClick={() => { setDefectDeletionDone(false); window.location.reload(false); }}>OK</Button>
        </DialogActions>
      </Dialog>

      {/* popup if any employee */}
      {newNoEmployee == 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifySubmittedEmployeeSuccessful}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successfully added no.emplyee
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688' }} variant="contained" onClick={() => { setNotifySubmittedEmployeeSuccessful(false); window.location.reload(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }
      {newNoEmployee == 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifySubmittedEmployeeFailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed to add no.emplyee, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{backgroundColor: '#009688' }} aligh='Center' variant="contained" onClick={() => { setNotifySubmittedEmployeeFailed(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }
      {editEmployee === 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyEdittedEmployeeSuccessful}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successfully editted no.Employee
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688' }} variant="contained" onClick={() => { setNotifyEdittedEmployeeSuccessful(false); window.location.reload(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }
      {editEmployee === 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyEdittedEmployeeFailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed to edit no.employee, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{backgroundColor: '#009688' }} aligh='Center' variant="contained" onClick={() => { setNotifyEdittedEmployeeFailed(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }
      {/* ask if wanna delete defect history */}
      <Dialog
        open={popUpEmployeeDeletion}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
            Do you want to delete no.employee record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: '#009688' }} variant="contained" onClick={() => handleEmployeeDeletion()}>OK</Button>
          <Button style={{backgroundColor: '#575755' }} variant="contained" onClick={() => { setPopUpEmployeeDeletion(false); }}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/* successfully delete */}
      <Dialog
        open={employeeDeletionDone}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
            Successfully deleted no.employee record
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{backgroundColor: '#009688' }} variant="contained" onClick={() => { setEmployeeDeletionDone(false); window.location.reload(false); }}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

