import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Time from 'assets/images/fast-time.png';
import Fighting from 'assets/images/first1.gif';
import Run from 'assets/images/running.gif';
import Target from 'assets/images/svg/target.svg';
import moment from 'moment-timezone';
import BarChartTargetLine7 from "../components/chart/BarChartTargetLine7";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import { apiUrl } from "../constants";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Running from 'assets/images/running_png.png';
import Fist from 'assets/images/protest_png.png';
import db from "firebase-config";
import Popup from '../components/model/Popup';





const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    height: '90%',
    width: '98%',
    borderRadius: 25,
    color: theme.palette.text.secondary,
}));


export default function TargetLine7Page2inmonitor() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');
    const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');


    const [no_data, setNo_data] = useState(false);
    const [line_monitoring, setLine_monitoring] = useState({
        WARNING: "",
        LINE: "",
        SKU: "",
        FACTORY_DATE: "",
        SHIFT: "",
        TARGET: "",
        TARGET_UNIT: "",
        ACTUAL_WEIGHT: "",
        ACTUAL_WEIGHT_PERCENT: "",
        REMAINING: "",
        REMAINING_PERCENT: "",
        ACTUAL_FEED_RATE: "",
        STD_FEED_RATE: "",
        TOTAL_RUNTIME: "",
        PRODUCTION_TIME: "",
        NON_PRODUCTION_TIME: "",
        START_DATETIME: "",
        FINISH_DATETIME: "",
        FORECAST_FINISH_PRODUCTION_TIME: "",
        PRODUCTIVITY_TARGET_NOW: "",
        PRODUCTIVITY_TARGET_NOW_PERCENT: "",
        FORECAST_TOTAL_PRODUCTION: "",
        OPPORTUNITY_COST_OF_PRODUCTION: "",
        PRODUCTION_EFFICIENCY: ""
    })

    const [taglist, setTaglist] = useState([]);

    setTimeout(() => {
        navigate('/target-line7/p1');
    }, 30000);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.LINE_MONITORING_PAGE2}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        sessionStorage.setItem('warning', response.data.WARNING);
                        sessionStorage.setItem('sku', response.data.SKU);
                        sessionStorage.setItem('target', response.data.TARGET);
                        sessionStorage.setItem('target_unit', response.data.TARGET_UNIT);
                        sessionStorage.setItem('actual_weight_percent', response.data.ACTUAL_WEIGHT_PERCENT);
                        sessionStorage.setItem('actual_weight', response.data.ACTUAL_WEIGHT);
                        sessionStorage.setItem('remaining_percent', response.data.REMAINING_PERCENT);
                        sessionStorage.setItem('remaining', response.data.REMAINING);
                        sessionStorage.setItem('std_feed_rate', response.data.STD_FEED_RATE);
                        sessionStorage.setItem('start_time', response.data.START_DATETIME);
                        sessionStorage.setItem('finish_time', response.data.FINISH_DATETIME);
                        sessionStorage.setItem('forecast_finish_production_time', response.data.FORECAST_FINISH_PRODUCTION_TIME);
                        sessionStorage.setItem('forecast_total_production', response.data.FORECAST_TOTAL_PRODUCTION);
                        sessionStorage.setItem('opportunity_cost_of_production', response.data.OPPORTUNITY_COST_OF_PRODUCTION);
                        sessionStorage.setItem('production_efficiency', response.data.PRODUCTION_EFFICIENCY);
                        sessionStorage.setItem('production_time', response.data.PRODUCTION_TIME);
                        sessionStorage.setItem('no_production_time', response.data.NON_PRODUCTION_TIME);
                        sessionStorage.setItem('actual_feed_rate', response.data.ACTUAL_FEED_RATE);
                        setLine_monitoring({
                            WARNING: response.data.WARNING,
                            LINE: response.data.LINE,
                            SKU: response.data.SKU,
                            FACTORY_DATE: response.data.FACTORY_DATE,
                            SHIFT: response.data.SHIFT,
                            TARGET: response.data.TARGET,
                            TARGET_UNIT: response.data.TARGET_UNIT,
                            ACTUAL_WEIGHT: response.data.ACTUAL_WEIGHT,
                            ACTUAL_WEIGHT_PERCENT: response.data.ACTUAL_WEIGHT_PERCENT,
                            REMAINING: response.data.REMAINING,
                            REMAINING_PERCENT: response.data.REMAINING_PERCENT,
                            ACTUAL_FEED_RATE: response.data.ACTUAL_FEED_RATE,
                            STD_FEED_RATE: response.data.STD_FEED_RATE,
                            TOTAL_RUNTIME: response.data.TOTAL_RUNTIME,
                            PRODUCTION_TIME: response.data.PRODUCTION_TIME,
                            NON_PRODUCTION_TIME: response.data.NON_PRODUCTION_TIME,
                            START_DATETIME: response.data.START_DATETIME,
                            FINISH_DATETIME: response.data.FINISH_DATETIME,
                            FORECAST_FINISH_PRODUCTION_TIME: response.data.FORECAST_FINISH_PRODUCTION_TIME,
                            PRODUCTIVITY_TARGET_NOW: response.data.PRODUCTIVITY_TARGET_NOW,
                            PRODUCTIVITY_TARGET_NOW_PERCENT: response.data.PRODUCTIVITY_TARGET_NOW_PERCENT,
                            FORECAST_TOTAL_PRODUCTION: response.data.FORECAST_TOTAL_PRODUCTION,
                            OPPORTUNITY_COST_OF_PRODUCTION: response.data.OPPORTUNITY_COST_OF_PRODUCTION,
                            PRODUCTION_EFFICIENCY: response.data.PRODUCTION_EFFICIENCY
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }



    }, []);


    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_line_monitoring_tag_list")
            .where("TAG", "==", "ACCUMURATE_WEIGHT")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                sessionStorage.setItem('is_connected', getPostFromFirebase[0].IS_CONNECTED);
                setTaglist(getPostFromFirebase);
            });

        // return cleanup function
        return () => subscriber();
    }, [taglist]);




    if (timestrs == "00:00:00") {
        sessionStorage.clear();
    }

    var accumurate_weight = taglist.find((user) => user.TAG.includes("ACCUMURATE_WEIGHT"));

    //console.log(accumurate_weight?.IS_RUNNING != undefined ? accumurate_weight?.IS_RUNNING : true)

    ///// split time non production time

    var non_production_time = line_monitoring.NON_PRODUCTION_TIME != 'NA' ? (line_monitoring.NON_PRODUCTION_TIME).split(":") : "00:00"

    var non_production_hour = Number(non_production_time[0])

    var non_production_minute = Number(non_production_time[1])

    ///// split time production time

    var production_time = line_monitoring.PRODUCTION_TIME != 'NA' ? (line_monitoring.PRODUCTION_TIME).split(":") : "00:00"

    var production_hour = Number(production_time[0])

    var production_minute = Number(production_time[1])

    ///// split time non production time sessionStorage

    var non_production_time_storage = sessionStorage.getItem('no_production_time') != null ? (sessionStorage.getItem('no_production_time') != 'NA' ? (sessionStorage.getItem('no_production_time')).split(":") : "00:00") : "00:00"

    var non_production_hour_storage = Number(non_production_time_storage[0])

    var non_production_minute_storage = Number(non_production_time_storage[1])

    ///// split time  production time sessionStorage

    var production_time_storage = sessionStorage.getItem('production_time') != null ? (sessionStorage.getItem('production_time') != 'NA' ? (sessionStorage.getItem('production_time')).split(":") : "00:00") : "00:00"

    var production_hour_storage = Number(production_time_storage[0])

    var production_minute_storage = Number(production_time_storage[1])

    const getChartColor = () => {
        let color;
        try {
            if ((line_monitoring.ACTUAL_WEIGHT == 'NA' ? 0 : line_monitoring.ACTUAL_WEIGHT) >= ((((production_hour + (production_minute / 60)) + (non_production_hour + (non_production_minute / 60))) * (line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : line_monitoring.STD_FEED_RATE)) * 1)) {
                color = "#2e9281"
            } else if ((line_monitoring.ACTUAL_WEIGHT == 'NA' ? 0 : line_monitoring.ACTUAL_WEIGHT) >= ((((production_hour + (production_minute / 60)) + (non_production_hour + (non_production_minute / 60))) * (line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : line_monitoring.STD_FEED_RATE)) * 0.8)) {
                color = "#b58514"
            } else if ((line_monitoring.ACTUAL_WEIGHT == 'NA' ? 0 : line_monitoring.ACTUAL_WEIGHT) < ((((production_hour + (production_minute / 60)) + (non_production_hour + (non_production_minute / 60))) * (line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : line_monitoring.STD_FEED_RATE)) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }



    const getChartColorOld = () => {
        let color;
        try {
            if ((sessionStorage.getItem('actual_weight') == 'NA' ? 0 : Number(sessionStorage.getItem('actual_weight'))) >= ((((production_hour_storage + (production_minute_storage / 60)) + (non_production_hour_storage + (non_production_minute_storage / 60))) * (sessionStorage.getItem('std_feed_rate') === 'NA' || sessionStorage.getItem('std_feed_rate') === 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : Number(sessionStorage.getItem('std_feed_rate')))) * 1)) {
                color = "#2e9281"
            } else if ((sessionStorage.getItem('actual_weight') == 'NA' ? 0 : Number(sessionStorage.getItem('actual_weight'))) >= ((((production_hour_storage + (production_minute_storage / 60)) + (non_production_hour_storage + (non_production_minute_storage / 60))) * (sessionStorage.getItem('std_feed_rate') === 'NA' || sessionStorage.getItem('std_feed_rate') === 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : Number(sessionStorage.getItem('std_feed_rate')))) * 0.8)) {
                color = "#b58514"
            } else if ((sessionStorage.getItem('actual_weight') == 'NA' ? 0 : Number(sessionStorage.getItem('actual_weight'))) < ((((production_hour_storage + (production_minute_storage / 60)) + (non_production_hour_storage + (non_production_minute_storage / 60))) * (sessionStorage.getItem('std_feed_rate') === 'NA' || sessionStorage.getItem('std_feed_rate') === 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : Number(sessionStorage.getItem('std_feed_rate')))) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getFeedrateColor = () => {
        let color;
        try {
            if ((line_monitoring.PRODUCTION_EFFICIENCY == 'NA' ? 0 : line_monitoring.PRODUCTION_EFFICIENCY) >= (((line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : line_monitoring.STD_FEED_RATE) * 1)) {
                color = "#2e9281"
            } else if ((line_monitoring.PRODUCTION_EFFICIENCY == 'NA' ? 0 : line_monitoring.PRODUCTION_EFFICIENCY) >= (((line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : line_monitoring.STD_FEED_RATE) * 0.8)) {
                color = "#b58514"
            } else if ((line_monitoring.PRODUCTION_EFFICIENCY == 'NA' ? 0 : line_monitoring.PRODUCTION_EFFICIENCY) < (((line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : line_monitoring.STD_FEED_RATE) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getFeedrateOldColor = () => {
        let color;
        try {
            if ((sessionStorage.getItem('production_efficiency') == 'NA' ? 0 : sessionStorage.getItem('production_efficiency')) >= (((sessionStorage.getItem('std_feed_rate') == 'NA' || sessionStorage.getItem('std_feed_rate') == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : sessionStorage.getItem('std_feed_rate')) * 1)) {
                color = "#2e9281"
            } else if ((sessionStorage.getItem('production_efficiency') == 'NA' ? 0 : sessionStorage.getItem('production_efficiency')) >= (((sessionStorage.getItem('std_feed_rate') == 'NA' || sessionStorage.getItem('std_feed_rate') == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : sessionStorage.getItem('std_feed_rate')) * 0.8)) {
                color = "#b58514"
            } else if ((sessionStorage.getItem('production_efficiency') == 'NA' ? 0 : sessionStorage.getItem('production_efficiency')) < (((sessionStorage.getItem('std_feed_rate') == 'NA' || sessionStorage.getItem('std_feed_rate') == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : sessionStorage.getItem('std_feed_rate')) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getForecastColor = () => {
        let color;
        try {
            if ((line_monitoring.FORECAST_TOTAL_PRODUCTION == 'NA' ? 0 : line_monitoring.FORECAST_TOTAL_PRODUCTION) >= (line_monitoring.TARGET == 'NA' ? 0 : line_monitoring.TARGET)) {
                color = "#2e9281"
            } else if ((line_monitoring.FORECAST_TOTAL_PRODUCTION == 'NA' ? 0 : line_monitoring.FORECAST_TOTAL_PRODUCTION) < (line_monitoring.TARGET == 'NA' ? 0 : line_monitoring.TARGET)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }



    const getForecastOldColor = () => {
        let color;
        try {
            if ((sessionStorage.getItem('forecast_total_production') == 'NA' ? 0 : Number(sessionStorage.getItem('forecast_total_production'))) >= (sessionStorage.getItem('target') == 'NA' ? 0 : Number(sessionStorage.getItem('target')))) {
                color = "#2e9281"
            } else if ((sessionStorage.getItem('forecast_total_production') == 'NA' ? 0 : Number(sessionStorage.getItem('forecast_total_production'))) < (sessionStorage.getItem('target') == 'NA' ? 0 : Number(sessionStorage.getItem('target')))) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    var connect = (sessionStorage.getItem('is_connected') === "true")

    var connected = sessionStorage.getItem('is_connected') === null ? true : (accumurate_weight?.IS_CONNECTED == undefined ? connect : accumurate_weight?.IS_CONNECTED);

    //console.log(connected)

    // console.log((((10 + (46/60)) + (4+(58/60))) * 560) * 1)
    // console.log((((10 + (46/60)) + (4+(58/60))) * 560) * 0.8)













    return (
        <>
            {connected == false ? <Popup /> :
                <Helmet>
                    <title>Target Line7 Page 2 | MES Korat Fur2</title>
                </Helmet>
            }
            <Grid
                container spacing={1}
                direction="row"
                alignItems="top"
                justifyContent="center"
                style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 25, paddingTop: 75 }}
            >
                {/* Top row */}

                <Grid id="top-row" container sx={{ pt: 0 }} spacing={9}>
                    <Box sx={{ width: '20%', bgcolor: "#2e9281" }}>
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: 30, position: "relative", left: "4.5vw", top: "0.5vw" }} textAlign="center" >
                            {no_data === 1 ? (line_monitoring.LINE == 'NA' ? 'Line7' : line_monitoring.LINE) : 'Line7'}
                        </Typography>
                    </Box>
                    <Box sx={{ width: '80%', bgcolor: colors.while[100], display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", paddingLeft: 0, top: "-1.5vw" }} textAlign="center" >
                        <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 30, position: "relative", paddingLeft: 5 }}>
                            SKU :&nbsp;
                        </Typography>
                        <Typography style={{ color: "#4AC6B1", fontWeight: "bold", fontSize: 30 }}>
                            {no_data === 1 ? (line_monitoring.WARNING == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : line_monitoring.WARNING == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? line_monitoring.SKU : line_monitoring.SKU) : (sessionStorage.getItem('warning')== 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : sessionStorage.getItem('warning') === 'ไม่มีข้อมูล Std. Cap ในระบบ' ? sessionStorage.getItem('sku') : sessionStorage.getItem('sku'))}
                        </Typography>
                        <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 30, position: "relative", paddingLeft: 0 }}>
                            &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;{timestr}&nbsp;&nbsp;{timestrs}
                        </Typography>
                        <IconButton onClick={colorMode.toggleColorMode} style={{ position: "relative", top: "-0.5vw" }}>
                            {line_monitoring.SHIFT === "N" ? (
                                <DarkModeOutlinedIcon />
                            ) : (
                                <LightModeOutlinedIcon />
                            )}
                        </IconButton>
                    </Box>
                </Grid>



                <Grid id="top-row" container sx={{ pt: 0 }} spacing={5}>
                    <Box sx={{ width: '100%', height: "72%", bgcolor: "#bcd6d0", display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "2vw" }} textAlign="center" >
                        <Typography sx={{ display: 'relative', pl: 6 }}>
                            <img src={Time} height="70px" style={{ position: "relative", top: "1vw" }} />
                        </Typography>
                        <table style={{ width: '70%', position: "relative", top: "-1vw", left: "0vw" }}>
                            <tbody>
                                <tr >
                                    <td style={{ width: '100%', color: "#000000", fontWeight: "bold", fontSize: 20, position: "relative", top: "2.5vw" }} align="center">
                                        Start
                                    </td>
                                </tr>
                                <tr >
                                    <td style={{ width: '30%', color: "#000000", fontWeight: "bold", fontSize: 30 }} align="center">
                                        {no_data === 1 ? (line_monitoring.START_DATETIME == 'NA' ? '-' : moment(line_monitoring.START_DATETIME).format('HH:mm')) : (sessionStorage.getItem('start_time') == 'NA' || sessionStorage.getItem('start_time') == null ? '-' : moment(sessionStorage.getItem('start_time')).format('HH:mm'))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table style={{ width: '70%', position: "relative", left: "-2.5vw", top: "-1vw" }}>
                            <tbody>
                                <tr >
                                    <td style={{ width: '100%', color: "#000000", fontWeight: "bold", fontSize: 20, position: "relative", top: "2.5vw" }} align="center">
                                        End
                                    </td>
                                </tr>
                                <tr >
                                    <td style={{ width: '30%', color: line_monitoring.FINISH_DATETIME == 'NA' && sessionStorage.getItem('finish_time') == 'NA' ? "#636363" : "#000000", fontWeight: "bold", fontSize: 30 }} align="center">
                                        {no_data === 1 ? (line_monitoring.FINISH_DATETIME == 'NA' ? line_monitoring.FORECAST_FINISH_PRODUCTION_TIME == 'NA' || line_monitoring.FORECAST_FINISH_PRODUCTION_TIME == null ? '-' : moment(line_monitoring.FORECAST_FINISH_PRODUCTION_TIME).format('HH:mm') : moment(line_monitoring.FINISH_DATETIME).format('HH:mm')) : (sessionStorage.getItem('finish_time') == null ? '-' : sessionStorage.getItem('finish_time') == 'NA' ? sessionStorage.getItem('forecast_finish_production_time') == 'NA' || sessionStorage.getItem('forecast_finish_production_time') == 'null' ? '-' : moment(sessionStorage.getItem('forecast_finish_production_time')).format('HH:mm') : moment(sessionStorage.getItem('finish_time')).format('HH:mm'))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <Box sx={{ display: 'relative', pl: 0, height: 45, borderRight: "3px dashed #3cbea8" }} style={{ position: "relative", left: "-3vw" }}></Box>

                        <Grid item xs={8} style={{ position: "relative", left: "-1.2vw", top: "15.5vw" }} textAlign="center">
                            <Grid item xs={12} textAlign="center">
                                <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 20, position: "relative", top: "-14vw", }} >
                                    Production
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center" style={{ position: "relative", top: "-16vw" }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                    <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 32 }} >
                                        {no_data === 1 ? (line_monitoring.PRODUCTION_TIME == 'NA' ? "-" : production_hour) : production_hour_storage}
                                    </Typography>
                                    <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 24, position: "relative", top: "0.2vw" }} >
                                        hr
                                    </Typography>
                                    <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 32 }} >
                                        {no_data === 1 ? (line_monitoring.PRODUCTION_TIME == 'NA' ? "-" : production_minute) : production_minute_storage}
                                    </Typography>
                                    <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 24, position: "relative", top: "0.2vw" }} >
                                        min
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box sx={{ display: 'relative', pl: 3, height: 45, borderRight: "3px dashed #3cbea8" }} style={{ position: "relative", left: "-3vw" }}></Box>



                        <Grid item xs={8} style={{ position: "relative", left: "-1.5vw", top: "15.5vw" }} textAlign="center">
                            <Grid item xs={12} textAlign="center">
                                <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 20, position: "relative", top: "-14vw", }} >
                                    Non-Production
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center" style={{ position: "relative", top: "-16vw" }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                    <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 32 }} >
                                        {no_data === 1 ? (line_monitoring.NON_PRODUCTION_TIME == 'NA' ? '-' : non_production_hour) : non_production_hour_storage}
                                    </Typography>
                                    <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 24, position: "relative", top: "0.2vw" }} >
                                        hr
                                    </Typography>
                                    <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 32 }} >
                                        {no_data === 1 ? (line_monitoring.NON_PRODUCTION_TIME == 'NA' ? '-' : non_production_minute) : non_production_minute_storage}
                                    </Typography>
                                    <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 24, position: "relative", top: "0.2vw" }} >
                                        min
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                </Grid>

                <Grid direction="rows" item xs={12} container style={{ position: "relative", top: "-1.8vw", left: "-1vw" }} spacing={0}>
                    <Item sx={{ background: colors.while[100], border: "3px solid #4a4a49" }}>
                        <Grid container >
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={6} style={{ position: "relative", left: "4vw", top: '-1vw' }}>
                                        <table>
                                            <tbody>
                                                <tr >
                                                    <td style={{ width: '30%', fontSize: 14, fontWeight: 'bold,', fontFamily: "CPF_Imm_Sook" }} align="right">
                                                        {accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Fighting} height="35px" /> : <img src={Fist} height="35px" />) : <img src={Fist} height="35px" />}
                                                    </td>
                                                    <td style={{ width: '20%', color: no_data == 1 ? getChartColor() : getChartColorOld(), fontSize: 30, fontWeight: 'bold' }} align="right">
                                                        {no_data === 1 ? line_monitoring.ACTUAL_WEIGHT_PERCENT : sessionStorage.getItem('actual_weight_percent')}
                                                    </td>
                                                    <td style={{ width: '20%', color: no_data == 1 ? getChartColor() : getChartColorOld(), fontSize: 30, fontWeight: 'bold' }} align="left">
                                                        %
                                                    </td>
                                                </tr>

                                                <tr >
                                                    <td style={{ width: '30%', color: colors.black[100], fontSize: 25, fontWeight: 'bold', position: "relative", top: "-3vw" }} align="right">
                                                        Actual
                                                    </td>
                                                    <td style={{ width: '20%', color: no_data == 1 ? getChartColor() : getChartColorOld(), fontSize: 35, fontWeight: 'bold', position: "relative", top: "-3.4vw" }} align="right">
                                                        {no_data === 1 ? (line_monitoring.ACTUAL_WEIGHT).toLocaleString() : Number(sessionStorage.getItem('actual_weight')).toLocaleString()}
                                                    </td>
                                                    <td style={{ width: '20%', color: colors.black[100], fontSize: 20, fontWeight: 'bold', position: "relative", top: "-2.9vw" }} align="left">
                                                    kg
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </Grid>
                                    <Grid item xs={6} style={{ position: "relative", left: "3vw", top: '-1vw' }}>
                                        <table style={{ width: '150%' }}>
                                            <tbody>
                                                <tr >
                                                    <td style={{ width: '30%', fontSize: 14, fontWeight: 'bold,', position: "relative", top: "0.5vw" }} align="right">
                                                        {accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Run} height="35px" /> : <img src={Running} height="35px" />) : <img src={Running} height="35px" />}
                                                    </td>
                                                    <td style={{ width: '20%', color: colors.grey[100], fontSize: 30, fontWeight: 'bold' }} align="right">
                                                        {no_data === 1 ?(line_monitoring.ACTUAL_WEIGHT_PERCENT === 'NA' ? 'NA' :(100 - line_monitoring.ACTUAL_WEIGHT_PERCENT <= 0 ? 0 : 100 - line_monitoring.ACTUAL_WEIGHT_PERCENT)) : (sessionStorage.getItem('actual_weight_percent') === 'NA' ? 'NA' : (100 - Number(sessionStorage.getItem('actual_weight_percent')) <= 0 ? 0 : 100 - Number(sessionStorage.getItem('actual_weight_percent'))))}
                                                    </td>
                                                    <td style={{ width: '20%', color: colors.grey[100], fontSize: 30, fontWeight: 'bold' }} align="left">
                                                        %
                                                    </td>
                                                </tr>

                                                <tr >
                                                    <td style={{ width: '40%', color: colors.black[100], fontSize: 25, fontWeight: 'bold', position: "relative", top: "-3vw" }} align="right">
                                                        Diff.
                                                    </td>
                                                    <td style={{ width: '20%', color: colors.grey[100], fontSize: 35, fontWeight: 'bold', position: "relative", top: "-3.4vw" }} align="right">
                                                        {no_data === 1 ? (line_monitoring.REMAINING).toLocaleString() : Number(sessionStorage.getItem('remaining')).toLocaleString()}
                                                    </td>
                                                    <td style={{ width: '20%', color: colors.black[100], fontSize: 20, fontWeight: 'bold', position: "relative", top: "-2.9vw" }} align="left">
                                                    kg
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} style={{ position: "relative", top: "-5vw" }}>
                                    <Typography style={{ color: "#ffffff", fontSize: "16px", fontFamily: 'Monospace', position: "relative", left: '1vw', top: "0px" }} textAlign="left">
                                        {(line_monitoring.TARGET ===
                                            ''
                                            && line_monitoring.ACTUAL_WEIGHT ===
                                            ''
                                            && line_monitoring.PRODUCTIVITY_TARGET_NOW ===
                                            ''
                                            && line_monitoring.PRODUCTIVITY_TARGET_NOW_PERCENT ===
                                            ''
                                        ) || (line_monitoring.TARGET ===
                                            'NA'
                                            && line_monitoring.ACTUAL_WEIGHT ===
                                            'NA'
                                            ) ?
                                            <BarChartTargetLine7
                                                target_quantity={0}
                                                actual_weight={0}
                                                target_now={0}
                                                persent={0}
                                                //chart_color={getChartColor()}
                                                position={40}
                                                fontsize={24}
                                                width_svg={18}
                                                height_svg={18}
                                                height='55%'
                                            />
                                            : <BarChartTargetLine7
                                                target_quantity={line_monitoring.TARGET === 'NA' ? 0 : line_monitoring.TARGET}
                                                actual_weight={line_monitoring.ACTUAL_WEIGHT === 'NA' ? 0 : line_monitoring.ACTUAL_WEIGHT}
                                                target_now={line_monitoring.PRODUCTIVITY_TARGET_NOW === 'NA' ? 0 : line_monitoring.PRODUCTIVITY_TARGET_NOW}
                                                persent={line_monitoring.PRODUCTIVITY_TARGET_NOW_PERCENT === 'NA' ? 0 : line_monitoring.PRODUCTIVITY_TARGET_NOW_PERCENT}
                                                chart_color={getChartColor()}
                                                position={line_monitoring.PRODUCTIVITY_TARGET_NOW_PERCENT === 'NA' ? 0 : (line_monitoring.PRODUCTIVITY_TARGET_NOW_PERCENT <= 50 ? 40 : -60)}
                                                fontsize={24}
                                                width_svg={18}
                                                height_svg={18}
                                                height='55%'
                                            />
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={4.5} style={{ position: "relative", top: no_data === 1 ? (line_monitoring.TARGET == 'NA' ? '3vw' : "3vw") : "3vw", left: "14vw" }}>
                                <Grid item xs={12} textAlign="center" style={{ position: "relative", top: "0vw" }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                        <img src={Target} height="40px" />
                                        <Typography style={{ color: "#2e9281", fontWeight: "bold", fontSize: 35, position: "relative", top: "0vw" }}>
                                            Target
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} textAlign="center" style={{ position: "relative", top: "-2.8vw", left: "0.5vw" }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                        <Typography style={{ color: "#2e9281", fontSize: 38, fontWeight: 'bold' }}>
                                            {no_data === 1 ? (line_monitoring.TARGET).toLocaleString() : Number(sessionStorage.getItem('target')).toLocaleString()}
                                        </Typography>
                                        <Typography style={{ color: "#2e9281", fontSize: 25, fontWeight: 'bold', position: "relative", top: "0.3vw" }}>
                                            &nbsp;kg
                                        </Typography>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Item>
                </Grid>

                <Grid container spacing={2} >
                    <Grid item xs={8} style={{ position: "relative", left: "-3vw", top: "0vw", height: '0px' }} textAlign="center">
                        <Grid item xs={12} textAlign="center">
                            <Typography style={{ color: colors.black[100], fontSize: 25, position: "relative", top: "-4vw", }} >
                                Performance ({no_data === 1 ? (line_monitoring.STD_FEED_RATE == "ไม่มีข้อมูล Std. Cap ในระบบ" ? (line_monitoring.STD_FEED_RATE).toLocaleString() : "Std." + (line_monitoring.STD_FEED_RATE).toLocaleString()) : (sessionStorage.getItem('std_feed_rate') == "ไม่มีข้อมูล Std. Cap ในระบบ" ? sessionStorage.getItem('std_feed_rate') : "Std." + sessionStorage.getItem('std_feed_rate'))})
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center" style={{ position: "relative", top: "-7vw" }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: no_data == 1 ? getFeedrateColor() : getFeedrateOldColor(), fontSize: 38, fontWeight: 'bold' }} >
                                    {no_data === 1 ? (line_monitoring.PRODUCTION_EFFICIENCY == 'NA' ? 'NA' : (Math.round(line_monitoring.PRODUCTION_EFFICIENCY)).toLocaleString()) : sessionStorage.getItem('production_efficiency')}
                                </Typography>
                                <Typography style={{ color: colors.black[100], fontSize: 25, position: "relative", top: "0.5vw" }} >
                                    &nbsp;kg/hr
                                </Typography>
                                <Typography style={{ color: colors.black[100], fontSize: 30, position: "relative", top: "0vw" }} >
                                    &nbsp;(Realtime { accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? (no_data == 1 ? (line_monitoring.ACTUAL_FEED_RATE).toLocaleString() : sessionStorage.getItem('actual_feed_rate')) : 0 ) : 0})
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={4} style={{ position: "relative", top: "0vw", left: "-4vw", height: '0px' }}>
                        <Grid item xs={12} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 25, position: "relative", top: "-4vw" }}>
                                Forecast
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center" style={{ position: "relative", top: "-6vw" }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: colors.black[100], fontSize: 30, fontWeight: 'bold' }} >
                                    {no_data === 1 ? (line_monitoring.FORECAST_TOTAL_PRODUCTION).toLocaleString() : sessionStorage.getItem('forecast_total_production')}
                                </Typography>
                                <Typography style={{ color: colors.black[100], fontSize: 20, fontWeight: 'bold', position: "relative", top: "0.3vw" }} >
                                    &nbsp;kg
                                </Typography>
                                <Typography style={{ color: no_data == 1 ? getForecastColor() : getForecastOldColor(), fontWeight: "bold", fontSize: 25, position: "relative", top: "0.1vw" }} >
                                    &nbsp;({no_data === 1 ? (line_monitoring.OPPORTUNITY_COST_OF_PRODUCTION >= 0 ? "+" + (line_monitoring.OPPORTUNITY_COST_OF_PRODUCTION).toLocaleString() : (line_monitoring.OPPORTUNITY_COST_OF_PRODUCTION).toLocaleString()) : sessionStorage.getItem('opportunity_cost_of_production')})
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}


