import React, { useEffect, useState } from "react";
import { apiUrl } from "constants/index";
import { Box, Grid, Card } from "@mui/material";
import { Helmet } from "react-helmet";
import moment from 'moment-timezone';
import axios from 'axios';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';


var token_url = apiUrl.TOKEN;



function Multihead() {
  const today = new Date();
  const hourago = new Date(today.getTime());
  const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD'));
  const [getDataFromMultihead, setGetDataFromMultihead] = useState([]);
  const [openPopupFinshMultihead, setOpenPopupFinshMultihead] = useState();
  const [finishMultiheadCheckOn, setFinishMultiheadCheckOn] = useState();
  const [finishMultihead, setFinishMultihead] = useState(false);
  const [notifyFinishMultiheadSuccessful, setNotifyFinishMultiheadSuccessful] = useState(false);
  const [notifyFinishMultiheadFailed, setNotifyFinishMultiheadFailed] = useState(false);

  const [openEditStatus, setOpenEditStatus] = useState();
  const [multiheadCheckOnEditStatus, setMultiheadCheckOnEditStatus] = useState();
  const [openMultiheadSelection, setOpenMultiheadSelection] = React.useState(false);
  const [multiheadHistorySelectedStatus, setMultiheadHistorySelectedStatus] = useState('');
  const [editStatus, setEditStatus] = useState(false);
  const [notifyEdittedStatusSuccessful, setNotifyEdittedStatusSuccessful] = useState(false);
  const [notifyEdittedStatusFailed, setNotifyEdittedStatusFailed] = useState(false);

  const handleClickPopupFinshMultihead = () => {
    setOpenPopupFinshMultihead(true);
  };

  const handleClosePopupFinshMultihead = () => {
    setOpenPopupFinshMultihead(false);
  };

  const handleOpenEditStatus = () => {
    setOpenEditStatus(true);
  };

  const handleCloseEditStatus = () => {
    setOpenEditStatus(false);
  };

  const selectStatusMultihead = [
    {
      value: "Completed",
      label: "Completed"
    },
    {
      value: "Wip,Reject",
      label: "Wip,Reject"
    },
  ];

  const handleCloseMultiheadSelection = () => {
    setOpenMultiheadSelection(false);
  };

  const handleOpenMultiheadSelection = () => {
    setOpenMultiheadSelection(true);
  };

  const handleStatusGetCheckOnForEdition = async () => {
    var get_tumbler_url = `${apiUrl.GET_TUMBLER_AT_MULTIHEAD}${date}/`
    await axios.get(get_tumbler_url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        //console.log(multiheadCheckOnEditStatus.STATUS)
        if (response.status == 200) {
          setMultiheadHistorySelectedStatus(multiheadCheckOnEditStatus.STATUS)
        }
        else {
          console.log(' ')
        }
      })
      .catch(error => {
        console.log('inserting status error: ', error);
      })
  };

  useEffect(() => {
    handleStatusGetCheckOnForEdition();
  }, [multiheadCheckOnEditStatus, finishMultiheadCheckOn])

  /*useEffect(() => {
    console.log('');
  }, [finishMultiheadCheckOn])*/




  // ฟังก์ชั่นเช็คtoken
  const checkToken = async () => {
    let access_exp = localStorage.getItem("rb_ac_exp");
    let refresh_exp = localStorage.getItem("rb_rf_exp");
    //console.log('access_exp: ' + access_exp);
    //console.log('refresh_exp: ' + refresh_exp);
    if (refresh_exp < Date.now() / 1000) {
      //console.log("refresh is expiried");
      // alert('Please log in again')
      localStorage.clear();
      window.location = "/Login";
    } else if (access_exp < Date.now() / 1000) {
      const refresh = localStorage.getItem("rb_rf_token");

      let data = {
        refresh: refresh,
      };
      let result = await axios.post(token_url, data);
      //console.log("got new access_token!");
      localStorage.setItem("rb_ac_token", result.data.access);
    }
  };

  useEffect(() => {
    async function fetchMyAPI() {
    // check access token expiry
    checkToken();
    //console.log('get_allkorat ------>' + `${apiUrl.GET_TUMBLER_AT_MULTIHEAD}${date}/`)
    var get_datakorat = `${apiUrl.GET_TUMBLER_AT_MULTIHEAD}${date}/` // date format must be YYYY-MM-DD, eg. 2022-06-16
    await axios.get(get_datakorat, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    }
    )
      .then(response => {
        //console.log(response.data.filter(i => i.MULTIHEAD_CHECK_ON !== " "))
        if (response.status == 200) {
          setGetDataFromMultihead(response.data.filter(i => i.MULTIHEAD_CHECK_ON !== " "))
          setLoading(1)
        }
      })
      .catch(error => {
        setLoading(2)
        console.log('error: ', error)

      })
    }
    fetchMyAPI()

  }, [getDataFromMultihead, date]);

  //console.log(getDataFromMultihead,timestrs);



  const handleFinishMultihead = async () => {
    /*console.log({
      "status": "Completed",
      "MATERIAL_DESCRIPTION": finishMultiheadCheckOn.MATERIAL_DESCRIPTION,
      "MULTIHEAD_CHECK_ON": finishMultiheadCheckOn.MULTIHEAD_CHECK_ON

    })
    console.log(`${apiUrl.UPDATE_STATUS_AT_MULTIHEAD}${finishMultiheadCheckOn.MULTIHEAD_CHECK_ON}/`)*/
    await axios.put(`${apiUrl.UPDATE_STATUS_AT_MULTIHEAD}${finishMultiheadCheckOn.MULTIHEAD_CHECK_ON}/`, {
      "status": "Completed"
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          setFinishMultihead(1)
          setNotifyFinishMultiheadSuccessful(true)
        }
        else {
          setFinishMultihead(2)
          setNotifyFinishMultiheadFailed(true)
        }
      })
      .catch(error => {
        console.log('finish multihead error: ', error);
      })
  }

  const handleEditionInStatus = (event) => {
    if (event.target.name === "status") {
      setMultiheadHistorySelectedStatus(event.target.value);
    }
  };


  const handleEditStatus = async () => {
    /*console.log({
      "status": multiheadHistorySelectedStatus,
      "check_on": multiheadCheckOnEditStatus.TUMBLER_CHECK_ON,
      "multihead": multiheadCheckOnEditStatus.MULTIHEAD_CHECK_ON
    })
    console.log(`${apiUrl.EDIT_TUMBLER}${multiheadCheckOnEditStatus.TUMBLER_CHECK_ON}/`)*/
    await axios.put(`${apiUrl.EDIT_TUMBLER}${multiheadCheckOnEditStatus.TUMBLER_CHECK_ON}/`, {
      "status": multiheadHistorySelectedStatus,
      "check_on": multiheadCheckOnEditStatus.TUMBLER_CHECK_ON,
      "multihead": multiheadCheckOnEditStatus.MULTIHEAD_CHECK_ON
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          setMultiheadHistorySelectedStatus();
          setEditStatus(1)
          setNotifyEdittedStatusSuccessful(true)
        }
        else {
          setEditStatus(2)
          setNotifyEdittedStatusFailed(true)
        }
      })
      .catch(error => {
        console.log('edit status error: ', error);
      })
  }

  const categories = {};
  for (const item of getDataFromMultihead) {
    if (item.MATERIAL_DESCRIPTION in categories) {
      categories[item.MATERIAL_DESCRIPTION].push(item);
    } else {
      categories[item.MATERIAL_DESCRIPTION] = [item];
    }
  }

  return (
    <>
      <Helmet>
        <title>Multihead | MES Korat Fur2</title>
      </Helmet>
      <Grid
        container spacing={1}
        direction="row"
        alignItems="top"
        justifyContent="center"
        style={{ minHeight: '78vh', maxHeight: '100vh', flexGrow: 1, paddingLeft: 25, paddingTop: 75 }}
      >
        {/* Top row */}
        <Grid id="top-row" container sx={{ pt: 2.2 }} spacing={1}>

          <Grid xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={1}>
                <DatePicker
                  //style={{width:300}}
                  inputFormat="DD/MM/YYYY"
                  //label="Select date"
                  value={date}
                  onChange={(newValue) => {
                    //////Get React DatePicker date value in onChange(https://stackoverflow.com/questions/70182747/get-react-datepicker-date-value-in-onchange)
                    const d = new Date(newValue);
                    const formatted = moment(d).tz('Asia/Bangkok').format('YYYY-MM-DD');
                    setDate(formatted)
                    //console.log("date: " + formatted);
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: '100%',
                        svg: { color: '#000000' },
                        "& .MuiOutlinedInput-input": {
                          color: "#000000",
                          fontFamily: 'CPF_Imm_Sook',
                          fontWeight: "bold",
                          fontSize: 16
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>

          {loading === 1 ?
          <Grid xs={12}>
            <Box mb={35}>
              <div>
                {Object.entries(categories).map((entry) => {
                  const category = entry[0];
                  const itemList = entry[1];
                  return (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{fontSize: "16px", fontWeight: 'bold' }}>{category}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/*sort array of objects by string property value(https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value comment 952) */}
                        {itemList.sort((a, b) => b.STATUS.localeCompare(a.STATUS)).map((item, index) => (
                          <Card sx={{
                            p: 1,
                            margin: 'auto',
                            //maxWidth: 1500,
                            flexGrow: 1,
                            backgroundColor: (theme) =>
                              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                          }}>
                            <Grid item xs={12} sm container>
                              <Grid item xs container direction="column" spacing={2} style={{ position: "relative", top: "5px" }}>
                                <Grid item xs>
                                  <Typography style={{fontSize: "16px"}} gutterBottom>
                                    {index + 1}.{item.MATERIAL_DESCRIPTION}
                                  </Typography>
                                  {/*<Typography variant="body2" fontFamily='Monospace' gutterBottom >
                                    LOT:&nbsp;&nbsp;SUBLOT:
                                  </Typography>*/}
                                  <Typography style={{fontSize: "16px"}} gutterBottom>
                                    {item.AGE}
                                  </Typography>
                                  <Typography style={{fontSize: "15px"}} gutterBottom>
                                    {Math.round(item.WEIGHT)} kg.
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  {/*<Button variant="contained" disabled={item.STATUS == 'Completed'} style={{ fontSize: '12px', fontFamily: 'Monospace', width: '80px', fontWeight: 'bold' }} color="success" onClick={() => { setFinishMultiheadCheckOn({ MULTIHEAD_CHECK_ON: item.MULTIHEAD_CHECK_ON, STATUS: item.STATUS, MATERIAL_DESCRIPTION: item.MATERIAL_DESCRIPTION }); handleClickPopupFinshMultihead();}}>
                                    Finish
                                  </Button>*/}
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Tooltip title="edit status" placement="top-start">
                                  <Button style={{ color: "black", fontWeight: 'bold', fontSize: '14px' }} onClick={() => { setMultiheadCheckOnEditStatus({ TUMBLER_CHECK_ON: item.TUMBLER_CHECK_ON, MULTIHEAD_CHECK_ON: item.MULTIHEAD_CHECK_ON, STATUS: item.STATUS }); handleOpenEditStatus(); }}>
                                    {item.STATUS}&nbsp;
                                    <ModeEditIcon style={{ fontSize: '14px' }} ></ModeEditIcon>
                                  </Button>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Card>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            </Box>
          </Grid>
          : console.log()
        }

        {loading === 2 ?
          <Grid xs={12}>
            <Box mb={35} >
            <Typography style={{ color: "black", fontWeight: "bold", fontSize: "20px"}} variant="button" >
              No Data
            </Typography>
            </Box>
          </Grid>
          : console.log()
        }
        </Grid>
      </Grid>

      {/* pop up finish multihead */}
      <Dialog
        open={openPopupFinshMultihead}
        onClose={handleClosePopupFinshMultihead}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you went to finish product
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenPopupFinshMultihead(false); }}>Cancel</Button>
          <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenPopupFinshMultihead(false); handleFinishMultihead(); }}>Confirm</Button>
        </DialogActions>
      </Dialog>

      {/* pop up edit status */}
      <Dialog
        open={openEditStatus}
        onClose={handleCloseEditStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ position: "relative", top: "-5px", left: "-12px"}}>
          Edit Status
        </DialogTitle>
        <DialogContentText id="alert-dialog-description">
          <FormControl sx={{ m: 1, minWidth: '90%' }}>
            <InputLabel fontFamily='Monospace'>Select Status</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={openMultiheadSelection}
              onClose={handleCloseMultiheadSelection}
              onOpen={handleOpenMultiheadSelection}
              value={multiheadHistorySelectedStatus}
              label="Select Status"
              onChange={handleEditionInStatus}
              name="status"
              style={{ fontFamily: 'Monospace' }}
            >
              {selectStatusMultihead.map((item) => (
                <MenuItem value={item.value}>{item.value}</MenuItem>
              ))

              }
            </Select>
          </FormControl>
        </DialogContentText>
        <DialogActions>
          <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenEditStatus(false); }}>Cancel</Button>
          <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenEditStatus(false); handleEditStatus(); }}>Save</Button>
        </DialogActions>
      </Dialog>


      {/*show pop up success finish multihead*/}
      {finishMultihead === 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyFinishMultiheadSuccessful}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successfully finish product
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} variant="contained" onClick={() => { setNotifyFinishMultiheadSuccessful(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }
      {finishMultihead === 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyFinishMultiheadFailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed to finish product, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} aligh='Center' variant="contained" onClick={() => { setNotifyFinishMultiheadFailed(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }

      {/*show pop up success edit status*/}
      {editStatus === 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyEdittedStatusSuccessful}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successfully editted status
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} variant="contained" onClick={() => { setNotifyEdittedStatusSuccessful(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }
      {editStatus === 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyEdittedStatusFailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed to edit status, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ backgroundColor: '#009688', borderColor: '#009688'}} aligh='Center' variant="contained" onClick={() => { setNotifyEdittedStatusFailed(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }


    </>
  );
}

export default Multihead;