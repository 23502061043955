import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import { Box, Grid } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';


export default function AppbarMarinationTracking(props) {
    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');
    const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');

    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
          setTime(new Date());
        }, 1000);
    
        return () => clearInterval(interval);
      }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav">
                <Toolbar variant="string" sx={{height: '33px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography
                                style={{ color: "#2e9281", fontWeight: "bold", fontSize: 30, position: "relative", left: "-1vw" }}
                            >
                                Marination Tracking
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Typography
                                    style={{ color: "#ffffff", fontWeight: "bold", fontSize: 30 }}
                                    textAlign="right"
                                >
                                    {timestr}&nbsp;&nbsp;{time.toLocaleTimeString('en-GB')}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

        </Box>
    );
}
