import React, { useEffect, useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import { Box, Grid, Card, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Warning from 'assets/images/warning.png';
import axios from 'axios';
import { apiUrl } from "constants";
import Disconnected from 'assets/images/disconnected.png';
import db from "firebase-config";







export default function PopupHeatingYield() {
    const [warning_heating_yield, setWarning_heating_yield] = useState([]);
    const [keeplive, setkeeplive] = useState([]);




    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("line_monitoring_heating_yield")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setWarning_heating_yield(getPostFromFirebase);

            });

        // return cleanup function
        return () => subscriber();
    }, [warning_heating_yield]);

    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_keeplive")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setkeeplive(getPostFromFirebase);

            });

        // return cleanup function
        return () => subscriber();
    }, [keeplive]);

    const warning = warning_heating_yield == undefined ? '' : warning_heating_yield.find((x) => x.id.includes("4117.00000000000000.Line7WARNING"));


    let arrToObjectWarning = Object.keys(warning?.SAP_SKU == undefined ? [] : warning?.SAP_SKU).map(key => {
        return { value: warning?.SAP_SKU[key] }
    });


    let cooking = arrToObjectWarning.some(v => v.value === (warning?.BELT_SCALE_FRYER_PRODUCT_NAME == undefined ? '-' : warning?.BELT_SCALE_FRYER_PRODUCT_NAME));

    let fryer = arrToObjectWarning.some(v => v.value === (warning?.FRYER_PRODUCT_NAME == undefined ? '-' : warning?.FRYER_PRODUCT_NAME));

    let cooling = arrToObjectWarning.some(v => v.value === (warning?.BELT_SCALE_PRODUCT_NAME == undefined ? '-' : warning?.BELT_SCALE_PRODUCT_NAME));


    const status_cooking = keeplive == undefined ? '' : keeplive.find((x) => x.ID.includes("Further.4117.Belt_Scale.Belt_Scale_Fryer.Line_7.0NoError"));

    const status_fryer = keeplive == undefined ? '' : keeplive.find((x) => x.ID.includes("Further.4117.Fryer.EFRB5107.Line7.MachineState"));

    const status_cooling = keeplive == undefined ? '' : keeplive.find((x) => x.ID.includes("Further.4117.Belt_Scale.Belt_Scale_1.Line_7.0NoError"));














    return (
        <div>
            <Backdrop
                sx={{ backgroundColor: "rgb(0, 0, 0, 0.85)", zIndex: (theme) => theme.zIndex.drawer + 1 }}

                open
            >
                <Grid
                    container spacing={1}
                    direction="row"
                    alignItems="top"
                    justifyContent="center"
                    style={{ minHeight: '60vh', maxHeight: '0vh', flexGrow: 1, paddingLeft: 25, paddingTop: 0 }}
                >

                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{ position: "relative", top: '-10vw', left: '0vw', height: '1px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <img src={Warning} height="35px" />
                            <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                                Online Status & Preset Setting
                            </Typography>
                        </Box>
                    </Grid>


                    <Grid container spacing={2} style={{ position: "relative", top: '-8vw', left: '0vw' }}>
                        <Grid id="top-row" container xs={8} md={8} lg={8} xl={8} style={{ position: "relative", padding: 3 }}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4vw', height: '12px' }}>
                                <Typography style={{ color: '#ffffff', fontSize: 25, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                    Machine
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ border: "2px dashed #4A4A49", borderRadius: 20, height: '170%' }}>

                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '1vw', height: '12px' }}>

                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="left" >
                                        <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '5%' }} >
                                            {status_cooking?.VALUE == undefined ? '' : ( status_cooking?.VALUE == false ? <img src={Disconnected} height="25px" /> : '')}
                                        </Typography>
                                        <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                            &nbsp;&nbsp;Belt Scale Cooking:
                                        </Typography>
                                        <Typography style={{ color: cooking == true ? '#2e9281' : '#8c0e0e', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                            &nbsp;{warning?.BELT_SCALE_FRYER_PRODUCT_NAME == undefined ? '-' : warning?.BELT_SCALE_FRYER_PRODUCT_NAME}
                                        </Typography>
                                    </Box>



                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="left" >
                                        <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '5%' }} >
                                            {status_fryer?.IS_CONNECTED == undefined ? '' : ( status_fryer?.IS_CONNECTED == false ? <img src={Disconnected} height="25px" /> : '')}
                                        </Typography>
                                        <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                            &nbsp;&nbsp;Fryer:
                                        </Typography>
                                        <Typography style={{ color: fryer == true ? '#2e9281' : '#8c0e0e', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                            &nbsp;{warning?.FRYER_PRODUCT_NAME == undefined ? '-' : warning?.FRYER_PRODUCT_NAME}
                                        </Typography>
                                    </Box>



                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="left" >
                                        <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '5%' }} >
                                            {status_cooling?.VALUE == undefined ? '' : ( status_cooling?.VALUE == false ? <img src={Disconnected} height="25px" /> : '')}
                                        </Typography>
                                        <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                            &nbsp;&nbsp;Belt Scale Cooling:
                                        </Typography>
                                        <Typography style={{ color: cooling == true ? '#2e9281' : '#8c0e0e', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                            &nbsp;{warning?.BELT_SCALE_PRODUCT_NAME == undefined ? '-' : warning?.BELT_SCALE_PRODUCT_NAME}
                                        </Typography>
                                    </Box>

                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid id="top-row" container xs={4} md={4} lg={4} xl={4} style={{ position: "relative", padding: 3 }}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4vw', height: '12px' }}>
                                <Typography style={{ color: '#ffffff', fontSize: 25, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                    PO
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ border: "2px dashed #4A4A49", borderRadius: 20, height: '170%' }}>
                                <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '3.3vw' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-2vw', height: '12px' }}>
                                        {warning?.SAP_SKU == undefined ? '' :
                                            arrToObjectWarning.map((item) => {
                                                const getColor = () => {
                                                    let color;
                                                    try {
                                                        if ((item.value == warning?.BELT_SCALE_FRYER_PRODUCT_NAME) || (item.value == warning?.FRYER_PRODUCT_NAME) || (item.value == warning?.BELT_SCALE_PRODUCT_NAME)) {
                                                            color = "#2e9281"
                                                        } else
                                                            color = "#ffffff"
                                                        return color;
                                                    }
                                                    catch {
                                                        console.log()
                                                    }
                                                }


                                                return (
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: getColor(), fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '100%' }} >
                                                            {item.value}
                                                        </Typography>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Backdrop>
        </div>
    );
}