import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import SettingsPassword from '../components/setting/SettingsPassword';

const Resetpw = () => (
  <>
    <Helmet>
      <title>Settings | MES Korat Fur2</title>
    </Helmet>
      <Container maxWidth="xl">
        <Box sx={{ pt: 0, pl: 2 }}>
          <SettingsPassword />
        </Box>
      </Container>
  </>
);

export default Resetpw;
