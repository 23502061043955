import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from "@mui/material";
import Chart from 'react-apexcharts';

function OEE(props) {
    const getColor = () => {
        let color;
        try {
            if (props.value >= 85) {
                color = "#4caf50"
            } else if (props.value >= 75 && props.value <= 84.99) {
                color = "#ffeb3b"
            } else if (props.value < 75) {
                color = "#e53935"
            }
            return color;
        }
        catch {
            console.log()
        }
    }
    return (
        <>
            <Typography color="black" fontSize={'20px'} fontWeight="bold" align='left'>
                {props.title}
            </Typography>
            <Box sx={{ display: 'block' }}>
                <Chart
                    options={{
                        chart: {
                            type: 'radialBar',
                            offsetY: -20,
                            offsetX: -40,
                            parentHeightOffset: 0,
                            toolbar: {
                                show: false
                            }
                        },
                        plotOptions: {
                            radialBar: {
                                hollow: {
                                    margin: 0,
                                    size: "60%",
                                },

                                dataLabels: {
                                    showOn: "always",
                                    name: {
                                        offsetY: 20,
                                        show: true,
                                        color: "#888",
                                        fontSize: "17px",
                                        fontFamily: 'CPF_Imm_Sook'
                                    },
                                    value: {
                                        offsetY: -10,
                                        color: "#111",
                                        fontSize: "42px",
                                        fontFamily: 'CPF_Imm_Sook',
                                        show: true
                                    }
                                }
                            }
                        },
                        colors: [getColor()],
                        labels: ['Target 85%'],
                        fill: {
                            type: "gradient",
                            gradient: {
                                shade: "dark",
                                type: "vertical",
                                gradientToColors: [getColor()],
                                stops: [0, 100]
                            }
                        },
                    }}
                    series={props.value != 'NA' ? props.value : 0}
                    type="radialBar"
                    width="130%"
                    height="130%"
                />
            </Box>
        </>
    )
}

OEE.propTypes = {}

export default OEE
