import React from 'react'
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Chart from 'react-apexcharts';
import Trophy from "../../assets/images/trophy.png"


function LineChartHeatingYield(props) {


  return (
    <>
      <Box width="100%" mb={0} ml={0}>
        <Chart
          options={{
            chart: {
              type: 'line',
              stacked: 'true',
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              }
            },
            colors: ['#ffffff'],
            plotOptions: {
              bar: {
                borderRadius: 10,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val;
              },
              offsetY: 8,
              style: {
                fontSize: 12,
                fontFamily: 'CPF_Imm_Sook',
                colors: ["#ffffff"]
              },
              background: {
                enabled: false,
                foreColor: '#fff',
                borderWidth: 0
              }
            },
            stroke: {
              curve: 'smooth'
            },
            legend: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
            xaxis: {
              categories: props.time,
              position: 'bottom',
              labels: {
                show: false,
                style: {
                  fontFamily: 'CPF_Imm_Sook',
                  colors: '#ffffff',
                  fontSize: 14
                }
              },
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              },
              crosshairs: {
                fill: {
                  type: 'gradient',
                  gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                  }
                }
              },
              tooltip: {
                enabled: true,
              }
            },
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                formatter: function (val) {
                  return val + "%";
                }
              },
              min: props.min,
              max: props.max

            },
            grid: {
              show: false,
            },
            annotations: {
              yaxis: [{
                y: props.target,
                borderColor: '#000000',
                label: {
                  borderColor: 'transparent',
                  offsetY: 0,
                  offsetX: -255,
                  style: {
                    color: '#000',
                    background: 'transparent',
                    fontFamily: 'CPF_Imm_Sook',
                    fontWeight: 'bold',
                    fontSize: 26

                  },
                  text: props.target,
                }
              }
              ],
              points: [{
                y: props.target,
                marker: {
                  size: 0
                },
                image: {
                  path: Trophy,
                  width: 30,
                  height: 30,
                  offsetY: -15
                }
              }]
            }
          }}
          series={
            [{
              name: 'actual',
              data: props.value
            }]
          }
          type='line'
          width='100%'
          height='62%'
        />
      </Box>
    </>
  )
}

LineChartHeatingYield.propTypes = {}

export default LineChartHeatingYield
