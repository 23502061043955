import { useRef, useEffect, useState } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { apiUrl } from '../../../constants'
import axios from 'axios'
import db from "firebase-config"
import moment from 'moment-timezone';
import GaugeChart from "components/chart/GaugeChart"
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "theme";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import HeaderDetail from "components/header/HeaderDetail";
import GridToGiveawayDetail from "components/grid/GridToGivawayDetail";

const ItemGiveaway = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(0),
    textAlign: 'center',
    border: "2px dashed #4a4a49",
    borderRadius: 30,
    //height: '102%'

}));

const ItemActual = styled(Paper)(({ theme }) => ({
    backgroundColor: '#4a4a49',
    padding: theme.spacing(0.2),
    textAlign: 'center',
    //border: "3px dashed #4a4a49",
    borderRadius: 10,
    //height: '102%'

}));


const GiveawayLine9P1 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');
    const [no_data, setNo_data] = useState(false);
    const [giveawayP1, setGiveawayP1] = useState({
        WARNING: "",
        LINE: "",
        SHIFT: "",
        SKU: "",
        TARGET_KG: "",
        ACTUAL_KG: "",
        TARGET_PACK: "",
        ACTUAL_PACK: "",
        REMAINING_PACK: "",
        REJECT_PACK: "",
        PACK_SIZE_STD: "",
        SETTING_PACK_SIZE: "",
        AVG_PACK_SIZE: "",
        ACTUAL_PERCENT_GIVEAWAY: "",
        COST_SAVING_PACK: "",
        TARGET_GIVEAWAY: "",
        MIN_GIVEAWAY: ""
    });

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.GIVEAWAY_LINE9_PAGE1}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        setGiveawayP1({
                            WARNING: response.data[0].WARNING,
                            LINE: response.data[0].LINE,
                            SHIFT: response.data[0].SHIFT,
                            SKU: response.data[0].SKU,
                            TARGET_KG: response.data[0].TARGET_KG,
                            ACTUAL_KG: response.data[0].ACTUAL_KG,
                            TARGET_PACK: response.data[0].TARGET_PACK,
                            ACTUAL_PACK: response.data[0].ACTUAL_PACK,
                            REMAINING_PACK: response.data[0].REMAINING_PACK,
                            REJECT_PACK: response.data[0].REJECT_PACK,
                            PACK_SIZE_STD: response.data[0].PACK_SIZE_STD,
                            SETTING_PACK_SIZE: response.data[0].SETTING_PACK_SIZE,
                            AVG_PACK_SIZE: response.data[0].AVG_PACK_SIZE,
                            ACTUAL_PERCENT_GIVEAWAY: response.data[0].ACTUAL_PERCENT_GIVEAWAY,
                            COST_SAVING_PACK: response.data[0].COST_SAVING_PACK,
                            TARGET_GIVEAWAY: response.data[0].TARGET_GIVEAWAY,
                            MIN_GIVEAWAY: response.data[0].MIN_GIVEAWAY,
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }

    }, []);

    const scale = (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : ((giveawayP1.SETTING_PACK_SIZE - giveawayP1.PACK_SIZE_STD) / giveawayP1.PACK_SIZE_STD) * 100) * 2

    const getColorGiveaway = () => {
        let color;
        try {
            if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.50) {
                color = '#37af9b'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.75) {
                color = '#56cab7'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.75 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#96ded2'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.25) {
                color = '#f5c842'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.50) {
                color = '#fcad00'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.75) {
                color = '#ec7728'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.75) {
                color = '#e20000'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.50) {
                color = '#37af9b'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.75) {
                color = '#56cab7'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.75 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#96ded2'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.25) {
                color = '#f5c842'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.50) {
                color = '#fcad00'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.75) {
                color = '#ec7728'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.75) {
                color = '#e20000'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.5) {
                color = '#2c8c7c'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.5 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#37af9b'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.5) {
                color = '#56cab7'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.5 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 2) {
                color = '#96ded2'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 2.5) {
                color = '#f5c842'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 2.5 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 3) {
                color = '#fcad00'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 3 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 3.5) {
                color = '#ec7728'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 3.5) {
                color = '#e20000'
            }
            return color;
        }
        catch {
            console.log()
        }
    }


    return (
        <>
            <Helmet>
                <title>Giveaway Line9 Page1 | MES</title>
            </Helmet>
            <Grid item xs={12}>
                <HeaderDetail
                    line={no_data == 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 'Line9' : giveawayP1.LINE) : 'Line9'}
                    sku={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : giveawayP1.SKU) : '-'}
                    timestr={timestr}
                    icon={giveawayP1.SHIFT === "N" ? (<DarkModeOutlinedIcon style={{ color: '#ffffff' }} />) : (<LightModeOutlinedIcon style={{ color: '#ffffff' }} />)}
                />
            </Grid>

            <Grid item xs={12} sm={12} style={{ backgroundColor: colors.while[100], paddingTop: 5, paddingRight: 10, paddingLeft: 10 }}>
                <GridToGiveawayDetail
                    target_kg={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : Number(giveawayP1.TARGET_KG).toLocaleString()) : '-'}
                    actual_kg={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : Number(giveawayP1.ACTUAL_KG).toLocaleString()) : '-'}
                    target_pack={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.TARGET_PACK == '-' ? '-' : Number(giveawayP1.TARGET_PACK).toLocaleString())) : '-'}
                    actual_pack={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.ACTUAL_PACK == '-' ? '-' : Number(giveawayP1.ACTUAL_PACK).toLocaleString())) : '-'}
                    remaining_pack={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.REMAINING_PACK == '-' ? '-' : Number(giveawayP1.REMAINING_PACK).toLocaleString())) : '-'}
                    reject_pack={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.REJECT_PACK == '-' ? '-' : Number(giveawayP1.REJECT_PACK).toLocaleString())) : '-'}
                />
            </Grid>

            <Grid item xs={12} sm={12} style={{ backgroundColor: colors.while[100], paddingTop: 5, paddingRight: 10, paddingLeft: 10 }}>
                <ItemGiveaway>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                        <Grid item xs={3.9}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                    <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold' }} >
                                        Pack Size
                                    </Typography>
                                    <Typography style={{ color: colors.black[100], fontSize: 20, fontWeight: 'bold', paddingTop: 1 }} >
                                        &nbsp;(g/bag)
                                    </Typography>
                                </Box>

                                <Typography style={{ color: colors.green[100], fontSize: 24, fontWeight: 'bold' }} >
                                    Avg. Actual
                                </Typography>
                                <Typography style={{ color: colors.green[100], fontSize: 32, fontWeight: 'bold', lineHeight: "6vh" }} >
                                    {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.AVG_PACK_SIZE == '-' ? '-' : Number(giveawayP1.AVG_PACK_SIZE).toLocaleString())) : '-'}
                                </Typography>

                                <Typography style={{ color: colors.grey[100], fontSize: 20, fontWeight: 'bold', lineHeight: "20vh" }} >
                                    {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.PACK_SIZE_STD == 'ไม่มีข้อมูล Std. Pack ในระบบ' ? 'ไม่มีข้อมูล Std. Pack ในระบบ' : 'Std. ' + Number(giveawayP1.PACK_SIZE_STD).toLocaleString())) : '-'}/Setting {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.SETTING_PACK_SIZE == '-' ? '-' : Number(giveawayP1.SETTING_PACK_SIZE).toLocaleString())) : '-'}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={0.2} style={{ borderLeft: "2px dashed #4A4A49", height: '30vh', position: "relative", top: '0.5vw' }} >

                        </Grid>

                        <Grid item xs={7.9}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold' }} >
                                    Giveaway
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                    <Grid item xs={3} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.grey[100], fontSize: 22, lineHeight: "4vh" }} >
                                                Forecast
                                            </Typography>
                                            <Typography style={{ color: colors.grey[100], fontSize: 28, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.TARGET_GIVEAWAY == '-' ? '-' : Number(giveawayP1.TARGET_GIVEAWAY).toLocaleString())) : '-'}%
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 6 }} >
                                            <Typography style={{ color: colors.grey[100], fontSize: 22 }} >
                                                Best Practice
                                            </Typography>
                                            <Typography style={{ color: colors.grey[100], fontSize: 28, fontWeight: 'bold', lineHeight: "6vh" }} >
                                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.MIN_GIVEAWAY == '-' ? '-' : Number(giveawayP1.MIN_GIVEAWAY).toLocaleString())) : '-'}%
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 15, paddingLeft: 10 }} >
                                            <GaugeChart
                                                value={[no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : Number(giveawayP1.ACTUAL_PERCENT_GIVEAWAY))) : 0]}
                                                setting={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.SETTING_PACK_SIZE == '-' ? 0 : Number(giveawayP1.SETTING_PACK_SIZE))) : 0}
                                                std={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.PACK_SIZE_STD == '-' ? 0 : Number(giveawayP1.PACK_SIZE_STD))) : 0}
                                                width={230}
                                                height={60}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "2vh" }} >
                                                Actual
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }} >
                                            <ItemActual>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: no_data == 1 ? getColorGiveaway() : "#f5c842", fontSize: 32, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                        {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? '-' : Number(giveawayP1.ACTUAL_PERCENT_GIVEAWAY).toLocaleString())) : '-'}
                                                    </Typography>
                                                    <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', paddingTop: 5, lineHeight: "10vh" }} >
                                                        &nbsp;%
                                                    </Typography>
                                                </Box>
                                            </ItemActual>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 5, paddingRight: 10, paddingLeft: 10 }}>
                                            <ItemActual>
                                                <Typography style={{ color: no_data == 1 ? getColorGiveaway() : "#f5c842", fontSize: 32, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                    {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.COST_SAVING_PACK == '-' ? '-' : Number(giveawayP1.COST_SAVING_PACK).toLocaleString())) : '-'}
                                                </Typography>
                                                <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "3vh", position: "relative", top: "-0.5vw" }} textAlign="right" >
                                                    bag&nbsp;
                                                </Typography>
                                            </ItemActual>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ItemGiveaway>
            </Grid>


        </>
    )
}

export default GiveawayLine9P1
