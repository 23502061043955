import SidebarReportLine7 from "../layout/SidebarReportLine7";
import { Box} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { useState } from "react";
import { Outlet } from 'react-router-dom';
import * as React from 'react';
import CPFImmSook from 'assets/fonts/CPF_Imm_Sook_Regular.ttf';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function MainLayoutReportLine7() {
    const [mode, setMode] = useState("light");
    const colorMode = React.useMemo(
        () => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
        }),
        [],
    );
    const theme = React.useMemo(
        () =>
        createTheme({
            palette: {
            mode,
            },
            typography: {
                fontFamily: "CPF_Imm_Sook"
              },
              components: {
                MuiCssBaseline: {
                  styleOverrides: {
                    "@font-face": {
                      fontFamily: "CPF_Imm_Sook",
                      src: `url(${CPFImmSook}) format("truetype")`
                    },
                  }
                }
              }
        }),
    );
  return (
    <>
        <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
                <Box sx={{ display: 'flex', backgroundColor: '#e0f2f1', pl: 8}}>
                    <SidebarReportLine7 />
                    <Outlet />
                </Box>
        </ThemeProvider>
        </ColorModeContext.Provider>
    </>
    
  )
}

export default MainLayoutReportLine7
