import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import axios from 'axios';
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import { useNavigate } from 'react-router-dom';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import GaugeChartYield from '../components/chart/MockupGaugeChart';
import Pack from '../assets/images/pack.gif';
import PopupGiveawayDashboard from "../components/model/PopupGiveawayDashboard";
import db from "firebase-config";




export default function GiveawayPage1_ver1() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');
    const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');

    const [no_data, setNo_data] = useState(false);
    const [giveaway_dashboard, setgiveaway_dashboard] = useState({
        WARNING: "",
        LINE: "",
        SHIFT: "",
        SKU: "",
        TARGET_KG: "",
        ACTUAL_KG: "",
        TARGET_PACK: "",
        ACTUAL_PACK: "",
        REMAINING_PACK: "",
        REJECT_PACK: "",
        PACK_SIZE_STD: "",
        SETTING_PACK_SIZE: "",
        AVG_PACK_SIZE: "",
        ACTUAL_PERCENT_GIVEAWAY: "",
        COST_SAVING_PACK: "",
        TARGET_GIVEAWAY: "",
        MIN_GIVEAWAY: ""
    });
    const [keeplive, setkeeplive] = useState([]);

    setTimeout(() => {
        navigate('/monitor-giveaway/p2');
      }, 30000);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.GIVEAWAY_DASHBOARG_PAGE1}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        sessionStorage.setItem('warning', response.data[0].WARNING);
                        sessionStorage.setItem('line', response.data[0].LINE);
                        sessionStorage.setItem('sku', response.data[0].SKU);
                        sessionStorage.setItem('target_kg', response.data[0].TARGET_KG);
                        sessionStorage.setItem('actual_kg', response.data[0].ACTUAL_KG);
                        sessionStorage.setItem('target_pack', response.data[0].TARGET_PACK);
                        sessionStorage.setItem('actual_pack', response.data[0].ACTUAL_PACK);
                        sessionStorage.setItem('remaining_pack', response.data[0].REMAINING_PACK);
                        sessionStorage.setItem('reject_pack', response.data[0].REJECT_PACK);
                        sessionStorage.setItem('pack_size_std', response.data[0].PACK_SIZE_STD);
                        sessionStorage.setItem('setting_pack_size', response.data[0].SETTING_PACK_SIZE);
                        sessionStorage.setItem('avg_pack_size', response.data[0].AVG_PACK_SIZE);
                        sessionStorage.setItem('actual_percent_giveaway', response.data[0].ACTUAL_PERCENT_GIVEAWAY);
                        sessionStorage.setItem('cost_saving_pack', response.data[0].COST_SAVING_PACK);
                        sessionStorage.setItem('target_giveaway', response.data[0].TARGET_GIVEAWAY);
                        sessionStorage.setItem('min_giveaway', response.data[0].MIN_GIVEAWAY);
                        setgiveaway_dashboard({
                            WARNING: response.data[0].WARNING,
                            LINE: response.data[0].LINE,
                            SHIFT: response.data[0].SHIFT,
                            SKU: response.data[0].SKU,
                            TARGET_KG: response.data[0].TARGET_KG,
                            ACTUAL_KG: response.data[0].ACTUAL_KG,
                            TARGET_PACK: response.data[0].TARGET_PACK,
                            ACTUAL_PACK: response.data[0].ACTUAL_PACK,
                            REMAINING_PACK: response.data[0].REMAINING_PACK,
                            REJECT_PACK: response.data[0].REJECT_PACK,
                            PACK_SIZE_STD: response.data[0].PACK_SIZE_STD,
                            SETTING_PACK_SIZE: response.data[0].SETTING_PACK_SIZE,
                            AVG_PACK_SIZE: response.data[0].AVG_PACK_SIZE,
                            ACTUAL_PERCENT_GIVEAWAY: response.data[0].ACTUAL_PERCENT_GIVEAWAY,
                            COST_SAVING_PACK: response.data[0].COST_SAVING_PACK,
                            TARGET_GIVEAWAY: response.data[0].TARGET_GIVEAWAY,
                            MIN_GIVEAWAY: response.data[0].MIN_GIVEAWAY,
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }



    }, []);

    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_keeplive")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setkeeplive(getPostFromFirebase);
                let mul1 = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));
                sessionStorage.setItem('multihead1', mul1?.IS_CONNECTED);
                let mul2 = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line7_2.0NoError"));
                sessionStorage.setItem('multihead2', mul2?.IS_CONNECTED);
                let c_w = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line7.Status"));
                sessionStorage.setItem('c_w', c_w?.VALUE);
                let x_ray = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line7.Status"));
                sessionStorage.setItem('x_ray', x_ray?.VALUE);
                let m_d = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line7.Status"));
                sessionStorage.setItem('m_d', m_d?.VALUE);

            });

        // return cleanup function
        return () => subscriber();
    }, [keeplive]);

    

    const multihead1 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));
    const multihead2 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line7_2.0NoError"));
    const check_weihgt = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line7.Status"));
    const xray = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line7.Status"));
    const md = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line7.Status"));


    const getColorGiveaway = () => {
        let color;
        try {
            if ((giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต'  ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) >= 0 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) <= 0.25) {
                color = '#2c8c7c'
            } else if ((giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) > 0.25 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) <= 0.50) {
                color = '#37af9b'
            } else if ((giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) > 0.50 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY)<= 0.75) {
                color = '#56cab7'
            } else if ((giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) > 0.75 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) <= 1) {
                color = '#96ded2'
            } else if ((giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) > 1 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) <= 1.25) {
                color = '#f5c842'
            } else if ((giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) > 1.25 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) <= 1.50) {
                color = '#fcad00'
            } else if ((giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) > 1.50 && (giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) <= 1.75) {
                color = '#ec7728'
            } else if ((giveaway_dashboard.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY) > 1.75) {
                color = '#e20000'
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getColorGiveawayOld = () => {
        let color;
        try {
            if ((sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต'  ? 0 : sessionStorage.getItem('actual_percent_giveaway')) >= 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) <= 0.25) {
                color = '#2c8c7c'
            } else if ((sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) > 0.25 && (sessionStorage.getItem('warning')== 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) <= 0.50) {
                color = '#37af9b'
            } else if ((sessionStorage.getItem('warning')== 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) > 0.50 && (sessionStorage.getItem('warning')== 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway'))<= 0.75) {
                color = '#56cab7'
            } else if ((sessionStorage.getItem('warning')== 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) > 0.75 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) <= 1) {
                color = '#96ded2'
            } else if ((sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) > 1 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) <= 1.25) {
                color = '#f5c842'
            } else if ((sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) > 1.25 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) <= 1.50) {
                color = '#fcad00'
            } else if ((sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) > 1.50 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) <= 1.75) {
                color = '#ec7728'
            } else if ((sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : sessionStorage.getItem('actual_percent_giveaway')) > 1.75) {
                color = '#e20000'
            }
            return color;
        }
        catch {
            console.log()
        }
    }



  const fruits = [
    { time: "09:22:10", actual: "2,949", diff: "4,623", realtime: "707", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:11", actual: "2,949", diff: "4,623", realtime: "707", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:12", actual: "2,949", diff: "4,623", realtime: "705", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:13", actual: "2,950", diff: "4,621", realtime: "703", forecast: "2,253", giveaway: 1.00 },
    { time: "09:22:14", actual: "2,950", diff: "4,621", realtime: "705", forecast: "2,253", giveaway: 1.01 },
    { time: "09:22:15", actual: "2,951", diff: "4,620", realtime: "703", forecast: "2,252", giveaway: 1.00 },
    { time: "09:22:16", actual: "2,953", diff: "4,619", realtime: "702", forecast: "2,253", giveaway: 1.00 },
    { time: "09:22:17", actual: "2,953", diff: "4,619", realtime: "703", forecast: "2,253", giveaway: 1.00 },
    { time: "09:22:18", actual: "2,954", diff: "4,617", realtime: "703", forecast: "2,251", giveaway: 1.00 },
    { time: "09:22:19", actual: "2,954", diff: "4,617", realtime: "705", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:20", actual: "2,954", diff: "4,617", realtime: "702", forecast: "2,252", giveaway: 1.00 },
    { time: "09:22:21", actual: "2,956", diff: "4,616", realtime: "704", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:22", actual: "2,956", diff: "4,616", realtime: "703", forecast: "2,251", giveaway: 1.00 },
    { time: "09:22:23", actual: "2,957", diff: "4,614", realtime: "707", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:24", actual: "2,957", diff: "4,614", realtime: "705", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:25", actual: "2,957", diff: "4,614", realtime: "705", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:26", actual: "2,981", diff: "4,590", realtime: "703", forecast: "2,253", giveaway: 1.00 },
    { time: "09:22:27", actual: "2,981", diff: "4,590", realtime: "705", forecast: "2,253", giveaway: 1.01 },
    { time: "09:22:28", actual: "2,981", diff: "4,590", realtime: "703", forecast: "2,252", giveaway: 1.00 },
    { time: "09:22:29", actual: "3,000", diff: "4,571", realtime: "702", forecast: "2,253", giveaway: 1.00 },
    { time: "09:22:30", actual: "3,000", diff: "4,571", realtime: "703", forecast: "2,253", giveaway: 1.00 },
    { time: "09:22:31", actual: "3,000", diff: "4,571", realtime: "703", forecast: "2,251", giveaway: 1.00 },
    { time: "09:22:32", actual: "3,014", diff: "4,557", realtime: "705", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:33", actual: "3,014", diff: "4,557", realtime: "702", forecast: "2,252", giveaway: 1.00 },
    { time: "09:22:34", actual: "3,014", diff: "4,557", realtime: "704", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:35", actual: "3,071", diff: "4,550", realtime: "703", forecast: "2,251", giveaway: 1.00 },
    { time: "09:22:36", actual: "3,071", diff: "4,550", realtime: "705", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:37", actual: "3,113", diff: "4,459", realtime: "702", forecast: "2,252", giveaway: 1.00 },
    { time: "09:22:38", actual: "3,143", diff: "4,429", realtime: "704", forecast: "2,251", giveaway: 1.01 },
    { time: "09:22:39", actual: "3,164", diff: "4,407", realtime: "703", forecast: "2,251", giveaway: 1.00 },
  
  ]

  

  const [index, setIndex] = useState(0);
  const [currentFruit, setCurrentFruit] = useState(fruits[index]);



  useEffect(() => {
    setCurrentFruit(fruits[index])
  }, [index])

  useEffect(() => {
    const interval = setTimeout(() => {
      setIndex(index === fruits.length - 1 ? 0 : index + 1)
    }, 1000);
  }, [currentFruit])

 


    return (
        <>
            <Helmet>
                <title>Giveaway | MES Korat Fur2</title>
            </Helmet>
            <Grid
                container spacing={1}
                direction="row"
                alignItems="top"
                justifyContent="center"
                style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 48, paddingTop: 45 }}
            >
                {/* Top row */}

                <Grid container sx={{ pt: 0 }} spacing={5} style={{ position: "relative", left: "0vw", top: "0vw" }}>
                    <Grid id="top-row" container xs={1.5} md={1.5} lg={1.5} xl={1.5} style={{ position: "relative", left: "0vw" }}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center">
                            <Typography style={{ color: "white", fontWeight: "bold", fontSize: 30, position: "relative", left: "0vw", top: "0vw", background: "#2e9281", height: '60%' }} textAlign="center" >
                            Line7
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid id="top-row" container xs={4.5} md={4.5} lg={2} xl={2}>
                        <Grid item xs={12} textAlign="left">
                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 30, position: "relative", paddingLeft: 5 }}>
                                    SKU :&nbsp;
                                </Typography>
                                <Typography style={{ color: "#4AC6B1", fontWeight: "bold", fontSize: 30 }}>
                                    MN-F-3034
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid id="top-row" container xs={5} md={5} lg={2} xl={2}>
                        <Grid item xs={12} textAlign="right">
                            <Box sx={{ display: 'flex', alignItems: 'right', justifyContent: 'right' }} textAlign="right" >
                                <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 30, position: "relative", left: '0vw' }}>
                                {timestr}
                                </Typography>
                                <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 30, position: "relative", left: '0vw' }}>
                                    &nbsp;&nbsp;{currentFruit.time}
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>
                    <Grid id="top-row" container xs={1} md={1} lg={0.5} xl={0.5}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="right">
                            <Typography>
                                   {<LightModeOutlinedIcon style={{ color: '#ffffff', position: "relative", top: "1vw", left: '-3vw' }} />}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container spacing={5} style={{ position: "relative", top: '2vw', left: '0vw' }}>
                    <Grid id="top-row" container xs={3} md={3} lg={1.5} xl={1.5} style={{ position: "relative", padding: 3 }}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ background: colors.grey[100], borderRadius: 20, height: '90%' }}>
                            <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '1.5vw' }}>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-2vw', height: '12px' }}>
                                    <Typography style={{ color: colors.while[100], fontSize: 30, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                        FG (kg)
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '4.5vw', height: '60px', left: '0vw' }}>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                    <Typography style={{ color: colors.while[100], fontSize: 25, position: "relative", top: "-1.2vw", fontWeight: 'bold' }} >
                                        Target&nbsp;
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4.1vw' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                        <Typography style={{ color: colors.while[100], fontSize: 38, fontWeight: 'bold' }} >
                                        5,300
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid id="top-row" container xs={9} md={9} lg={4.5} xl={4.5} style={{ position: "relative", padding: 3 }}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ background: '#BCD6D0', borderRadius: 20, height: '90%' }}>
                            <Grid container spacing={2} >
                                <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '4vw' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-2vw', height: '12px' }}>
                                        <Typography style={{ color: colors.while[100], fontSize: 30, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            FG (bag)
                                        </Typography>

                                    </Grid>
                                </Grid>

                                <Grid id="top-row" container xs={3.5} md={3.5} lg={1.5} xl={1.5} textAlign="center" style={{ borderRight: "1px dashed #2e9281", position: "relative", top: '7vw', height: '50px' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-1.5vw' }} >
                                        <Typography style={{ color: colors.while[100], fontSize: 25, position: "relative", top: "0.4vw", fontWeight: 'bold' }} >
                                            &nbsp;&nbsp;Target
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4.2vw' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <Typography style={{ color: colors.while[100], fontSize: 38, fontWeight: 'bold' }} >
                                            &nbsp;&nbsp;7,571
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid id="top-row" container xs={3} md={3} lg={1.5} xl={1.5} textAlign="center" style={{ borderRight: "1px dashed #2e9281", position: "relative", top: '7vw', height: '50px' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-1vw' }}>
                                        <Typography style={{ color: colors.while[100], fontSize: 25, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            Actual
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4.1vw' }} >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <Typography style={{ color: '#2e9281', fontSize: 38, fontWeight: 'bold' }} >
                                            {currentFruit.actual}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid id="top-row" container xs={3} md={3} lg={1.5} xl={1.5} textAlign="center" style={{ position: "relative", top: '7vw' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-1vw' }}>
                                        <Typography style={{ color: colors.while[100], fontSize: 25, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            Diff.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4vw' }} >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <Typography style={{ color: colors.while[100], fontSize: 38, fontWeight: 'bold' }} >
                                            {currentFruit.diff}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>



                                <Grid id="top-row" container xs={2.5} md={2.5} lg={1.5} xl={1.5} textAlign="center" style={{ borderLeft: "1px dashed #2e9281", position: "relative", top: '7vw', height: '50px' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-1vw' }}>
                                        <Typography style={{ color: '#8c0e0e', fontSize: 25, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            Reject
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4vw' }} >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <Typography style={{ color: '#000000', fontSize: 38, fontWeight: 'bold' }} >
                                            0
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={5} style={{ position: "relative", top: '-1vw', left: '0vw' }}>
                    <Grid id="top-row" container xs={12} md={12} lg={6} xl={6} style={{ position: "relative", padding: 3 }}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ border: "2px dashed #4A4A49", borderRadius: 20, height: '120%', position: "relative", top: '7.5vw' }}>
                            <Grid container spacing={2} >
                                <Grid id="top-row" container xs={4} md={4} lg={4} xl={4} textAlign="center" style={{ position: "relative", top: '5vw', height: '125px', left: '1vw', borderRight: "1px dashed #c2c2c2" }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw', height: '50%' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                            <Typography style={{ color: colors.black[100], fontSize: 32, position: "relative", top: "-2.5vw", fontWeight: 'bold' }} >
                                                &nbsp;&nbsp;Pack Size
                                            </Typography>
                                            <Typography style={{ color: colors.black[100], fontSize: 22, position: "relative", top: "-2.5vw", fontWeight: 'bold' }} >
                                                &nbsp;(g/bag)
                                            </Typography>
                                        </Box>

                                        <Typography style={{ color: '#2e9281', fontSize: 28, position: "relative", top: "-3vw", fontWeight: 'bold' }} >
                                            Avg. Actual
                                        </Typography>
                                        <Typography style={{ color: '#2e9281', fontSize: 38, position: "relative", top: "-6vw", fontWeight: 'bold' }} >
                                        {currentFruit.realtime}
                                        </Typography>
                                        <Typography style={{ color: colors.grey[100], fontSize: 19, position: "relative", top: "-7vw", fontWeight: 'bold' }} >
                                        Std. 700/Setting 707
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Grid id="top-row" container xs={8} md={8} lg={8} xl={8} textAlign="center" style={{ position: "relative", top: '4vw' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ height: '75%' }}>
                                        <Grid container spacing={3} >
                                            <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '4vw' }}>
                                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-2vw', height: '12px' }}>
                                                    <Typography style={{ color: colors.black[100], fontSize: 32, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                                        Giveaway
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid id="top-row" container xs={4} md={4} lg={2.5} xl={2.5} textAlign="center" style={{ position: "relative", top: '7vw', left: '2vw', height: '60px' }}>
                                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-1.5vw', height: '30%' }} >
                                                    <Typography style={{ color: colors.grey[100], fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                                        Best Practice
                                                    </Typography>
                                                    <Typography style={{ color: colors.grey[100], fontSize: 28, position: "relative", top: "-2.5vw", fontWeight: 'bold' }} >
                                                    0.98%
                                                    </Typography>
                                                    <Typography style={{ color: colors.grey[100], fontSize: 22, position: "relative", top: "-2.2vw", fontWeight: 'bold' }} >
                                                        Forecast
                                                    </Typography>
                                                    <Typography style={{ color: colors.grey[100], fontSize: 28, position: "relative", top: "-4.5vw", fontWeight: 'bold' }} >
                                                    1%
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid id="top-row" container xs={4} md={4} lg={2} xl={2} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '8vw', left: '2vw' }}>
                                                    <GaugeChartYield
                                                        value={currentFruit.giveaway}                                                       
                                                        setting={707}
                                                        std={700}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid id="top-row" container xs={4} md={4} lg={1.5} xl={1.5} textAlign="center" style={{ position: "relative", top: '5vw', height: '60px' }}>
                                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-1vw' }}>
                                                    <Typography style={{ color: colors.black[100], fontSize: 26, position: "relative", top: "1.2vw", fontWeight: 'bold' }} >
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Actual
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }} >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#4a4a49', width: '75%', height: '60%', borderRadius: 2 }} style={{ position: "relative", left: '5vw' }} textAlign="center" >
                                                        <Typography style={{ color: '#f5c842', fontSize: 38, fontWeight: 'bold' }} >
                                                        {currentFruit.giveaway}
                                                        </Typography>
                                                        <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', position: "relative", top: "0.5vw" }} >
                                                            %
                                                        </Typography>
                                                    </Box>
                                                </Grid>


                                                <Grid item xs={9} md={9} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-3vw', left: '5vw', height: '80%', background: '#4a4a49', borderRadius: 10 }} >
                                                    <Typography style={{ color: '#f5c842', fontSize: 38, position: "relative", top: "-1.5vw", fontWeight: 'bold' }} >
                                                    0
                                                    </Typography>
                                                    <Typography style={{ color: colors.black[100], fontSize: 22, position: "relative", top: "-5.5vw", fontWeight: 'bold' }} textAlign="right">
                                                        pack&nbsp;&nbsp;
                                                    </Typography>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>




                </Grid>



            </Grid>

        </>
    );
}




