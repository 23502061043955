import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import Target from 'assets/images/svg/target.svg';
import Fighting from 'assets/images/first1.gif';
import Run from 'assets/images/running.gif';
import Running from 'assets/images/running_png.png';
import Fist from 'assets/images/protest_png.png';
import axios from 'axios';
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import { useNavigate } from 'react-router-dom';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import db from "firebase-config";
import Popup from '../components/model/Popup';



export default function TargetLine7inmonitor() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const today = new Date();
  const hourago = new Date(today.getTime());
  const day = hourago.toLocaleDateString('th-TH', {
    year: '2-digit',
    month: 'short',
    day: 'numeric',
  })
  //const timestr = moment(hourago).add(543, 'year').tz('Asia/Bangkok').format('DD/MM/YY');
  const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');



  const [no_data, setNo_data] = useState(false);
  const [no_data_p2, setNo_data_p2] = useState(false);
  const [line_monitoring, setLine_monitoring] = useState({
    WARNING: "",
    LINE: "",
    SKU: "",
    FACTORY_DATE: "",
    SHIFT: "",
    TARGET: "",
    TARGET_UNIT: "",
    ACTUAL_WEIGHT: "",
    ACTUAL_WEIGHT_PERCENT: "",
    REMAINING: "",
    REMAINING_PERCENT: "",
    ACTUAL_FEED_RATE: "",
    STD_FEED_RATE: ""
  })

  const [line_monitoring_p2, setLine_monitoring_p2] = useState({
    PRODUCTION_TIME: "",
    NON_PRODUCTION_TIME: "",
    FINISH_DATETIME: "",
    PRODUCTION_EFFICIENCY: ""
  })

  const [taglist, setTaglist] = useState([]);


  setTimeout(() => {
    navigate('/target-line7/p2');
  }, 30000);



  useEffect(() => {
    async function fetchMyAPI() {
      await axios.get(`${apiUrl.LINE_MONITORING_PAGE1}`, {
        "headers": {
          "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
        }
      }
      )
        .then(response => {
          //console.log(response.data)
          if (response.status == 200) {
            sessionStorage.setItem('warning', response.data.WARNING);
            sessionStorage.setItem('sku', response.data.SKU);
            sessionStorage.setItem('target', response.data.TARGET);
            sessionStorage.setItem('target_unit', response.data.TARGET_UNIT);
            sessionStorage.setItem('actual_weight_percent', response.data.ACTUAL_WEIGHT_PERCENT);
            sessionStorage.setItem('actual_weight', response.data.ACTUAL_WEIGHT);
            sessionStorage.setItem('remaining_percent', response.data.REMAINING_PERCENT);
            sessionStorage.setItem('remaining', response.data.REMAINING);
            sessionStorage.setItem('std_feed_rate', response.data.STD_FEED_RATE);
            setLine_monitoring({
              WARNING: response.data.WARNING,
              LINE: response.data.LINE,
              SKU: response.data.SKU,
              FACTORY_DATE: response.data.FACTORY_DATE,
              SHIFT: response.data.SHIFT,
              TARGET: response.data.TARGET,
              TARGET_UNIT: response.data.TARGET_UNIT,
              ACTUAL_WEIGHT: response.data.ACTUAL_WEIGHT,
              ACTUAL_WEIGHT_PERCENT: response.data.ACTUAL_WEIGHT_PERCENT,
              REMAINING: response.data.REMAINING,
              REMAINING_PERCENT: response.data.REMAINING_PERCENT,
              ACTUAL_FEED_RATE: response.data.ACTUAL_FEED_RATE,
              STD_FEED_RATE: response.data.STD_FEED_RATE
            })
            setNo_data(1)

          }
        })
        .catch(error => {
          setNo_data(2)
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

    const interval = setInterval(() => fetchMyAPI(), 1000)
    return () => {
      clearInterval(interval);
    }



  }, []);

  if (timestrs == "00:00:00") {
    sessionStorage.clear();
  }

  useEffect(() => {
    async function fetchMyAPI() {
      await axios.get(`${apiUrl.LINE_MONITORING_PAGE2}`, {
        "headers": {
          "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
        }
      }
      )
        .then(response => {
          //console.log(response.data)
          if (response.status == 200) {
            sessionStorage.setItem('production_efficiency', response.data.PRODUCTION_EFFICIENCY);
            sessionStorage.setItem('production_time', response.data.PRODUCTION_TIME);
            sessionStorage.setItem('no_production_time', response.data.NON_PRODUCTION_TIME);
            setLine_monitoring_p2({
              PRODUCTION_TIME: response.data.PRODUCTION_TIME,
              NON_PRODUCTION_TIME: response.data.NON_PRODUCTION_TIME,
              FINISH_DATETIME: response.data.FINISH_DATETIME,
              PRODUCTION_EFFICIENCY: response.data.PRODUCTION_EFFICIENCY
            })
            setNo_data_p2(1)
          }
        })
        .catch(error => {
          setNo_data_p2(2)
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

    const interval = setInterval(() => fetchMyAPI(), 1000)
    return () => {
      clearInterval(interval);
    }



  }, []);

  useEffect(() => {
    const getPostFromFirebase = [];
    const subscriber = db
      .collection("meskr_line_monitoring_tag_list")
      .where("TAG", "==", "ACCUMURATE_WEIGHT")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getPostFromFirebase.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        sessionStorage.setItem('is_connected', getPostFromFirebase[0].IS_CONNECTED);
        setTaglist(getPostFromFirebase);
      });

    // return cleanup function
    return () => subscriber();
  }, [taglist]);


  var accumurate_weight = taglist.find((user) => user.TAG.includes("ACCUMURATE_WEIGHT"));

  ///// split time non production time

  var non_production_time = no_data_p2 == 1 ? (line_monitoring_p2.NON_PRODUCTION_TIME != 'NA' ? (line_monitoring_p2.NON_PRODUCTION_TIME).split(":") : "00:00") : "00:00"

  var non_production_hour = Number(non_production_time[0])

  var non_production_minute = Number(non_production_time[1])

  ///// split time  production time

  var production_time = no_data_p2 == 1 ? (line_monitoring_p2.PRODUCTION_TIME != 'NA' ? (line_monitoring_p2.PRODUCTION_TIME).split(":") : "00:00") : "00:00"

  var production_hour = Number(production_time[0])

  var production_minute = Number(production_time[1])

  ///// split time non production time sessionStorage

  var non_production_time_storage = sessionStorage.getItem('no_production_time') != null ? (sessionStorage.getItem('no_production_time') != 'NA' ? (sessionStorage.getItem('no_production_time')).split(":") : "00:00") : "00:00"

  var non_production_hour_storage = Number(non_production_time_storage[0])

  var non_production_minute_storage = Number(non_production_time_storage[1])

  ///// split time  production time sessionStorage

  var production_time_storage = sessionStorage.getItem('production_time') != null ? (sessionStorage.getItem('production_time') != 'NA' ? (sessionStorage.getItem('production_time')).split(":") : "00:00") : "00:00"

  var production_hour_storage = Number(production_time_storage[0])

  var production_minute_storage = Number(production_time_storage[1])




  const getChartColor = () => {
    let color;
    try {
      if ((no_data == 1 ? (line_monitoring.ACTUAL_WEIGHT == 'NA' ? 0 : line_monitoring.ACTUAL_WEIGHT) : Number(sessionStorage.getItem('actual_weight'))) >= (((((no_data_p2 == 1 ? production_hour : production_hour_storage) + ((no_data_p2 == 1 ? production_minute : production_minute_storage) / 60)) + ((no_data_p2 == 1 ? non_production_hour : non_production_hour_storage) + ((no_data_p2 == 1 ? non_production_minute : non_production_minute_storage) / 60))) * (no_data == 1 ? (line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : line_monitoring.STD_FEED_RATE) : (sessionStorage.getItem('std_feed_rate') == 'NA' || sessionStorage.getItem('std_feed_rate') == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : Number(sessionStorage.getItem('std_feed_rate'))))) * 1)) {
        color = "#2e9281"
      } else if ((no_data == 1 ? (line_monitoring.ACTUAL_WEIGHT == 'NA' ? 0 : line_monitoring.ACTUAL_WEIGHT) : Number(sessionStorage.getItem('actual_weight'))) >= (((((no_data_p2 == 1 ? production_hour : production_hour_storage) + ((no_data_p2 == 1 ? production_minute : production_minute_storage) / 60)) + ((no_data_p2 == 1 ? non_production_hour : non_production_hour_storage) + ((no_data_p2 == 1 ? non_production_minute : non_production_minute_storage) / 60))) * (no_data == 1 ? (line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : line_monitoring.STD_FEED_RATE) : (sessionStorage.getItem('std_feed_rate') == 'NA' || sessionStorage.getItem('std_feed_rate') == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : Number(sessionStorage.getItem('std_feed_rate'))))) * 0.8)) {
        color = "#b58514"
      } else if ((no_data == 1 ? (line_monitoring.ACTUAL_WEIGHT == 'NA' ? 0 : line_monitoring.ACTUAL_WEIGHT) : Number(sessionStorage.getItem('actual_weight'))) < (((((no_data_p2 == 1 ? production_hour : production_hour_storage) + ((no_data_p2 == 1 ? production_minute : production_minute_storage) / 60)) + ((no_data_p2 == 1 ? non_production_hour : non_production_hour_storage) + ((no_data_p2 == 1 ? non_production_minute : non_production_minute_storage) / 60))) * (no_data == 1 ? (line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : line_monitoring.STD_FEED_RATE) : (sessionStorage.getItem('std_feed_rate') == 'NA' || sessionStorage.getItem('std_feed_rate') == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : Number(sessionStorage.getItem('std_feed_rate'))))) * 0.8)) {
        color = "#7d2e1e"
      }
      return color;
    }
    catch {
      console.log()
    }
  }






  const getFeedrateColor = () => {
    let color;
    try {
      if ((no_data_p2 == 1 ? (line_monitoring_p2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : line_monitoring_p2.PRODUCTION_EFFICIENCY) : (sessionStorage.getItem('production_efficiency') == 'NA' ? 0 : Number(sessionStorage.getItem('production_efficiency')))) >= ((no_data == 1 ? ((line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : line_monitoring.STD_FEED_RATE) : (sessionStorage.getItem('std_feed_rate') == 'NA' || sessionStorage.getItem('std_feed_rate') == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : Number(sessionStorage.getItem('std_feed_rate')))) * 1)) {
        color = "#2e9281"
      } else if ((no_data_p2 == 1 ? (line_monitoring_p2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : line_monitoring_p2.PRODUCTION_EFFICIENCY) : (sessionStorage.getItem('production_efficiency') == 'NA' ? 0 : Number(sessionStorage.getItem('production_efficiency')))) >= ((no_data == 1 ? ((line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : line_monitoring.STD_FEED_RATE) : (sessionStorage.getItem('std_feed_rate') == 'NA' || sessionStorage.getItem('std_feed_rate') == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : Number(sessionStorage.getItem('std_feed_rate')))) * 0.8)) {
        color = "#b58514"
      } else if ((no_data_p2 == 1 ? (line_monitoring_p2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : line_monitoring_p2.PRODUCTION_EFFICIENCY) : (sessionStorage.getItem('production_efficiency') == 'NA' ? 0 : Number(sessionStorage.getItem('production_efficiency')))) < ((no_data == 1 ? ((line_monitoring.STD_FEED_RATE == 'NA' || line_monitoring.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : line_monitoring.STD_FEED_RATE) : (sessionStorage.getItem('std_feed_rate') == 'NA' || sessionStorage.getItem('std_feed_rate') == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : Number(sessionStorage.getItem('std_feed_rate')))) * 0.8)) {
        color = "#7d2e1e"
      }
      return color;
    }
    catch {
      console.log()
    }
  }

  var connect = (sessionStorage.getItem('is_connected') === "true")

  var connected = sessionStorage.getItem('is_connected') === null ? true : (accumurate_weight?.IS_CONNECTED == undefined ? connect : accumurate_weight?.IS_CONNECTED);

  //console.log(connected)








  return (
    <>
      {connected == false ? <Popup /> :
        <Helmet>
          <title>Target Line7 | MES Korat Fur2</title>
        </Helmet>
      }
      <Grid
        container spacing={1}
        direction="row"
        alignItems="top"
        justifyContent="center"
        style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 25, paddingTop: 75 }}
      >
        {/* Top row */}

        <Grid id="top-row" container sx={{ pt: 0 }} spacing={9}>
          <Box sx={{ width: '20%', bgcolor: "#2e9281" }}>
            <Typography style={{ color: "white", fontWeight: "bold", fontSize: 30, position: "relative", left: "4.5vw", top: "0.5vw" }} textAlign="center" >
              {no_data === 1 ? (line_monitoring.LINE == 'NA' ? 'Line7' : line_monitoring.LINE) : 'Line7'}
            </Typography>
          </Box>
          <Box sx={{ width: '80%', bgcolor: colors.while[100], display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", paddingLeft: 0, top: "-1.5vw" }} textAlign="center" >
            <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 30, position: "relative", paddingLeft: 5, }}>
              SKU :&nbsp;
            </Typography>
            <Typography style={{ color: "#4AC6B1", fontWeight: "bold", fontSize: 30 }}>
              {no_data === 1 ? (line_monitoring.WARNING == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : line_monitoring.WARNING == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? line_monitoring.SKU : line_monitoring.SKU) : (sessionStorage.getItem('warning')== 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : sessionStorage.getItem('warning') === 'ไม่มีข้อมูล Std. Cap ในระบบ' ? sessionStorage.getItem('sku') : sessionStorage.getItem('sku'))}
            </Typography>
            <Typography style={{ color: colors.black[100], fontWeight: "bold", fontSize: 30, position: "relative", paddingLeft: 0 }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{day}&nbsp;&nbsp;{timestrs}
            </Typography>
            <IconButton onClick={colorMode.toggleColorMode} style={{ position: "relative", top: "-0.5vw" }}>
              {line_monitoring.SHIFT === "N" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
          </Box>
        </Grid>



        <Grid id="top-row" container sx={{ pt: 0 }} spacing={5}>
          <Box sx={{ width: '100%', height: '95%', bgcolor: "#bcd6d0", display: 'flex', alignItems: 'center', justifyContent: 'center' }} style={{ position: "relative", top: "2vw" }} textAlign="center" >
            <Typography style={{ position: "relative", paddingLeft: 10 }}>
              <img src={Target} height="80px" />
            </Typography>
            <Typography style={{ color: "#2e9281", fontSize: 50, position: "relative", paddingLeft: 20, pt: 0 }}>
              เป้าหมาย
            </Typography>
            <Typography style={{ color: "#2e9281", fontWeight: "bold", fontSize: 60, position: "relative", paddingLeft: 20 }}>
              {no_data === 1 ? (line_monitoring.TARGET).toLocaleString() : Number(sessionStorage.getItem('target')).toLocaleString()}
            </Typography>
            <Typography style={{ color: "#2e9281", fontSize: 35, position: "relative", paddingLeft: 20, top: "1vw" }}>
              กก.
            </Typography>
          </Box>
        </Grid>


        <Grid container spacing={0} style={{ position: "relative", top: "0vw", left: "0vw" }}>
          <Grid item xs={6}>
            <Box sx={{ height: '5%', display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "0vw" }} textAlign="center" >

            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} style={{ position: "relative", top: "0vw" }} textAlign="center">
              <Typography >
                {accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Fighting} height="60px" /> : <img src={Fist} height="60px" />) : <img src={Fist} height="60px" />}
              </Typography>
              <Typography style={{ color: colors.black[100], fontSize: 40, position: "relative", paddingLeft: 0, top: "2.5vw" }}>
                ทำได้
              </Typography>
              <Typography style={{ color: line_monitoring.ACTUAL_WEIGHT === 'NA' || sessionStorage.getItem('actual_weight') === 'NA' ? "#2e9281" : getChartColor(), fontSize: 50, position: "relative", paddingLeft: 10, top: "2vw" }}>
                {no_data === 1 ? line_monitoring.ACTUAL_WEIGHT_PERCENT : sessionStorage.getItem('actual_weight_percent')}%
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "-1vw" }} >
              <Box sx={{ width: '80%', bgcolor: line_monitoring.ACTUAL_WEIGHT === 'NA' || sessionStorage.getItem('actual_weight') === 'NA' ? "#2e9281" : getChartColor(), display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="75px" style={{ position: "relative", left: '5vw', borderRadius: 15 }} textAlign="center" >
                <Typography style={{ color: "#ffffff", fontSize: 70 }}>
                  {no_data === 1 ? (line_monitoring.ACTUAL_WEIGHT).toLocaleString() : Number(sessionStorage.getItem('actual_weight')).toLocaleString()}
                </Typography>
                <Typography style={{ color: "#ffffff", fontSize: 30, position: "relative", paddingLeft: 10, top: "1vw" }}>
                  กก.
                </Typography>
              </Box>
            </Box>

          </Grid>

          <Grid item xs={6} style={{ position: "relative", left: "-2vw" }}>
            <Box sx={{ height: '5%', display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "0vw" }} textAlign="center" >

            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "0vw" }} textAlign="center" >
              <Typography style={{ position: "relative", paddingLeft: 35 }}>
                {accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Run} height="60px" /> : <img src={Running} height="60px" />) : <img src={Running} height="60px" />}
              </Typography>
              <Typography style={{ color: colors.black[100], fontSize: 40, position: "relative", paddingLeft: 0, top: "2.5vw" }}>
                คงเหลือ
              </Typography>
              <Typography style={{ color: colors.grey[100], fontSize: 50, position: "relative", paddingLeft: 0, top: "2vw" }}>
                {no_data === 1 ? (line_monitoring.ACTUAL_WEIGHT_PERCENT === 'NA' ? 'NA' : (100 - line_monitoring.ACTUAL_WEIGHT_PERCENT <= 0 ? 0 :  100 - line_monitoring.ACTUAL_WEIGHT_PERCENT)) : (sessionStorage.getItem('actual_weight_percent') === 'NA' ? 'NA' : (100 - Number(sessionStorage.getItem('actual_weight_percent')) <= 0 ? 0 : 100 - Number(sessionStorage.getItem('actual_weight_percent'))))}%
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} style={{ position: "relative", top: "-1vw" }} textAlign="center" >
              <Box sx={{ width: '80%', bgcolor: "#4a4a49", display: 'flex', alignItems: 'center', justifyContent: 'center' }} height="75px" style={{ position: "relative", left: '5vw', borderRadius: 15 }} textAlign="center" >
                <Typography style={{ color: "#ffffff", fontSize: 70 }}>
                  {no_data === 1 ? (line_monitoring.REMAINING).toLocaleString() : Number(sessionStorage.getItem('remaining')).toLocaleString()}
                </Typography>
                <Typography style={{ color: "#ffffff", fontSize: 30, position: "relative", paddingLeft: 10, top: "1vw" }}>
                  กก.
                </Typography>
              </Box>
            </Box>

          </Grid>
        </Grid>



        <Grid container spacing={0}>
          <Grid item xs={7} style={{ position: "relative", top: "-1vw", left: "-3.8vw" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} style={{ position: "relative", top: "0vw" }} textAlign="center" >
              <Typography style={{ color: getFeedrateColor(), fontSize: 45, position: "relative", paddingLeft: 0, top: "0vw" }}>
                {no_data_p2 === 1 ? (line_monitoring_p2.PRODUCTION_EFFICIENCY == 'NA' ? 'NA' : (Math.round(line_monitoring_p2.PRODUCTION_EFFICIENCY)).toLocaleString()) : sessionStorage.getItem('production_efficiency')}
              </Typography>
              <Typography style={{ color: colors.black[100], fontSize: 25, position: "relative", paddingLeft: 0, top: "0.6vw" }}>
                &nbsp;กก./ชม.&nbsp;({no_data === 1 ? (line_monitoring.STD_FEED_RATE == "ไม่มีข้อมูล Std. Cap ในระบบ" ? (line_monitoring.STD_FEED_RATE).toLocaleString() : "Std." + (line_monitoring.STD_FEED_RATE).toLocaleString()) : (sessionStorage.getItem('std_feed_rate') == "ไม่มีข้อมูล Std. Cap ในระบบ" ? sessionStorage.getItem('std_feed_rate') : "Std." + Number(sessionStorage.getItem('std_feed_rate')).toLocaleString())})
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}