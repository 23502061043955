import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Link,
  } from "@material-ui/core";
  import { Link as RouterLink, useNavigate } from "react-router-dom";
  
  function UserToolbar(props) {
    return (
      <Box {...props}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            pt: 10,
            pl: 2
            
          }}
        >
          <Button
            component={RouterLink}
            to="/register"
            variant="contained"
            style={{ borderRadius: 5, fontSize: 16, fontWeight: "bold", background: '#575755' }}
          >
            Create new account
          </Button>
        </Box>
      </Box>
    );
  }
  
  UserToolbar.propTypes = {};
  
  export default UserToolbar;
  