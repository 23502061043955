import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../constants";
import UserList from "../components/setting/UserList";
import UserToolbar from "../components/setting/UserToolbar";

var token_url = apiUrl.TOKEN;

function Settings(props) {
  const [User, setUser] = useState([]);

    // check token
    const checkToken = async () => {
        let access_exp = localStorage.getItem("rb_ac_exp");
        let refresh_exp = localStorage.getItem("rb_rf_exp");
        if (refresh_exp < Date.now() / 1000) {
          console.log("refresh is expiried");
          // alert('Please log in again')
          localStorage.clear();
          window.location = "/login";
        } else if (access_exp < Date.now() / 1000) {
          const refresh = localStorage.getItem("rb_rf_token");
    
          let data = {
            refresh: refresh,
          };
          let result = await axios.post(token_url, data);
          console.log("got new access_token!");
          localStorage.setItem("rb_ac_token", result.data.access);
        }
      };



  useEffect(() => {
    async function fetchMyAPI() {
    // check access token expiry
    checkToken();
    //console.log('get_allkorat ------>')
    var get_user = `${apiUrl.GET_USER}` // date format must be YYYY-MM-DD, eg. 2022-06-16
    await axios.get(get_user, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy":  "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    }
    )
      .then(response => {
        //console.log(response)
        if (response.status == 200) {
            setUser(response.data)
        }
      })
      .catch(error => {
        console.log('error: ', error)

      })
    }
    fetchMyAPI()
  }, []);

  



  return (
    <>
      <Helmet>
        <title>Settings | MES Korat Fur2</title>
      </Helmet>
        <Container maxWidth={false}>
          <UserToolbar />
          <Box sx={{ pt: 1, pl: 2 }}>
            <UserList customers={User} />
          </Box>
        </Container>
    </>
  );
}

export default Settings;
