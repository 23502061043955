export const apiUrl = {
  LOGIN_URL: `https://api.cpfmes.com/account/login/`,
  GET_SAP_DATA: `https://api.cpfmes.com/api/sap/data/`,
  GET_SPECIFIC_LINE_DATA: `https://api.cpfmes.com/api/line/4117/`,
  GET_ALL_FACTORY_DATA: `https://api.cpfmes.com/api/all-factory-line/4117/`,
  UPDATE_SAP_PO: `https://api.cpfmes.com/api/po/update/`,
  ADD_DEFECT: `https://api.cpfmes.com/api/add-defect/`,
  GET_DEFECT: `https://api.cpfmes.com/api/defect/get/4117/`,
  GET_DEFECT_FOR_DONUT: `https://api.cpfmes.com/api/defect/get/donut/4117/`,
  DELETE_DEFECT: `https://api.cpfmes.com/api/defect/delete/`,
  EDIT_DEFECT: `https://api.cpfmes.com/api/defect/update/`,
  ADD_NO_EMPLOYEE: `https://api.cpfmes.com/api/add-number-of-employee/`,
  GET_NO_EMPLOYEE: `https://api.cpfmes.com/api/number-of-employee/get/4117/`,
  EDIT_NO_EMPLOYEE: `https://api.cpfmes.com/api/number-of-employee/update/`,
  DELECT_NO_EMPLOYEE: `https://api.cpfmes.com/api/number-of-employee/delete/`,
  GET_MEAT_AT_TUMBLER: `https://api.cpfmes.com/api/tumbler/get/4117/`,
  EDIT_TUMBLER: `https://api.cpfmes.com/tumbler/update/`,
  GET_TUMBLER_AT_MULTIHEAD: `https://api.cpfmes.com/api/tumbler-at-multihead/get/4117/`,
  UPDATE_STATUS_AT_MULTIHEAD: `https://api.cpfmes.com/api/multihead/update/`,
  GET_GIVEAWAY: `https://api.cpfmes.com/api/giveaway/4117/`,
  DAILY_REPORT: `https://api.cpfmes.com/api/post-date/get/4117/LINE7/`,
  TOKEN: `https://api.cpfmes.com/account/token/refresh/`,
  MACHINE_RUNTIME: `https://api.cpfmes.com/api/machine-runtime/4117/`,
  CURRENT_PERFORMANCE: `https://api.cpfmes.com/api/current/performance/belt-scale/4117/LINE7/`,
  UNLOCK_GIVEAWAY: `https://api.cpfmes.com/api/function/giveaway/`,
  GIVEAWAY_CURRENT: `https://api.cpfmes.com/api/function/giveaway/current-concurrency/4117/LINE7/`,
  DESIRED_USER: `https://api.cpfmes.com/account/user/`,
  GET_USER: `https://api.cpfmes.com/account/user/`,
  RESET_PW: `https://api.cpfmes.com/account/request-reset-email/`,
  SET_PW: `https://api.cpfmes.com/account/password-reset-complete/`,
  REDIRECT_URL: `https://immjai-linemonitor.cpfmes.com/reset-pw`,
  REGISTER: `https://api.cpfmes.com/account/signup/`,
  LINE_MONITORING_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page1/line7`,
  LINE_MONITORING_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page2/line7`,
  GIVEAWAY_DASHBOARG_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page1/line7`,
  GIVEAWAY_DASHBOARG_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page2/line7`,
  POST_PREPARATION_ZONE: `https://api.cpfmes.com/api/tumbler-v2/`,
  GET_PREPARATION_ZONE: `https://api.cpfmes.com/api/tumbler-v2/4117/00000000000000/`,
  PUT_PREPARATION_ZONE: `https://api.cpfmes.com/api/tumbler-v2-update/`,
  BETWEEN_PREPARATION_ZONE: `https://api.cpfmes.com/api/tumbler-v2/4117/00000000000000/`,

  UPDATE_SAP_PO_NEW: `https://sit.internaltestzone.com/sap/sap/outbound/meskr`,

  ///// rollout monitor//////
  PERFORMANCE_LINE8_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page1/line8`,
  PERFORMANCE_LINE8_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page2/line8`,
  PERFORMANCE_LINE9_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page1/line9`,
  PERFORMANCE_LINE9_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page2/line9`,
  PERFORMANCE_LINE10_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page1/line10`,
  PERFORMANCE_LINE10_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/line-monitor/page2/line10`,

  GIVEAWAY_LINE8_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page1/line8`,
  GIVEAWAY_LINE8_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page2/line8`,
  GIVEAWAY_LINE9_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page1/line9`,
  GIVEAWAY_LINE9_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page2/line9`,
  GIVEAWAY_LINE10_PAGE1: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page1/line10`,
  GIVEAWAY_LINE10_PAGE2: `https://immjai-backendapi-uat.cpfmes.com/4117/packing/page2/line10`,


};




