import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';
//import Img from '../../../assets/img/bg.png'

function Navbar(props) {
    return (
        <AppBar
        elevation={0}
        {...props}
      >

          <RouterLink to="/">
             {/*<img src={Img} width='110' height='100'></img>*/}
          </RouterLink>

      </AppBar>
    )
}

Navbar.propTypes = {

}

export default Navbar

