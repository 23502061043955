import { useRef, useEffect, useState } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { apiUrl } from '../../../constants'
import axios from 'axios'
import db from "firebase-config"
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "theme";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Target from "assets/images/svg/target.svg"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import Fighting from "assets/images/first1.gif";
import Running from "assets/images/running.gif"
import Run from 'assets/images/running_png.png';
import Fist from 'assets/images/protest_png.png';
import HeaderDetail from "components/header/HeaderDetail";
import GrigToProductionTracking from "components/grid/GridToProductionTracking";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#bcd6d0',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    //borderRadius: 10,
    //height: '102%'

}));

const ItemTarget = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    borderRadius: 0,
    //height: '65vh'

}));


const ProductionLine10P1 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const today = new Date();
    const hourago = new Date(today.getTime());
    const day = hourago.toLocaleDateString('th-TH', {
        year: '2-digit',
        month: 'short',
        day: 'numeric',
    })
    const [no_data, setNo_data] = useState(false);
    const [no_data_p2, setNo_data_p2] = useState(false);
    const [performanceP1, setPerformanceP1] = useState({
        WARNING: "",
        LINE: "",
        SKU: "",
        FACTORY_DATE: "",
        SHIFT: "",
        TARGET: "",
        TARGET_UNIT: "",
        ACTUAL_WEIGHT: "",
        ACTUAL_WEIGHT_PERCENT: "",
        REMAINING: "",
        REMAINING_PERCENT: "",
        ACTUAL_FEED_RATE: "",
        STD_FEED_RATE: ""
    })

    const [performanceP2, setPerformanceP2] = useState({
        PRODUCTION_TIME: "",
        NON_PRODUCTION_TIME: "",
        FINISH_DATETIME: "",
        PRODUCTION_EFFICIENCY: ""
    })

    const [taglist, setTaglist] = useState([]);


    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.PERFORMANCE_LINE9_PAGE1}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        setPerformanceP1({
                            WARNING: response.data.WARNING,
                            LINE: response.data.LINE,
                            SKU: response.data.SKU,
                            FACTORY_DATE: response.data.FACTORY_DATE,
                            SHIFT: response.data.SHIFT,
                            TARGET: response.data.TARGET,
                            TARGET_UNIT: response.data.TARGET_UNIT,
                            ACTUAL_WEIGHT: response.data.ACTUAL_WEIGHT,
                            ACTUAL_WEIGHT_PERCENT: response.data.ACTUAL_WEIGHT_PERCENT,
                            REMAINING: response.data.REMAINING,
                            REMAINING_PERCENT: response.data.REMAINING_PERCENT,
                            ACTUAL_FEED_RATE: response.data.ACTUAL_FEED_RATE,
                            STD_FEED_RATE: response.data.STD_FEED_RATE
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()

        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
          clearInterval(interval);
        }

    }, []);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.PERFORMANCE_LINE9_PAGE2}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        setPerformanceP2({
                            PRODUCTION_TIME: response.data.PRODUCTION_TIME,
                            NON_PRODUCTION_TIME: response.data.NON_PRODUCTION_TIME,
                            FINISH_DATETIME: response.data.FINISH_DATETIME,
                            PRODUCTION_EFFICIENCY: response.data.PRODUCTION_EFFICIENCY
                        })
                        setNo_data_p2(1)
                    }
                })
                .catch(error => {
                    setNo_data_p2(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()

        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
          clearInterval(interval);
        }

    }, []);

    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_line_monitoring_tag_list")
            .where("TAG", "==", "ACCUMURATE_WEIGHT")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setTaglist(getPostFromFirebase);
            });

        // return cleanup function
        return () => subscriber();
    }, [taglist]);

    var accumurate_weight = taglist.find((user) => user.TAG.includes("ACCUMURATE_WEIGHT"));

    ///// split time non production time
    var non_production_time = no_data_p2 == 1 ? (performanceP2.NON_PRODUCTION_TIME != 'NA' ? (performanceP2.NON_PRODUCTION_TIME).split(":") : "00:00") : "00:00"

    var non_production_hour = Number(non_production_time[0])

    var non_production_minute = Number(non_production_time[1])

    ///// split time  production time
    var production_time = no_data_p2 == 1 ? (performanceP2.PRODUCTION_TIME != 'NA' ? (performanceP2.PRODUCTION_TIME).split(":") : "00:00") : "00:00"

    var production_hour = Number(production_time[0])

    var production_minute = Number(production_time[1])


    const getChartColor = () => {
        let color;
        try {
            if ((no_data == 1 ? (performanceP1.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP1.ACTUAL_WEIGHT) : 0) >= (((((no_data_p2 == 1 ? production_hour : 0) + ((no_data_p2 == 1 ? production_minute : 0) / 60)) + ((no_data_p2 == 1 ? non_production_hour : 0) + ((no_data_p2 == 1 ? non_production_minute : 0) / 60))) * (no_data == 1 ? (performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1.STD_FEED_RATE) : 0)) * 1)) {
                color = "#2e9281"
            } else if ((no_data == 1 ? (performanceP1.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP1.ACTUAL_WEIGHT) : 0) >= (((((no_data_p2 == 1 ? production_hour : 0) + ((no_data_p2 == 1 ? production_minute : 0) / 60)) + ((no_data_p2 == 1 ? non_production_hour : 0) + ((no_data_p2 == 1 ? non_production_minute : 0) / 60))) * (no_data == 1 ? (performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1.STD_FEED_RATE) : 0)) * 0.8)) {
                color = "#b58514"
            } else if ((no_data == 1 ? (performanceP1.ACTUAL_WEIGHT == 'NA' ? 0 : performanceP1.ACTUAL_WEIGHT) : 0) < (((((no_data_p2 == 1 ? production_hour : 0) + ((no_data_p2 == 1 ? production_minute : 0) / 60)) + ((no_data_p2 == 1 ? non_production_hour : 0) + ((no_data_p2 == 1 ? non_production_minute : 0) / 60))) * (no_data == 1 ? (performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? 0 : performanceP1.STD_FEED_RATE) : 0)) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }






    const getFeedrateColor = () => {
        let color;
        try {
            if ((no_data_p2 == 1 ? (performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) : 0) >= ((no_data == 1 ? ((performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP1.STD_FEED_RATE) : 0) * 1)) {
                color = "#2e9281"
            } else if ((no_data_p2 == 1 ? (performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) : 0) >= ((no_data == 1 ? ((performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP1.STD_FEED_RATE) : 0) * 0.8)) {
                color = "#b58514"
            } else if ((no_data_p2 == 1 ? (performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 0 : performanceP2.PRODUCTION_EFFICIENCY) : 0) < ((no_data == 1 ? ((performanceP1.STD_FEED_RATE == 'NA' || performanceP1.STD_FEED_RATE == 'ไม่มีข้อมูล Std. Cap ในระบบ') ? 0 : performanceP1.STD_FEED_RATE) : 0) * 0.8)) {
                color = "#7d2e1e"
            }
            return color;
        }
        catch {
            console.log()
        }
    }



    return (
        <>
            <Helmet>
                <title>Production Tracking Line10 Page1 | MES</title>
            </Helmet>
            <Grid item xs={12}>
                <HeaderDetail
                    line={no_data === 1 ? (performanceP1.LINE == 'NA' ? 'Line10' : performanceP1.LINE) : 'Line10'}
                    sku={no_data === 1 ? (performanceP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : performanceP1.WARNING == 'ไม่มีข้อมูล Std. Cap ในระบบ' ? performanceP1.SKU : performanceP1.SKU) : '-'}
                    timestr={day}
                    icon={performanceP1.SHIFT === "N" ? (<DarkModeOutlinedIcon style={{ color: '#ffffff' }} />) : (<LightModeOutlinedIcon style={{ color: '#ffffff' }} />)}
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <Item>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                        <Typography style={{ color: '#b58514', fontSize: 20, lineHeight: "0px", fontWeight: 'bold' }} >
                            <img src={Target} height="60px" />
                        </Typography>
                        <Typography style={{ color: colors.green[100], fontSize: 45, fontWeight: 'bold', lineHeight: "0px", paddingTop: 5 }} >
                            &nbsp;เป้าหมาย&nbsp;
                        </Typography>
                        <Typography style={{ color: colors.green[100], fontSize: 55, lineHeight: "0px", fontWeight: 'bold' }} >
                            &nbsp;{no_data === 1 ? (performanceP1.TARGET).toLocaleString() : 0 }&nbsp;
                        </Typography>
                        <Typography style={{ color: colors.green[100], fontSize: 35, lineHeight: "0px", fontWeight: 'bold', paddingTop: 10 }} >
                            kg
                        </Typography>
                    </Box>
                </Item>
            </Grid>

            <Grid item xs={12} sm={12}>
                <ItemTarget>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
                        <GrigToProductionTracking
                            color={performanceP1.ACTUAL_WEIGHT === 'NA' ? colors.green[100] : getChartColor()}
                            image={accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Fighting} height="50vh" /> : <img src={Fist} height="50vh" />) : <img src={Fist} height="50vh" />}
                            section={'ทำได้'}
                            persent={no_data === 1 ? performanceP1.ACTUAL_WEIGHT_PERCENT : 0}
                            color_persent={performanceP1.ACTUAL_WEIGHT === 'NA' ? colors.green[100] : getChartColor()}
                            value={no_data === 1 ? (performanceP1.ACTUAL_WEIGHT).toLocaleString() : 0}
                            unit={'กก.'}
                        />

                        <GrigToProductionTracking
                            color={'#4a4a49'}
                            image={accumurate_weight?.IS_RUNNING != undefined ? (accumurate_weight?.IS_RUNNING == true ? <img src={Running} height="50vh" /> : <img src={Run} height="50vh" />) : <img src={Run} height="50vh" />}
                            section={'คงเหลือ'}
                            persent={no_data === 1 ? (performanceP1.ACTUAL_WEIGHT_PERCENT === 'NA' ? 'NA' : 100 - performanceP1.ACTUAL_WEIGHT_PERCENT) : 0}
                            color_persent={colors.grey[100]}
                            value={no_data === 1 ? (performanceP1.REMAINING).toLocaleString() : 0}
                            unit={'กก.'}
                        />
                    </Grid>

                    <Grid item xs={12} textAlign="left" style={{ paddingRight: 0, paddingLeft: 0 }}>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'center' }}>
                                    <Typography style={{ color: getFeedrateColor(), fontSize: 45, lineHeight: "15vh" }}>
                                        {no_data_p2 === 1 ? (performanceP2.PRODUCTION_EFFICIENCY == 'NA' ? 'NA' : (Math.round(performanceP2.PRODUCTION_EFFICIENCY)).toLocaleString()) : 0}
                                    </Typography>
                                    <Typography style={{ color: '#ffffff', fontSize: 20, paddingTop: 4, lineHeight: "15vh" }}>
                                        &nbsp;กก./ชม.&nbsp;({no_data === 1 ? (performanceP1.STD_FEED_RATE == "ไม่มีข้อมูล Std. Cap ในระบบ" ? (performanceP1.STD_FEED_RATE).toLocaleString() : "Std." + (performanceP1.STD_FEED_RATE).toLocaleString()) : "Std." + 0})
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </ItemTarget>
            </Grid>








        </>
    )
}

export default ProductionLine10P1
