import React from 'react'
import PropTypes from 'prop-types'

function RemainingProductionPage2(props) {
    let accum_target = 0
    props.data.map(index => (
        accum_target += index.TARGET
    ))
    let accum_actual = 0
    props.data.map(index => (
        accum_actual += index.ACTUAL_WEIGHT
    ))
  return (
    <>
      {(Math.round((accum_target-accum_actual) * 100) / 100).toFixed(2)}
    </>
  )
}

RemainingProductionPage2.propTypes = {

}

export default RemainingProductionPage2

