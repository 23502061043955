import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import axios from 'axios';
import { apiUrl } from "../constants";
import JSMpeg from "@cycjimmy/jsmpeg-player"



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    border: '0.5px dashed #4A4A49',
    borderRadius: 10,
    height: '100%'

}));



export default function RtspStream() {
    useEffect(() => {
        const url = 'ws://127.0.0.1:9999'
        let canvas = document.getElementById("video-canvas")
        new JSMpeg.Player(url, { canvas: canvas })
    }, [])

    const rtspurl = ""//enter the rtsp url here

    const httpRequest = (url) => {
        axios.get(`http://127.0.0.1:3002/stream?rtsp=${url}`)
    }






    return (
        <>
            <Helmet>
                <title>RTSP | MES Korat Fur2</title>
            </Helmet>
            <Grid
                container spacing={1}
                direction="row"
                alignItems="top"
                justifyContent="center"
                style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 15, paddingRight: 10, paddingTop: 45 }}
            >
                {/* Top row */}


                <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }}>
                    <Grid item xs={6}>
                        <Item>
                            <Grid item xs={12} textAlign="left" >
                                <div>
                                    <canvas id="video-canvas" style={{height: '300px'}}></canvas>
                                </div>
                                
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>

            </Grid>

        </>
    );
}