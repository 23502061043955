import React from 'react'
import { Box } from "@mui/material";
import Chart from 'react-apexcharts';

function BarChartStatusMachine(props) {
    let data_series = []
    let color = []
//     props.data.map(index => (
//       data_series.push({"name": index.MATERIAL_DESCRIPTION, "data": [index.ACTUAL_WEIGHT]})
//   ))
    for (let i = 0; i < props.data.length; i++) {
        if (props.data[i].name == 'Offline'){
            data_series.push({"name": props.data[i].name, "data": [props.data[i].data]})
            color.push('#ffffff')
        }
        if (props.data[i].name == 'Standby'){
            data_series.push({"name": props.data[i].name, "data": [props.data[i].data]})
            color.push('#9e9e9e')
        }
        if (props.data[i].name == 'Running') {
            data_series.push({"name": props.data[i].name, "data": [props.data[i].data]})
            color.push('#4caf50')
        }
        if (props.data[i].name == 'Stop') {
            data_series.push({"name": props.data[i].name, "data": [props.data[i].data]})
            color.push('#f44336')
        }
    }

  return (
    <>
        <Box mb={-4} ml={1} style={{ position: "relative", display:"block", top: "-20px" }}>
        <Chart
                options = {{
                    chart: {
                        type: 'bar',
                        stacked: 'true',
                        // stackType: '100%',
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                        },
                    plotOptions: {
                        bar: {
                        horizontal: true,
                        
                        }
                    },
                    dataLabels: {
                            enabled: true,
                            enabledOnSeries: undefined,
                            formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                                return w.config.series[seriesIndex].data + "m"
                            },
                            textAnchor: 'middle',
                            style: {
                                colors: ['#000000']
                            },
                            distributed: false,
                            offsetX: 0,
                            offsetY: 0,
                            total: {
                                enabled: true,
                                offsetX: 0,
                                style: {
                                fontSize: '10px',
                                fontWeight: 900,
                                alignItems: 'left',
                                fontFamily: 'CPF_Imm_Sook'
                                }
                            }
                        },
                    colors: color,
                    legend: {
                        show: false,
                        showForSingleSeries: true,
                        customLegendItems: ['Offline', 'Standby', 'Running', 'Stop'],
                        markers: {
                        fillColors: ['#fafafa', '#9e9e9e', '#4caf50', '#f44336']
                        }
                    },
                    tooltip: {
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                            var name = w.globals.initialSeries[seriesIndex].name
        
                            return (
                            '<div class="callout" >' +
                            '<div class="callout-header">' + name + '</div>' +
                                '<div class="container">' +
                                '<b>Time</b>: ' + data + "m"  + '<br/>' +
                                '</div>' +
                            '</div>' 
                            )
                            },
                            theme: 'dark',
                            fill: {
                            opacity: 20
                            },
                            fixed: {
                                enabled: true,
                                position: 'top',
                                offsetX: 0,
                                offsetY: -30,
                            },
                    },
                    xaxis: {
                        type: 'category',
                        categories: [],type: 'category',
                        categories: [],
                        range: props.data,
                        min: 0,
                        max: props.data,
                        show: false,
                        labels: {
                        show: false
                        },
                        axisBorder: {
                        show: false
                        },
                        axisTicks: {
                        show: false
                        },
                    },
                    yaxis: {
                        axisBorder: {
                        show: false
                        },
                        axisTicks: {
                        show: false,
                        },
                        labels: {
                        show: false,
                        },
                    },
                    grid: {
                        padding: {
                        left: -16
                        }
                    },
                    
                    }}
            
                series={props.data}
                type='bar'
                width='50%'
                height={props.height}
            />
        </Box>
    </>
  )
}

BarChartStatusMachine.propTypes = {}

export default BarChartStatusMachine
