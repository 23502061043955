import React from 'react'
import { Box, Grid, Typography } from "@mui/material";
import Chart from 'react-apexcharts';

function BarChartAccumulativeProduction(props) {

  return (
    <>
        <Box width="100%" mb={-7} ml={3} style={{ position: "relative", top: "-40px" }}>
            <Chart
                options = {{
                    chart: {
                        type: 'bar',
                        stacked: 'true',
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                        },
                    plotOptions: {
                        bar: {
                        horizontal: true,
                        dataLabels: {
                            enabled: true,
                            enabledOnSeries: undefined,
                            formatter: function (val, opts) {
                                return val.toFixed(2)
                            },
                            textAnchor: 'start',
                            distributed: false,
                            offsetX: 0,
                            offsetY: 0,
                            total: {
                                enabled: false,
                                offsetX: 0,
                                style: {
                                fontSize: '10px',
                                fontWeight: 900,
                                alignItems: 'left',
                                fontFamily: 'CPF_Imm_Sook'
                                }
                            }
                        }
                        }
                    },
                    colors: ['#00E396', '#e0e0e0'],
                    legend: {
                        show: false,
                        showForSingleSeries: true,
                        customLegendItems: ['Actual', 'Expected'],
                        markers: {
                        fillColors: ['#00E396', '#775DD0']
                        }
                    },
                    tooltip: {
                        enabled: false,
                      },
                    xaxis: {
                        type: 'category',
                        categories: [],type: 'category',
                        categories: [],
                        range: props.target_quantity,
                        min: 0,
                        max: props.target_quantity > props.actual_weight? props.target_quantity: props.actual_weight,
                        show: false,
                        labels: {
                        show: false
                        },
                        axisBorder: {
                        show: false
                        },
                        axisTicks: {
                        show: false
                        },
                    },
                    yaxis: {
                        axisBorder: {
                        show: false
                        },
                        axisTicks: {
                        show: false,
                        },
                        labels: {
                        show: false,
                        },
                    },
                    grid: {
                        padding: {
                        left: -16
                        }
                    },
                    annotations: {
                        xaxis: [
                            {
                                x: props.target_now,
                                strokeDashArray: 0,
                                borderColor: "#775DD0",
                                label: {
                                borderColor: "#775DD0",
                                borderWidth: 2,
                                borderRadius: 20,
                                style: {
                                    color: "#fff",
                                    background: "#775DD0",
                                    fontFamily: 'CPF_Imm_Sook'
                                },
                                orientation: "horizontal",
                                text: props.target_now
                                }
                            },
                        ]
                    }
                    }}
            
                series={[
                            {
                                name: 'Actual',
                                data: [(Math.round(props.actual_weight * 100)/100).toFixed(2)]
                            }, 
                            {
                                name: 'Waiting',
                                data: [(Math.round((props.target_quantity - props.actual_weight < 0? 0: props.target_quantity - props.actual_weight) * 100)/100).toFixed(2)] 
                            }
                        ]}
                type='bar'
                width='100%'
                height={props.height}
            />
        </Box>
        <Box width="100%" ml={3} mr={3}>
            <Grid container>
            <Grid item xs={6}>
                <Typography color="black" fontSize={'14px'} align='left'>
                Actual {[Math.round(props.actual_weight / props.target_quantity * 100)] != 'NaN' ? [Math.round(props.actual_weight / props.target_quantity * 100)] : 0} % &nbsp;
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color="black" fontSize={'14px'} align='right'>
                Target {props.target_quantity} {props.unit}
                </Typography>
            </Grid>
            </Grid>
        </Box>
    </>
  )
}

BarChartAccumulativeProduction.propTypes = {}

export default BarChartAccumulativeProduction
