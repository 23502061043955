import React from 'react'
import { Box } from "@mui/material";
import Chart from 'react-apexcharts';

function BarChartAccumulativeSKUProduction(props) {
    let data_series = []
    let color = []
//     props.data.map(index => (
//       data_series.push({"name": index.MATERIAL_DESCRIPTION, "data": [index.ACTUAL_WEIGHT]})
//   ))
    for (let i = 0; i < props.data.length; i++) {
        if (props.data[i].ACTUAL_WEIGHT != 0){
            data_series.push({"name": '', "data": [props.data[i].ACTUAL_WEIGHT]})
            color.push('#00E396')
        }
        if (props.data[i].ACTUAL_WEIGHT == 0){
            data_series.push({"name": props.data[i].MATERIAL_DESCRIPTION, "data": [props.data[i].TARGET]})
            color.push('#e0e0e0')
        }
        if (props.data[i].ACTUAL_WEIGHT != 0 && props.data[i].ACTUAL_WEIGHT < props.data[i].TARGET) {
            data_series.push({"name": props.data[i].MATERIAL_DESCRIPTION, "data": [props.data[i].TARGET - props.data[i].ACTUAL_WEIGHT]})
            color.push('#fff9c4')
        }
    }
    let accum_target = 0
    props.data.map(index => (
        accum_target += index.TARGET
    ))
    let accum_actual = 0
    props.data.map(index => (
        accum_actual += index.ACTUAL_WEIGHT
    ))
  return (
    <>
        <Box mb={-4} ml={1} style={{ position: "relative", display:"block", top: "-20px" }}>
            <Chart
                options = {{
                    chart: {
                        type: 'bar',
                        stacked: 'true',
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                        },
                    plotOptions: {
                            bar: {
                            horizontal: true,
                        }
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    colors: color,
                    dataLabels: {
                        enabled: true,
                        textAnchor: 'middle',
                        style: {
                            colors: ['#616161']
                        },
                        formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                            return w.config.series[seriesIndex].name
                        },
                        offsetX: 0,
                        dropShadow: {
                            enabled: false
                        },
                        distributed: true,
                        offsetX: 0,
                        offsetY: 0,
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                            fontSize: '13px',
                            fontWeight: 900,
                            alignItems: 'left',
                            fontFamily: 'CPF_Imm_Sook'
                            }
                        }
                },
                    legend: {
                        show: false,
                        showForSingleSeries: true,
                        customLegendItems: ['Actual', 'Expected'],
                        markers: {
                        fillColors: ['#00E396', '#775DD0']
                        }
                    },
                    tooltip: {
                        enabled: false,
                      },
                    xaxis: {
                        type: 'category',
                        categories: [],type: 'category',
                        categories: [],
                        range: accum_target,
                        min: 0,
                        max: accum_target > accum_actual? accum_target: accum_actual,
                        show: false,
                        labels: {
                        show: false
                        },
                        axisBorder: {
                        show: false
                        },
                        axisTicks: {
                        show: false
                        },
                    },
                    yaxis: {
                        axisBorder: {
                        show: false
                        },
                        axisTicks: {
                        show: false,
                        },
                        labels: {
                        show: false,
                        },
                    },
                    grid: {
                        padding: {
                        left: -16
                        }
                    },
                    annotations: {
                        xaxis: [
                            {
                                x: props.target_now,
                                strokeDashArray: 0,
                                borderColor: "#775DD0",
                                label: {
                                borderColor: "#775DD0",
                                borderWidth: 2,
                                borderRadius: 20,
                                style: {
                                    color: "#fff",
                                    background: "#775DD0",
                                    fontFamily: 'CPF_Imm_Sook'
                                },
                                orientation: "horizontal",
                                text: props.target_now
                                }
                            },
                        ]
                    }
                    }}
            
                series={data_series}
                type='bar'
                width='100%'
                height={props.height}
            />
        </Box>
    </>
  )
}

BarChartAccumulativeSKUProduction.propTypes = {}

export default BarChartAccumulativeSKUProduction
